import { useEffect, useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import MainHeader from 'components/modules/main_header'
import { getPlatform, updatePlatforms } from 'actions/platforms'
import { useDispatch, useSelector } from 'react-redux'
import { CombineReducers } from 'models'
import { PlatformsState } from 'models/platforms'
import { useHistory, useParams } from 'react-router-dom'

const Platform: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const platformsState: PlatformsState = useSelector(
    (state: CombineReducers) => state.platforms
  )

  const [styles, setStyles] = useStyles({
    page: 'components/platforms/detail',
  })

  const [version, setVersion] = useState<string>()

  const submitHandler = () => {
    version && dispatch(updatePlatforms.started({ id: parseInt(id), version }))
  }

  useEffect(() => {
    dispatch(getPlatform.started({ id: parseInt(id) }))
    if (platformsState.status.succeededUpdatePlatform) history.goBack()
  }, [platformsState.status.succeededUpdatePlatform])

  if (!styles) return <></>

  return (
    <>
      <MainHeader heading="バージョン管理" />
      <div className={styles.default.platform_component}>
        <div className={styles.default.platform_name}>
          <span>プラットフォーム</span>
          <span>{platformsState.platform?.name}</span>
        </div>
        <div className={styles.default.platform_version}>
          <span>バージョン</span>
          <span>現在: {platformsState.platform?.version}</span>
          <input
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setVersion(event.target.value)
            }
          />
        </div>
        <div className={styles.default.submit}>
          <button onClick={submitHandler}>更新</button>
        </div>
      </div>
    </>
  )
}

export default Platform
