import React, { useEffect } from 'react'
import moment from 'moment'
import { useHistory, useParams, RouteComponentProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useStyles } from 'hooks/useStyles'
import {
  getContributorMessage,
  deleteContributorMessage,
} from 'actions/messages'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import { MessageContent, MessagesState } from 'models/messages'
import { getContributor } from 'actions/contributors'
import DetailHeader from 'components/messages/detail/components/DetailHeader'
import MessageDate from 'components/messages/detail/components/MessageDate'
import MessageContentComponent from 'components/messages/detail/components/MessageContent'
import './index.css'

type MessageDetailProps = RouteComponentProps<{
  id: string
  message_id: string
}>

const Message: React.FC<MessageDetailProps> = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/messages/detail',
  })
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const messagesState: MessagesState = useSelector((state: CombineReducers) => {
    return state.messages
  })

  const { message_id } = props.match.params
  const message = messagesState.currentContributorMessage
  const contributor = contributorsState.currentContributor
  const params = useParams<{
    id: string
  }>()

  useEffect(() => {
    dispatch(
      getContributorMessage.started({
        id: Number(message_id),
      })
    )
  }, [])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  const parseDateTime = (datetime: string) => {
    return moment(new Date(datetime)).format('YYYY/MM/DD HH:mm')
  }

  const deleteMessageHandler = (messageId: number, closeModal: () => void) => {
    dispatch(deleteContributorMessage.started({ id: messageId }))
    history.goBack()
    closeModal()
  }

  if (!styles || !contributor || !message) return <></>

  return (
    <>
      <DetailHeader
        deleteHandler={deleteMessageHandler}
        targetId={Number(message_id)}
      />
      <div className="messages_detail_body_wrapper">
        <div className="messages_detail_body">
          <MessageDate
            deliveryDate={parseDateTime(message.runAt || message.createdAt)}
          />
          {message.messageContents &&
            message.messageContents.data.map(
              (message_content: MessageContent) => {
                if (message_content.contentType === 'text')
                  return (
                    <div key={message_content.id}>
                      <MessageContentComponent
                        type={message_content.contentType}
                        value={message.text}
                        liked={message_content.likes}
                      />
                    </div>
                  )
                if (message_content.contentType === 'voice')
                  return (
                    <div key={message_content.id}>
                      <MessageContentComponent
                        type={message_content.contentType}
                        value={message_content.voice}
                        liked={message_content.likes}
                      />
                    </div>
                  )
                if (message_content.contentType === 'image')
                  return (
                    <div key={message_content.id}>
                      <MessageContentComponent
                        type={message_content.contentType}
                        value={message_content.image}
                        liked={message_content.likes}
                      />
                    </div>
                  )
                if (message_content.contentType === 'video')
                  return (
                    <div key={message_content.id}>
                      <MessageContentComponent
                        type={message_content.contentType}
                        value={message_content.video}
                        liked={message_content.likes}
                      />
                    </div>
                  )

                return <></>
              }
            )}
        </div>
      </div>
    </>
  )
}

export default Message
