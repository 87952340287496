import { useEffect, useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import moment from 'moment'
import {
  getAppMaintenanceWindows,
  getCMSMaintenanceWindows,
  createMaintenanceWindow,
  deleteMaintenanceWindow,
} from 'actions/maintenances'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import { CombineReducers } from 'models'
import { MaintenancesState, MaintenanceWindow } from 'models/maintenances'
import Toast from 'components/modules/toast'
import Button from 'components/modules/button'

const MaintenanceWindows: React.FC = () => {
  const dispatch = useDispatch()
  const maintenancesState: MaintenancesState = useSelector(
    (state: CombineReducers) => state.maintenances
  )

  const [styles, setStyles] = useStyles({
    page: 'components/maintenance_windows/list',
  })

  const [selectedPlatform, setSelectedPlatform] = useState<string>('cms')
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [platform, setPlatform] = useState<string>('app')
  const [windowStartDate, setWindowStartDate] = useState<string>()
  const [windowStartTime, setWindowStartTime] = useState<string>()
  const [windowEndDate, setWindowEndDate] = useState<string>()
  const [windowEndTime, setWindowEndTime] = useState<string>()
  const [announcedAtDate, setAnnouncedAtDate] = useState<string>()
  const [announcedAtTime, setAnnouncedAtTime] = useState<string>()

  const submitHandler = () => {
    if (
      !title ||
      !description ||
      !platform ||
      !windowStartDate ||
      !windowStartTime ||
      !windowEndDate ||
      !windowEndTime ||
      !announcedAtDate ||
      !announcedAtTime
    )
      return

    dispatch(
      createMaintenanceWindow.started({
        title,
        body: description,
        platform,
        windowStart: `${windowStartDate} ${windowStartTime}`,
        windowEnd: `${windowEndDate} ${windowEndTime}`,
        announcedAt: `${announcedAtDate} ${announcedAtTime}`,
      })
    )
  }

  const removeMaintenanceWindowHandler = (id: number) => {
    dispatch(deleteMaintenanceWindow.started({ id }))
  }

  useEffect(() => {
    dispatch(getCMSMaintenanceWindows.started())
  }, [
    maintenancesState.status.succeededCreateMaintenanceWindow,
    maintenancesState.status.succeededDeleteMaintenanceWindow,
  ])

  if (!styles) return <></>

  return (
    <>
      <Toast body={maintenancesState.success.message} error={false} />
      <Toast body={maintenancesState.error.message} error={true} />
      <MainHeader heading="メンテナンス管理">
        <Button
          label={'プラットフォーム切替'}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() => {
            const nextPlatform = selectedPlatform === 'cms' ? 'app' : 'cms'
            setSelectedPlatform(nextPlatform)
            if (nextPlatform === 'app') {
              dispatch(getAppMaintenanceWindows.started())
            } else {
              dispatch(getCMSMaintenanceWindows.started())
            }
          }}
        />
      </MainHeader>
      <div className={styles.default.maintenance_windows_component}>
        <div className={styles.default.mw_for_cms}>
          <div className={styles.default.label}>
            <span>メンテナンスウィンドウ</span>
          </div>
          <MainTable
            columns={{
              scale: '20% 20% 20% 20% 20%',
              items: [
                { label: 'プラットフォーム', grid: '1 / 2' },
                { label: '日付', grid: '2 / 3' },
                { label: '開始時間', grid: ' 3 / 4' },
                { label: '終了時間', grid: '4 / 5' },
                { label: '表示開始日時', grid: '5 / 6' },
              ],
            }}
            items={maintenancesState.maintenanceWindows.data.map(
              (maintenance_window: MaintenanceWindow) => ({
                id: maintenance_window.id,
                data: {
                  platform: {
                    id: maintenance_window.id,
                    value: maintenance_window.platform,
                    clickable: false,
                    subLabel: false,
                    grid: '1 / 2',
                  },
                  maintenance_date: {
                    id: maintenance_window.id,
                    value: maintenance_window.maintenanceDate,
                    clickable: false,
                    subLabel: false,
                    grid: '2 / 3',
                  },
                  maintenance_starts_at: {
                    id: maintenance_window.id,
                    value: maintenance_window.maintenanceStartsAt,
                    clickable: false,
                    subLabel: false,
                    grid: '3 / 4',
                  },
                  maintenance_ends_at: {
                    id: maintenance_window.id,
                    value: maintenance_window.maintenanceEndsAt,
                    clickable: false,
                    subLabel: false,
                    grid: '4 / 5',
                  },
                  maintenance_announced_at: {
                    id: maintenance_window.id,
                    value: maintenance_window.maintenanceAnnouncedAt,
                    clickable: false,
                    subLabel: false,
                    grid: '5 / 6',
                  },
                },
              })
            )}
            selectItemHandler={() => null}
            option={{
              svg: Bucket,
              clickHandler: (targetId: number) =>
                removeMaintenanceWindowHandler(targetId),
            }}
          />
          <div className={styles.default.label}>
            <span>メンテナンスウィンドウ作成</span>
          </div>
          <div className={styles.default.create_mw}>
            <div className={styles.default.form_row}>
              <span>タイトル</span>
              <div className={styles.default.input}>
                <input
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTitle(event.target.value)
                  }
                />
              </div>
            </div>
            <div className={styles.default.form_row}>
              <span>説明文</span>
              <div className={styles.default.input}>
                <input
                  type="text"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setDescription(event.target.value)
                  }
                />
              </div>
            </div>
            <div className={styles.default.form_row}>
              <span>プラットフォーム</span>
              <div className={styles.default.input}>
                <select
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                    setPlatform(event.target.value)
                  }
                >
                  <option value="app">app</option>
                  <option value="cms">cms</option>
                </select>
              </div>
            </div>
            <div className={styles.default.form_row}>
              <span>メンテナンス開始</span>
              <div className={styles.default.input}>
                <input
                  type="date"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setWindowStartDate(
                      moment(event.target.value).format('YYYY/MM/DD')
                    )
                  }}
                />
                <input
                  type="time"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setWindowStartTime(event.target.value)
                  }}
                />
              </div>
            </div>
            <div className={styles.default.form_row}>
              <span>メンテナンス終了</span>
              <div className={styles.default.input}>
                <input
                  type="date"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setWindowEndDate(
                      moment(event.target.value).format('YYYY/MM/DD')
                    )
                  }}
                />
                <input
                  type="time"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setWindowEndTime(event.target.value)
                  }}
                />
              </div>
            </div>
            <div className={styles.default.form_row}>
              <span>メンテナンスウィンドウ表示開始</span>
              <div className={styles.default.input}>
                <input
                  type="date"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAnnouncedAtDate(
                      moment(event.target.value).format('YYYY/MM/DD')
                    )
                  }}
                />
                <input
                  type="time"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAnnouncedAtTime(event.target.value)
                  }}
                />
              </div>
            </div>
            <div className={styles.default.form_submit}>
              <button onClick={submitHandler}>作成</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MaintenanceWindows
