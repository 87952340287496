import React, { useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { useValidation } from 'hooks/useValidation'
import Template from 'components/auth/components/Template'
import FormText from 'components/auth/components/FormText'
import FormInput from 'components/auth/components/FormInput'
import Button from 'components/auth/components/button'
import ErrorText from 'components/auth/components/ErrorText'
import { useChangePassword } from 'hooks/api/useChangePassword'

const ChangePassword: React.FC = () => {
  const [styles, setStyles] = useStyles({
    page: 'components/auth',
  })

  const [password, setPassword] = useState<string>()
  const [confirmationPassword, setConfirmationPassword] = useState<string>()
  const [passwordValidation, setPasswordValidation] = useValidation({
    type: 'password',
    value: password,
  })
  const [confirmationPasswordValidation, setConfirmationPasswordValidation] =
    useValidation({
      type: 'password',
      value: confirmationPassword,
    })

  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValidation('password', event.target.value)
    setPassword(event.target.value)
  }
  const confirmationPasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmationPasswordValidation('password', event.target.value)
    setConfirmationPassword(event.target.value)
  }
  const [changePassword] = useChangePassword({ password, confirmationPassword })
  const buttonActivationCheck = () => {
    if (
      passwordValidation?.type === 'error' ||
      confirmationPasswordValidation?.type === 'error' ||
      password !== confirmationPassword
    )
      return true
    return false
  }

  if (!styles) return <></>

  return (
    <Template label={'パスワードを変更する'} styles={styles}>
      {passwordValidation?.type === 'error' && (
        <ErrorText message={passwordValidation.message} styles={styles} />
      )}
      {confirmationPasswordValidation?.type === 'error' && (
        <ErrorText
          message={confirmationPasswordValidation.message}
          styles={styles}
        />
      )}
      {password !== confirmationPassword && (
        <ErrorText message={'パスワードが一致しません'} styles={styles} />
      )}
      <FormText
        text="パスワードは英字・数字を含む、8文字以上のもので登録してください。"
        styles={styles}
      />
      <FormInput
        label="新しいパスワード"
        type="password"
        placeholder="英数字を含む8文字のパスワード"
        onChangeHandler={passwordHandler}
        styles={styles}
      />
      <FormInput
        label="新しいパスワード（確認）"
        type="password"
        placeholder="英数字を含む8文字のパスワード"
        onChangeHandler={confirmationPasswordHandler}
        styles={styles}
      />
      <div className={styles.default.form_submit_btn_spacer} />
      <Button
        label="OK"
        onClickHandler={() => changePassword.mutate()}
        disabled={buttonActivationCheck()}
      />
    </Template>
  )
}

export default ChangePassword
