import { useStyles } from 'hooks/useStyles'
import { ReactComponent as LoadPreviousMessages } from 'images/load_previous_messages.svg'
import { ContributorMessages } from 'models/messages'

type Props = {
  messages?: ContributorMessages
  onClick: () => void
}

const MessageLoadButton: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/message_load_button',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.message_load_button_component}>
      {props.messages?.meta?.nextPage && props.messages?.meta?.nextPage > 0 ? (
        <button onClick={props.onClick}>
          <div className={styles.default.load_button}>
            <LoadPreviousMessages />
            <span>過去のトークを読み込む</span>
          </div>
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}

export default MessageLoadButton
