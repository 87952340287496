import MainHeader from 'components/modules/main_header'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { buildHeader } from 'services'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { DEFAULT_API_CONFIG, CombineReducers } from 'models'
import { useDispatch, useSelector } from 'react-redux'
import { SessionsState } from 'models/sessions'
import { getContributor } from 'actions/contributors'
import meetingReservationsDeserializer from 'deserializers/meeting_reservations'
import MainTable from 'components/modules/main_table'
import { MeetingReservation } from 'models/meeting_reservations'
import ReactPaginate from 'react-paginate'

const MeetingReservations: React.FC = () => {
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const [page, setPage] = useState<number>(1)
  const count = 10

  const fetchMeetingReservations = useQuery('meetingReservations', async () => {
    const uri = `/api/cms/v1/meeting_reservations?contributor_id=${params.id}&page=${page}&count=${count}`
    const instance = axios.create({
      ...DEFAULT_API_CONFIG,
    })
    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    return meetingReservationsDeserializer({
      data: response.data.meeting_reservations,
      meta: response.data.meta,
    })
  })

  const selectItemHandler = (meetingReservationId: number) => {
    if (!fetchMeetingReservations.data) return

    const target = fetchMeetingReservations.data.data.find(
      (meetingReservation: MeetingReservation) =>
        meetingReservation.id === meetingReservationId
    )

    if (!target) return

    history.push(`/contributors/${params.id}/meetings/${target.listener.id}`)
  }

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id: parseInt(params.id),
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  if (!fetchMeetingReservations.data) return <></>

  return (
    <>
      <MainHeader heading={'通話リスト'} />
      <MainTable
        columns={{
          scale: '50% 50%',
          items: [
            { label: 'ユーザー名', grid: '1 / 2' },
            { label: '予約日時', grid: '2 / 3' },
          ],
        }}
        items={fetchMeetingReservations.data.data.map(
          (meetingReservation: MeetingReservation) => ({
            id: meetingReservation.id,
            data: {
              listener_name: {
                id: meetingReservation.id,
                value: meetingReservation.listener.name,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              reserved_date: {
                id: meetingReservation.id,
                value: meetingReservation.startAt,
                clickable: false,
                subLabel: false,
                grid: '2 / 3',
              },
            },
          })
        )}
        selectItemHandler={selectItemHandler}
      >
        <ReactPaginate
          pageCount={fetchMeetingReservations.data.meta.totalPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
          containerClassName={'pagination'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          previousClassName={'page-item'}
          previousLinkClassName={'page-link'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          activeClassName={'active'}
          onPageChange={(event: { selected: number }) =>
            setPage(event.selected + 1)
          }
          previousLabel={
            <div>
              <span>&lt;</span>
            </div>
          }
          nextLabel={
            <div>
              <span>&gt;</span>
            </div>
          }
          breakLabel={'...'}
        />
      </MainTable>
    </>
  )
}

export default MeetingReservations
