import { useState, useMemo } from 'react'
import {
  AudioVisualMessage,
  Validation,
  MessageValidations,
  EventBotMessageValues,
} from 'models/messages'

export function useButtonStatus(input: {
  values: EventBotMessageValues
  validations: MessageValidations
}): [
  executable: boolean,
  checkExecutable: (
    validations: MessageValidations,
    messageText: string,
    audioVisualMessage?: AudioVisualMessage
  ) => void
] {
  const [executable, setExecutable] = useState<boolean>(false)

  const checkMessageText = (
    validation: Validation,
    messageText: string,
    audioVisualMessage?: AudioVisualMessage
  ) => {
    if (messageText.length > 0) {
      return validation && validation.type === 'succeeded'
    } else if (audioVisualMessage) {
      return true
    } else {
      return false
    }
  }

  const checkAudioVisual = (
    type: string,
    validation: Validation,
    audioVisualMessage?: AudioVisualMessage
  ) => {
    if (audioVisualMessage) {
      if (audioVisualMessage.type === type) {
        return validation && validation.type === 'succeeded'
      } else {
        return true
      }
    }

    return true
  }

  const checkExecutable = (
    validations: MessageValidations,
    messageText: string,
    audioVisualMessage?: AudioVisualMessage
  ) => {
    setExecutable(
      checkMessageText(
        validations.messageTextValidation,
        messageText,
        audioVisualMessage
      ) &&
        checkAudioVisual(
          'message_image',
          validations.messageImageValidation,
          audioVisualMessage
        ) &&
        checkAudioVisual(
          'message_video',
          validations.messageVideoValidation,
          audioVisualMessage
        ) &&
        checkAudioVisual(
          'message_voice',
          validations.messageVoiceValidation,
          audioVisualMessage
        )
    )
  }

  useMemo(() => {
    checkExecutable(
      input.validations,
      input.values.messageText,
      input.values.audioVisualMessage
    )
    checkExecutable(
      input.validations,
      input.values.messageText,
      input.values.audioVisualMessage
    )
    checkExecutable(
      input.validations,
      input.values.messageText,
      input.values.audioVisualMessage
    )
    checkExecutable(
      input.validations,
      input.values.messageText,
      input.values.audioVisualMessage
    )
  }, [
    input.validations.messageTextValidation,
    input.validations.messageImageValidation,
    input.validations.messageVideoValidation,
    input.validations.messageVoiceValidation,
  ])

  return [executable, checkExecutable]
}
