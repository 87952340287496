import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getManualBotMessage,
  updateManualBotMessage,
} from 'actions/manual_bot_messages'
import MainHeader from 'components/modules/main_header'
import Button from 'components/modules/button'
import { ReactComponent as Create } from 'images/create.svg'
import { useContributor } from 'hooks/useContributor'
import MainForm from 'components/modules/main_form'
import TextInput from 'components/modules/main_form/components/text_input'
import SPButton from 'components/modules/main_form/components/button'
import SPButtonSpacer from 'components/modules/main_form/components/button/components/spacer'
import SPButtonWrapper from 'components/modules/main_form/components/button_wrapper'
import { useResponsive } from 'hooks/useResponsive'
import { useValues } from 'components/manual_bot_messages/create/hooks/use_values'
import { useConstructFormData } from 'components/manual_bot_messages/create/hooks/use_construct_form_data'
import { useValidations } from 'components/manual_bot_messages/create/hooks/use_validations'
import { useButtonStatus } from 'components/manual_bot_messages/create/hooks/use_button_status'
import {
  ManualBotMessage,
  ManualBotMessagesState,
} from 'models/manual_bot_messages'
import { CombineReducers } from 'models'
import { useEffect, useState } from 'react'

const EditManualBotMessage: React.FC = () => {
  const [responsive] = useResponsive()

  const history = useHistory()
  const params = useParams<{
    id: string
    manual_bot_message_id: string
  }>()

  const dispatch = useDispatch()
  const manualBotMessagesState: ManualBotMessagesState = useSelector(
    (state: CombineReducers) => {
      return state.manualBotMessages
    }
  )

  const [user, contributor] = useContributor({ queryString: params })
  const [values, setValue] = useValues()
  const [validations, setValidation] = useValidations()
  const [executable, checkExecutable] = useButtonStatus({ values, validations })
  const [formData, setFormData] = useConstructFormData()
  const [initialized, setInitialized] = useState<boolean>(false)

  const titleHandler = (value: string) => {
    setValidation('manual_bot_message_title', value)
    setValue('title', value)
    setFormData('title', value)
  }

  const descriptionHandler = (value: string) => {
    setValidation('manual_bot_message_body', value)
    setValue('body', value)
    setFormData('body', value)
  }

  const onClickHandler = () => {
    setFormData('id', params.manual_bot_message_id)
    dispatch(updateManualBotMessage.started({ formData }))
    history.push(`/contributors/${params.id}/manual_bot_messages`)
  }

  const setDefaultValues = (manualBotMessage: ManualBotMessage) => {
    const title = manualBotMessage.title
    const body = manualBotMessage.body
    setValidation('manual_bot_message_title', title)
    setValue('title', title)
    setFormData('title', title)
    setValidation('manual_bot_message_body', body)
    setValue('body', body)
    setFormData('body', body)
    setInitialized(true)
  }

  useEffect(() => {
    if (contributor) {
      const formData = new FormData()
      formData.append('id', params.manual_bot_message_id)
      dispatch(getManualBotMessage.started({ formData }))
    }
  }, [contributor])

  useEffect(() => {
    if (manualBotMessagesState.currentManualBotMessage && !initialized) {
      setDefaultValues(manualBotMessagesState.currentManualBotMessage)
    }
  }, [manualBotMessagesState.currentManualBotMessage])

  if (!user || !contributor) return <></>

  return (
    <>
      <MainHeader heading={'マニュアル bot 編集'}>
        <Button
          label={'保存する'}
          icon={Create}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={!executable}
          onClickHandler={onClickHandler}
        />
      </MainHeader>
      <MainForm>
        <TextInput
          label={'タイトル'}
          defaultValue={values.title}
          updateHandler={(value: string) => titleHandler(value)}
          wordCount={{ max: 20, current: values.title.length }}
          validation={validations.titleValidation}
        />
        <TextInput
          label={'メッセージ本文'}
          defaultValue={values.body}
          updateHandler={(value: string) => descriptionHandler(value)}
          wordCount={{ max: 500, current: values.body.length }}
          textarea
          validation={validations.bodyValidation}
        />
        {(responsive === 'small' || responsive === 'medium') && (
          <SPButtonWrapper>
            <SPButton
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid #E0E0E0'}
              disabled={false}
              onClickHandler={() =>
                history.push(
                  `/contributors/${params.manual_bot_message_id}/manual_bot_messages`
                )
              }
            />
            <SPButtonSpacer />
            <SPButton
              label={'保存する'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable}
              onClickHandler={onClickHandler}
            />
          </SPButtonWrapper>
        )}
      </MainForm>
    </>
  )
}

export default EditManualBotMessage
