import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/subscription_tiers/types'
import {
  getSubscriptionTier,
  getSubscriptionTiers,
  updateSubscriptionTier,
} from 'actions/subscription_tiers'
import { SubscriptionTier } from 'models/subscription_tiers'
import {
  getSubscriptionTiersFactory,
  getSubscriptionTierFactory,
  updateSubscriptionTierFactory,
} from 'services/subscription_tiers/api'

function* runGetSubscriptionTiers(
  action: ReturnType<typeof getSubscriptionTiers.started>
) {
  try {
    const subscriptionTiers: SubscriptionTier[] = yield call(
      getSubscriptionTiersFactory(),
      action.payload
    )

    yield put(
      getSubscriptionTiers.succeeded({ subscriptionTiers, message: '' })
    )
  } catch (err) {
    yield put(getSubscriptionTiers.failed({ message: '' }))
  }
}

function* runGetSubscriptionTier(
  action: ReturnType<typeof getSubscriptionTier.started>
) {
  try {
    const subscriptionTier: SubscriptionTier = yield call(
      getSubscriptionTierFactory(),
      action.payload
    )

    yield put(getSubscriptionTier.succeeded({ subscriptionTier, message: '' }))
  } catch (err) {
    yield put(getSubscriptionTier.failed({ message: '' }))
  }
}

function* runUpdateSubscriptionTier(
  action: ReturnType<typeof updateSubscriptionTier.started>
) {
  try {
    yield call(updateSubscriptionTierFactory(), action.payload)

    yield put(updateSubscriptionTier.succeeded({ message: '' }))
  } catch (err) {
    yield put(updateSubscriptionTier.failed({ message: '' }))
  }
}

export function* watchSubscriptionTiersSaga() {
  yield takeLatest(
    Action.STARTED_GET_SUBSCRIPTION_TIERS,
    runGetSubscriptionTiers
  )
  yield takeLatest(Action.STARTED_GET_SUBSCRIPTION_TIER, runGetSubscriptionTier)
  yield takeLatest(
    Action.STARTED_UPDATE_SUBSCRIPTION_TIER,
    runUpdateSubscriptionTier
  )
}
