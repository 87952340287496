import { MaintenancesAction } from 'actions/maintenances'
import * as ActionType from 'actions/maintenances/types'
import { MaintenancesState } from 'models/maintenances'

export const initialState: MaintenancesState = {
  maintenanceWindow: null,
  maintenanceWindows: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  status: {
    startedGetMaintenanceWindows: false,
    succeededGetMaintenanceWindows: false,
    failedGetMaintenanceWindows: false,
    startedCreateMaintenanceWindow: false,
    succeededCreateMaintenanceWindow: false,
    failedCreateMaintenanceWindow: false,
    startedDeleteMaintenanceWindow: false,
    succeededDeleteMaintenanceWindow: false,
    failedDeleteMaintenanceWindow: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const maintenanceReducer = (
  state: MaintenancesState = initialState,
  action: MaintenancesAction
): MaintenancesState => {
  switch (action.type) {
    case ActionType.STARTED_GET_APP_MAINTENANCE_WINDOWS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMaintenanceWindows: true,
          succeededGetMaintenanceWindows: false,
          failedGetMaintenanceWindows: false,
        },
      }
    case ActionType.SUCCEEDED_GET_APP_MAINTENANCE_WINDOWS:
      return {
        ...state,
        maintenanceWindows: action.payload.maintenance_windows,
        status: {
          ...state.status,
          startedGetMaintenanceWindows: false,
          succeededGetMaintenanceWindows: true,
          failedGetMaintenanceWindows: false,
        },
      }
    case ActionType.FAILED_GET_APP_MAINTENANCE_WINDOWS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMaintenanceWindows: false,
          succeededGetMaintenanceWindows: false,
          failedGetMaintenanceWindows: true,
        },
      }
    case ActionType.STARTED_GET_CMS_MAINTENANCE_WINDOWS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMaintenanceWindows: true,
          succeededGetMaintenanceWindows: false,
          failedGetMaintenanceWindows: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CMS_MAINTENANCE_WINDOWS:
      return {
        ...state,
        maintenanceWindows: action.payload.maintenance_windows,
        status: {
          ...state.status,
          startedGetMaintenanceWindows: false,
          succeededGetMaintenanceWindows: true,
          failedGetMaintenanceWindows: false,
        },
      }
    case ActionType.FAILED_GET_CMS_MAINTENANCE_WINDOWS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMaintenanceWindows: false,
          succeededGetMaintenanceWindows: false,
          failedGetMaintenanceWindows: true,
        },
      }
    case ActionType.STARTED_CREATE_MAINTENANCE_WINDOW:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateMaintenanceWindow: true,
          succeededCreateMaintenanceWindow: false,
          failedCreateMaintenanceWindow: false,
        },
      }
    case ActionType.SUCCEEDED_CREATE_MAINTENANCE_WINDOW:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateMaintenanceWindow: false,
          succeededCreateMaintenanceWindow: true,
          failedCreateMaintenanceWindow: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_CREATE_MAINTENANCE_WINDOW:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateMaintenanceWindow: false,
          succeededCreateMaintenanceWindow: false,
          failedCreateMaintenanceWindow: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_DELETE_MAINTENANCE_WINDOW:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteMaintenanceWindow: true,
          succeededDeleteMaintenanceWindow: false,
          failedDeleteMaintenanceWindow: false,
        },
      }
    case ActionType.SUCCEEDED_DELETE_MAINTENANCE_WINDOW:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteMaintenanceWindow: false,
          succeededDeleteMaintenanceWindow: true,
          failedDeleteMaintenanceWindow: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_DELETE_MAINTENANCE_WINDOW:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteMaintenanceWindow: false,
          succeededDeleteMaintenanceWindow: false,
          failedDeleteMaintenanceWindow: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_RESET_MESSAGES:
      return {
        ...state,
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_RESET_MESSAGES:
      return { ...state }
    case ActionType.FAILED_RESET_MESSAGES:
      return { ...state }
    case ActionType.STARTED_RESET_ALL_STATUS_FLAGS:
      return {
        ...state,
        status: {
          startedGetMaintenanceWindows: false,
          succeededGetMaintenanceWindows: false,
          failedGetMaintenanceWindows: false,
          startedCreateMaintenanceWindow: false,
          succeededCreateMaintenanceWindow: false,
          failedCreateMaintenanceWindow: false,
          startedDeleteMaintenanceWindow: false,
          succeededDeleteMaintenanceWindow: false,
          failedDeleteMaintenanceWindow: false,
        },
      }
    case ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    case ActionType.FAILED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    default:
      return state
  }
}

export default maintenanceReducer
