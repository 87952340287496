import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/manual_bot_messages/types'
import {
  getManualBotMessage,
  getManualBotMessages,
  createManualBotMessage,
  updateManualBotMessage,
  deleteManualBotMessage,
} from 'actions/manual_bot_messages'
import { ManualBotMessage, ManualBotMessages } from 'models/manual_bot_messages'
import {
  getManualBotMessageFactory,
  getManualBotMessagesFactory,
  createManualBotMessageFactory,
  updateManualBotMessageFactory,
  deleteManualBotMessageFactory,
} from 'services/manual_bot_messages/api'

function* runGetManualBotMessages(
  action: ReturnType<typeof getManualBotMessages.started>
) {
  try {
    const result: ManualBotMessages = yield call(
      getManualBotMessagesFactory(),
      action.payload
    )

    yield put(
      getManualBotMessages.succeeded({
        manualBotMessages: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getManualBotMessages.failed({ message: '' }))
  }
}

function* runGetManualBotMessage(
  action: ReturnType<typeof getManualBotMessage.started>
) {
  try {
    const result: ManualBotMessage = yield call(
      getManualBotMessageFactory(),
      action.payload
    )

    yield put(
      getManualBotMessage.succeeded({
        manualBotMessage: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getManualBotMessage.failed({ message: '' }))
  }
}

function* runCreateManualBotMessage(
  action: ReturnType<typeof createManualBotMessage.started>
) {
  try {
    yield call(createManualBotMessageFactory(), action.payload)

    yield put(
      createManualBotMessage.succeeded({
        message: 'マニュアル bot メッセージを作成しました',
      })
    )
  } catch (err) {
    yield put(
      createManualBotMessage.failed({
        message: 'マニュアル bot メッセージの作成に失敗しました',
      })
    )
  }
}

function* runUpdateManualBotMessageFactory(
  action: ReturnType<typeof updateManualBotMessage.started>
) {
  try {
    yield call(updateManualBotMessageFactory(), action.payload)

    yield put(
      updateManualBotMessage.succeeded({
        message: 'マニュアル bot メッセージを更新しました',
      })
    )
  } catch (err) {
    yield put(
      updateManualBotMessage.failed({
        message: 'マニュアル bot メッセージの更新に失敗しました',
      })
    )
  }
}

function* runDeleteManualBotMessage(
  action: ReturnType<typeof deleteManualBotMessage.started>
) {
  try {
    yield call(deleteManualBotMessageFactory(), action.payload)

    yield put(
      deleteManualBotMessage.succeeded({
        message: 'マニュアル bot メッセージを削除しました',
      })
    )
  } catch (err) {
    yield put(
      deleteManualBotMessage.failed({
        message: 'マニュアル bot メッセージの削除に失敗しました',
      })
    )
  }
}

export function* watchManualBotMessagesSaga() {
  yield takeLatest(
    Action.STARTED_GET_MANUAL_BOT_MESSAGES,
    runGetManualBotMessages
  )
  yield takeLatest(
    Action.STARTED_GET_MANUAL_BOT_MESSAGE,
    runGetManualBotMessage
  )
  yield takeLatest(
    Action.STARTED_CREATE_MANUAL_BOT_MESSAGE,
    runCreateManualBotMessage
  )
  yield takeLatest(
    Action.STARTED_UPDATE_MANUAL_BOT_MESSAGE,
    runUpdateManualBotMessageFactory
  )
  yield takeLatest(
    Action.STARTED_DELETE_MANUAL_BOT_MESSAGE,
    runDeleteManualBotMessage
  )
}
