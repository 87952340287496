import { useStyles } from 'hooks/useStyles'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import MainHeader from 'components/modules/main_header'
import { useResponsive } from 'hooks/useResponsive'
import Preview from 'components/modules/preview'
import PreviewMessageForm from 'components/modules/preview/components/message_form'
import { ReactComponent as Save } from 'images/save.svg'
import { updateContributorBotMessage } from 'actions/messages'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import MessageForm from 'components/modules/message_form'
import CreateIcon from 'images/create_message.png'
import MessageOptions from 'components/event_bot_messages/edit/components/message_options'
import { useContributor } from 'hooks/useContributor'
import { useEventBotMessage } from 'components/event_bot_messages/edit/hooks/use_event_bot_message'
import { useValues } from 'components/event_bot_messages/edit/hooks/use_values'
import { useConstructFormData } from 'components/event_bot_messages/edit/hooks/use_construct_form_data'
import { generateUrlFromFileV2 } from 'utils/file_manager'
import { useValidations } from 'components/event_bot_messages/edit/hooks/use_validations'
import { useDefaultPreviewContents } from 'components/event_bot_messages/edit/hooks/use_default_preview_contents'
import { useButtonStatus } from 'components/event_bot_messages/edit//hooks/use_button_status'

const EventBotMessage: React.FC = () => {
  const [styles, setStyles] = useStyles({
    page: 'components/event_bot_messages/edit',
  })

  const dispatch = useDispatch()

  const history = useHistory()
  const params = useParams<{
    id: string
    bot_message_id: string
  }>()

  const [user, contributor] = useContributor({ queryString: params })
  const [botMessage] = useEventBotMessage({ id: params.bot_message_id })
  const [values, setValue] = useValues({ message: botMessage })
  const [validations, setValidation] = useValidations({ message: botMessage })
  const [defaultPreviewContents, resetPreviewContents] =
    useDefaultPreviewContents({ message: botMessage })
  const [executable, checkExecutable] = useButtonStatus({ values, validations })
  const [formData, setFormData] = useConstructFormData()

  const [responsive] = useResponsive()

  const headerLabel = () => {
    if (contributor)
      return `この bot メッセージは ${contributor.name}（個人）のメッセージとして送信されます。`
    return ''
  }

  const defaultAudioVisualMessage = () => {
    if (Object.keys(defaultPreviewContents.image).length > 0)
      return defaultPreviewContents.image.previewBlob
    if (Object.keys(defaultPreviewContents.video).length > 0)
      return defaultPreviewContents.video.previewBlob
    if (Object.keys(defaultPreviewContents.voice).length > 0)
      return defaultPreviewContents.voice.previewBlob
  }

  const selectedTimeHandler = (time: string) => setValue('selected_time', time)

  const adaptVariableHandler = (variable: string) =>
    messageTextHandler(values.messageText + `(${variable})`)

  const messageTextHandler = (value: string) => {
    setValue('message_text', value)

    setValidation('message_text', value)
  }

  const uploadAudioVisualHandler = (type: string, blob: File) => {
    const previewUrl = generateUrlFromFileV2({ type, blob })
    if (type === 'image') {
      setValue('video_preview', undefined)
      setValue('voice_preview', undefined)
      if (previewUrl) setValue('image_preview', previewUrl)
      if (values.mediaRemoval === 'message_image')
        setValue('media_removal', undefined)
      setValidation('message_image', blob)
    }
    if (type === 'video') {
      setValue('image_preview', undefined)
      setValue('voice_preview', undefined)
      if (previewUrl) setValue('video_preview', previewUrl)
      if (values.mediaRemoval === 'message_video')
        setValue('media_removal', undefined)
      setValidation('message_video', blob)
    }
    if (type === 'voice') {
      setValue('image_preview', undefined)
      setValue('video_preview', undefined)
      if (previewUrl) setValue('voice_preview', previewUrl)
      if (values.mediaRemoval === 'message_voice')
        setValue('media_removal', undefined)
      setValidation('message_voice', blob)
    }
    setValue('audio_visual_message', { type, blob })
  }

  const addAudioVisualHandler = () =>
    setValue('audio_visual_message', { type: 'image', blob: undefined })

  const removeAudioVisualHandler = () => {
    if (Object.keys(defaultPreviewContents.image).length > 0)
      setValue('media_removal', 'message_image')
    if (Object.keys(defaultPreviewContents.video).length > 0)
      setValue('media_removal', 'message_video')
    if (Object.keys(defaultPreviewContents.voice).length > 0)
      setValue('media_removal', 'message_voice')
    setValue('audio_visual_message', undefined)
    resetPreviewContents()
    setValue('image_preview', undefined)
    setValue('video_preview', undefined)
    setValue('voice_preview', undefined)
  }

  const pageRollbackHandler = () => history.goBack()

  const sendMessageHandler = () => {
    if (!user || !contributor) return

    setFormData('contributor_id', String(contributor.id))
    setFormData('text', values.messageText)
    if (values.mediaRemoval) {
      setFormData('media_removal', values.mediaRemoval)
    }
    if (values.audioVisualMessage && values.audioVisualMessage.blob) {
      switch (values.audioVisualMessage.type) {
        case 'image':
          setFormData('message_image', values.audioVisualMessage.blob)
          break
        case 'voice':
          setFormData('message_voice', values.audioVisualMessage.blob)
          break
        case 'video':
          setFormData('message_video', values.audioVisualMessage.blob)
          break
      }
    }
    if (!defaultAudioVisualMessage() && !values.audioVisualMessage)
      setFormData('delete_audio_visual', 'true')
    if (botMessage && botMessage.runAtEditable)
      setFormData('run_at', values.selectedTime)

    dispatch(
      updateContributorBotMessage.started({
        id: parseInt(params.bot_message_id),
        data: formData,
      })
    )

    pageRollbackHandler()
  }

  const resetMessageHandler = () => {
    if (!user || !contributor) return

    setFormData('contributor_id', String(contributor.id))
    setFormData('text', '')

    if (Object.keys(defaultPreviewContents.image).length > 0)
      setFormData('media_removal', 'message_image')
    if (Object.keys(defaultPreviewContents.video).length > 0)
      setFormData('media_removal', 'message_video')
    if (Object.keys(defaultPreviewContents.voice).length > 0)
      setFormData('media_removal', 'message_voice')

    dispatch(
      updateContributorBotMessage.started({
        id: parseInt(params.bot_message_id),
        data: formData,
      })
    )

    pageRollbackHandler()
  }

  if (!styles || !contributor || !botMessage) return <></>

  return (
    <>
      <MainHeader heading={botMessage.patternName} subText={headerLabel()}>
        {responsive === 'normal' ? (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid #E0E0E0'}
              disabled={false}
              onClickHandler={() => pageRollbackHandler()}
            />
            <ButtonSpacer />
            <Button
              label={'リセット'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={false}
              onClickHandler={() => resetMessageHandler()}
            />
            <ButtonSpacer />
            <Button
              icon={Save}
              label={'保存する'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable}
              onClickHandler={() => sendMessageHandler()}
            />
          </>
        ) : (
          <Button
            label={'リセット'}
            textColor={'#FFFFFF'}
            disabledTextColor={'#FFFFFF'}
            color={'#17DBC9'}
            disabledColor={'#E0E0E0'}
            disabled={false}
            onClickHandler={() => resetMessageHandler()}
          />
        )}
      </MainHeader>
      <div className={styles.default.new_message_component}>
        <div className={styles.default.message_form}>
          <MessageOptions
            specifyReservedTime={botMessage.runAtEditable}
            birthDayBotSendingTime={values.selectedTime}
            birthDayBotSendingTimeHandler={selectedTimeHandler}
          />
          <MessageForm
            type={'text'}
            value={values.messageText}
            textValidation={validations.messageTextValidation}
            adaptVariableHandler={adaptVariableHandler}
            onChangeText={messageTextHandler}
            onChangeAudioVisual={uploadAudioVisualHandler}
            botPattern={botMessage.patternName}
          >
            <div className={styles.default.message_thread} />
          </MessageForm>
          {defaultAudioVisualMessage() || values.audioVisualMessage ? (
            <MessageForm
              type={'audio_visual'}
              selectedFile={
                defaultAudioVisualMessage() || values.audioVisualMessage
              }
              imageValidation={validations.messageImageValidation}
              videoValidation={validations.messageVideoValidation}
              voiceValidation={validations.messageVoiceValidation}
              onChangeText={messageTextHandler}
              onChangeAudioVisual={uploadAudioVisualHandler}
              closeForm={removeAudioVisualHandler}
            />
          ) : (
            <div className={styles.default.append_media_btn}>
              <button onClick={addAudioVisualHandler}>
                <div className={styles.default.label}>
                  <img src={CreateIcon} />
                  <span>画像・音声・動画を追加する</span>
                </div>
              </button>
            </div>
          )}
        </div>
        <div className={styles.default.message_preview}>
          <Preview
            contributor={contributor}
            messageText={values.messageText}
            audioVisualMessage={
              defaultAudioVisualMessage() || values.audioVisualMessage
            }
            imagePreview={
              values.imagePreview || defaultPreviewContents.image.previewUrl
            }
            videoPreview={
              values.videoPreview || defaultPreviewContents.video.previewUrl
            }
            voicePreview={
              values.voicePreview || defaultPreviewContents.voice.previewUrl
            }
            sp={responsive === 'small' || responsive === 'medium'}
            immediate={false}
            reservation={false}
            eventBot={true}
            directMessage={false}
          >
            {(responsive === 'small' || responsive === 'medium') && (
              <PreviewMessageForm
                messageText={values.messageText}
                messageTextHandler={messageTextHandler}
                audioVisualMessage={
                  defaultAudioVisualMessage() || values.audioVisualMessage
                }
                selectedTime={values.selectedTime}
                selectedTimeHandler={selectedTimeHandler}
                imagePreview={
                  values.imagePreview || defaultPreviewContents.image.previewUrl
                }
                videoPreview={
                  values.videoPreview || defaultPreviewContents.video.previewUrl
                }
                voicePreview={
                  values.voicePreview || defaultPreviewContents.voice.previewUrl
                }
                adaptVariableHandler={adaptVariableHandler}
                uploadAudioVisualHandler={uploadAudioVisualHandler}
                removeAudioVisualHandler={removeAudioVisualHandler}
                textValidation={validations.messageTextValidation}
                imageValidation={validations.messageImageValidation}
                videoValidation={validations.messageVideoValidation}
                voiceValidation={validations.messageVoiceValidation}
                sendMessageHandler={sendMessageHandler}
                disabled={!executable}
                reserved={botMessage.runAtEditable}
                bot={true}
                botPattern={botMessage.patternName}
              />
            )}
          </Preview>
        </div>
      </div>
    </>
  )
}

export default EventBotMessage
