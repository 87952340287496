import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MainHeader from 'components/modules/main_header'
import { useStyles } from 'hooks/useStyles'
import MessageOptions from 'components/messages/create/components/message_options'
import PreviewMessageForm from 'components/modules/preview/components/message_form'
import {
  createContributorMessage,
  createReservedContributorMessage,
  setInitialState,
} from 'actions/messages'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import MessageForm from 'components/modules/message_form'
import CreateIcon from 'images/create_message.png'
import { ReactComponent as Save } from 'images/save.svg'
import { useResponsive } from 'hooks/useResponsive'
import { generateUrlFromFileV2 } from 'utils/file_manager'
import { useValues } from 'components/direct_messages/detail/hooks/use_values'
import { useConstructFormData } from 'components/direct_messages/detail/hooks/use_construct_form_data'
import { useContributor } from 'hooks/useContributor'
import { useValidations } from 'components/direct_messages/detail/hooks/use_validations'
import { useButtonStatus } from 'components/direct_messages/detail/hooks/use_button_status'
import { MessagesState } from 'models/messages'
import { useDMs } from './hooks/use_dms'
import Preview from 'components/modules/preview'
import { CombineReducers } from 'models'
import DMDetail from './components/dm_detail'
import { removeDmToRespond } from 'actions/messages'
import { useEffect, useState } from 'react'
import {
  ManualBotMessage,
  ManualBotMessagesState,
} from 'models/manual_bot_messages'
import { getManualBotMessages } from 'actions/manual_bot_messages'
import moment from 'moment'

const DirectMessage: React.FC = () => {
  const dispatch = useDispatch()
  const messagesState: MessagesState = useSelector(
    (state: CombineReducers) => state.messages
  )
  const manualBotMessagesState: ManualBotMessagesState = useSelector(
    (state: CombineReducers) => {
      return state.manualBotMessages
    }
  )
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/direct_messages/detail',
  })
  const [responsive] = useResponsive()
  const params = useParams<{
    id: string
    listener_id: string
  }>()

  const [user, contributor] = useContributor({ queryString: params })
  const [messages, executeGetListenerDMs] = useDMs({
    contributorId: params.id,
    listenerId: params.listener_id,
  })
  const [values, setValue] = useValues()
  const [validations, setValidation] = useValidations()
  const [executable, checkExecutable] = useButtonStatus({ values, validations })
  const [formData, setFormData] = useConstructFormData()

  const [templateResponse, setTemplateResponse] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] = useState<ManualBotMessage>()

  const adaptVariableHandler = (variable: string, targetCursor: number) => {
    messageTextHandler(
      `${values.messageText.slice(
        0,
        targetCursor
      )}(${variable})${values.messageText.slice(targetCursor)}`
    )
  }

  const messageTextHandler = (value: string) => {
    setValue('message_text', value)
    setValidation('message_text', value)
  }

  const cleanSelectedTemplate = () => {
    messageTextHandler('')
    setSelectedTemplate(undefined)
  }

  const templateResponseHandler = (checked: boolean) => {
    setTemplateResponse(checked)
    if (!checked) {
      cleanSelectedTemplate()
    }
  }

  const uploadAudioVisualHandler = (type: string, blob: File) => {
    const previewUrl = generateUrlFromFileV2({ type, blob })
    if (type === 'image') {
      setValue('video_preview', undefined)
      setValue('voice_preview', undefined)
      if (previewUrl) setValue('image_preview', previewUrl)
      setValidation('message_image', blob)
    }
    if (type === 'video') {
      setValue('image_preview', undefined)
      setValue('voice_preview', undefined)
      if (previewUrl) setValue('video_preview', previewUrl)
      setValidation('message_video', blob)
    }
    if (type === 'voice') {
      setValue('image_preview', undefined)
      setValue('video_preview', undefined)
      if (previewUrl) setValue('voice_preview', previewUrl)
      setValidation('message_voice', blob)
    }
    setValue('audio_visual_message', { type, blob })
  }

  const addAudioVisualHandler = () =>
    setValue('audio_visual_message', { type: 'image', blob: undefined })

  const removeAudioVisualHandler = () => {
    setValue('audio_visual_message', undefined)
    setValue('image_preview', undefined)
    setValue('video_preview', undefined)
    setValue('voice_preview', undefined)
  }

  const pageRollbackHandler = () => history.goBack()

  const sendMessageHandler = () => {
    if (!user || !contributor) return

    setFormData('contributor_id', params.id)
    if (values.messageText.length > 0) setFormData('text', values.messageText)
    setFormData('public_text', String(values.publicMessage))
    setFormData('public_image', String(values.publicMessage))
    setFormData('public_voice', String(values.publicMessage))
    setFormData('public_video', String(values.publicMessage))
    if (values.audioVisualMessage && values.audioVisualMessage.blob) {
      switch (values.audioVisualMessage.type) {
        case 'image':
          setFormData('message_image', values.audioVisualMessage.blob)
          break
        case 'voice':
          setFormData('message_voice', values.audioVisualMessage.blob)
          break
        case 'video':
          setFormData('message_video', values.audioVisualMessage.blob)
          break
      }
    }
    setFormData('target_listener_id', params.listener_id)
    setFormData('direct_message', 'true')
    if (messagesState.dmToRespond) {
      setFormData('dm_to_respond_id', String(messagesState.dmToRespond.id))
    }

    if (reservedMessage) {
      const runAt =
        moment(selectedDate).format('YYYY/MM/DD') +
        ' ' +
        selectedHour +
        ':' +
        selectedMinute
      formData.append('run_at', runAt)
      formData.append('delivered', 'false')
      formData.append('reserved', 'true')
      dispatch(
        createReservedContributorMessage.started({
          data: formData,
        })
      )
    } else {
      dispatch(
        createContributorMessage.started({
          data: formData,
        })
      )
    }

    dispatch(removeDmToRespond())

    localStorage.removeItem('stashed-message')

    pageRollbackHandler()
  }

  const refetchMessages = () =>
    executeGetListenerDMs(params.id, params.listener_id)

  const [reservedMessage, setReservedMessage] = useState<boolean>(false)
  const [selectedDate, setSelectedDate] = useState<Date>()
  const [selectedHour, setSelectedHour] = useState<string>('00')
  const [selectedMinute, setSelectedMinute] = useState<string>('00')
  const reservedMessageHandler = (reserved: boolean) => {
    setReservedMessage(reserved)

    const runAt =
      moment(selectedDate).format('YYYY/MM/DD') +
      ' ' +
      selectedHour +
      ':' +
      selectedMinute

    setValidation('message_datetime', runAt)
  }
  const selectedHourHandler = (hour: string) => {
    setSelectedHour(hour)

    const runAt =
      moment(selectedDate).format('YYYY/MM/DD') +
      ' ' +
      hour +
      ':' +
      selectedMinute

    setValidation('message_datetime', runAt)
  }
  const selectedMinuteHandler = (minute: string) => {
    setSelectedMinute(minute)

    const runAt =
      moment(selectedDate).format('YYYY/MM/DD') +
      ' ' +
      selectedHour +
      ':' +
      minute

    setValidation('message_datetime', runAt)
  }
  const selectedDateHandler = (date: Date) => {
    setSelectedDate(date)

    const runAt =
      moment(date).format('YYYY/MM/DD') +
      ' ' +
      selectedHour +
      ':' +
      selectedMinute

    setValidation('message_datetime', runAt)
  }

  useEffect(() => {
    const stashedMessage = localStorage.getItem('stashed-message')
    if (stashedMessage) {
      messageTextHandler(stashedMessage)
    }

    if (contributor) {
      const formData = new FormData()
      formData.append('type', 'catalog')
      formData.append('contributor_id', String(contributor.id))
      dispatch(getManualBotMessages.started({ formData }))
    }
  }, [contributor])

  useEffect(() => {
    dispatch(setInitialState())
  }, [])

  if (!styles || !contributor) return <></>

  const dmDetail = () => {
    return (
      <DMDetail
        dm={messagesState.dmToRespond}
        templateResponse={templateResponse}
        closeHandler={() => {
          dispatch(removeDmToRespond())
          templateResponseHandler(false)
        }}
        manualBotMessages={manualBotMessagesState.manualBotMessages.data}
        checkTemplateHandler={(checked: boolean) => {
          templateResponseHandler(checked)
        }}
        selectTemplateHandler={(templateId: number) => {
          const target = manualBotMessagesState.manualBotMessages.data.find(
            (message: ManualBotMessage) => message.id === templateId
          )

          if (!target) return cleanSelectedTemplate()

          messageTextHandler(target.body)
          setSelectedTemplate(target)
        }}
      />
    )
  }

  return (
    <>
      <MainHeader heading={'メッセージ新規作成'}>
        {responsive === 'normal' && (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid rgb(224, 224, 224)'}
              disabled={false}
              onClickHandler={() => pageRollbackHandler()}
            />
            <ButtonSpacer />
            <Button
              label={'送信する'}
              icon={Save}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable}
              onClickHandler={sendMessageHandler}
            />
          </>
        )}
      </MainHeader>
      <div className={styles.default.new_message_component}>
        <div className={styles.default.message_form}>
          <MessageOptions
            reservation={reservedMessage}
            reservationHandler={reservedMessageHandler}
            selectedDateHandler={selectedDateHandler}
            selectedHourHandler={selectedHourHandler}
            selectedMinuteHandler={selectedMinuteHandler}
            reservationOnly={false}
          />
          {messagesState.dmToRespond && dmDetail()}
          {!templateResponse && (
            <>
              <MessageForm
                type={'text'}
                value={values.messageText}
                textValidation={validations.messageTextValidation}
                adaptVariableHandler={adaptVariableHandler}
                onChangeText={messageTextHandler}
                onChangeAudioVisual={uploadAudioVisualHandler}
              >
                <div className={styles.default.message_thread} />
              </MessageForm>
              {values.audioVisualMessage && (
                <MessageForm
                  type={'audio_visual'}
                  selectedFile={values.audioVisualMessage}
                  preview={values.imagePreview}
                  imageValidation={validations.messageImageValidation}
                  videoValidation={validations.messageVideoValidation}
                  voiceValidation={validations.messageVoiceValidation}
                  onChangeText={messageTextHandler}
                  onChangeAudioVisual={uploadAudioVisualHandler}
                  closeForm={removeAudioVisualHandler}
                />
              )}
              {!values.audioVisualMessage && (
                <div className={styles.default.append_media_btn}>
                  <button onClick={addAudioVisualHandler}>
                    <div className={styles.default.label}>
                      <img src={CreateIcon} />
                      <span>画像・音声・動画を追加する</span>
                    </div>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.default.message_preview}>
          {(responsive === 'small' || responsive === 'medium') &&
            messagesState.dmToRespond &&
            dmDetail()}
          <Preview
            contributor={contributor}
            messageText={values.messageText}
            messages={messages}
            refetchMessages={refetchMessages}
            audioVisualMessage={values.audioVisualMessage}
            imagePreview={values.imagePreview}
            videoPreview={values.videoPreview}
            voicePreview={values.voicePreview}
            sp={responsive === 'small' || responsive === 'medium'}
            immediate={false}
            reservation={false}
            eventBot={false}
            directMessage={true}
            targetListenerId={Number(params.listener_id)}
          >
            {(responsive === 'small' || responsive === 'medium') && (
              <>
                {templateResponse ? (
                  <div className={styles.default.template_response_btn}>
                    {selectedTemplate && (
                      <button onClick={sendMessageHandler}>
                        テンプレートを送信する
                      </button>
                    )}
                  </div>
                ) : (
                  <PreviewMessageForm
                    messageText={values.messageText}
                    messageTextHandler={messageTextHandler}
                    audioVisualMessage={values.audioVisualMessage}
                    imagePreview={values.imagePreview}
                    voicePreview={values.voicePreview}
                    videoPreview={values.videoPreview}
                    adaptVariableHandler={adaptVariableHandler}
                    uploadAudioVisualHandler={uploadAudioVisualHandler}
                    removeAudioVisualHandler={removeAudioVisualHandler}
                    textValidation={validations.messageTextValidation}
                    imageValidation={validations.messageImageValidation}
                    videoValidation={validations.messageVideoValidation}
                    voiceValidation={validations.messageVoiceValidation}
                    sendMessageHandler={sendMessageHandler}
                    disabled={!executable}
                    reserved={false}
                    bot={false}
                  />
                )}
              </>
            )}
          </Preview>
        </div>
      </div>
    </>
  )
}

export default DirectMessage
