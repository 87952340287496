export const contributorNameValidation = (value: string) => {
  let message = 'テキストの入力値は正常です'
  let type = 'succeeded'
  if (value.length < 1 || value.length > 50) {
    message = '1文字以上20文字以下のテキストを入力してください'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
