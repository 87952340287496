import {
  ContributorMessage,
  ContributorMessages,
  MessagesState,
} from 'models/messages'
import { useState, useEffect } from 'react'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { Contributor } from 'models/contributors'
import { useDispatch, useSelector } from 'react-redux'
import {
  getPreviewMessages,
  getPreviewReservedMessages,
} from 'actions/messages'
import { getListenerDMs } from 'actions/messages'

export function usePreviewMessages(input: {
  contributor: Contributor
  immediate: boolean
  reservation: boolean
  directMessage: boolean
  targetListenerId?: number
}): [
  executeRefetchMessages: () => void,
  dateList: string[],
  messages?: ContributorMessages
] {
  const dispatch = useDispatch()

  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const messagesState: MessagesState = useSelector(
    (state: CombineReducers) => state.messages
  )

  const [messages, setMessages] = useState<ContributorMessages>()
  const [dateList, setDateList] = useState<string[]>([])

  const executeRefetchMessages = (page?: number) => {
    if (!sessionsState.currentUser) return

    if (input.immediate) {
      const args = {
        contributorId:
          sessionsState.currentUser.authType === 'Administrator'
            ? input.contributor.id
            : sessionsState.currentUser.contributor.id,
        page: messagesState.currentImmediatePreviewMessages.meta!.nextPage ?? 1,
      }
      dispatch(getPreviewMessages.started(args))
    } else if (input.reservation) {
      const args = {
        contributorId:
          sessionsState.currentUser.authType === 'Administrator'
            ? input.contributor.id
            : sessionsState.currentUser.contributor.id,
        page: messagesState.currentReservedPreviewMessages.meta!.nextPage ?? 1,
      }
      dispatch(getPreviewReservedMessages.started(args))
    } else if (input.directMessage && input.targetListenerId) {
      const args = {
        contributorId:
          sessionsState.currentUser.authType === 'Administrator'
            ? input.contributor.id
            : sessionsState.currentUser.contributor.id,
        listenerId: input.targetListenerId,
        page: messagesState.listenerDMs.meta!.nextPage ?? 1,
      }
      dispatch(getListenerDMs.started(args))
    } else {
      setMessages(undefined)
      extractDateList([])
    }
  }

  useEffect(() => {
    executeRefetchMessages()
  }, [])

  useEffect(() => {
    if (
      messagesState.status.succeededCreateContributorMessage ||
      messagesState.status.succeededDeleteContributorMessage ||
      messagesState.status.succeededCreateReservedContributorMessage
    ) {
      location.reload()
    }
  }, [
    messagesState.status.succeededCreateContributorMessage,
    messagesState.status.succeededDeleteContributorMessage,
    messagesState.status.succeededCreateReservedContributorMessage,
  ])

  const extractDateList = (fetchedMessages: ContributorMessage[]) => {
    const extractedDateList = new Set<string>()
    fetchedMessages.forEach((message: ContributorMessage) => {
      const matchedDate = message.createdAt.match(/^\d{4}\/\d{2}\/\d{2}/)
      if (matchedDate) extractedDateList.add(matchedDate[0])
    })

    setDateList(Array.from(extractedDateList.values()))
  }

  useEffect(() => {
    if (input.directMessage && input.targetListenerId) {
      setMessages(messagesState.listenerDMs)
      extractDateList(messagesState.listenerDMs.data)
    } else if (input.immediate) {
      setMessages(messagesState.currentImmediatePreviewMessages)
      extractDateList(messagesState.currentImmediatePreviewMessages.data)
    } else if (input.reservation) {
      setMessages(messagesState.currentReservedPreviewMessages)
      extractDateList(messagesState.currentReservedPreviewMessages.data)
    }
  }, [
    messagesState.status.succeededGetPreviewMessages,
    messagesState.status.succeededGetPreviewReservedMessages,
    messagesState.status.succeededGetListenersDMs,
  ])

  return [executeRefetchMessages, dateList, messages]
}
