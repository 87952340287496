export const validateSession = () => {
  if (
    localStorage.getItem('access-token') &&
    localStorage.getItem('client') &&
    localStorage.getItem('uid')
  )
    return true

  return false
}
