import axios from 'axios'
import { loggingError } from 'services/interceptor'
import digitalContentsDeserializer from 'deserializers/digital_contents'
import { RESPONSE_NO_CONTENT, buildErrorLog, buildHeader } from 'services'
import digitalContentDeserializer from 'deserializers/digital_content'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200

export const getDigitalContentsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getDigitalContents = async (request: {
    page: number
    contributorId: number
  }) => {
    const response = await instance.get(
      `/api/cms/v1/digital_contents?contributor_id=${request.contributorId}&page=${request.page}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/digital_contents?contributor_id=${request.contributorId}&page=${request.page}`,
        'getDigitalContentsFactory'
      )

    return digitalContentsDeserializer({
      data: response.data.digital_contents,
      meta: response.data.meta,
    })
  }
  return getDigitalContents
}

export const getDigitalContentFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getDigitalContent = async (request: { id: number }) => {
    const response = await instance.get(
      `/api/cms/v1/digital_contents/${request.id}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/digital_contents/${request.id}`,
        'getDigitalContentFactory'
      )

    return digitalContentDeserializer({
      data: response.data.digital_content,
    })
  }
  return getDigitalContent
}

export const createDigitalContentFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const createDigitalContent = async (request: { data: FormData }) => {
    const method = 'createDigitalContentFactory'
    const uri = '/api/cms/v1/digital_contents'

    const response = await instance.post(uri, request.data, {
      headers: buildHeader('multipart/form-data'),
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return createDigitalContent
}

export const updateDigitalContentFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateManualBotMessage = async (request: {
    id: number
    data: FormData
  }) => {
    const method = 'updateDigitalContentFactory'
    const uri = `/api/cms/v1/digital_contents/${request.id}`

    const response = await instance.put(uri, request.data, {
      headers: {
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'content-type': 'multipart/form-data',
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      },
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return updateManualBotMessage
}

export const deleteDigitalContentFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const deleteManualBotMessage = async (request: { id: number }) => {
    const method = 'updateDigitalContentFactory'
    const uri = `/api/cms/v1/digital_contents/${request.id}`

    const response = await instance.delete(uri, {
      headers: {
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'content-type': 'multipart/form-data',
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      },
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return deleteManualBotMessage
}
