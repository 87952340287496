import { useState, useMemo } from 'react'
import {
  EventBotMessage,
  AudioVisualMessage,
  MessageValueTypes,
  EventBotMessageValues,
} from 'models/messages'
import { typeGuard } from 'utils/type_guard'
import { generateUrlFromFile } from 'utils/file_manager'

export function useValues(input: {
  message?: EventBotMessage
}): [
  values: EventBotMessageValues,
  setValue: (type: string, value?: MessageValueTypes) => void
] {
  const [selectedTime, setSelectedTime] = useState<string>('00:00')
  const [messageText, setMessageText] = useState<string>('')
  const [audioVisualMessage, setAudioVisualMessage] =
    useState<AudioVisualMessage>()
  const [imagePreview, setImagePreview] = useState<string>()
  const [videoPreview, setVideoPreview] = useState<string>()
  const [voicePreview, setVoicePreview] = useState<string>()
  const [mediaRemoval, setMediaRemoval] = useState<string>()

  const setValue = (type: string, value?: MessageValueTypes) => {
    switch (type) {
      case 'selected_time':
        if (typeGuard<string>(value)) setSelectedTime(value)
        break
      case 'message_text':
        if (typeGuard<string>(value)) setMessageText(value)
        break
      case 'audio_visual_message':
        if (!value) {
          setAudioVisualMessage(undefined)
        } else if (typeGuard<AudioVisualMessage>(value)) {
          setAudioVisualMessage(value)
          generateUrlFromFile(
            value,
            setImagePreview,
            setVideoPreview,
            setVoicePreview
          )
        }
        break
      case 'image_preview':
        if (!value) {
          setImagePreview(undefined)
        } else if (typeGuard<string>(value)) {
          setImagePreview(value)
        }
        break
      case 'video_preview':
        if (!value) {
          setVideoPreview(undefined)
        } else if (typeGuard<string>(value)) {
          setVideoPreview(value)
        }
        break
      case 'voice_preview':
        if (!value) {
          setVoicePreview(undefined)
        } else if (typeGuard<string>(value)) {
          setVoicePreview(value)
        }
        break
      case 'media_removal':
        if (!value) {
          setMediaRemoval(undefined)
        } else if (typeGuard<string>(value)) {
          setMediaRemoval(value)
        }
    }
  }

  useMemo(() => {
    if (input.message) {
      setValue('image_preview', undefined)
      setValue('video_preview', undefined)
      setValue('voice_preview', undefined)
      setValue('media_removal', undefined)
      setValue('message_text', input.message.text || '')
      setValue('selected_time', input.message.runAt || '00:00')
    }
  }, [input.message])

  return [
    {
      selectedTime,
      messageText,
      audioVisualMessage,
      imagePreview,
      videoPreview,
      voicePreview,
      mediaRemoval,
    },
    setValue,
  ]
}
