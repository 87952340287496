import { UsersAction } from 'actions/users'
import * as ActionType from 'actions/users/types'
import { UsersState } from 'models/users'

export const initialState: UsersState = {
  user: undefined,
}

const usersReducer = (
  state: UsersState = initialState,
  action: UsersAction
): UsersState => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        user: action.payload.user,
      }
    default:
      return state
  }
}

export default usersReducer
