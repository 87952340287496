import { messageTextValidation } from './text'
import { imageValidation } from './image'
import { videoValidation } from './video'
import { voiceValidation } from './voice'
import { datetimeValidation } from './datetime'

export const messageValidations = {
  text: messageTextValidation,
  image: imageValidation,
  video: videoValidation,
  voice: voiceValidation,
  datetime: datetimeValidation,
}
