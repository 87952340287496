import React from 'react'
import { useStyles } from 'hooks/useStyles'

const MainForm: React.FC<{ children: React.ReactNode }> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.main_form_component}>{props.children}</div>
  )
}

export default MainForm
