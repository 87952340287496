import { CombineReducers } from 'models'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContributorBotMessage } from 'actions/messages'
import { EventBotMessage, MessagesState } from 'models/messages'

export function useEventBotMessage(input: {
  id: string
}): [eventBotMessage?: EventBotMessage] {
  const dispatch = useDispatch()

  const messagesState: MessagesState = useSelector(
    (state: CombineReducers) => state.messages
  )

  useEffect(() => {
    dispatch(
      getContributorBotMessage.started({
        id: Number(input.id),
      })
    )
  }, [])

  return [messagesState.currentContributorBotMessage]
}
