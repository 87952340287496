import { useStyles } from 'hooks/useStyles'

type Props = {
  label: string
  defaultValue?: string
  updateHandler: (value: string) => void
  min: number
  max: number
}

const NumberInput: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/number_input',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.number_input_component}>
      <div className={styles.default.input_label}>
        <span>{props.label}</span>
      </div>
      <div className={styles.default.input_field}>
        <input
          type="number"
          value={props.defaultValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (
              props.min > Number(event.target.value) ||
              props.max < Number(event.target.value)
            ) {
              props.updateHandler(String(props.min))
            } else {
              props.updateHandler(event.target.value)
            }
          }}
          min={props.min}
          max={props.max}
        />
      </div>
    </div>
  )
}

export default NumberInput
