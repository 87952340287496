import { Membership, Memberships } from 'models/memberships'

function membershipsDeserializer(input: {
  data: any
  meta?: any
}): Membership | Memberships {
  return Array.isArray(input.data)
    ? {
        data: input.data.map((item: any) => ({
          id: item.id,
          name: item.name,
          birthDate: item.birth_date,
          gender: item.gender,
          meetingTickets: item.meeting_tickets,
          membershipDuration: item.membership_duration,
          membershipSerialNumber: item.membership_serial_number,
          subscriptionId: item.subscription_id,
          listenerId: item.listener_id,
        })),
        meta: input.meta && {
          currentPage: input.meta.current_page,
          nextPage: input.meta.next_page,
          prevPage: input.meta.prev_page,
          totalCount: input.meta.total_count,
          totalPages: input.meta.total_pages,
        },
      }
    : {
        id: input.data.id,
        name: input.data.name,
        birthDate: input.data.birth_date,
        gender: input.data.gender,
        meetingTickets: input.data.meeting_tickets,
        membershipDuration: input.data.membership_duration,
        membershipSerialNumber: input.data.membership_serial_number,
        subscriptionId: input.data.subscription_id,
        listenerId: input.data.listener_id,
      }
}

export default membershipsDeserializer
