import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/memberships/types'
import {
  getMembership,
  getMembershipBirthdays,
  getMemberships,
} from 'actions/memberships'
import {
  Membership,
  MembershipBirthdays,
  Memberships,
} from 'models/memberships'
import {
  getMembershipsFactory,
  getMembershipFactory,
  getMembershipBirthdaysFactory,
} from 'services/memberships/api'

function* runGetMemberships(action: ReturnType<typeof getMemberships.started>) {
  try {
    const memberships: Memberships = yield call(
      getMembershipsFactory(),
      action.payload
    )

    yield put(getMemberships.succeeded({ memberships, message: '' }))
  } catch (err) {
    yield put(getMemberships.failed({ message: '' }))
  }
}

function* runGetMembership(action: ReturnType<typeof getMembership.started>) {
  try {
    const membership: Membership = yield call(
      getMembershipFactory(),
      action.payload
    )

    yield put(getMembership.succeeded({ membership, message: '' }))
  } catch (err) {
    yield put(getMembership.failed({ message: '' }))
  }
}

function* runGetMembershipBirthdays(
  action: ReturnType<typeof getMembershipBirthdays.started>
) {
  try {
    const membershipBirthdays: MembershipBirthdays = yield call(
      getMembershipBirthdaysFactory(),
      action.payload
    )

    yield put(
      getMembershipBirthdays.succeeded({ membershipBirthdays, message: '' })
    )
  } catch (err) {
    yield put(getMembershipBirthdays.failed({ message: '' }))
  }
}

export function* watchMembershipsSaga() {
  yield takeLatest(Action.STARTED_GET_MEMBERSHIPS, runGetMemberships)
  yield takeLatest(Action.STARTED_GET_MEMBERSHIP, runGetMembership)
  yield takeLatest(
    Action.STARTED_GET_MEMBERSHIP_BIRTHDAYS,
    runGetMembershipBirthdays
  )
}
