import React from 'react'

const FormInput: React.FC<{
  label: string
  type: string
  placeholder?: string
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
  styles: { default: { readonly [key: string]: string } }
}> = ({ label, type, placeholder, onChangeHandler, styles }) => (
  <div className={styles.default.form_input}>
    <p>{label}</p>
    <input type={type} placeholder={placeholder} onChange={onChangeHandler} />
  </div>
)

export default FormInput
