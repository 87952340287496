import { Administrator } from 'models/auth'

function administratorDeserializer(input: {
  data: any
  meta?: any
}): Administrator {
  return {
    id: input.data?.id,
    name: input.data?.name,
    authType: input.data?.auth_type,
  }
}

export default administratorDeserializer
