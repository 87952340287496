import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/messages/types'
import {
  createContributorMessage,
  createReservedContributorMessage,
  deleteContributorMessage,
  getContributorBotMessage,
  getContributorBotMessages,
  getContributorMessage,
  getContributorMessages,
  getListenerDMs,
  getPreviewMessages,
  getPreviewReservedMessages,
  getReservedContributorMessages,
  updateContributorBotMessage,
} from 'actions/messages'
import {
  ContributorMessage,
  ContributorMessages,
  EventBotMessage,
  EventBotMessages,
} from 'models/messages'
import {
  createContributorMessageFactory,
  deleteContributorMessageFactory,
  getContributorBotMessageFactory,
  getContributorBotMessagesFactory,
  getContributorMessageFactory,
  getContributorMessagesFactory,
  getListenerDMsFactory,
  getPreviewMessagesFactory,
  getPreviewReservedMessagesFactory,
  getReservedContributorMessagesFactory,
  updateContributorBotMessageFactory,
} from 'services/messages/api'

function* runGetContributorMessages(
  action: ReturnType<typeof getContributorMessages.started>
) {
  try {
    const result: ContributorMessages = yield call(
      getContributorMessagesFactory(),
      action.payload
    )

    yield put(
      getContributorMessages.succeeded({
        contributorMessages: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getContributorMessages.failed({ message: '' }))
  }
}

function* runGetReservedContributorMessages(
  action: ReturnType<typeof getReservedContributorMessages.started>
) {
  try {
    const result: ContributorMessages = yield call(
      getReservedContributorMessagesFactory(),
      action.payload
    )

    yield put(
      getReservedContributorMessages.succeeded({
        contributorMessages: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getReservedContributorMessages.failed({ message: '' }))
  }
}

function* runCreateContributorMessage(
  action: ReturnType<typeof createContributorMessage.started>
) {
  let succeededMessage = ''
  let failedMessage = ''
  try {
    if (action.payload.data.has('reserved')) {
      succeededMessage = '新規メッセージを予約しました'
      failedMessage = '新規メッセージを予約に失敗しました'
    } else if (action.payload.data.has('message_category_id')) {
      succeededMessage = 'メッセージを作成しました'
      failedMessage = 'メッセージの作成に失敗しました'
    } else {
      succeededMessage = '新規メッセージを配信しました'
      failedMessage = '新規メッセージの配信に失敗しました'
    }

    yield call(createContributorMessageFactory(), action.payload)

    yield put(
      createContributorMessage.succeeded({
        message: succeededMessage,
      })
    )
  } catch (err) {
    yield put(createContributorMessage.failed({ message: failedMessage }))
  }
}

function* runCreateReservedContributorMessage(
  action: ReturnType<typeof createReservedContributorMessage.started>
) {
  try {
    yield call(createContributorMessageFactory(), action.payload)

    yield put(
      createReservedContributorMessage.succeeded({
        message: '新規メッセージを予約しました',
      })
    )
  } catch (err) {
    yield put(
      createReservedContributorMessage.failed({
        message: 'メッセージの予約に失敗しました',
      })
    )
  }
}

function* runGetContributorMessage(
  action: ReturnType<typeof getContributorMessage.started>
) {
  try {
    const result: ContributorMessage = yield call(
      getContributorMessageFactory(),
      action.payload
    )

    yield put(
      getContributorMessage.succeeded({
        contributorMessage: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(
      getContributorMessage.failed({
        message: '',
      })
    )
  }
}

function* runDeleteContributorMessage(
  action: ReturnType<typeof deleteContributorMessage.started>
) {
  try {
    yield call(deleteContributorMessageFactory(), action.payload)

    yield put(
      deleteContributorMessage.succeeded({
        message: 'メッセージを削除しました',
      })
    )
  } catch (err) {
    yield put(
      deleteContributorMessage.failed({
        message: 'メッセージの削除に失敗しました',
      })
    )
  }
}

function* runGetContributorBotMessages(
  action: ReturnType<typeof getContributorBotMessages.started>
) {
  try {
    const result: EventBotMessages = yield call(
      getContributorBotMessagesFactory(),
      action.payload
    )

    yield put(
      getContributorBotMessages.succeeded({
        contributorBotMessages: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getContributorBotMessages.failed({ message: '' }))
  }
}

function* runGetContributorBotMessage(
  action: ReturnType<typeof getContributorBotMessage.started>
) {
  try {
    const result: EventBotMessage = yield call(
      getContributorBotMessageFactory(),
      action.payload
    )

    yield put(
      getContributorBotMessage.succeeded({
        contributorBotMessage: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getContributorBotMessage.failed({ message: '' }))
  }
}

function* runUpdateContributorBotMessage(
  action: ReturnType<typeof updateContributorBotMessage.started>
) {
  try {
    yield call(updateContributorBotMessageFactory(), action.payload)

    yield put(
      updateContributorBotMessage.succeeded({
        message: 'botメッセージを更新しました',
      })
    )
  } catch (err) {
    yield put(
      updateContributorBotMessage.failed({
        message: 'botメッセージの更新に失敗しました',
      })
    )
  }
}

function* runGetPreviewMessages(
  action: ReturnType<typeof getPreviewMessages.started>
) {
  try {
    const result: ContributorMessages = yield call(
      getPreviewMessagesFactory(),
      action.payload
    )

    yield put(
      getPreviewMessages.succeeded({
        previewMessages: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getPreviewMessages.failed({ message: '' }))
  }
}

function* runGetPreviewReservedMessages(
  action: ReturnType<typeof getPreviewReservedMessages.started>
) {
  try {
    const result: ContributorMessages = yield call(
      getPreviewReservedMessagesFactory(),
      action.payload
    )

    yield put(
      getPreviewReservedMessages.succeeded({
        previewMessages: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getPreviewReservedMessages.failed({ message: '' }))
  }
}

function* runGetListenerDMs(action: ReturnType<typeof getListenerDMs.started>) {
  try {
    const result: ContributorMessages = yield call(
      getListenerDMsFactory(),
      action.payload
    )

    yield put(
      getListenerDMs.succeeded({
        contributorMessages: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getListenerDMs.failed({ message: '' }))
  }
}

export function* watchMessagesSaga() {
  yield takeLatest(
    Action.STARTED_GET_CONTRIBUTOR_MESSAGES,
    runGetContributorMessages
  )
  yield takeLatest(
    Action.STARTED_GET_RESERVED_CONTRIBUTOR_MESSAGES,
    runGetReservedContributorMessages
  )
  yield takeLatest(
    Action.STARTED_CREATE_CONTRIBUTOR_MESSAGE,
    runCreateContributorMessage
  )
  yield takeLatest(
    Action.STARTED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE,
    runCreateReservedContributorMessage
  )
  yield takeLatest(
    Action.STARTED_GET_CONTRIBUTOR_MESSAGE,
    runGetContributorMessage
  )
  yield takeLatest(
    Action.STARTED_DELETE_CONTRIBUTOR_MESSAGE,
    runDeleteContributorMessage
  )
  yield takeLatest(
    Action.STARTED_GET_CONTRIBUTOR_BOT_MESSAGES,
    runGetContributorBotMessages
  )
  yield takeLatest(
    Action.STARTED_GET_CONTRIBUTOR_BOT_MESSAGE,
    runGetContributorBotMessage
  )
  yield takeLatest(
    Action.STARTED_UPDATE_CONTRIBUTOR_BOT_MESSAGE,
    runUpdateContributorBotMessage
  )
  yield takeLatest(Action.STARTED_GET_PREVIEW_MESSAGES, runGetPreviewMessages)
  yield takeLatest(
    Action.STARTED_GET_PREVIEW_RESERVED_MESSAGES,
    runGetPreviewReservedMessages
  )
  yield takeLatest(Action.STARTED_GET_LISTENER_DMS, runGetListenerDMs)
}
