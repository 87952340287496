import moment from 'moment'
import { useStyles } from 'hooks/useStyles'
import { ContributorMessage } from 'models/messages'

type Props = {
  index: number
  message: ContributorMessage
  messages: ContributorMessage[]
  dateList: string[]
}

const MessageDateBar: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/message_date_bar',
  })

  const translateDayIndex = (index: number) => {
    if (index === 0) return '日曜日'
    if (index === 1) return '月曜日'
    if (index === 2) return '火曜日'
    if (index === 3) return '水曜日'
    if (index === 4) return '木曜日'
    if (index === 5) return '金曜日'
    return '土曜日'
  }

  const messagesDateBar = (message: ContributorMessage, index: number) => {
    const updatedAt = props.dateList.find((date: string) => {
      const matchedDate = message.updatedAt.match(/^\d{4}-\d{2}-\d{2}/)
      if (matchedDate && date === matchedDate[0]) {
        if (props.messages && props.messages[index - 1]) {
          const previousMatchedDate =
            props.messages[index - 1].updatedAt.match(/^\d{4}-\d{2}-\d{2}/)
          return previousMatchedDate && date !== previousMatchedDate[0]
        }
      }
    })

    if (updatedAt)
      return `${moment(updatedAt).format(
        'YYYY年 MM月 DD日'
      )} ${translateDayIndex(moment(updatedAt).day())}`
  }

  if (!styles) return <></>

  return (
    <>
      {messagesDateBar(props.message, props.index) && (
        <div className={styles.default.date_bar_component}>
          <div className={styles.default.date_bar_wrap}>
            <div className={styles.default.date_bar_label}>
              <span>{messagesDateBar(props.message, props.index)}</span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default MessageDateBar
