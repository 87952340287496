import { useState } from 'react'
import { MessageValueTypes } from 'models/messages'
import { typeGuard } from 'utils/type_guard'
import {
  ManualBotMessageValues,
  ManualBotMessageValueTypes,
} from 'models/manual_bot_messages'

export function useValues(): [
  values: ManualBotMessageValues,
  setValue: (type: string, value?: ManualBotMessageValueTypes) => void
] {
  const [title, setTitle] = useState<string>('')
  const [body, setBody] = useState<string>('')

  const setValue = async (type: string, value?: MessageValueTypes) => {
    switch (type) {
      case 'title':
        if (typeGuard<string>(value)) setTitle(value)
        break
      case 'body':
        if (typeGuard<string>(value)) setBody(value)
        break
    }
  }

  return [
    {
      title,
      body,
    },
    setValue,
  ]
}
