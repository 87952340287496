import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { createTag } from 'actions/tags'
import MainHeader from 'components/modules/main_header'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import MainForm from 'components/modules/main_form'
import TextInput from 'components/modules/main_form/components/text_input'
import ColorPicker from 'components/modules/main_form/components/color_picker'
import SelectInput from 'components/modules/main_form/components/select_input'
import { useValidation } from 'hooks/useValidation'

const Tag: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [tagLabel, setTagLabel] = useState<string>('')
  const [tagLabelValidation, setTagLabelValidation] = useValidation({
    type: 'tag_label',
    value: tagLabel,
  })
  const tagLabelHandler = (value: string) => {
    setTagLabel(value)
    setTagLabelValidation('tag_label', value)
  }

  const [tagBGColor, setTagBGColor] = useState<string>()
  const tagBGColorHandler = (value: string) => setTagBGColor(value)

  const [tagLetterColor, setTagLetterColor] = useState<string>()
  const tagLetterColorHandler = (tagLetterColor: string) =>
    setTagLetterColor(tagLetterColor)

  const [activeRegistrationButton, setActiveRegistrationButton] =
    useState<boolean>(false)

  const registrationButtonChecker = () => {
    if (tagLabelValidation.type === 'error' || !tagBGColor || !tagLetterColor) {
      setActiveRegistrationButton(false)
      return
    }
    setActiveRegistrationButton(true)
  }

  const registrationHandler = () => {
    if (tagLabel && tagBGColor && tagLetterColor)
      dispatch(createTag.started({ tagLabel, tagBGColor, tagLetterColor }))

    history.push('/tags')
  }

  useEffect(() => {
    registrationButtonChecker()
  }, [tagLabelValidation, tagBGColor, tagLetterColor])

  return (
    <>
      <MainHeader heading={'タグ追加'}>
        <Button
          label={'キャンセル'}
          textColor={'#828282'}
          color={'#FFFFFF'}
          border={'2px solid rgb(224, 224, 224)'}
          disabled={false}
          onClickHandler={() => history.push('/tags')}
        />
        <ButtonSpacer />
        <Button
          label={'保存する'}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={!activeRegistrationButton}
          onClickHandler={() => registrationHandler()}
        />
      </MainHeader>
      <MainForm>
        <TextInput
          label={'タグ名'}
          defaultValue={tagLabel}
          updateHandler={(value: string) => tagLabelHandler(value)}
          wordCount={{
            max: 20,
            current: tagLabel ? tagLabel.length : 0,
          }}
          validation={tagLabelValidation}
        />
        <ColorPicker
          label={'背景色'}
          selectedColor={tagBGColor || ''}
          onChange={(value: string) => tagBGColorHandler(value)}
        />
        <SelectInput
          label={'文字色'}
          onChangeHandler={(item: { label: string; value: number | string }) =>
            tagLetterColorHandler(String(item.value))
          }
          selectableItems={[
            { label: '白', value: '#FFFFFF' },
            { label: '黒', value: '#000000' },
          ]}
        />
      </MainForm>
    </>
  )
}

export default Tag
