import React from 'react'
import { useStyles } from 'hooks/useStyles'

const MediaSelection: React.FC<{
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >
  label: string
  selectedMedia?: boolean
  onClick?: () => void
}> = ({ Icon, label, selectedMedia, onClick }) => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/message_form/components/media_selection',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.type}>
      <div
        className={styles.default.icon}
        style={
          onClick && {
            backgroundColor: selectedMedia ? '#F2F2F2' : 'transparent',
          }
        }
        onClick={onClick}
      >
        <Icon />
        <span>{label}</span>
      </div>
    </div>
  )
}

export default MediaSelection
