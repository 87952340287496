// GET_APP_MAINTENANCE_WINDOWS
export const STARTED_GET_APP_MAINTENANCE_WINDOWS =
  'MAINTENANCES/STARTED_GET_APP_MAINTENANCE_WINDOWS'
export const SUCCEEDED_GET_APP_MAINTENANCE_WINDOWS =
  'MAINTENANCES/SUCCEEDED_GET_APP_MAINTENANCE_WINDOWS'
export const FAILED_GET_APP_MAINTENANCE_WINDOWS =
  'MAINTENANCES/FAILED_GET_APP_MAINTENANCE_WINDOWS'

// GET_CMS_MAINTENANCE_WINDOWS
export const STARTED_GET_CMS_MAINTENANCE_WINDOWS =
  'MAINTENANCES/STARTED_GET_CMS_MAINTENANCE_WINDOWS'
export const SUCCEEDED_GET_CMS_MAINTENANCE_WINDOWS =
  'MAINTENANCES/SUCCEEDED_GET_CMS_MAINTENANCE_WINDOWS'
export const FAILED_GET_CMS_MAINTENANCE_WINDOWS =
  'MAINTENANCES/FAILED_GET_CMS_MAINTENANCE_WINDOWS'

// CREATE_MAINTENANCE_WINDOW
export const STARTED_CREATE_MAINTENANCE_WINDOW =
  'MAINTENANCES/STARTED_CREATE_MAINTENANCE_WINDOW'
export const SUCCEEDED_CREATE_MAINTENANCE_WINDOW =
  'MAINTENANCES/SUCCEEDED_CREATE_MAINTENANCE_WINDOW'
export const FAILED_CREATE_MAINTENANCE_WINDOW =
  'MAINTENANCES/FAILED_CREATE_MAINTENANCE_WINDOW'

// DELETE_MAINTENANCE_WINDOW
export const STARTED_DELETE_MAINTENANCE_WINDOW =
  'MAINTENANCES/STARTED_DELETE_MAINTENANCE_WINDOW'
export const SUCCEEDED_DELETE_MAINTENANCE_WINDOW =
  'MAINTENANCES/SUCCEEDED_DELETE_MAINTENANCE_WINDOW'
export const FAILED_DELETE_MAINTENANCE_WINDOW =
  'MAINTENANCES/FAILED_DELETE_MAINTENANCE_WINDOW'

// RESET_MESSAGES
export const STARTED_RESET_MESSAGES = 'MAINTENANCES/STARTED_RESET_MESSAGES'
export const SUCCEEDED_RESET_MESSAGES = 'MAINTENANCES/SUCCEEDED_RESET_MESSAGES'
export const FAILED_RESET_MESSAGES = 'MAINTENANCES/FAILED_RESET_MESSAGES'

// RESET_ALL_STATUS_FLAGS
export const STARTED_RESET_ALL_STATUS_FLAGS =
  'MAINTENANCES/STARTED_RESET_ALL_STATUS_FLAGS'
export const SUCCEEDED_RESET_ALL_STATUS_FLAGS =
  'MAINTENANCES/SUCCEEDED_RESET_ALL_STATUS_FLAGS'
export const FAILED_RESET_ALL_STATUS_FLAGS =
  'MAINTENANCES/FAILED_RESET_ALL_STATUS_FLAGS'
