import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CombineReducers } from 'models'
import { Tag, TagsState } from 'models/tags'
import { getTags, deleteTag } from 'actions/tags'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import Button from 'components/modules/button'
import Toast from 'components/modules/toast'
import Overlay from 'components/modules/overlay'
import Modal from 'components/modules/modal'
import ModalButton from 'components/modules/modal/components/button'
import { ReactComponent as Create } from 'images/create.svg'
import { ReactComponent as Bucket } from 'images/bucket.svg'

const Tags: React.FC = () => {
  const dispatch = useDispatch()
  const tagsState: TagsState = useSelector((state: CombineReducers) => {
    return state.tags
  })
  const history = useHistory()

  const [removeTagModal, setRemoveTagModal] = useState<boolean>(false)
  const [removeTarget, setRemoveTarget] = useState<number>()

  const routeHandler = () => history.push('/tags/new')

  const showRemoveTagModalHandler = (targetId: number) => {
    setRemoveTagModal(true)
    setRemoveTarget(targetId)
  }

  const removeTag = () => {
    setRemoveTagModal(false)
    if (removeTarget)
      dispatch(
        deleteTag.started({
          id: removeTarget,
        })
      )
  }

  const fetchAllTags = () => dispatch(getTags.started())

  useEffect(() => {
    fetchAllTags()
  }, [tagsState.status.succeededCreateTag, tagsState.status.succeededDeleteTag])

  return (
    <>
      <Toast body={tagsState.success.message} error={false} />
      <Toast body={tagsState.error.message} error={true} />
      {removeTagModal && (
        <Overlay dismissHandler={() => setRemoveTagModal(false)}>
          <Modal
            title={'タグを削除しますか？'}
            dismissHandler={() => setRemoveTagModal(false)}
          >
            <ModalButton
              color={'#FFFFFF'}
              textColor={'#828282'}
              label={'キャンセル'}
              onClickHandler={() => setRemoveTagModal(false)}
            />
            <ModalButton
              color={'#E25047'}
              textColor={'#FFFFFF'}
              label={'削除'}
              onClickHandler={() => removeTag()}
            />
          </Modal>
        </Overlay>
      )}
      <MainHeader heading="タグリスト">
        <Button
          icon={Create}
          label={'新規作成'}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() => routeHandler()}
        />
      </MainHeader>
      <MainTable
        columns={{
          scale: '50% 50%',
          items: [
            { label: 'タグ名', grid: '1 / 2' },
            { label: '配信者数', grid: '2 / 3' },
          ],
        }}
        items={
          tagsState.tags
            ? tagsState.tags.data.map((tag: Tag) => ({
                id: tag.id,
                data: {
                  name: {
                    id: tag.id,
                    value: tag.label,
                    clickable: true,
                    subLabel: false,
                    grid: '1 / 2',
                  },
                  contributors: {
                    id: tag.id,
                    value: tag.contributors || 0,
                    clickable: false,
                    subLabel: false,
                    grid: '2 / 3',
                  },
                },
              }))
            : []
        }
        selectItemHandler={() => null}
        option={{
          svg: Bucket,
          clickHandler: (targetId: number) =>
            showRemoveTagModalHandler(targetId),
        }}
      />
    </>
  )
}

export default Tags
