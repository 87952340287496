import administratorDeserializer from 'deserializers/administrator'
import contributorDeserializer from 'deserializers/contributor'
import { User } from 'models/auth'

function userDeserializer(input: { data: any }): User {
  return {
    id: input.data.id,
    email: input.data.email,
    authType: input.data.auth_type,
    administrator: administratorDeserializer({
      data: input.data.administrator,
    }),
    contributor: contributorDeserializer({ data: input.data.contributor }),
  }
}

export default userDeserializer
