import { useStyles } from 'hooks/useStyles'
import { useResponsive } from 'hooks/useResponsive'
import MenuItem from 'components/modules/side_pane_menu/components/menu_items/components/menu_item'
import { Route } from 'models/routes'

type Props = {
  routes: Route[]
  closeSidePaneHandler: () => void
}

const MenuItems: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/side_pane_menu/components/menu_items',
  })
  const [responsive] = useResponsive()

  if (!styles) return <></>

  return (
    <div className={styles.default.button_component}>
      {props.routes &&
        props.routes
          .filter((route: Route) => {
            if (responsive === 'normal' && route.spOnly) return false
            return true
          })
          .map((route: Route, index: number) => (
            <MenuItem
              key={index}
              route={route}
              closeSidePaneHandler={props.closeSidePaneHandler}
            />
          ))}
    </div>
  )
}

export default MenuItems
