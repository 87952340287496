export const digitalContentVoiceValidation = (value: File | null) => {
  let message = '音声ファイルの入力値は正常です'
  let type = 'succeeded'
  if (!value) return { message, type }
  if (value.size === 0) {
    message = '添付されたファイルが破損している可能性があります'
    type = 'error'
    return { message, type }
  }
  if (
    value.type !== 'audio/mpeg' &&
    value.type !== 'audio/aac' &&
    value.type !== 'audio/x-m4a'
  ) {
    message = '音声には mp3 / m4a / acc のみアップロードできます'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
