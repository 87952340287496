import { resetLoader, setLoader } from 'actions/loaders'
import axios from 'axios'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import paymentStatementsDeserializer from 'deserializers/payment_statements'
import { DEFAULT_API_CONFIG } from 'models'
import { PaymentStatement } from 'models/financial_reports'
import { useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { buildHeader } from 'services'
import OverlayLoader from 'components/modules/overlay/components/loader'

export default function PaymentStatementsList() {
  const params = useParams<{
    financial_report_id: string
  }>()
  const [page, setPage] = useState<number>(1)
  const history = useHistory()

  const fetchPaymentStatements = useQuery(
    ['paymentStatements', page],
    async () => {
      const uri = `/api/cms/v1/vtuber_payment_statements?financial_report_id=${params.financial_report_id}&page=${page}`
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.get(uri, {
        headers: buildHeader(),
      })

      return paymentStatementsDeserializer({
        data: response.data.vtuber_payment_statements,
        meta: response.data.meta,
      })
    }
  )

  if (fetchPaymentStatements.isLoading) return <OverlayLoader loading={true} />

  if (!fetchPaymentStatements.data) return <></>

  return (
    <>
      <MainHeader heading={'明細書リスト'} />
      <MainTable
        columns={{
          scale: '40% 60%',
          items: [
            { label: '明細書', grid: '1 / 2' },
            { label: 'タレント', grid: '2 / 3' },
          ],
        }}
        items={fetchPaymentStatements.data.data.map(
          (paymentStatement: PaymentStatement) => ({
            id: paymentStatement.id,
            data: {
              title: {
                id: paymentStatement.id,
                value: `${paymentStatement.financialReport.year}年${paymentStatement.financialReport.month}月明細書`,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              contributor: {
                id: paymentStatement.id,
                value: paymentStatement.contributor.name,
                clickable: false,
                subLabel: false,
                grid: '2 / 3',
              },
            },
          })
        )}
        selectItemHandler={(id: number) => {
          history.push(`/financial_reports/${id}/payment_statements/${id}`)
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ReactPaginate
            pageCount={fetchPaymentStatements.data.meta.totalPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
            onPageChange={(event: { selected: number }) => {
              setPage(event.selected + 1)
            }}
            previousLabel={
              <div>
                <span>&lt;</span>
              </div>
            }
            nextLabel={
              <div>
                <span>&gt;</span>
              </div>
            }
            breakLabel={'...'}
          />
        </div>
      </MainTable>
    </>
  )
}
