import { useState } from 'react'
import heic2any from 'heic2any'
import { useStyles } from 'hooks/useStyles'
import { typeGuard } from 'utils/type_guard'
import { MessageContent as Content, ContributorMessage } from 'models/messages'
import { useTextParse } from 'components/modules/preview/hooks/use_text_parse'
import { Contributor } from 'models/contributors'
import { setDmToRespond } from 'actions/messages'
import { useDispatch } from 'react-redux'

type Props = {
  contributor: Contributor
  message: ContributorMessage
  content: Content
}

const projectColor = process.env.REACT_APP_PROJECT_BASE_COLOR!

const MessageContent: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/message_content',
  })

  const [parseText] = useTextParse()

  const [loadingImg, setLoadingImg] = useState<boolean>(false)
  const [previewImg, setPreviewImg] = useState<string>()
  const [previewImgContentId, setPreviewImgContentId] = useState<number>()

  const getHeicBlob = async (url: string) => {
    setLoadingImg(true)
    await fetch(url, {
      method: 'GET',
      mode: 'cors',
    })
      .then((res: Response) => res.blob())
      .then((blob: Blob) => {
        heic2any({
          blob,
          toType: 'image/jpeg',
          quality: 1,
        }).then((result: Blob | Blob[]) => {
          setLoadingImg(false)
          if (!typeGuard<Blob>(result)) return
          const jpgImg = URL.createObjectURL(result)
          setPreviewImg(jpgImg)
        })
      })
  }

  const checkContentType = (url: string, contentId: number) => {
    const heic = url.match(/\.(heic|HEIC)$/)
    if (heic) {
      getHeicBlob(url)
      setPreviewImgContentId(contentId)
      return url
    } else {
      return url
    }
  }

  const premiumChat = props.message.messageType === 'premium_chat'
  const currentContributorMessage =
    props.contributor.name === props.message.contributor.name

  if (!styles) return <></>

  return (
    <div
      className={styles.default.message_content_component}
      style={
        premiumChat
          ? { justifyContent: 'flex-start' }
          : { justifyContent: 'flex-end' }
      }
    >
      <div className={styles.default.detail}>
        {props.content.contentType === 'text' && (
          <div
            className={styles.default.text_content}
            style={{
              background: premiumChat
                ? '#EFEFEF'
                : currentContributorMessage
                ? `#${projectColor}`
                : '#EFEFEF',
            }}
          >
            <pre
              dangerouslySetInnerHTML={{
                __html: parseText(props.content.body),
              }}
            ></pre>
          </div>
        )}
        {!loadingImg && props.content.contentType === 'image' && (
          <img
            src={
              (previewImgContentId &&
                previewImgContentId === props.content.id &&
                previewImg) ||
              checkContentType(props.content.image, props.content.id)
            }
            className={styles.default.image_content}
          />
        )}
        {props.content.contentType === 'video' && (
          <video
            src={props.content.video}
            controls
            className={styles.default.video_content}
          />
        )}
        {premiumChat && (
          <button
            className={styles.default.letter_request}
            onClick={() => dispatch(setDmToRespond({ message: props.message }))}
          >
            ❗️メッセージに返信する（
            {props.message.premiumChatRequest}
            消費されています）
          </button>
        )}
      </div>
      {props.content.voice && <audio src={props.content.voice} controls />}
    </div>
  )
}

export default MessageContent
