import { ContributorsAction } from 'actions/contributors'
import * as ActionType from 'actions/contributors/types'
import { ContributorsState } from 'models/contributors'

export const initialState: ContributorsState = {
  contributors: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  currentContributor: undefined,
  currentContributorAnalysis: {
    subscribersTransition: [],
    followSubscribers: { subscribers: 0 },
    trialSubscribers: { subscribers: 0 },
    billingSubscribers: { subscribers: 0 },
  },
  status: {
    startedGetContributors: false,
    succeededGetContributors: false,
    failedGetContributors: false,
    startedGetContributor: false,
    succeededGetContributor: false,
    failedGetContributor: false,
    startedCreateContributor: false,
    succeededCreateContributor: false,
    failedCreateContributor: false,
    startedUpdateContributor: false,
    succeededUpdateContributor: false,
    failedUpdateContributor: false,
    startedDeleteContributor: false,
    succeededDeleteContributor: false,
    failedDeleteContributor: false,
    startedUpdateAccountDetails: false,
    succeededUpdateAccountDetails: false,
    failedUpdateAccountDetails: false,
    startedGetContributorAnalysis: false,
    succeededGetContributorAnalysis: false,
    failedGetContributorAnalysis: false,
    startedUploadOGPImage: false,
    succeededUploadOGPImage: false,
    failedUploadOGPImage: false,
    startedUpdateContributorProfiles: false,
    succeededUpdateContributorProfiles: false,
    failedUpdateContributorProfiles: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const contributorsReducer = (
  state: ContributorsState = initialState,
  action: ContributorsAction
): ContributorsState => {
  switch (action.type) {
    case ActionType.STARTED_GET_CONTRIBUTORS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributors: true,
          succeededGetContributors: false,
          failedGetContributors: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CONTRIBUTORS:
      return {
        ...state,
        contributors: action.payload.contributors,
        status: {
          ...state.status,
          startedGetContributors: false,
          succeededGetContributors: true,
          failedGetContributors: false,
        },
      }
    case ActionType.FAILED_GET_CONTRIBUTORS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributors: false,
          succeededGetContributors: false,
          failedGetContributors: true,
        },
      }
    case ActionType.STARTED_GET_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributor: true,
          succeededGetContributor: false,
          failedGetContributor: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CONTRIBUTOR:
      return {
        ...state,
        currentContributor: action.payload.contributor,
        status: {
          ...state.status,
          startedGetContributor: false,
          succeededGetContributor: true,
          failedGetContributor: false,
        },
      }
    case ActionType.FAILED_GET_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributor: false,
          succeededGetContributor: false,
          failedGetContributor: true,
        },
      }
    case ActionType.STARTED_CREATE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateContributor: true,
          succeededCreateContributor: false,
          failedCreateContributor: false,
        },
      }
    case ActionType.SUCCEEDED_CREATE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateContributor: false,
          succeededCreateContributor: true,
          failedCreateContributor: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_CREATE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateContributor: false,
          succeededCreateContributor: false,
          failedCreateContributor: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_UPDATE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateContributor: true,
          succeededUpdateContributor: false,
          failedUpdateContributor: false,
        },
      }
    case ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateContributor: false,
          succeededUpdateContributor: true,
          failedUpdateContributor: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_UPDATE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateContributor: false,
          succeededUpdateContributor: false,
          failedUpdateContributor: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_DELETE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteContributor: true,
          succeededDeleteContributor: false,
          failedDeleteContributor: false,
        },
      }
    case ActionType.SUCCEEDED_DELETE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteContributor: false,
          succeededDeleteContributor: true,
          failedDeleteContributor: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_DELETE_CONTRIBUTOR:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteContributor: false,
          succeededDeleteContributor: false,
          failedDeleteContributor: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateAccountDetails: true,
          succeededUpdateAccountDetails: false,
          failedUpdateAccountDetails: false,
        },
      }
    case ActionType.SUCCEEDED_UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateAccountDetails: false,
          succeededUpdateAccountDetails: true,
          failedUpdateAccountDetails: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateAccountDetails: false,
          succeededUpdateAccountDetails: false,
          failedUpdateAccountDetails: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_GET_CONTRIBUTOR_ANALYSIS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributorAnalysis: true,
          succeededGetContributorAnalysis: false,
          failedGetContributorAnalysis: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CONTRIBUTOR_ANALYSIS:
      return {
        ...state,
        currentContributorAnalysis: action.payload.analysis,
        status: {
          ...state.status,
          startedGetContributorAnalysis: false,
          succeededGetContributorAnalysis: true,
          failedGetContributorAnalysis: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_GET_CONTRIBUTOR_ANALYSIS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributorAnalysis: false,
          succeededGetContributorAnalysis: false,
          failedGetContributorAnalysis: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_UPDATE_CONTRIBUTOR_PROFILES:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateContributorProfiles: true,
          succeededUpdateContributorProfiles: false,
          failedUpdateContributorProfiles: false,
        },
      }
    case ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR_PROFILES:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateContributorProfiles: false,
          succeededUpdateContributorProfiles: true,
          failedUpdateContributorProfiles: false,
        },
      }
    case ActionType.FAILED_UPDATE_CONTRIBUTOR_PROFILES:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateContributorProfiles: false,
          succeededUpdateContributorProfiles: false,
          failedUpdateContributorProfiles: true,
        },
      }
    case ActionType.STARTED_RESET_MESSAGES:
      return {
        ...state,
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_RESET_MESSAGES:
      return { ...state }
    case ActionType.FAILED_RESET_MESSAGES:
      return { ...state }
    case ActionType.STARTED_RESET_ALL_STATUS_FLAGS:
      return {
        ...state,
        status: {
          startedGetContributors: false,
          succeededGetContributors: false,
          failedGetContributors: false,
          startedGetContributor: false,
          succeededGetContributor: false,
          failedGetContributor: false,
          startedCreateContributor: false,
          succeededCreateContributor: false,
          failedCreateContributor: false,
          startedUpdateContributor: false,
          succeededUpdateContributor: false,
          failedUpdateContributor: false,
          startedDeleteContributor: false,
          succeededDeleteContributor: false,
          failedDeleteContributor: false,
          startedUpdateAccountDetails: false,
          succeededUpdateAccountDetails: false,
          failedUpdateAccountDetails: false,
          startedGetContributorAnalysis: false,
          succeededGetContributorAnalysis: false,
          failedGetContributorAnalysis: false,
          startedUploadOGPImage: false,
          succeededUploadOGPImage: false,
          failedUploadOGPImage: false,
          startedUpdateContributorProfiles: false,
          succeededUpdateContributorProfiles: false,
          failedUpdateContributorProfiles: false,
        },
      }
    case ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    case ActionType.FAILED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    default:
      return state
  }
}

export default contributorsReducer
