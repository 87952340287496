import React from 'react'
import './index.css'
import {
  Legend,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import { useDispatch, useSelector } from 'react-redux'

import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { SubscribersTransition } from 'models/analysis_dashboards'
import { Contributor, ContributorsState } from 'models/contributors'
import {
  getContributor,
  getContributors,
  getContributorAnalysis,
} from 'actions/contributors'

import Select from 'components/modules/select'

const Analysis: React.FC<{ match: any }> = props => {
  const dispatch = useDispatch()
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })

  const [subscribersTransition, setSubscribersTransition] = React.useState<
    SubscribersTransition[]
  >([])
  const [selectableContributors, setSelectableContributors] = React.useState<
    Array<{ label: string; value: number }>
  >([])
  const [brushStartIndex, setBrushStartIndex] = React.useState<number>(0)
  const [brushEndIndex, setBrushEndIndex] = React.useState<number>(0)
  const [selectedContributor, setSelectedContributor] =
    React.useState<number>(1)

  const changeContributorAnalysis = (selectedContributorId: number) => {
    setSelectedContributor(selectedContributorId)
    dispatch(getContributor.started({ id: selectedContributorId }))
  }

  const initializeAnalysisChart = () => {
    setSubscribersTransition(
      contributorsState.currentContributorAnalysis.subscribersTransition
    )

    if (
      contributorsState.currentContributorAnalysis.subscribersTransition
        .length <= 30
    ) {
      setBrushStartIndex(0)
    } else {
      setBrushStartIndex(
        contributorsState.currentContributorAnalysis.subscribersTransition
          .length - 31
      )
    }
    setBrushEndIndex(
      contributorsState.currentContributorAnalysis.subscribersTransition
        .length - 1
    )
  }

  React.useEffect(() => {
    if (
      contributorsState.contributors &&
      contributorsState.contributors.data.length > 0
    ) {
      if (selectableContributors.length === 0) {
        setSelectableContributors(
          contributorsState.contributors.data.map(
            (contributor: Contributor) => ({
              label: contributor.name,
              value: contributor.id,
            })
          )
        )
      }

      if (sessionsState.currentUser && !contributorsState.currentContributor) {
        if (
          sessionsState.currentUser.authType === 'Administrator' &&
          contributorsState.contributors.data.length > 0
        ) {
          if (location.pathname.match(/^\/contributors/)) {
            changeContributorAnalysis(props.match.params.id)
          } else {
            changeContributorAnalysis(contributorsState.contributors.data[0].id)
          }
        } else if (sessionsState.currentUser.authType === 'Contributor') {
          changeContributorAnalysis(sessionsState.currentUser.contributor.id)
        }
      }
    } else if (!contributorsState.status.succeededGetContributors) {
      sessionsState.currentUser &&
        sessionsState.currentUser.authType === 'Administrator' &&
        dispatch(getContributors.started({ page: 1 }))
    }

    initializeAnalysisChart()
  }, [
    contributorsState.status.succeededGetContributor,
    contributorsState.status.succeededGetContributors,
    contributorsState.status.succeededGetContributorAnalysis,
    subscribersTransition,
  ])

  React.useEffect(() => {
    if (
      sessionsState.currentUser &&
      contributorsState.currentContributor &&
      contributorsState.contributors
    ) {
      if (
        sessionsState.currentUser.authType === 'Administrator' &&
        contributorsState.contributors.data.length > 0
      ) {
        dispatch(
          getContributorAnalysis.started({
            id: contributorsState.currentContributor.id,
          })
        )
      } else if (sessionsState.currentUser.authType === 'Contributor') {
        dispatch(
          getContributorAnalysis.started({
            id: sessionsState.currentUser.contributor.id,
          })
        )
      }
    }
  }, [
    contributorsState.status.succeededGetContributor,
    contributorsState.currentContributor,
  ])

  return (
    <>
      <div className="analytics_header">
        <p>アナリティクス</p>
        {sessionsState.currentUser &&
          sessionsState.currentUser.authType === 'Administrator' &&
          !props.match.params.id && (
            <div className="analytics_header_contributor_select_wrapper">
              <Select
                type={'custom'}
                width={'320px'}
                selectableItems={selectableContributors}
                onChange={(value: any) => changeContributorAnalysis(value)}
              />
            </div>
          )}
      </div>
      <div className="analytics_body_wrapper">
        <div className="analytics_body">
          <div className="large_analytics_wrapper">
            <div className="large_analytics_label">
              <p>累計登録者数推移</p>
              <span>
                フォロー登録者数、トライアル登録者数、課金登録者数のそれぞれの推移と、それらの合計の推移を表します。
              </span>
            </div>
            <AreaChart
              width={831}
              height={320}
              data={subscribersTransition}
              margin={{ top: 40, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis
                dataKey="formattedSubscriptionDate"
                tick={{ fontSize: '.7rem' }}
              />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend verticalAlign="top" wrapperStyle={{ top: 4 }} />
              <Area
                name={'フォロー登録者数'}
                dataKey="followSubscribers"
                stroke="#17dbc9"
                fill="#17dbc9"
              />
              <Area
                name={'トライアル登録者数'}
                dataKey="trialSubscribers"
                stroke="#54cbe4"
                fill="#54cbe4"
              />
              <Area
                name={'課金登録者数'}
                dataKey="billingSubscribers"
                stroke="#32a8db"
                fill="#32a8db"
              />
            </AreaChart>
          </div>
          <div className="small_analytics_row_wrapper">
            <div className="small_analytics_row_wrapper_left">
              <div className="small_analytics_label">
                <p>現在のフォロー登録者</p>
                <span>現在フォロー登録しているユーザー数を表しています。</span>
              </div>
              <div className="small_analytics_text_result">
                <p>
                  {
                    contributorsState.currentContributorAnalysis
                      .followSubscribers.subscribers
                  }
                  <span>人</span>
                </p>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
          <div className="small_analytics_row_wrapper">
            <div className="small_analytics_row_wrapper_right">
              <div className="small_analytics_label">
                <p>現在の課金登録者数</p>
                <span>現在課金登録しているユーザー数を表しています。</span>
              </div>
              <div className="small_analytics_text_result">
                <p>
                  {contributorsState.currentContributorAnalysis
                    .billingSubscribers
                    ? contributorsState.currentContributorAnalysis
                        .billingSubscribers.subscribers
                    : 0}
                  <span>人</span>
                </p>
              </div>
            </div>
            <div className="small_analytics_row_wrapper_left">
              <div className="small_analytics_label">
                <p>現在のトライアル登録者数</p>
                <span>
                  現在トライアル登録しているユーザー数を表しています。
                </span>
              </div>
              <div className="small_analytics_text_result">
                <p>
                  {contributorsState.currentContributorAnalysis.trialSubscribers
                    ? contributorsState.currentContributorAnalysis
                        .trialSubscribers.subscribers
                    : 0}
                  <span>人</span>
                </p>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Analysis
