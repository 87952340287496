export const ascFileValidation = (value: File | null) => {
  let message = 'ファイルの入力値は正常です'
  let type = 'succeeded'
  if (!value) return { message, type }
  if (value.size === 0) {
    message = '添付されたファイルが破損している可能性があります'
    type = 'error'
    return { message, type }
  }
  if (value.type !== 'text/plain') {
    message = 'ファイルには TXT のみアップロードできます'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
