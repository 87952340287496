import React from 'react'
import Popup from 'reactjs-popup'

import Cross from 'images/cross.png'

const DeleteModal: React.FC<{
  trigger: JSX.Element
  deleteHandler: any
  targetId: number
}> = ({ trigger, deleteHandler, targetId }) => {
  return (
    <Popup
      trigger={trigger}
      contentStyle={{
        width: '373px',
        height: '158px',
        padding: '0px',
        border: 'none',
      }}
      modal
    >
      {(close: any) => (
        <div
          style={{ width: '100%' }}
          className="modal_module_inner_popup_content_wrapper"
        >
          <div className="modal_module_inner_popup_content_text_wrapper">
            <img src={Cross} onClick={() => close()} />
            <p>このメッセージを削除しますか？</p>
          </div>
          <div
            className="modal_module_inner_popup_content_btn_wrapper"
            style={{
              width: '373px',
            }}
          >
            <div
              className="modal_module_inner_popup_content_cancel_btn"
              onClick={() => close()}
            >
              <p>キャンセル</p>
            </div>
            <div
              className="modal_module_inner_popup_content_execute_btn"
              onClick={() => deleteHandler(targetId, close)}
            >
              <p>削除</p>
            </div>
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      )}
    </Popup>
  )
}

export default DeleteModal
