import MainHeader from 'components/modules/main_header'
import Button from 'components/modules/button'
import { useHistory, useParams } from 'react-router-dom'
import MainForm from 'components/modules/main_form'
import SPButton from 'components/modules/main_form/components/button'
import SPButtonSpacer from 'components/modules/main_form/components/button/components/spacer'
import SPButtonWrapper from 'components/modules/main_form/components/button_wrapper'
import { useResponsive } from 'hooks/useResponsive'
import DatetimeInput from 'components/modules/main_form/components/datetime_input'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as Save } from 'images/save.svg'
import { useCallback, useEffect, useState } from 'react'
import { format } from 'date-fns'
import { CombineReducers } from 'models'
import { useDispatch, useSelector } from 'react-redux'
import { SessionsState } from 'models/sessions'
import { getContributor } from 'actions/contributors'
import TextInput from 'components/modules/main_form/components/text_input'
import FileInput from 'components/modules/main_form/components/file_input'
import { useValidation } from 'hooks/useValidation'
import NumberInput from 'components/modules/main_form/components/number_input'
import SelectInput from 'components/modules/main_form/components/select_input'
import { createDigitalContent } from 'actions/digital_contents'

const NewVoiceContents: React.FC = () => {
  const [responsive] = useResponsive()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{ id: string }>()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const [image, setImage] = useState<File>()
  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [point, setPoint] = useState<string>('0')
  const [qrContent, setQrContent] = useState<boolean>(false)
  const [releasedAt, setReleasedAt] = useState<string>()
  const [file, setFile] = useState<File>()
  const [sampleFile, setSampleFile] = useState<File>()

  const titleHandler = (value: string) => {
    setTitle(value)
    useTitleValidation('digital_content_title', value)
  }
  const descriptionHandler = (value: string) => {
    setDescription(value)
    useDescriptionValidation('digital_content_description', value)
  }
  const imageHandler = (value: File) => {
    setImage(value)
    useImageValidation('digital_content_image', value)
  }
  const fileHandler = (value: File) => {
    setFile(value)
    useFileValidation('digital_content_voice', value)
  }
  const sampleFileHandler = (value: File) => {
    setSampleFile(value)
    useSampleFileValidation('digital_content_voice', value)
  }
  const [titleValidation, useTitleValidation] = useValidation({
    type: 'digital_content_title',
    value: title,
  })
  const [descriptionValidation, useDescriptionValidation] = useValidation({
    type: 'digital_content_description',
    value: description,
  })
  const [imageValidation, useImageValidation] = useValidation({
    type: 'digital_content_image',
    value: image,
  })
  const [fileValidation, useFileValidation] = useValidation({
    type: 'digital_content_voice',
    value: file,
  })
  const [sampleFileValidation, useSampleFileValidation] = useValidation({
    type: 'digital_content_voice',
    value: sampleFile,
  })

  const saveDigitalContent = () => {
    const formData = new FormData()
    formData.append('contributor_id', params.id)
    formData.append('title', title)
    formData.append('description', description)
    formData.append('point', point)
    formData.append('content_type', 'voice')
    formData.append('qr_content', qrContent ? 'true' : 'false')
    if (releasedAt) formData.append('released_at', releasedAt)
    if (image) formData.append('image', image)
    if (file) formData.append('file', file)
    if (sampleFile) formData.append('sample_file', sampleFile)

    dispatch(createDigitalContent.started({ data: formData }))
    history.push(`/contributors/${params.id}/voice_contents`)
  }

  const executable = useCallback(() => {
    if (!image || imageValidation.type === 'error') return false
    if (!file || fileValidation.type === 'error') return false
    if (sampleFile && sampleFileValidation.type === 'error') return false
    if (titleValidation.type === 'error') return false
    if (descriptionValidation.type === 'error') return false
    if (!releasedAt) return false
    return true
  }, [
    title,
    description,
    releasedAt,
    image,
    file,
    sampleFile,
    imageValidation,
    fileValidation,
    sampleFileValidation,
    titleValidation,
    descriptionValidation,
  ])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id: parseInt(params.id),
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  return (
    <>
      <MainHeader heading={'ボイスコンテンツ作成'}>
        {responsive === 'normal' && (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid rgb(224, 224, 224)'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <ButtonSpacer />
            <Button
              label={'保存する'}
              icon={Save}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable()}
              onClickHandler={saveDigitalContent}
            />
          </>
        )}
      </MainHeader>
      <MainForm>
        <FileInput
          label={'コンテンツ画像'}
          fileType={'IMAGE'}
          attachedFile={image}
          onChangeHandler={(value: File) => imageHandler(value)}
          currentPreview={image ? URL.createObjectURL(image) : undefined}
          croppable
        />
        <FileInput
          label={'コンテンツファイル'}
          fileType={'VOICE'}
          attachedFile={file}
          onChangeHandler={(value: File) => fileHandler(value)}
          currentPreview={file ? URL.createObjectURL(file) : undefined}
        />
        <FileInput
          label={'コンテンツサンプルファイル'}
          fileType={'VOICE'}
          attachedFile={sampleFile}
          onChangeHandler={(value: File) => sampleFileHandler(value)}
          currentPreview={
            sampleFile ? URL.createObjectURL(sampleFile) : undefined
          }
        />
        <TextInput
          label={'タイトル'}
          defaultValue={title}
          updateHandler={(value: string) => titleHandler(value)}
          wordCount={{
            max: 50,
            current: title ? title.length : 0,
          }}
          validation={titleValidation}
        />
        <TextInput
          label={'説明文'}
          defaultValue={description}
          textarea
          updateHandler={(value: string) => descriptionHandler(value)}
          wordCount={{
            max: 120,
            current: description ? description.length : 0,
          }}
          validation={descriptionValidation}
        />
        <NumberInput
          label={'購入ポイント'}
          defaultValue={point}
          updateHandler={(value: string) => setPoint(value)}
          min={0}
          max={999999}
        />
        <SelectInput
          label={'QR コード生成'}
          onChangeHandler={(item: { label: string; value: number | string }) =>
            setQrContent(item.value === 'true')
          }
          selectableItems={[
            { label: 'QR コードを生成する', value: 'true' },
            { label: 'QR コードを生成しない', value: 'false' },
          ]}
        />
        <DatetimeInput
          label={'公開日時'}
          onChangeHandler={e => {
            setReleasedAt(format(new Date(e.target.value), 'yyyy/MM/dd HH:mm'))
          }}
        />
        {(responsive === 'small' || responsive === 'medium') && (
          <SPButtonWrapper>
            <SPButton
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid #E0E0E0'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <SPButtonSpacer />
            <SPButton
              label={'保存する'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable()}
              onClickHandler={saveDigitalContent}
            />
          </SPButtonWrapper>
        )}
      </MainForm>
    </>
  )
}

export default NewVoiceContents
