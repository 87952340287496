import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { CombineReducers } from 'models'
import MenuItems from 'components/modules/side_pane_menu/components/menu_items'
import { composeRoutes, isContributorPath } from 'utils/routes_generator'

type Props = {
  store: CombineReducers
  closeSidePaneHandler: () => void
  sp?: boolean
}

const Administrator: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/side_pane_menu',
  })

  const contributorSidePane = () => {
    return (
      props.store.contributors.currentContributor &&
      isContributorPath(
        location.pathname,
        props.store.contributors.currentContributor
      )
    )
  }

  if (!styles || !props.store.sessions.currentUser) return <></>

  return (
    <>
      {contributorSidePane() && props.store.contributors.currentContributor ? (
        <div className={styles.default.side_pane_btns_component}>
          <div className={styles.default.distribution_header}>
            <span>個人配信</span>
          </div>
          <MenuItems
            routes={composeRoutes(
              props.store.sessions.currentUser.authType,
              'contributor_single',
              props.store.contributors.currentContributor.id
            )}
            closeSidePaneHandler={props.closeSidePaneHandler}
          />
        </div>
      ) : (
        <div className={styles.default.side_pane_btns_component}>
          <MenuItems
            routes={composeRoutes(
              props.store.sessions.currentUser.authType,
              'management'
            )}
            closeSidePaneHandler={props.closeSidePaneHandler}
          />
        </div>
      )}
    </>
  )
}

export default Administrator
