import React from 'react'
import Dropzone from 'react-dropzone'
import { useStyles } from 'hooks/useStyles'

const MediaInput: React.FC<{
  type: string
  setAudioVisual: (type: string, file: File) => void
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >
  selected: boolean
  disabled: boolean
}> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/messages/create/components/media_input',
  })

  if (!styles) return <></>

  return (
    <Dropzone
      onDrop={acceptedFiles =>
        props.setAudioVisual(props.type, acceptedFiles[0])
      }
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={
            props.selected
              ? styles.default.active_icon
              : styles.default.inactive_icon
          }
        >
          <input {...getInputProps()} disabled={props.disabled} />
          <props.icon />
        </div>
      )}
    </Dropzone>
  )
}

export default MediaInput
