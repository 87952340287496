import { FinancialReports } from 'models/financial_reports'

function financialReportsDeserializer(input: {
  data: any
  meta?: any
}): FinancialReports {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      year: item.year,
      month: item.month,
      status: item.status,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default financialReportsDeserializer
