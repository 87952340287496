import { useDispatch } from 'react-redux'
import { DEFAULT_API_CONFIG } from 'models'
import axios from 'axios'
import { UseMutationResult, useMutation } from 'react-query'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { useHistory } from 'react-router-dom'

export function useChangePassword(input: {
  password?: string
  confirmationPassword?: string
}): [changePassword: UseMutationResult<void, unknown, void, unknown>] {
  const dispatch = useDispatch()
  const history = useHistory()

  const changePassword = useMutation(
    async () => {
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      await instance.put('/api/cms/v1/auth/password', {
        password: input.password,
        password_confirmation: input.confirmationPassword,
        reset_password_token: new URLSearchParams(location.search).get(
          'reset_password_token'
        ),
      })
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: 'パスワードが変更されました' })
        )
        history.push('/sign_in')
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: 'パスワード変更に失敗しました',
          })
        )
        history.push('/sign_in')
      },
    }
  )

  return [changePassword]
}
