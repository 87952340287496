import { useStyles } from 'hooks/useStyles'
import { ContributorProfiles } from 'models/contributors'
import ItemLabel from 'components/account_profiles/detail/components/item_label'
import ItemValue from 'components/account_profiles/detail/components/item_value'

type Props = {
  label: string
  image?: string
  voice?: string
  inlineText?: string
  text?: string
  link?: { name: string; path: string }
  route?: { name: string; path: string }
  tags?: Array<{ label: string; bgColor: string; color: string }>
  profiles?: ContributorProfiles
}

const ItemRow: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/account_profiles/detail/components/item_row',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.item_row_component}>
      <ItemLabel label={props.label} />
      <ItemValue
        image={props.image}
        voice={props.voice}
        inlineText={props.inlineText}
        text={props.text}
        link={props.link}
        route={props.route}
        tags={props.tags}
        profiles={props.profiles}
      />
    </div>
  )
}

export default ItemRow
