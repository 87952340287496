// GET_CONTRIBUTOR_MESSAGES
export const STARTED_GET_CONTRIBUTOR_MESSAGES =
  'CONTRIBUTORS/STARTED_GET_CONTRIBUTOR_MESSAGES'
export const SUCCEEDED_GET_CONTRIBUTOR_MESSAGES =
  'CONTRIBUTORS/SUCCEEDED_GET_CONTRIBUTOR_MESSAGES'
export const FAILED_GET_CONTRIBUTOR_MESSAGES =
  'CONTRIBUTORS/FAILED_GET_CONTRIBUTOR_MESSAGES'

// GET_RESERVED_CONTRIBUTOR_MESSAGES
export const STARTED_GET_RESERVED_CONTRIBUTOR_MESSAGES =
  'CONTRIBUTORS/STARTED_GET_RESERVED_CONTRIBUTOR_MESSAGES'
export const SUCCEEDED_GET_RESERVED_CONTRIBUTOR_MESSAGES =
  'CONTRIBUTORS/SUCCEEDED_GET_RESERVED_CONTRIBUTOR_MESSAGES'
export const FAILED_GET_RESERVED_CONTRIBUTOR_MESSAGES =
  'CONTRIBUTORS/FAILED_GET_RESERVED_CONTRIBUTOR_MESSAGES'

// CREATE_CONTRIBUTOR_MESSAGE
export const STARTED_CREATE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/STARTED_CREATE_CONTRIBUTOR_MESSAGE'
export const SUCCEEDED_CREATE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/SUCCEEDED_CREATE_CONTRIBUTOR_MESSAGE'
export const FAILED_CREATE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/FAILED_CREATE_CONTRIBUTOR_MESSAGE'

// CREATE_CONTRIBUTOR_MESSAGE
export const STARTED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/STARTED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE'
export const SUCCEEDED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/SUCCEEDED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE'
export const FAILED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/FAILED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE'

// GET_CONTRIBUTOR_MESSAGE
export const STARTED_GET_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/STARTED_GET_CONTRIBUTOR_MESSAGE'
export const SUCCEEDED_GET_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/SUCCEEDED_GET_CONTRIBUTOR_MESSAGE'
export const FAILED_GET_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/FAILED_GET_CONTRIBUTOR_MESSAGE'

// UPDATE_CONTRIBUTOR_MESSAGE
export const STARTED_UPDATE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/STARTED_UPDATE_CONTRIBUTOR_MESSAGE'
export const SUCCEEDED_UPDATE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/SUCCEEDED_UPDATE_CONTRIBUTOR_MESSAGE'
export const FAILED_UPDATE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/FAILED_UPDATE_CONTRIBUTOR_MESSAGE'

// DELETE_CONTRIBUTOR_MESSAGE
export const STARTED_DELETE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/STARTED_DELETE_CONTRIBUTOR_MESSAGE'
export const SUCCEEDED_DELETE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/SUCCEEDED_DELETE_CONTRIBUTOR_MESSAGE'
export const FAILED_DELETE_CONTRIBUTOR_MESSAGE =
  'CONTRIBUTORS/FAILED_DELETE_CONTRIBUTOR_MESSAGE'

// GET_BOT_MESSAGES
export const STARTED_GET_CONTRIBUTOR_BOT_MESSAGES =
  'CONTRIBUTORS/STARTED_GET_CONTRIBUTOR_BOT_MESSAGES'
export const SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGES =
  'CONTRIBUTORS/SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGES'
export const FAILED_GET_CONTRIBUTOR_BOT_MESSAGES =
  'CONTRIBUTORS/FAILED_GET_CONTRIBUTOR_BOT_MESSAGES'

// GET_BOT_MESSAGE
export const STARTED_GET_CONTRIBUTOR_BOT_MESSAGE =
  'CONTRIBUTORS/STARTED_GET_CONTRIBUTOR_BOT_MESSAGE'
export const SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGE =
  'CONTRIBUTORS/SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGE'
export const FAILED_GET_CONTRIBUTOR_BOT_MESSAGE =
  'CONTRIBUTORS/FAILED_GET_CONTRIBUTOR_BOT_MESSAGE'

// UPDATE_BOT_MESSAGE
export const STARTED_UPDATE_CONTRIBUTOR_BOT_MESSAGE =
  'CONTRIBUTORS/STARTED_UPDATE_CONTRIBUTOR_BOT_MESSAGE'
export const SUCCEEDED_UPDATE_CONTRIBUTOR_BOT_MESSAGE =
  'CONTRIBUTORS/SUCCEEDED_UPDATE_CONTRIBUTOR_BOT_MESSAGE'
export const FAILED_UPDATE_CONTRIBUTOR_BOT_MESSAGE =
  'CONTRIBUTORS/FAILED_UPDATE_CONTRIBUTOR_BOT_MESSAGE'

// GET_PREVIEW_MESSAGES
export const STARTED_GET_PREVIEW_MESSAGES =
  'CONTRIBUTORS/STARTED_GET_PREVIEW_MESSAGES'
export const SUCCEEDED_GET_PREVIEW_MESSAGES =
  'CONTRIBUTORS/SUCCEEDED_GET_PREVIEW_MESSAGES'
export const FAILED_GET_PREVIEW_MESSAGES =
  'CONTRIBUTORS/FAILED_GET_PREVIEW_MESSAGES'

// GET_PREVIEW_RESERVED_MESSAGES
export const STARTED_GET_PREVIEW_RESERVED_MESSAGES =
  'CONTRIBUTORS/STARTED_GET_PREVIEW_RESERVED_MESSAGES'
export const SUCCEEDED_GET_PREVIEW_RESERVED_MESSAGES =
  'CONTRIBUTORS/SUCCEEDED_GET_PREVIEW_RESERVED_MESSAGES'
export const FAILED_GET_PREVIEW_RESERVED_MESSAGES =
  'CONTRIBUTORS/FAILED_GET_PREVIEW_RESERVED_MESSAGES'

// GET_LISTENER_DMS
export const STARTED_GET_LISTENER_DMS = 'MESSAGES/STARTED_GET_LISTENER_DMS'
export const SUCCEEDED_GET_LISTENER_DMS = 'MESSAGES/SUCCEEDED_GET_LISTENER_DMS'
export const FAILED_GET_LISTENER_DMS = 'MESSAGES/FAILED_GET_LISTENER_DMS'

// SET_INITIAL_STATE
export const SET_INITIAL_STATE = 'MESSAGES/SET_INITIAL_STATE'

// SET_DM_TO_RESPOND
export const SET_DM_TO_RESPOND = 'MESSAGES/SET_DM_TO_RESPOND'

// REMOVE_DM_TO_RESPOND
export const REMOVE_DM_TO_RESPOND = 'MESSAGES/REMOVE_DM_TO_RESPOND'

// RESET_ALL_STATUS_FLAGS
export const STARTED_RESET_ALL_STATUS_FLAGS =
  'CONTRIBUTORS/STARTED_RESET_ALL_STATUS_FLAGS'
export const SUCCEEDED_RESET_ALL_STATUS_FLAGS =
  'CONTRIBUTORS/SUCCEEDED_RESET_ALL_STATUS_FLAGS'
export const FAILED_RESET_ALL_STATUS_FLAGS =
  'CONTRIBUTORS/FAILED_RESET_ALL_STATUS_FLAGS'
