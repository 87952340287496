export const RESPONSE_OK = 200
export const RESPONSE_NO_CONTENT = 204

export const buildErrorLog = (
  status: number,
  expectedStatus: number,
  uri: string,
  method: string
) => {
  if (expectedStatus == RESPONSE_OK && status != RESPONSE_OK) {
    console.error(`ERROR: response from ${uri} in ${method}`)
  }
  if (expectedStatus == RESPONSE_NO_CONTENT && status != RESPONSE_NO_CONTENT) {
    console.error(`ERROR: response from ${uri} in ${method}`)
  }
}

export const buildHeader = (contentType?: string) => {
  if (contentType) {
    return {
      'Project-ID': process.env.REACT_APP_PROJECT_ID,
      'content-type': contentType,
      'access-token': localStorage.getItem('access-token'),
      client: localStorage.getItem('client'),
      uid: localStorage.getItem('uid'),
    }
  }

  return {
    'Project-ID': process.env.REACT_APP_PROJECT_ID,
    'access-token': localStorage.getItem('access-token'),
    client: localStorage.getItem('client'),
    uid: localStorage.getItem('uid'),
  }
}

export const setSessionTokens = (
  accessToken: string,
  client: string,
  uid: string
) => {
  localStorage.setItem('access-token', accessToken)
  localStorage.setItem('client', client)
  localStorage.setItem('uid', uid)
}
