import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getContributor } from 'actions/contributors'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import MainHeader from 'components/modules/main_header'
import Button from 'components/modules/button'
import Toast from 'components/modules/toast'
import { useStyles } from 'hooks/useStyles'
import { validateSession } from 'utils/session'
import { useContributor } from 'hooks/useContributor'
import { ToastsState } from 'models/toasts'
import { getDigitalContent } from 'actions/digital_contents'
import { DigitalContentsState } from 'models/digital_contents'
import MainBody from 'components/modules/main_body'
import ItemRow from 'components/account_profiles/detail/components/item_row'
import { ReactComponent as Edit } from 'images/edit.svg'
import Spacer from 'components/modules/button/components/spacer'

const VoiceContent: React.FC = () => {
  const [styles, setStyles] = useStyles({
    page: 'components/messages/list',
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{
    id: string
    digital_content_id: string
  }>()

  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const digitalContentsState: DigitalContentsState = useSelector(
    (state: CombineReducers) => {
      return state.digitalContents
    }
  )
  const toastsState: ToastsState = useSelector((state: CombineReducers) => {
    return state.toasts
  })

  const [user, contributor] = useContributor({ queryString: params })

  const selectItemHandler = (digitalContentId: number) => {
    history.push(
      `/contributors/${params.id}/voice_contents/${digitalContentId}`
    )
  }

  useEffect(() => {
    if (!validateSession()) history.push('/')
  }, [])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  useEffect(() => {
    if (contributorsState.currentContributor) {
      dispatch(
        getDigitalContent.started({
          id: Number(params.digital_content_id),
        })
      )
    }
  }, [contributorsState.status.succeededGetContributor])

  const digitalContent = digitalContentsState.digitalContent

  if (!styles || !user || !contributor || !digitalContent) return <></>

  return (
    <>
      <Toast body={toastsState.success.message} />
      <Toast body={toastsState.error.message} error />
      <MainHeader heading={'ボイスコンテンツ'}>
        <Button
          label={'キャンセル'}
          textColor={'#828282'}
          color={'#FFFFFF'}
          border={'2px solid rgb(224, 224, 224)'}
          disabled={false}
          onClickHandler={() =>
            history.push(`/contributors/${params.id}/voice_contents`)
          }
        />
        <Spacer />
        <Button
          label={'編集する'}
          icon={Edit}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            history.push(
              `/contributors/${params.id}/voice_contents/${
                digitalContentsState.digitalContent!.id
              }/edit`
            )
          }
        />
      </MainHeader>
      <MainBody>
        <ItemRow
          label={'コンテンツ画像'}
          image={digitalContentsState.digitalContent!.image}
        />
        <ItemRow
          label={'ボイスファイル'}
          voice={digitalContentsState.digitalContent!.file}
        />
        <ItemRow
          label={'サンプルボイスファイル'}
          voice={digitalContentsState.digitalContent!.sampleFile}
        />
        <ItemRow
          label={'タイトル'}
          inlineText={digitalContentsState.digitalContent!.title}
        />
        <ItemRow
          label={'説明文'}
          inlineText={digitalContentsState.digitalContent!.description}
        />
        <ItemRow
          label={'ポイント'}
          inlineText={digitalContentsState.digitalContent!.point}
        />
        {digitalContentsState.digitalContent!.qrContent ? (
          <ItemRow
            label={'QR コンテンツ'}
            link={{
              name: 'QR コード詳細を表示',
              path: digitalContentsState.digitalContent!.qrCode,
            }}
          />
        ) : (
          <ItemRow
            label={'QR コンテンツ'}
            inlineText={'QR コードは設定されていません'}
          />
        )}
        <ItemRow
          label={'公開日時'}
          inlineText={digitalContentsState.digitalContent!.releasedAt}
        />
      </MainBody>
    </>
  )
}

export default VoiceContent
