// CONTRIBUTORS
export const STARTED_GET_CONTRIBUTORS = 'CONTRIBUTORS/STARTED_GET_CONTRIBUTORS'
export const SUCCEEDED_GET_CONTRIBUTORS =
  'CONTRIBUTORS/SUCCEEDED_GET_CONTRIBUTORS'
export const FAILED_GET_CONTRIBUTORS = 'CONTRIBUTORS/FAILED_GET_CONTRIBUTORS'

// GET_CONTRIBUTOR
export const STARTED_GET_CONTRIBUTOR = 'CONTRIBUTORS/STARTED_GET_CONTRIBUTOR'
export const SUCCEEDED_GET_CONTRIBUTOR =
  'CONTRIBUTORS/SUCCEEDED_GET_CONTRIBUTOR'
export const FAILED_GET_CONTRIBUTOR = 'CONTRIBUTORS/FAILED_GET_CONTRIBUTOR'

// CREATE_CONTRIBUTOR
export const STARTED_CREATE_CONTRIBUTOR =
  'CONTRIBUTORS/STARTED_CREATE_CONTRIBUTOR'
export const SUCCEEDED_CREATE_CONTRIBUTOR =
  'CONTRIBUTORS/SUCCEEDED_CREATE_CONTRIBUTOR'
export const FAILED_CREATE_CONTRIBUTOR =
  'CONTRIBUTORS/FAILED_CREATE_CONTRIBUTOR'

// CREATE_CONTRIBUTOR
export const STARTED_UPDATE_CONTRIBUTOR =
  'CONTRIBUTORS/STARTED_UPDATE_CONTRIBUTOR'
export const SUCCEEDED_UPDATE_CONTRIBUTOR =
  'CONTRIBUTORS/SUCCEEDED_UPDATE_CONTRIBUTOR'
export const FAILED_UPDATE_CONTRIBUTOR =
  'CONTRIBUTORS/FAILED_UPDATE_CONTRIBUTOR'

// DELETE_CONTRIBUTOR
export const STARTED_DELETE_CONTRIBUTOR =
  'CONTRIBUTORS/STARTED_DELETE_CONTRIBUTOR'
export const SUCCEEDED_DELETE_CONTRIBUTOR =
  'CONTRIBUTORS/SUCCEEDED_DELETE_CONTRIBUTOR'
export const FAILED_DELETE_CONTRIBUTOR =
  'CONTRIBUTORS/FAILED_DELETE_CONTRIBUTOR'

// UPDATE_ACCOUNT_DETAILS
export const STARTED_UPDATE_ACCOUNT_DETAILS =
  'CONTRIBUTORS/STARTED_UPDATE_ACCOUNT_DETAILS'
export const SUCCEEDED_UPDATE_ACCOUNT_DETAILS =
  'CONTRIBUTORS/SUCCEEDED_UPDATE_ACCOUNT_DETAILS'
export const FAILED_UPDATE_ACCOUNT_DETAILS =
  'CONTRIBUTORS/FAILED_UPDATE_ACCOUNT_DETAILS'

// GET_CONTRIBUTOR_ANALYSIS
export const STARTED_GET_CONTRIBUTOR_ANALYSIS =
  'CONTRIBUTORS/STARTED_GET_CONTRIBUTOR_ANALYSIS'
export const SUCCEEDED_GET_CONTRIBUTOR_ANALYSIS =
  'CONTRIBUTORS/SUCCEEDED_GET_CONTRIBUTOR_ANALYSIS'
export const FAILED_GET_CONTRIBUTOR_ANALYSIS =
  'CONTRIBUTORS/FAILED_GET_CONTRIBUTOR_ANALYSIS'

// UPDATE_CONTRIBUTOR_PROFILES
export const STARTED_UPDATE_CONTRIBUTOR_PROFILES =
  'CONTRIBUTORS/STARTED_UPDATE_CONTRIBUTOR_PROFILES'
export const SUCCEEDED_UPDATE_CONTRIBUTOR_PROFILES =
  'CONTRIBUTORS/SUCCEEDED_UPDATE_CONTRIBUTOR_PROFILES'
export const FAILED_UPDATE_CONTRIBUTOR_PROFILES =
  'CONTRIBUTORS/FAILED_UPDATE_CONTRIBUTOR_PROFILES'

// RESET_MESSAGES
export const STARTED_RESET_MESSAGES = 'CONTRIBUTORS/STARTED_RESET_MESSAGES'
export const SUCCEEDED_RESET_MESSAGES = 'CONTRIBUTORS/SUCCEEDED_RESET_MESSAGES'
export const FAILED_RESET_MESSAGES = 'CONTRIBUTORS/FAILED_RESET_MESSAGES'

// RESET_ALL_STATUS_FLAGS
export const STARTED_RESET_ALL_STATUS_FLAGS =
  'CONTRIBUTORS/STARTED_RESET_ALL_STATUS_FLAGS'
export const SUCCEEDED_RESET_ALL_STATUS_FLAGS =
  'CONTRIBUTORS/SUCCEEDED_RESET_ALL_STATUS_FLAGS'
export const FAILED_RESET_ALL_STATUS_FLAGS =
  'CONTRIBUTORS/FAILED_RESET_ALL_STATUS_FLAGS'
