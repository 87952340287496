import { CombineReducers } from 'models'
import { User } from 'models/auth'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Contributor, ContributorsState } from 'models/contributors'
import { getContributor } from 'actions/contributors'

export function useAuth(input: { user?: User }): [contributor?: Contributor] {
  const dispatch = useDispatch()

  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => state.contributors
  )

  useEffect(() => {
    if (input.user && input.user.authType === 'Contributor') {
      dispatch(
        getContributor.started({
          id: input.user.contributor.id,
        })
      )
    }
  }, [])

  return [contributorsState.currentContributor]
}
