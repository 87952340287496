import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import Toast from 'components/modules/toast'
import { useHistory, useParams } from 'react-router-dom'
import { useContributor } from 'hooks/useContributor'
import { Listener, ListenersState } from 'models/listeners'
import Pagination from 'components/modules/pagination'
import { getDMSendingListeners } from 'actions/listeners'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { CombineReducers } from 'models'
import { MessagesState } from 'models/messages'

const DirectMessages: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{
    id: string
  }>()

  const [user, contributor] = useContributor({ queryString: params })

  const selectItemHandler = (listenerId: number) =>
    history.push(`/contributors/${params.id}/direct_messages/${listenerId}`)

  const messagesState: MessagesState = useSelector((state: CombineReducers) => {
    return state.messages
  })
  const listenersState: ListenersState = useSelector(
    (state: CombineReducers) => {
      return state.listeners
    }
  )

  const callApi = () => {
    dispatch(
      getDMSendingListeners.started({
        page: 1,
        contributorId: Number(params.id),
      })
    )
  }

  useEffect(() => {
    callApi()
  }, [])

  if (!user || !contributor) return <></>

  return (
    <>
      <Toast body={messagesState.success.message} error={false} />
      <Toast body={messagesState.error.message} error={true} />
      <Toast body={listenersState.success.message} error={false} />
      <Toast body={listenersState.error.message} error={true} />
      <MainHeader heading={'プレミアムチャット'} />
      <MainTable
        columns={{
          scale: '100%',
          items: [{ label: 'ユーザー名', grid: '1 / 2' }],
        }}
        items={listenersState.dmSendingListeners.data.map(
          (listener: Listener) => ({
            id: listener.id,
            data: {
              name: {
                id: listener.id,
                value: listener.name,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
            },
          })
        )}
        selectItemHandler={selectItemHandler}
      >
        <Pagination
          meta={listenersState.dmSendingListeners.meta!}
          dispatchedActions={getDMSendingListeners}
          actionArguments={{
            contributorId:
              user.authType === 'Administrator' ? contributor.id : user.id,
          }}
        />
      </MainTable>
    </>
  )
}

export default DirectMessages
