import { Dispatch } from 'react'
import { useStyles } from 'hooks/useStyles'
import InputItem from 'components/account_profiles/edit/components/profiles_input/components/input_item'
import { EditableProfile } from 'models/contributors'

type Props = {
  profiles: EditableProfile[]
  setProfiles: Dispatch<EditableProfile[]>
}

const ProfilesInput: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/account_profiles/edit/components/profiles_input',
  })

  const addProfileHandler = () => {
    props.setProfiles([
      ...props.profiles,
      {
        id:
          props.profiles.length > 0
            ? props.profiles[props.profiles.length - 1].id + 1
            : 1,
        title: '',
        description: '',
      },
    ])
  }

  const removeProfileHandler = (profileId: number) => {
    const restProfiles = props.profiles.filter((profile: EditableProfile) => {
      if (profile.id !== profileId) return profile
    })
    props.setProfiles(restProfiles)
  }

  const sortProfiles = (
    array: Array<{
      id: number
      title: string
      description: string
    }>
  ) => {
    array.sort((a, b) => {
      if (a.id > b.id) return 1
      if (a.id < b.id) return -1
      return 0
    })
  }

  const profileTitleHandler = (
    changedProfile: {
      id: number
      title: string
      description: string
    },
    value: string
  ) => {
    const targetProfile = props.profiles.find((profile: EditableProfile) => {
      if (profile.id === changedProfile.id) return profile
    })
    const restProfiles = props.profiles.filter((profile: EditableProfile) => {
      if (profile.id !== changedProfile.id) return profile
    })
    if (targetProfile && restProfiles) {
      const result = [...restProfiles, { ...targetProfile, title: value }]
      sortProfiles(result)
      props.setProfiles(result)
    }
  }

  const profileDescriptionHandler = (
    changedProfile: {
      id: number
      title: string
      description: string
    },
    value: string
  ) => {
    const targetProfile = props.profiles.find((profile: EditableProfile) => {
      if (profile.id === changedProfile.id) return profile
    })
    const restProfiles = props.profiles.filter((profile: EditableProfile) => {
      if (profile.id !== changedProfile.id) return profile
    })
    if (targetProfile && restProfiles) {
      const result = [...restProfiles, { ...targetProfile, description: value }]
      sortProfiles(result)
      props.setProfiles(result)
    }
  }

  if (!styles) return <></>

  return (
    <div className={styles.default.profiles_input_component}>
      <div className={styles.default.input_label}>
        <span>一問一答</span>
      </div>
      <div className={styles.default.input_field}>
        {props.profiles.map((profile: EditableProfile, index: number) => (
          <InputItem
            key={index}
            profile={profile}
            profileTitleHandler={profileTitleHandler}
            profileDescriptionHandler={profileDescriptionHandler}
            removeProfileHandler={removeProfileHandler}
          />
        ))}
        <button onClick={addProfileHandler}>追加</button>
      </div>
    </div>
  )
}

export default ProfilesInput
