import React from 'react'
import { useStyles } from 'hooks/useStyles'

type Props = {
  label: string
  color?: string
  disabledColor?: string
  textColor?: string
  disabledTextColor?: string
  border?: string
  disabled: boolean
  onClickHandler: () => void
}

const Button: React.FC<Props> = ({
  label,
  color = '#17DBC9',
  disabledColor = '#E0E0E0',
  textColor = '#FFFFFF',
  disabledTextColor = '#FFFFFF',
  border = 'none',
  disabled,
  onClickHandler,
}) => {
  const [styles, setStyles] = useStyles({
    page: 'components/auth/components/button',
  })

  if (!styles) return <></>

  return (
    <button
      style={{
        color: disabled ? disabledTextColor : textColor,
        backgroundColor: disabled ? disabledColor : color,
        border: border,
      }}
      className={disabled ? styles.default.disabled : styles.default.enabled}
      onClick={onClickHandler}
      disabled={disabled}
    >
      {label}
    </button>
  )
}

export default Button
