import { useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { Tag } from 'models/tags'
import { ReactComponent as Cross } from 'images/cross_black.svg'

type Props = {
  label: string
  selectedTags: Tag[]
  selectableTags: Tag[]
  selectTagHandler: (tag: Tag) => void
  removeTagHandler: (tag: Tag[]) => void
}

const TagSelectorInput: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/tag_selector_input',
  })

  const [showSelectableTags, setShowSelectableTags] = useState<boolean>(false)

  const removeTagHandler = (tag: Tag) => {
    if (!props.selectedTags) return
    const restTags = props.selectedTags.filter((selectedTag: Tag) => {
      if (selectedTag.id !== tag.id) {
        return selectedTag
      }
    })

    props.removeTagHandler(restTags)
  }

  if (!styles) return <></>

  return (
    <div className={styles.default.tag_selector_input_component}>
      <div className={styles.default.input_label}>{props.label}</div>
      <div className={styles.default.input_field}>
        <div className={styles.default.tags_edit}>
          <div className={styles.default.tag_items}>
            {props.selectedTags &&
              props.selectedTags.map((tag: Tag) => (
                <div key={tag.id} className={styles.default.tag_item}>
                  <div
                    className={styles.default.label}
                    style={{
                      backgroundColor: tag.bgColor,
                      color: tag.letterColor,
                    }}
                  >
                    <span>#{tag.label}</span>
                  </div>
                  <Cross onClick={() => removeTagHandler(tag)} />
                </div>
              ))}
          </div>
          <div className={styles.default.add_tag_btn}>
            <button onClick={() => setShowSelectableTags(!showSelectableTags)}>
              {showSelectableTags ? '選択を閉じる' : 'タグを追加する'}
            </button>
          </div>
          {showSelectableTags && (
            <div className={styles.default.tags_select}>
              {props.selectableTags &&
                props.selectableTags.map((tag: Tag) => (
                  <div
                    key={tag.id}
                    className={styles.default.selectable}
                    onClick={() => props.selectTagHandler(tag)}
                  >
                    {tag.label}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TagSelectorInput
