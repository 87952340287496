import React, { useState, useEffect } from 'react'
import { useStyles } from 'hooks/useStyles'
import './index.css'
import { useDispatch, useSelector } from 'react-redux'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import { getContributor, updateContributor } from 'actions/contributors'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import Toast from 'components/modules/toast'
import MainHeader from 'components/modules/main_header'
import ItemRow from 'components/accounts/detail/components/item_row'
import { useSignOut } from 'hooks/api/useSignOut'
import { useRevokeXToken } from 'hooks/api/useRevokeXToken'

const Account: React.FC<{ match: any }> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/accounts/detail',
  })
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const [signOut] = useSignOut()
  const [revokeXToken] = useRevokeXToken()
  const [xApiRefreshTokenExpiresDate, setXApiRefreshTokenExpiresDate] =
    useState<string>()

  const [email, setEmail] = useState<string>()
  const [inAppPurchaseApprovalEditable, setInAppPurchaseApprovalEditable] =
    useState<boolean>(false)
  const [inAppPurchaseApproval, setInAppPurchaseApproval] =
    useState<boolean>(false)

  const inAppPurchaseApprovalRegistrationHandler = () => {
    if (!contributorsState.currentContributor) return

    const formData = new FormData()
    formData.append('is_approved', inAppPurchaseApproval.toString())

    dispatch(
      updateContributor.started({
        id: contributorsState.currentContributor.id,
        data: formData,
      })
    )
    setInAppPurchaseApprovalEditable(false)
  }

  const isAdmin = () =>
    sessionsState.currentUser &&
    sessionsState.currentUser.authType === 'Administrator'
  const isAdminOrManager = () => isAdmin()
  useEffect(() => {
    if (sessionsState.currentUser && !contributorsState.currentContributor) {
      dispatch(
        getContributor.started({
          id: isAdminOrManager()
            ? props.match.params.id
            : sessionsState.currentUser.contributor.id,
        })
      )
    }

    if (contributorsState.currentContributor) {
      setEmail(contributorsState.currentContributor.email)
      setInAppPurchaseApproval(contributorsState.currentContributor.isApproved)
      setXApiRefreshTokenExpiresDate(
        contributorsState.currentContributor.xAccount.refreshTokenExpiresDate
      )
    }
  }, [
    sessionsState.status.succeededGetCurrentUser,
    contributorsState.status.succeededGetContributor,
    contributorsState.status.succeededUpdateAccountDetails,
    contributorsState.status.failedUpdateAccountDetails,
  ])

  useEffect(() => {
    if (sessionsState.currentUser)
      dispatch(
        getContributor.started({
          id: isAdminOrManager()
            ? props.match.params.id
            : sessionsState.currentUser.contributor.id,
        })
      )
  }, [
    contributorsState.status.succeededUpdateContributor,
    contributorsState.status.failedUpdateContributor,
    contributorsState.status.succeededUpdateAccountDetails,
    contributorsState.status.failedUpdateAccountDetails,
  ])

  if (
    !styles ||
    !sessionsState.currentUser ||
    !contributorsState.currentContributor
  )
    return <></>

  return (
    <>
      <Toast body={contributorsState.success.message} error={false} />
      <Toast body={contributorsState.error.message} error={true} />
      <MainHeader heading={'アカウント情報'} />
      <div className={styles.default.account_component}>
        <div className={styles.default.item_rows}>
          <div className={styles.default.default_value_row}>
            <div className={styles.default.label}>
              <label>メールアドレス</label>
            </div>
            <div className={styles.default.value}>
              <span>{email}</span>
            </div>
          </div>
          {sessionsState.currentUser.authType === 'Administrator' && (
            <>
              {inAppPurchaseApprovalEditable ? (
                <ItemRow
                  type={'radio'}
                  label={'課金'}
                  onChangedString={(value: string) =>
                    setInAppPurchaseApproval(value === 'true')
                  }
                  defaultValue={String(inAppPurchaseApproval)}
                  radioSelectables={[
                    { label: '未承認', value: 'false' },
                    { label: '承認', value: 'true' },
                  ]}
                >
                  <Button
                    label={'保存'}
                    textColor={'#FFFFFF'}
                    disabledTextColor={'#FFFFFF'}
                    color={'#17DBC9'}
                    disabledColor={'#E0E0E0'}
                    disabled={false}
                    onClickHandler={() =>
                      inAppPurchaseApprovalRegistrationHandler()
                    }
                  />
                  <ButtonSpacer />
                  <Button
                    label={'キャンセル'}
                    textColor={'#828282'}
                    color={'#FFFFFF'}
                    border={'2px solid rgb(224, 224, 224)'}
                    disabled={false}
                    onClickHandler={() =>
                      setInAppPurchaseApprovalEditable(false)
                    }
                  />
                </ItemRow>
              ) : (
                <>
                  <div className={styles.default.default_value_row}>
                    <div className={styles.default.label}>
                      <label>課金</label>
                    </div>
                    <div className={styles.default.value}>
                      <span>
                        {contributorsState.currentContributor?.isApproved
                          ? '承認済'
                          : '未承認'}
                      </span>
                    </div>
                    <div
                      className={styles.default.change_btn}
                      onClick={() => setInAppPurchaseApprovalEditable(true)}
                    >
                      <span>変更</span>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          <div className={styles.default.default_value_row}>
            <div className={styles.default.label}>
              <label>X 連携設定</label>
            </div>
            <div className={styles.default.value}>
              <button
                className={styles.default.x_api_sync}
                onClick={() => {
                  window.open(
                    `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_X_API_OAUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_X_API_OAUTH_REDIRECT_URL}&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`,
                    '_blank',
                    'noreferrer'
                  )
                }}
              >
                {xApiRefreshTokenExpiresDate ? 'X 再連携' : 'X 連携'}
              </button>
              {xApiRefreshTokenExpiresDate && (
                <>
                  <p>現在のトークンの有効期限: {xApiRefreshTokenExpiresDate}</p>
                  <button
                    onClick={() => revokeXToken.mutate()}
                    className={styles.default.x_api_revoke}
                  >
                    X 連携を解除する
                  </button>
                </>
              )}
            </div>
          </div>
          <div className={styles.default.default_value_row}>
            <div className={styles.default.label}></div>
            <div className={styles.default.value}>
              <div className={styles.default.default_value_row}>
                <button
                  className={styles.default.sign_out_btn}
                  onClick={() => signOut.mutate()}
                >
                  ログアウト
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Account
