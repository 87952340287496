import MainHeader from 'components/modules/main_header'
import Button from 'components/modules/button'
import { useHistory, useParams } from 'react-router-dom'
import MainForm from 'components/modules/main_form'
import SPButton from 'components/modules/main_form/components/button'
import SPButtonSpacer from 'components/modules/main_form/components/button/components/spacer'
import SPButtonWrapper from 'components/modules/main_form/components/button_wrapper'
import { useResponsive } from 'hooks/useResponsive'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as Save } from 'images/save.svg'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { buildHeader } from 'services'
import { DEFAULT_API_CONFIG, CombineReducers } from 'models'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { SessionsState } from 'models/sessions'
import { getContributor } from 'actions/contributors'
import listenerDeserializer from 'deserializers/listener'

const NewMeetingTicket: React.FC = () => {
  const [responsive] = useResponsive()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{ id: string; listener_id: string }>()
  const queryClient = useQueryClient()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })

  const fetchListener = useQuery('listener', async () => {
    const uri = `/api/cms/v1/listeners/${params.listener_id}?contributor_id=${params.id}`
    const instance = axios.create({
      ...DEFAULT_API_CONFIG,
    })
    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    return listenerDeserializer({
      data: response.data.listener,
    })
  })

  const createMeetingTicket = useMutation(
    async () => {
      const formData = new FormData()
      formData.append('contributor_id', params.id)
      formData.append('listener_id', params.listener_id)

      const uri = '/api/cms/v1/meeting_tickets'
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.post(uri, formData, {
        headers: buildHeader('multipart/form-data'),
      })

      return response
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: '通話チケットを発行しました' })
        )
        queryClient.invalidateQueries('meetingSchedules')
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: '通話チケットの発行に失敗しました',
          })
        )
      },
      onSettled: () => {
        history.goBack()
      },
    }
  )

  const issueMeetingTicket = () => {
    createMeetingTicket.mutate()
  }

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id: parseInt(params.id),
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  if (!fetchListener.data) return <></>

  return (
    <>
      <MainHeader heading={'通話チケット発行'}>
        {responsive === 'normal' && (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid rgb(224, 224, 224)'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <ButtonSpacer />
            <Button
              label={'発行する'}
              icon={Save}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={false}
              onClickHandler={issueMeetingTicket}
            />
          </>
        )}
      </MainHeader>
      <MainForm>
        <div
          style={{
            maxWidth: responsive !== 'normal' ? '343px' : '100%',
            padding: '16px 0px',
          }}
        >
          <p>{fetchListener.data.name}さんに対して通話チケットを発行します。</p>
          <p>チケットは発行から1ヶ月間有効です。</p>
          <p>問題なければ「発行する」ボタンから発行を行なってください。</p>
        </div>
        {(responsive === 'small' || responsive === 'medium') && (
          <SPButtonWrapper>
            <SPButton
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid #E0E0E0'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <SPButtonSpacer />
            <SPButton
              label={'発行する'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={false}
              onClickHandler={issueMeetingTicket}
            />
          </SPButtonWrapper>
        )}
      </MainForm>
    </>
  )
}

export default NewMeetingTicket
