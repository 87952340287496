import { getContributor } from 'actions/contributors'
import { getMemberships } from 'actions/memberships'
import { getSubscriptionTier } from 'actions/subscription_tiers'
import Button from 'components/modules/button'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import Pagination from 'components/modules/pagination'
import { CombineReducers } from 'models'
import { Membership, MembershipsState } from 'models/memberships'
import { SessionsState } from 'models/sessions'
import { SubscriptionTiersState } from 'models/subscription_tiers'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const Memberships: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{
    id: string
    subscription_tier_id: string
  }>()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const membershipsState: MembershipsState = useSelector(
    (state: CombineReducers) => {
      return state.memberships
    }
  )
  const subscriptionTiersState: SubscriptionTiersState = useSelector(
    (state: CombineReducers) => {
      return state.subscriptionTiers
    }
  )

  const subscriptionTierTitle = (tier: string) => {
    switch (tier) {
      case 'bronze':
        return 'ブロンズプラン登録者'
      case 'silver':
        return 'シルバープラン登録者'
      case 'gold':
        return 'ゴールドプラン登録者'
      default:
        return 'プラン登録者'
    }
  }

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
      dispatch(
        getMemberships.started({
          contributorId: parseInt(params.id),
          subscriptionTierId: parseInt(params.subscription_tier_id),
          page: 1,
        })
      )
      dispatch(
        getSubscriptionTier.started({
          subscriptionTierId: parseInt(params.subscription_tier_id),
        })
      )
    }
  }, [])

  if (!subscriptionTiersState.subscriptionTier) return <></>

  return (
    <>
      <MainHeader
        heading={subscriptionTierTitle(
          subscriptionTiersState.subscriptionTier.tier
        )}
      >
        <Button
          label={'編集'}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            history.push(
              `/contributors/${params.id}/subscription_tiers/${subscriptionTiersState.subscriptionTier?.id}/edit`
            )
          }
        />
      </MainHeader>
      <MainTable
        columns={{
          scale: '25% 25% 25% 25%',
          items: [
            { label: 'ユーザー名', grid: '1 / 2' },
            { label: '継続歴', grid: '2 / 3' },
            { label: '生年月日', grid: '3 / 4' },
            { label: '性別', grid: '4 / 5' },
          ],
        }}
        items={membershipsState.memberships.data.map(
          (membership: Membership) => ({
            id: membership.id,
            data: {
              name: {
                id: membership.id,
                value: membership.name,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              membershipDuration: {
                id: membership.id,
                value: membership.membershipDuration,
                clickable: false,
                subLabel: true,
                grid: '2 / 3',
              },
              birthDate: {
                id: membership.id,
                value: membership.birthDate,
                clickable: false,
                subLabel: true,
                grid: '3 / 4',
              },
              gender: {
                id: membership.id,
                value: membership.gender,
                clickable: false,
                subLabel: true,
                grid: '4 / 5',
              },
            },
          })
        )}
        selectItemHandler={(membershipId: number) =>
          history.push(
            `/contributors/${params.id}/subscription_tiers/${params.subscription_tier_id}/memberships/${membershipId}`
          )
        }
      >
        <Pagination
          meta={membershipsState.memberships.meta!}
          dispatchedActions={getMemberships}
          actionArguments={{
            contributorId: parseInt(params.id),
            subscriptionTierId: parseInt(params.subscription_tier_id),
          }}
        />
      </MainTable>
    </>
  )
}

export default Memberships
