import axios from 'axios'
import contributorMessagesDeserializer from 'deserializers/contributor_messages/many'
import contributorMessageDeserializer from 'deserializers/contributor_messages/one'
import eventBotMessageDeserializer from 'deserializers/event_bot_message'
import eventBotMessagesDeserializer from 'deserializers/event_bot_messages'
import {
  RESPONSE_NO_CONTENT,
  RESPONSE_OK,
  buildErrorLog,
  buildHeader,
} from 'services'
import { loggingError } from 'services/interceptor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200

export const getContributorMessagesFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getContributorMessages = async (request: {
    contributorId: number
    page: number
  }) => {
    const method = 'getContributorMessagesFactory'
    const uri = `/api/cms/v1/contributor_messages?contributor_id=${request.contributorId}&page=${request.page}&type=delivered`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return contributorMessagesDeserializer({
      data: response.data.contributor_messages,
      meta: response.data.meta,
    })
  }
  return getContributorMessages
}

export const getReservedContributorMessagesFactory = (
  optionConfig?: ApiConfig
) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getReservedContributorMessages = async (request: {
    contributorId: number
    page: number
  }) => {
    const method = 'getReservedContributorMessagesFactory'
    const uri = `/api/cms/v1/contributor_messages?contributor_id=${request.contributorId}&page=${request.page}&type=reserved`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return contributorMessagesDeserializer({
      data: response.data.contributor_messages,
      meta: response.data.meta,
    })
  }
  return getReservedContributorMessages
}

export const createContributorMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const createContributorMessage = async (request: { data: FormData }) => {
    const method = 'createContributorMessageFactory'
    const uri = '/api/cms/v1/contributor_messages'

    const response = await instance.post(uri, request.data, {
      headers: buildHeader('multipart/form-data'),
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return createContributorMessage
}

export const getContributorMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getContributorMessage = async (request: { id: number }) => {
    const method = 'getContributorMessageFactory'
    const uri = `/api/cms/v1/contributor_messages/${request.id}`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return contributorMessageDeserializer({
      data: response.data.contributor_message,
      meta: response.data.meta,
    })
  }
  return getContributorMessage
}

export const deleteContributorMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const deleteContributorMessage = async (request: { id: number }) => {
    const method = 'deleteContributorMessageFactory'
    const uri = `/api/cms/v1/contributor_messages/${request.id}`

    const response = await instance.delete(uri, {
      headers: buildHeader('multipart/form-data'),
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return deleteContributorMessage
}

export const getContributorBotMessagesFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getContributorBotMessages = async (request: {
    contributorId: number
  }) => {
    const method = 'getContributorBotMessagesFactory'
    const uri = `/api/cms/v1/bot_messages?contributor_id=${request.contributorId}`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return eventBotMessagesDeserializer({
      data: response.data.bot_messages,
      meta: response.data.meta,
    })
  }
  return getContributorBotMessages
}

export const getContributorBotMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getContributorBotMessage = async (request: { id: number }) => {
    const method = 'getContributorBotMessageFactory'
    const uri = `/api/cms/v1/bot_messages/${request.id}`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return eventBotMessageDeserializer({
      data: response.data.bot_message,
    })
  }
  return getContributorBotMessage
}

export const updateContributorBotMessageFactory = (
  optionConfig?: ApiConfig
) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateContributorMessage = async (request: {
    id: number
    data: FormData
  }) => {
    const method = 'updateContributorBotMessageFactory'
    const uri = `/api/cms/v1/bot_messages/${request.id}`

    const response = await instance.put(uri, request.data, {
      headers: buildHeader('multipart/form-data'),
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return updateContributorMessage
}

export const getPreviewMessagesFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getPreviewMessages = async (request: {
    contributorId: number
    page: number
  }) => {
    const method = 'getPreviewMessagesFactory'
    const uri = `/api/cms/v1/contributor_messages?contributor_id=${request.contributorId}&page=${request.page}&type=delivered_previews`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return contributorMessagesDeserializer({
      data: response.data.contributor_messages,
      meta: response.data.meta,
    })
  }
  return getPreviewMessages
}

export const getPreviewReservedMessagesFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getPreviewReservedMessages = async (request: {
    contributorId: number
    page: number
  }) => {
    const method = 'getPreviewReservedMessagesFactory'
    const uri = `/api/cms/v1/contributor_messages?contributor_id=${request.contributorId}&page=${request.page}&type=reserved_previews`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return contributorMessagesDeserializer({
      data: response.data.contributor_messages,
      meta: response.data.meta,
    })
  }
  return getPreviewReservedMessages
}

export const getListenerDMsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getListenerDMs = async (request: {
    contributorId: number
    listenerId: number
    page: number
  }) => {
    const response = await instance.get(
      `/api/cms/v1/contributor_messages?contributor_id=${request.contributorId}&listener_id=${request.listenerId}&page=${request.page}&type=listener_previews`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/contributor_messages?contributor_id=${request.contributorId}&page=${request.page}&type=listener_previews`,
        'getListenerDMsFactory'
      )

    return contributorMessagesDeserializer({
      data: response.data.contributor_messages,
      meta: response.data.meta,
    })
  }
  return getListenerDMs
}
