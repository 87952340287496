import { useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { deleteContributorMessage } from 'actions/messages'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import { ReactComponent as Heart } from 'images/heart.svg'

type Props = {
  updatedAt: string
  liked: number
  messageType: string
  messageId: number
  reservation: boolean
  responseMessage: boolean
  directMessage: boolean
}

const SubContent: React.FC<Props> = props => {
  const dispatch = useDispatch()

  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/sub_content',
  })

  const [showDeleteMessagePopup, setShowDeleteMessagePopup] =
    useState<boolean>(false)

  const [targetId, setTargetId] = useState<number>()

  const parseDateTime = (datetime: string) => {
    return moment(new Date(datetime)).format('HH:mm')
  }

  const parseYearDateTime = (datetime: string) => {
    return moment(new Date(datetime)).format('YYYY/MM/DD HH:mm')
  }

  const removeTargetHandler = (messageId: number) => {
    setShowDeleteMessagePopup(!showDeleteMessagePopup)
    setTargetId(messageId)
  }

  const removeMessageHandler = () => {
    targetId && dispatch(deleteContributorMessage.started({ id: targetId }))
    setShowDeleteMessagePopup(false)
  }

  const translateMessageType = (messageType: string) => {
    let message
    switch (messageType) {
      case 'premium_chat_response':
        message = 'プレチャ返信'
        break
      case 'public_message':
        message = '一般送信'
        break
      case 'premium_chat':
        message = 'プレミアムチャット'
        break
      default:
        message = '一般送信'
        break
    }
    return message
  }

  if (!styles) return <></>

  return (
    <div className={styles.default.sub_content_component}>
      {showDeleteMessagePopup && (
        <div className={styles.default.delete_message_popup}>
          <button onClick={() => removeMessageHandler()}>削除する</button>
        </div>
      )}
      <span>
        {props.reservation
          ? `${parseYearDateTime(props.updatedAt)}配信予約`
          : props.directMessage
          ? parseYearDateTime(props.updatedAt)
          : parseDateTime(props.updatedAt)}
        {!props.responseMessage && '・'}
      </span>
      {!props.reservation && !props.directMessage && <Heart />}
      {!props.reservation && !props.directMessage && (
        <span>{props.liked}・</span>
      )}
      {!props.directMessage && (
        <span>{translateMessageType(props.messageType)}</span>
      )}
      {!props.responseMessage && (
        <Bucket
          className={styles.default.delete_bucket}
          onClick={() => removeTargetHandler(props.messageId)}
        />
      )}
    </div>
  )
}

export default SubContent
