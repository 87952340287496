import { CombineReducers } from 'models'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ContributorMessages, MessagesState } from 'models/messages'
import { getListenerDMs } from 'actions/messages'

export function useDMs(input: {
  contributorId: string
  listenerId: string
}): [
  messages: ContributorMessages,
  executeGetListenerDMs: (contributorId: string, listenerId: string) => void
] {
  const dispatch = useDispatch()

  const messagesState: MessagesState = useSelector(
    (state: CombineReducers) => state.messages
  )

  const executeGetListenerDMs = (contributorId: string, listenerId: string) => {
    dispatch(
      getListenerDMs.started({
        contributorId: Number(contributorId),
        listenerId: Number(listenerId),
        page: 1,
      })
    )
  }

  useEffect(() => {
    dispatch(
      getListenerDMs.started({
        contributorId: Number(input.contributorId),
        listenerId: Number(input.listenerId),
        page: 1,
      })
    )
  }, [])

  return [messagesState.listenerDMs, executeGetListenerDMs]
}
