import axios from 'axios'
import { Profile } from 'models/contributors'
import analysisDashboardsDeserializer from 'deserializers/analysis_dashboards'
import {
  buildErrorLog,
  buildHeader,
  RESPONSE_NO_CONTENT,
  RESPONSE_OK,
} from 'services'
import contributorsDeserializer from 'deserializers/contributors'
import contributorDeserializer from 'deserializers/contributor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

export const getContributorsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getContributors = async (request: { page: number }) => {
    const method = 'getContributorsFactory'
    const uri = `/api/cms/v1/contributors?page=${request.page}`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    const contributors = contributorsDeserializer({
      data: response.data.contributors,
      meta: response.data.meta,
    })

    return contributors
  }
  return getContributors
}

export const getContributorFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getContributor = async (request: { id: number }) => {
    const method = 'getContributorFactory'
    const uri = `/api/cms/v1/contributors/${request.id}`

    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return contributorDeserializer({
      data: response.data.contributor,
    })
  }
  return getContributor
}

export const createContributorFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const createContributor = async (request: {
    accountType: string
    email: string
    password: string
    name: string
    englishName: string
  }) => {
    const method = 'createContributorFactory'
    const uri = '/api/cms/v1/auth'

    const response = await instance.post(
      uri,
      {
        account_type: request.accountType,
        email: request.email,
        password: request.password,
        name: request.name,
        english_name: request.englishName,
        project_id: process.env.REACT_APP_PROJECT_ID!,
      },
      {
        headers: buildHeader(),
      }
    )

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return createContributor
}

export const updateContributorFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateContributor = async (request: { id: number; data: FormData }) => {
    const method = 'createContributorFactory'
    const uri = `/api/cms/v1/contributors/${request.id}`

    const response = await instance.put(uri, request.data, {
      headers: buildHeader('multipart/form-data'),
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return updateContributor
}

export const deleteContributorFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const deleteContributor = async (request: { id: number }) => {
    const method = 'deleteContributorFactory'
    const uri = `/api/cms/v1/contributors/${request.id}`

    const response = await instance.delete(uri, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return deleteContributor
}

export const updateAccountDetailsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateAccountDetails = async (request: {
    auth: string
    type: string
    email?: string
    accountId: string
    password?: string
    currentPassword?: string
  }) => {
    const method = 'updateAccountDetailsFactory'
    const uri = '/api/cms/v1/auth'

    const response = await instance.put(
      uri,
      {
        auth: request.auth,
        type: request.type,
        email: request.email,
        account_id: request.accountId,
        password: request.password,
        current_password: request.currentPassword,
      },
      {
        headers: buildHeader(),
      }
    )

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return updateAccountDetails
}

export const getContributorAnalysisFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getContributorAnalysis = async (request: { id: number }) => {
    const method = 'getContributorAnalysisFactory'
    const uri = '/api/cms/v1/analysis_dashboards'

    const response = await instance.get(`${uri}?contributor_id=${request.id}`, {
      headers: buildHeader(),
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    const analytics = analysisDashboardsDeserializer({
      data: response.data,
    })

    return analytics
  }
  return getContributorAnalysis
}

export const updateContributorProfilesFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateContributorProfiles = async (request: {
    contributorId: number
    contributorProfiles: Profile[]
  }) => {
    const method = 'updateContributorProfilesFactory'
    const uri = '/api/cms/v1/contributors/update_profiles'

    const response = await instance.post(
      uri,
      {
        contributor_id: request.contributorId,
        profiles: request.contributorProfiles,
      },
      {
        headers: buildHeader(),
      }
    )

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return updateContributorProfiles
}
