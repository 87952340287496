import * as ActionType from 'actions/memberships/types'
import {
  Membership,
  MembershipBirthdays,
  Memberships,
} from 'models/memberships'

export const getMemberships = {
  started: (request: {
    contributorId: number
    subscriptionTierId: number
    page: number
  }) => ({
    type: ActionType.STARTED_GET_MEMBERSHIPS as typeof ActionType.STARTED_GET_MEMBERSHIPS,
    payload: request,
  }),
  succeeded: (response: { memberships: Memberships; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_MEMBERSHIPS as typeof ActionType.SUCCEEDED_GET_MEMBERSHIPS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_MEMBERSHIPS as typeof ActionType.FAILED_GET_MEMBERSHIPS,
    payload: response,
  }),
}

export const getMembership = {
  started: (request: { membershipId: number }) => ({
    type: ActionType.STARTED_GET_MEMBERSHIP as typeof ActionType.STARTED_GET_MEMBERSHIP,
    payload: request,
  }),
  succeeded: (response: { membership: Membership; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_MEMBERSHIP as typeof ActionType.SUCCEEDED_GET_MEMBERSHIP,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_MEMBERSHIP as typeof ActionType.FAILED_GET_MEMBERSHIP,
    payload: response,
  }),
}

export const getMembershipBirthdays = {
  started: (request: { contributorId: number; page: number }) => ({
    type: ActionType.STARTED_GET_MEMBERSHIP_BIRTHDAYS as typeof ActionType.STARTED_GET_MEMBERSHIP_BIRTHDAYS,
    payload: request,
  }),
  succeeded: (response: {
    membershipBirthdays: MembershipBirthdays
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_MEMBERSHIP_BIRTHDAYS as typeof ActionType.SUCCEEDED_GET_MEMBERSHIP_BIRTHDAYS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_MEMBERSHIP_BIRTHDAYS as typeof ActionType.FAILED_GET_MEMBERSHIP_BIRTHDAYS,
    payload: response,
  }),
}

export type MembershipsAction =
  | ReturnType<typeof getMemberships.started>
  | ReturnType<typeof getMemberships.succeeded>
  | ReturnType<typeof getMemberships.failed>
  | ReturnType<typeof getMembership.started>
  | ReturnType<typeof getMembership.succeeded>
  | ReturnType<typeof getMembership.failed>
  | ReturnType<typeof getMembershipBirthdays.started>
  | ReturnType<typeof getMembershipBirthdays.succeeded>
  | ReturnType<typeof getMembershipBirthdays.failed>
