import { getContributor } from 'actions/contributors'
import { getMembership } from 'actions/memberships'
import ItemRow from 'components/account_profiles/detail/components/item_row'
import MainBody from 'components/modules/main_body'
import MainHeader from 'components/modules/main_header'
import { CombineReducers } from 'models'
import { MembershipsState } from 'models/memberships'
import { SessionsState } from 'models/sessions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const Membership: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{
    id: string
    subscription_tier_id: string
    membership_id: string
  }>()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const membershipsState: MembershipsState = useSelector(
    (state: CombineReducers) => {
      return state.memberships
    }
  )

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
      dispatch(
        getMembership.started({
          membershipId: parseInt(params.membership_id),
        })
      )
    }
  }, [])

  if (!membershipsState.membership) return <></>

  return (
    <>
      <MainHeader heading={'メンバーシップ詳細'} />
      <MainBody>
        <ItemRow
          label={'会員番号'}
          inlineText={`No. ${membershipsState.membership.membershipSerialNumber}`}
        />
        <ItemRow
          label={'メンバーシップ継続期間'}
          inlineText={membershipsState.membership.membershipDuration}
        />
        <ItemRow
          label={'ユーザー名'}
          inlineText={membershipsState.membership.name}
        />
        <ItemRow
          label={'誕生日'}
          inlineText={membershipsState.membership.birthDate}
        />
        <ItemRow
          label={'性別'}
          inlineText={membershipsState.membership.gender}
        />
        <ItemRow
          label={'通話チケット数'}
          inlineText={String(membershipsState.membership.meetingTickets)}
        />
        <div style={{ padding: '16px' }}>
          <button
            style={{
              width: '300px',
              height: '40px',
              border: 'none',
              backgroundColor: '#17DBC9',
              color: '#FFFFFF',
              borderRadius: '10px',
              cursor: 'pointer',
            }}
            onClick={() =>
              history.push(
                `/contributors/${params.id}/direct_messages/${membershipsState.membership?.listenerId}`
              )
            }
          >
            プレミアムチャット
          </button>
        </div>
        <div style={{ padding: '16px' }}>
          <button
            style={{
              width: '300px',
              height: '40px',
              border: 'none',
              backgroundColor: '#17DBC9',
              color: '#FFFFFF',
              borderRadius: '10px',
              cursor: 'pointer',
            }}
            onClick={() =>
              history.push(
                `/contributors/${params.id}/meeting_tickets/${membershipsState.membership?.listenerId}`
              )
            }
          >
            通話チケット管理
          </button>
        </div>
      </MainBody>
    </>
  )
}

export default Membership
