import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getManualBotMessages,
  deleteManualBotMessage,
} from 'actions/manual_bot_messages'
import { CombineReducers } from 'models'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import Button from 'components/modules/button'
import Modal from 'components/modules/modal'
import ModalButton from 'components/modules/modal/components/button'
import Overlay from 'components/modules/overlay'
import { ReactComponent as Create } from 'images/create.svg'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import Pagination from 'components/modules/pagination'
import { useContributor } from 'hooks/useContributor'
import {
  ManualBotMessage,
  ManualBotMessagesState,
} from 'models/manual_bot_messages'

const ManualBotMessages: React.FC = () => {
  const history = useHistory()
  const params = useParams<{
    id: string
  }>()

  const dispatch = useDispatch()
  const manualBotMessagesState: ManualBotMessagesState = useSelector(
    (state: CombineReducers) => {
      return state.manualBotMessages
    }
  )

  const [user, contributor] = useContributor({ queryString: params })

  const [removeMessageModal, setRemoveMessageModal] = useState<boolean>(false)
  const [removeTarget, setRemoveTarget] = useState<number>()

  const showRemoveMessageModalHandler = (targetId: number) => {
    setRemoveMessageModal(true)
    setRemoveTarget(targetId)
  }

  const removeMessage = () => {
    setRemoveMessageModal(false)

    if (removeTarget) {
      const formData = new FormData()
      formData.append('id', String(removeTarget))
      dispatch(deleteManualBotMessage.started({ formData }))
    }
  }

  const selectItemHandler = (messageId: number) => {
    if (contributor) {
      history.push(
        `/contributors/${contributor.id}/manual_bot_messages/${messageId}`
      )
    }
  }

  useEffect(() => {
    if (contributor) {
      const formData = new FormData()
      formData.append('page', String(1))
      formData.append('count', String(10))
      formData.append('type', 'list')
      formData.append('contributor_id', String(contributor.id))
      dispatch(getManualBotMessages.started({ formData }))
    }
  }, [
    contributor,
    manualBotMessagesState.status.succeededCreateManualBotMessage,
    manualBotMessagesState.status.succeededDeleteManualBotMessage,
  ])

  if (!user || !contributor) return <></>

  return (
    <>
      {removeMessageModal && (
        <Overlay dismissHandler={() => setRemoveMessageModal(false)}>
          <Modal
            title={'bot メッセージを削除しますか？'}
            dismissHandler={() => setRemoveMessageModal(false)}
          >
            <ModalButton
              color={'#FFFFFF'}
              textColor={'#828282'}
              label={'キャンセル'}
              onClickHandler={() => setRemoveMessageModal(false)}
            />
            <ModalButton
              color={'#E25047'}
              textColor={'#FFFFFF'}
              label={'削除'}
              onClickHandler={() => removeMessage()}
            />
          </Modal>
        </Overlay>
      )}
      <MainHeader heading={'マニュアル bot リスト'}>
        <Button
          label={'新規作成'}
          icon={Create}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            history.push(
              `/contributors/${contributor.id}/manual_bot_messages/new`
            )
          }
        />
      </MainHeader>
      <MainTable
        columns={{
          scale: '40% 30% 30%',
          items: [
            { label: 'タイトル', grid: '1 / 2' },
            { label: '作成日時', grid: '2 / 3' },
            { label: '編集日時', grid: '3 / 4' },
          ],
        }}
        items={manualBotMessagesState.manualBotMessages.data.map(
          (message: ManualBotMessage) => ({
            id: message.id,
            data: {
              title: {
                id: message.id,
                value: message.title,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              createdAt: {
                id: message.id,
                value: message.createdAt,
                clickable: false,
                subLabel: false,
                grid: '2 / 3',
              },
              updatedAt: {
                id: message.id,
                value: message.updatedAt,
                clickable: false,
                subLabel: false,
                grid: '3 / 4',
              },
            },
          })
        )}
        selectItemHandler={selectItemHandler}
        option={{
          svg: Bucket,
          clickHandler: (targetId: number) => {
            showRemoveMessageModalHandler(targetId)
          },
        }}
      >
        <Pagination
          meta={manualBotMessagesState.manualBotMessages.meta!}
          dispatchedActions={getManualBotMessages}
          actionArguments={{
            contributorId:
              user.authType === 'Administrator' ? contributor.id : user.id,
          }}
        />
      </MainTable>
    </>
  )
}

export default ManualBotMessages
