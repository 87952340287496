import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/listeners/types'
import { getDMSendingListeners } from 'actions/listeners'
import { getDMSendingListenersFactory } from 'services/listeners/api'
import { Listener, Listeners } from 'models/listeners'

function* runGetDMSendingListeners(
  action: ReturnType<typeof getDMSendingListeners.started>
) {
  try {
    const result: Listeners = yield call(
      getDMSendingListenersFactory(),
      action.payload
    )

    yield put(
      getDMSendingListeners.succeeded({
        listeners: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getDMSendingListeners.failed({ message: '' }))
  }
}

export function* watchListenersSaga() {
  yield takeLatest(
    Action.STARTED_GET_DM_SENDING_LISTENERS,
    runGetDMSendingListeners
  )
}
