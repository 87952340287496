import { SubscriptionTiersAction } from 'actions/subscription_tiers'
import * as ActionType from 'actions/subscription_tiers/types'
import { SubscriptionTiersState } from 'models/subscription_tiers'

export const initialState: SubscriptionTiersState = {
  subscriptionTiers: [],
  subscriptionTier: undefined,
  status: {
    startedGetSubscriptionTiers: false,
    succeededGetSubscriptionTiers: false,
    failedGetSubscriptionTiers: false,
    startedGetSubscriptionTier: false,
    succeededGetSubscriptionTier: false,
    failedGetSubscriptionTier: false,
    startedUpdateSubscriptionTier: false,
    succeededUpdateSubscriptionTier: false,
    failedUpdateSubscriptionTier: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const subscriptionTiersReducer = (
  state: SubscriptionTiersState = initialState,
  action: SubscriptionTiersAction
): SubscriptionTiersState => {
  switch (action.type) {
    case ActionType.STARTED_GET_SUBSCRIPTION_TIERS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetSubscriptionTiers: true,
          succeededGetSubscriptionTiers: false,
          failedGetSubscriptionTiers: false,
        },
      }
    case ActionType.SUCCEEDED_GET_SUBSCRIPTION_TIERS:
      return {
        ...state,
        subscriptionTiers: action.payload.subscriptionTiers,
        status: {
          ...state.status,
          startedGetSubscriptionTiers: false,
          succeededGetSubscriptionTiers: true,
          failedGetSubscriptionTiers: false,
        },
      }
    case ActionType.FAILED_GET_SUBSCRIPTION_TIERS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetSubscriptionTiers: false,
          succeededGetSubscriptionTiers: false,
          failedGetSubscriptionTiers: true,
        },
      }
    case ActionType.STARTED_GET_SUBSCRIPTION_TIER:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetSubscriptionTier: true,
          succeededGetSubscriptionTier: false,
          failedGetSubscriptionTier: false,
        },
      }
    case ActionType.SUCCEEDED_GET_SUBSCRIPTION_TIER:
      return {
        ...state,
        subscriptionTier: action.payload.subscriptionTier,
        status: {
          ...state.status,
          startedGetSubscriptionTier: false,
          succeededGetSubscriptionTier: true,
          failedGetSubscriptionTier: false,
        },
      }
    case ActionType.FAILED_GET_SUBSCRIPTION_TIER:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetSubscriptionTier: false,
          succeededGetSubscriptionTier: false,
          failedGetSubscriptionTier: true,
        },
      }
    case ActionType.STARTED_UPDATE_SUBSCRIPTION_TIER:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateSubscriptionTier: true,
          succeededUpdateSubscriptionTier: false,
          failedUpdateSubscriptionTier: false,
        },
      }
    case ActionType.SUCCEEDED_UPDATE_SUBSCRIPTION_TIER:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateSubscriptionTier: false,
          succeededUpdateSubscriptionTier: true,
          failedUpdateSubscriptionTier: false,
        },
      }
    case ActionType.FAILED_UPDATE_SUBSCRIPTION_TIER:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateSubscriptionTier: false,
          succeededUpdateSubscriptionTier: false,
          failedUpdateSubscriptionTier: true,
        },
      }
    default:
      return state
  }
}

export default subscriptionTiersReducer
