import { useStyles } from 'hooks/useStyles'

type Props = {
  label: string
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
  textColor?: string
  disabledTextColor?: string
  color?: string
  disabledColor?: string
  border?: string
  disabled: boolean
  onClickHandler: () => void
}

const Button: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/button',
  })

  if (!styles) return <></>

  return (
    <div
      className={styles.default.button_component}
      style={{
        color: props.disabled ? props.disabledTextColor : props.textColor,
        backgroundColor: props.disabled ? props.disabledColor : props.color,
        height: props.border ? '34px' : '36px',
        border: props.border,
      }}
      onClick={props.disabled ? undefined : props.onClickHandler}
    >
      <span>{props.label}</span>
    </div>
  )
}

export default Button
