import { useState, useEffect } from 'react'
import '../index.css'
import { useSelector } from 'react-redux'
import { CombineReducers } from 'models'

import ContributorMenu from 'components/modules/side_pane_menu/components/contributor'
import AdministratorMenu from 'components/modules/side_pane_menu/components/administrator'

const SidePaneMenu: React.FC = () => {
  const stateStore: CombineReducers = useSelector((state: CombineReducers) => {
    return state
  })

  const [closeSidePane, setCloseSidePane] = useState<boolean>(false)
  const closeSidePaneHandler = () => setCloseSidePane(!closeSidePane)

  return (
    <>
      {stateStore.sessions.currentUser &&
        stateStore.sessions.currentUser.authType === 'Contributor' && (
          <ContributorMenu
            store={stateStore}
            closeSidePaneHandler={closeSidePaneHandler}
          />
        )}
      {stateStore.sessions.currentUser &&
        stateStore.sessions.currentUser.authType === 'Administrator' && (
          <AdministratorMenu
            store={stateStore}
            closeSidePaneHandler={closeSidePaneHandler}
          />
        )}
    </>
  )
}

export default SidePaneMenu
