import { useDispatch } from 'react-redux'
import { DEFAULT_API_CONFIG } from 'models'
import axios from 'axios'
import { UseMutationResult, useMutation } from 'react-query'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { useHistory } from 'react-router-dom'
import userDeserializer from 'deserializers/user'
import { User } from 'models/auth'
import { getCurrentUser } from 'actions/sessions'

export function useVerifyToken(): [
  verifyToken: UseMutationResult<User, unknown, void, unknown>
] {
  const dispatch = useDispatch()
  const history = useHistory()

  const verifyToken = useMutation(
    async () => {
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.post('/api/cms/v1/auth/sign_in', {
        token: new URLSearchParams(location.search).get('token'),
      })

      localStorage.setItem('access-token', response.headers['access-token'])
      localStorage.setItem('client', response.headers['client'])
      localStorage.setItem('uid', response.headers['uid'])

      return userDeserializer({
        data: response.data.user,
      })
    },
    {
      onSuccess: (data: User) => {
        dispatch(setSuccessToast.started({ message: 'ログインに成功しました' }))
        dispatch(getCurrentUser.started())
        history.push(`/contributors/${data.contributor.id}/messages`)
      },
      onError: () => {
        dispatch(setErrorToast.started({ message: 'ログインに失敗しました' }))
        history.push('/sign_in')
      },
    }
  )

  return [verifyToken]
}
