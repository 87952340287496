import { descriptionValidation } from './description'
import { iconValidation } from './icon'
import { nameValidation } from './name'
import { sampleVoiceValidation } from './sample_voice'
import { sampleVoiceLabelValidation } from './sample_voice_label'
import { profileTitleValidation } from './profile_title'
import { profileDescriptionValidation } from './profile_description'

export const contributorValidations = {
  description: descriptionValidation,
  icon: iconValidation,
  name: nameValidation,
  sampleVoice: sampleVoiceValidation,
  sampleVoiceLabel: sampleVoiceLabelValidation,
  profileTitle: profileTitleValidation,
  profileDescription: profileDescriptionValidation,
}
