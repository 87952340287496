import * as ActionType from 'actions/listeners/types'
import { ListenersAction } from 'actions/listeners'
import { ListenersState } from 'models/listeners'

export const initialState: ListenersState = {
  dmSendingListeners: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  status: {
    startedGetDMSendingListeners: false,
    succeededGetDMSendingListeners: false,
    failedGetDMSendingListeners: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const listenersReducer = (
  state: ListenersState = initialState,
  action: ListenersAction
): ListenersState => {
  switch (action.type) {
    case ActionType.STARTED_GET_DM_SENDING_LISTENERS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetDMSendingListeners: true,
          succeededGetDMSendingListeners: false,
          failedGetDMSendingListeners: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_GET_DM_SENDING_LISTENERS:
      return {
        ...state,
        dmSendingListeners: action.payload.listeners,
        status: {
          ...state.status,
          startedGetDMSendingListeners: false,
          succeededGetDMSendingListeners: true,
          failedGetDMSendingListeners: false,
        },
      }
    case ActionType.FAILED_GET_DM_SENDING_LISTENERS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetDMSendingListeners: false,
          succeededGetDMSendingListeners: false,
          failedGetDMSendingListeners: true,
        },
      }
    default:
      return state
  }
}

export default listenersReducer
