import { useStyles } from 'hooks/useStyles'

type Props = {
  dismissHandler: (event: React.MouseEvent<HTMLInputElement>) => void
  children: React.ReactNode
}

const Overlay: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/overlay',
  })

  if (!styles) return <></>

  return (
    <div
      className={styles.default.overlay_component}
      onClick={props.dismissHandler}
    >
      {props.children}
    </div>
  )
}

export default Overlay
