import React from 'react'

const Link: React.FC<{
  label: string
  onClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
  styles: { default: { readonly [key: string]: string } }
}> = ({ label, onClickHandler, styles }) => {
  return (
    <button
      style={{
        border: 'none',
        backgroundColor: 'transparent',
        textDecorationLine: 'underline',
        cursor: 'pointer',
        color: '#828282',
      }}
      onClick={onClickHandler}
    >
      {label}
    </button>
  )
}

export default Link
