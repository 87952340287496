import React from 'react'

const MessageDate: React.FC<{ deliveryDate: string }> = ({ deliveryDate }) => {
  return (
    <div className="messages_detail_content">
      <p className="messages_detail_content_title">配信日時</p>
      <p className="messages_detail_content_date">{deliveryDate}</p>
    </div>
  )
}

export default MessageDate
