import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useValidation } from 'hooks/useValidation'
import { useStyles } from 'hooks/useStyles'
import { useHistory } from 'react-router-dom'

import Template from 'components/auth/components/Template'
import FormText from 'components/auth/components/FormText'
import FormInput from 'components/auth/components/FormInput'
import Button from 'components/auth/components/button'
import Link from 'components/auth/components/Link'
import ErrorText from 'components/auth/components/ErrorText'
import { useSendEmail } from 'hooks/api/useResetPassword'

const ResetPassword: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState<string>()
  const [emailValidation, setEmailValidation] = useValidation({
    type: 'email',
    value: email,
  })
  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValidation('email', event.target.value)
    setEmail(event.target.value)
  }
  const [sendEmail] = useSendEmail({ email })
  const [styles, setStyles] = useStyles({
    page: 'components/auth',
  })
  const linkHandler = () => history.push('/')
  const buttonActivationCheck = () => {
    if (emailValidation?.type === 'error') return true
    return false
  }

  if (!styles) return <></>

  return (
    <Template label={'パスワードを変更する'} styles={styles}>
      {emailValidation?.type === 'error' && (
        <ErrorText message={emailValidation.message} styles={styles} />
      )}
      <FormText
        text="ご登録されているメールアドレスを入力してください。新しいパスワードを設定するURLを送信します。"
        styles={styles}
      />
      <FormInput
        label="メールアドレス"
        type="email"
        placeholder="メールアドレス"
        onChangeHandler={emailHandler}
        styles={styles}
      />
      <div className={styles.default.form_submit_btn_spacer} />
      <Button
        label={'送信'}
        onClickHandler={() => sendEmail.mutate()}
        disabled={buttonActivationCheck()}
      />
      <section className={styles.default.other_options}>
        <Link label="ログイン" onClickHandler={linkHandler} styles={styles} />
      </section>
    </Template>
  )
}

export default ResetPassword
