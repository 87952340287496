type Props = {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >
  onClick?: () => void
  width: string
  height: string
  fill?: string
  stroke?: string
  pointer?: boolean
}

const SVGWrapper: React.FC<Props> = props => {
  return (
    <props.icon
      fill={props.fill}
      stroke={props.stroke}
      strokeWidth={0.1}
      width={props.width}
      height={props.height}
      onClick={props.onClick}
      style={props.pointer ? { cursor: 'pointer' } : {}}
    />
  )
}

export default SVGWrapper
