import { ManualBotMessage, ManualBotMessages } from 'models/manual_bot_messages'

function manualBotMessagesDeserializer(input: {
  data: any
  meta?: any
}): ManualBotMessage | ManualBotMessages {
  return Array.isArray(input.data)
    ? {
        data: input.data.map((item: any) => ({
          id: item.id,
          title: item.title,
          body: item.body,
          contributorId: item.contributor_id,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
        })),
        meta: input.meta && {
          currentPage: input.meta.current_page,
          nextPage: input.meta.next_page,
          prevPage: input.meta.prev_page,
          totalCount: input.meta.total_count,
          totalPages: input.meta.total_pages,
        },
      }
    : {
        id: input.data.id,
        title: input.data.title,
        body: input.data.body,
        contributorId: input.data.contributor_id,
        createdAt: input.data.created_at,
        updatedAt: input.data.updated_at,
      }
}

export default manualBotMessagesDeserializer
