import { ManualBotMessagesAction } from 'actions/manual_bot_messages'
import * as ActionType from 'actions/manual_bot_messages/types'
import { ManualBotMessagesState } from 'models/manual_bot_messages'

export const initialState: ManualBotMessagesState = {
  currentManualBotMessage: null,
  manualBotMessages: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  status: {
    startedGetManualBotMessage: false,
    succeededGetManualBotMessage: false,
    failedGetManualBotMessage: false,
    startedGetManualBotMessages: false,
    succeededGetManualBotMessages: false,
    failedGetManualBotMessages: false,
    startedCreateManualBotMessage: false,
    succeededCreateManualBotMessage: false,
    failedCreateManualBotMessage: false,
    startedUpdateManualBotMessage: false,
    succeededUpdateManualBotMessage: false,
    failedUpdateManualBotMessage: false,
    startedDeleteManualBotMessage: false,
    succeededDeleteManualBotMessage: false,
    failedDeleteManualBotMessage: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const manualBotMessagesReducer = (
  state: ManualBotMessagesState = initialState,
  action: ManualBotMessagesAction
): ManualBotMessagesState => {
  switch (action.type) {
    case ActionType.STARTED_GET_MANUAL_BOT_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetManualBotMessage: true,
          succeededGetManualBotMessage: false,
          failedGetManualBotMessage: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_GET_MANUAL_BOT_MESSAGES:
      return {
        ...state,
        manualBotMessages: action.payload.manualBotMessages,
        status: {
          ...state.status,
          startedGetManualBotMessage: false,
          succeededGetManualBotMessage: true,
          failedGetManualBotMessage: false,
        },
      }
    case ActionType.FAILED_GET_MANUAL_BOT_MESSAGES:
      return {
        ...state,
        manualBotMessages: state.manualBotMessages,
        status: {
          ...state.status,
          startedGetManualBotMessage: false,
          succeededGetManualBotMessage: false,
          failedGetManualBotMessage: true,
        },
      }
    case ActionType.STARTED_GET_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetManualBotMessages: true,
          succeededGetManualBotMessages: false,
          failedGetManualBotMessages: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_GET_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        currentManualBotMessage: action.payload.manualBotMessage,
        status: {
          ...state.status,
          startedGetManualBotMessages: false,
          succeededGetManualBotMessages: true,
          failedGetManualBotMessages: false,
        },
      }
    case ActionType.FAILED_GET_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        currentManualBotMessage: null,
        status: {
          ...state.status,
          startedGetManualBotMessages: false,
          succeededGetManualBotMessages: false,
          failedGetManualBotMessages: true,
        },
      }
    case ActionType.STARTED_CREATE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateManualBotMessage: true,
          succeededCreateManualBotMessage: false,
          failedCreateManualBotMessage: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_CREATE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateManualBotMessage: false,
          succeededCreateManualBotMessage: true,
          failedCreateManualBotMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_CREATE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateManualBotMessage: false,
          succeededCreateManualBotMessage: false,
          failedCreateManualBotMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_UPDATE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateManualBotMessage: true,
          succeededUpdateManualBotMessage: false,
          failedUpdateManualBotMessage: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_UPDATE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateManualBotMessage: false,
          succeededUpdateManualBotMessage: true,
          failedUpdateManualBotMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_UPDATE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateManualBotMessage: false,
          succeededUpdateManualBotMessage: false,
          failedUpdateManualBotMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_DELETE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteManualBotMessage: true,
          succeededDeleteManualBotMessage: false,
          failedDeleteManualBotMessage: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_DELETE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteManualBotMessage: false,
          succeededDeleteManualBotMessage: true,
          failedDeleteManualBotMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_DELETE_MANUAL_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteManualBotMessage: false,
          succeededDeleteManualBotMessage: false,
          failedDeleteManualBotMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_RESET_ALL_STATUS_FLAGS:
      return {
        ...state,
        status: {
          startedGetManualBotMessage: false,
          succeededGetManualBotMessage: false,
          failedGetManualBotMessage: false,
          startedGetManualBotMessages: false,
          succeededGetManualBotMessages: false,
          failedGetManualBotMessages: false,
          startedCreateManualBotMessage: false,
          succeededCreateManualBotMessage: false,
          failedCreateManualBotMessage: false,
          startedUpdateManualBotMessage: false,
          succeededUpdateManualBotMessage: false,
          failedUpdateManualBotMessage: false,
          startedDeleteManualBotMessage: false,
          succeededDeleteManualBotMessage: false,
          failedDeleteManualBotMessage: false,
        },
      }
    case ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    case ActionType.FAILED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    default:
      return state
  }
}

export default manualBotMessagesReducer
