import listenerDeserializer from 'deserializers/listener'
import { MeetingReservations } from 'models/meeting_reservations'

function meetingReservationsDeserializer(input: {
  data: any
  meta?: any
}): MeetingReservations {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      startAt: item.start_at,
      listener: listenerDeserializer({ data: item.listener }),
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default meetingReservationsDeserializer
