import { MessageContents } from 'models/messages'

function messageContentsDeserializer(input: {
  data: any
  meta?: any
}): MessageContents {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      body: item.body,
      contentType: item.content_type,
      image: item.image,
      video: item.video,
      voice: item.voice,
      likes: item.likes,
      contributorMessageId: item.contributor_message_id,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default messageContentsDeserializer
