import React from 'react'
import './index.css'
import Contributors from 'components/accounts/list'
import ContributorMessages from 'components/messages/list'
import ReservedContributorMessages from 'components/reserved_messages/create'
import NewContributorMessages from 'components/messages/create'
import ContributorMessage from 'components/messages/detail'
import SPContributorMessage from 'components/messages/detail'
import NewContributor from 'components/accounts/create'
import Tags from 'components/tags/list'
import Analysis from 'components/analysis_dashboard/list'
import SPAnalysis from 'components/analysis_dashboard/list/sp'
import NewTag from 'components/tags/create'
import BotMessages from 'components/event_bot_messages/list'
import EditBotMessage from 'components/event_bot_messages/edit'
import Profile from 'components/account_profiles/detail'
import EditProfile from 'components/account_profiles/edit'
import Account from 'components/accounts/detail'
import MaintenanceWindows from 'components/maintenance_windows/list'
import Platforms from 'components/platforms/list'
import Platform from 'components/platforms/detail'
import DirectMessages from 'components/direct_messages/list'
import DirectMessage from 'components/direct_messages/detail'
import ManualBotMessages from 'components/manual_bot_messages/list'
import CreateManualBotMessage from 'components/manual_bot_messages/create'
import EditManualBotMessage from 'components/manual_bot_messages/edit'
import SubscriptionTiers from 'components/subscription_tiers/list'
import SubscriptionTier from 'components/memberships/list'
import EditSubscriptionTier from 'components/memberships/edit'
import Membership from 'components/memberships/detail'
import MembershipBirthdays from 'components/memberships/birthdays'
import MeetingSchedules from 'components/meeting_schedules/list'
import NewMeetingSchedule from 'components/meeting_schedules/new'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useUser } from 'hooks/useUser'
import NewMeetingTicket from 'components/meeting_tickets/new'
import MeetingReservations from 'components/meeting_reservations/list'
import XAPIToken from 'components/x_api_token'
import VoiceContents from 'components/voice_contents/list'
import NewVoiceContents from 'components/voice_contents/create'
import VoiceContent from 'components/voice_contents/detail'
import EditVoiceContent from 'components/voice_contents/edit'
import FinancialReportsList from 'components/financial_reports/list'
import FinancialReportCreate from 'components/financial_reports/create'
import PaymentStatementsList from 'components/payment_statements/list'
import PaymentStatementDetail from 'components/payment_statements/detail'

const Home: React.FC = () => {
  const [user] = useUser()
  const isSmartPhone = useMediaQuery({ query: '(max-width: 1023px)' })

  if (!user) return <></>

  return (
    <Switch>
      <Route exact path="/">
        <Redirect
          to={
            user.authType === 'Contributor'
              ? `/contributors/${user.contributor.id}/messages`
              : '/contributors'
          }
        />
      </Route>
      <Route path="/contributors" component={Contributors} exact />
      <Route path="/contributors/new" component={NewContributor} exact />
      <Route path="/tags" component={Tags} exact />
      <Route path="/tags/new" component={NewTag} exact />
      <Route
        path="/contributors/:id/messages"
        component={isSmartPhone ? NewContributorMessages : ContributorMessages}
        exact
      />
      <Route
        path="/contributors/:id/messages/:message_id"
        component={isSmartPhone ? SPContributorMessage : ContributorMessage}
        exact
      />
      <Route
        path="/contributors/:id/new_message"
        component={NewContributorMessages}
        exact
      />
      <Route
        path="/contributors/:id/reserved_messages"
        component={ReservedContributorMessages}
        exact
      />
      <Route
        path="/contributors/:id/bot_messages"
        component={BotMessages}
        exact
      />
      <Route
        path="/contributors/:id/bot_messages/:bot_message_id"
        component={EditBotMessage}
        exact
      />
      <Route
        path="/contributors/:id/manual_bot_messages"
        component={ManualBotMessages}
        exact
      />
      <Route
        path="/contributors/:id/manual_bot_messages/new"
        component={CreateManualBotMessage}
        exact
      />
      <Route
        path="/contributors/:id/manual_bot_messages/:manual_bot_message_id"
        component={EditManualBotMessage}
        exact
      />
      <Route
        path="/analysis"
        component={isSmartPhone ? SPAnalysis : Analysis}
        exact
      />
      <Route
        path="/contributors/:id/analysis"
        component={isSmartPhone ? SPAnalysis : Analysis}
        exact
      />
      <Route path="/contributors/:id/profile" component={Profile} exact />
      <Route
        path="/contributors/:id/profile/edit"
        component={EditProfile}
        exact
      />
      <Route path="/contributors/:id/account" component={Account} exact />
      <Route path="/maintenance_windows" component={MaintenanceWindows} exact />
      <Route path="/platforms" component={Platforms} exact />
      <Route path="/platforms/:id" component={Platform} exact />
      <Route
        path="/contributors/:id/direct_messages"
        component={DirectMessages}
        exact
      />
      <Route
        path="/contributors/:id/direct_messages/:listener_id"
        component={DirectMessage}
        exact
      />
      <Route
        path="/contributors/:id/subscription_tiers"
        component={SubscriptionTiers}
        exact
      />
      <Route
        path="/contributors/:id/subscription_tiers/:subscription_tier_id"
        component={SubscriptionTier}
        exact
      />
      <Route
        path="/contributors/:id/subscription_tiers/:subscription_tier_id/edit"
        component={EditSubscriptionTier}
        exact
      />
      <Route
        path="/contributors/:id/subscription_tiers/:subscription_tier_id/memberships/:membership_id"
        component={Membership}
        exact
      />
      <Route
        path="/contributors/:id/membership_birthdays"
        component={MembershipBirthdays}
        exact
      />
      <Route
        path="/contributors/:id/meeting_schedules"
        component={MeetingSchedules}
        exact
      />
      <Route
        path="/contributors/:id/meeting_schedules/new"
        component={NewMeetingSchedule}
        exact
      />
      <Route
        path="/contributors/:id/meeting_tickets/:listener_id"
        component={NewMeetingTicket}
        exact
      />
      <Route
        path="/contributors/:id/meeting_reservations"
        component={MeetingReservations}
        exact
      />
      <Route
        path="/contributors/:id/voice_contents"
        component={VoiceContents}
        exact
      />
      <Route
        path="/contributors/:id/voice_contents/new"
        component={NewVoiceContents}
        exact
      />
      <Route
        path="/contributors/:id/voice_contents/:digital_content_id"
        component={VoiceContent}
        exact
      />
      <Route
        path="/contributors/:id/voice_contents/:digital_content_id/edit"
        component={EditVoiceContent}
        exact
      />
      <Route path="/x_api_token" component={XAPIToken} exact />
      <Route path="/financial_reports" component={FinancialReportsList} exact />
      <Route
        path="/financial_reports/new"
        component={FinancialReportCreate}
        exact
      />
      <Route
        path="/financial_reports/:financial_report_id/payment_statements"
        component={PaymentStatementsList}
        exact
      />
      <Route
        path="/financial_reports/:financial_report_id/payment_statements/:payment_statement_id"
        component={PaymentStatementDetail}
        exact
      />
    </Switch>
  )
}

export default Home
