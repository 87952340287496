import MainForm from 'components/modules/main_form'
import FileInput from 'components/modules/main_form/components/file_input'
import MainHeader from 'components/modules/main_header'
import { useResponsive } from 'hooks/useResponsive'
import SPButton from 'components/modules/main_form/components/button'
import SPButtonSpacer from 'components/modules/main_form/components/button/components/spacer'
import SPButtonWrapper from 'components/modules/main_form/components/button_wrapper'
import { useCallback, useState } from 'react'
import { useValidation } from 'hooks/useValidation'
import { useHistory } from 'react-router-dom'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as Save } from 'images/save.svg'
import { useMutation } from 'react-query'
import axios from 'axios'
import { DEFAULT_API_CONFIG } from 'models'
import { buildHeader } from 'services'
import { useDispatch } from 'react-redux'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { resetLoader, setLoader } from 'actions/loaders'

export default function FinancialReportCreate() {
  const [responsive] = useResponsive()
  const history = useHistory()
  const dispatch = useDispatch()

  const [stripeFile, setStripeFile] = useState<File>()
  const [ascFile, setAscFile] = useState<File>()

  const stripeFileHandler = (value: File) => {
    setStripeFile(value)
    useStripeFileValidation('stripe_file', value)
  }
  const [stripeFileValidation, useStripeFileValidation] = useValidation({
    type: 'stripe_file',
    value: stripeFile,
  })

  const ascFileHandler = (value: File) => {
    setAscFile(value)
    useAscFileValidation('asc_file', value)
  }
  const [ascFileValidation, useAscFileValidation] = useValidation({
    type: 'asc_file',
    value: ascFile,
  })

  const lastMonth = () => {
    const now = new Date()
    const endOfLastMonth = new Date(now.getTime())
    endOfLastMonth.setDate(0)
    return endOfLastMonth.getMonth() + 1
  }

  const lastMonthYear = () => {
    const now = new Date()
    const endOfLastMonth = new Date(now.getTime())
    endOfLastMonth.setDate(0)
    return endOfLastMonth.getFullYear()
  }

  const createFinancialReport = useMutation(
    async () => {
      dispatch(setLoader.started())
      const formData = new FormData()

      const year = String(lastMonthYear())
      const month = String(lastMonth())
      formData.append('title', `明細収益${year}年${month}月`)
      formData.append('description', `明細収益${year}年${month}月`)
      formData.append('year', year)
      formData.append('month', month)
      formData.append('payment_statement', 'true')
      if (stripeFile) formData.append('stripe_file', stripeFile)
      if (ascFile) formData.append('asc_file', ascFile)

      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      await instance.post('/api/cms/v1/financial_reports', formData, {
        headers: buildHeader('multipart/form-data'),
      })
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: '財務レポートを作成しました' })
        )
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: '財務レポートの作成に失敗しました',
          })
        )
      },
      onSettled: () => {
        dispatch(resetLoader.started())
        history.goBack()
      },
    }
  )

  const executable = useCallback(() => {
    if (!stripeFile || stripeFileValidation.type === 'error') return false
    if (!ascFile || ascFileValidation.type === 'error') return false
    return true
  }, [stripeFile, ascFile, stripeFileValidation, ascFileValidation])

  return (
    <>
      <MainHeader heading={'支払明細書作成'}>
        {responsive === 'normal' && (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid rgb(224, 224, 224)'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <ButtonSpacer />
            <Button
              label={'作成する'}
              icon={Save}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable()}
              onClickHandler={() => createFinancialReport.mutate()}
            />
          </>
        )}
      </MainHeader>
      <MainForm>
        <p style={{ padding: '20px 0px' }}>
          {lastMonthYear()}年{lastMonth()}月のレポートを作成します。
        </p>
        <FileInput
          label={'Stripe 収益レポート(CSV)'}
          fileType={'CSV'}
          attachedFile={stripeFile}
          onChangeHandler={(value: File) => stripeFileHandler(value)}
        />
        <FileInput
          label={'AppStoreConnect 収益レポート(TXT)'}
          fileType={'TXT'}
          attachedFile={ascFile}
          onChangeHandler={(value: File) => ascFileHandler(value)}
        />
        {(responsive === 'small' || responsive === 'medium') && (
          <SPButtonWrapper>
            <SPButton
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid #E0E0E0'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <SPButtonSpacer />
            <SPButton
              label={'保存する'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable()}
              onClickHandler={() => createFinancialReport.mutate()}
            />
          </SPButtonWrapper>
        )}
      </MainForm>
    </>
  )
}
