import axios from 'axios'
import subscriptionTiersDeserializer from 'deserializers/subscription_tiers'
import { RESPONSE_NO_CONTENT, buildErrorLog, buildHeader } from 'services'
import { loggingError } from 'services/interceptor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200

export const getSubscriptionTiersFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getSubscriptionTiers = async (request: { contributorId: number }) => {
    const response = await instance.get(
      `/api/cms/v1/subscription_tiers?contributor_id=${request.contributorId}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/subscription_tiers?contributor_id=${request.contributorId}`,
        'getSubscriptionTiersFactory'
      )

    return subscriptionTiersDeserializer({
      data: response.data.subscription_tiers,
    })
  }
  return getSubscriptionTiers
}

export const getSubscriptionTierFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getSubscriptionTier = async (request: {
    subscriptionTierId: number
  }) => {
    const response = await instance.get(
      `/api/cms/v1/subscription_tiers/${request.subscriptionTierId}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/subscription_tiers/${request.subscriptionTierId}`,
        'getSubscriptionTierFactory'
      )

    return subscriptionTiersDeserializer({
      data: response.data.subscription_tier,
    })
  }
  return getSubscriptionTier
}

export const updateSubscriptionTierFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateContributor = async (request: { id: number; data: FormData }) => {
    const method = 'createContributorFactory'
    const uri = `/api/cms/v1/subscription_tiers/${request.id}`

    const response = await instance.put(uri, request.data, {
      headers: buildHeader('multipart/form-data'),
    })

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return updateContributor
}
