import { Listener } from 'models/listeners'

function listenerDeserializer(input: { data: any }): Listener {
  return {
    id: input.data.id,
    name: input.data.name,
    birthDate: input.data.birth_date,
    gender: input.data.gender,
    isWithdrawn: input.data.is_withdrawn,
    firstPlatform: input.data.first_platform,
    latestPlatform: input.data.latest_platform,
    registrationToken: input.data.registration_token,
    customerId: input.data.customer_id,
    userId: input.data.user_id,
  }
}

export default listenerDeserializer
