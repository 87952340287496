import { useStyles } from 'hooks/useStyles'
import { Howl } from 'howler'
import { ReactComponent as VoicePhone } from 'images/voice_phone.svg'
import { ReactComponent as VoicePlay } from 'images/voice_play.svg'
import { ReactComponent as VoicePause } from 'images/voice_pause.svg'
import { ReactComponent as Video } from 'images/video.svg'

type Props = {
  type: string
  messageText: string
  audioVisualMessage?: { type: string; file?: File }
  imagePreview?: string
  videoPreview?: string
  voicePreviewAudio?: Howl
  voicePreviewPlaying: boolean
  contributorName: string
  xPostEnabled: boolean
  controlPreviewVoice: () => void
  xPostHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
  dismissHandler: () => void
  executeHandler: () => void
}

const PrecheckMessage: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/precheck_message',
  })

  const parseText = (text: string) => {
    if (!text) return

    const urls = text.match(/https?:\/\/[\w/:%#$&!?()~.=+-]+/g)

    let urlReplacedText = text
    const replacedUrls: Array<{ index: number; url: string }> = []
    urls?.forEach((url: string, index: number) => {
      urlReplacedText = urlReplacedText.replace(url, `\\url-${index}`)
      replacedUrls.push({ index: index, url: url })
    })

    const parsedText = urlReplacedText
      .split(/(\n|\r|\r\n|\\urls|\\urle)/)
      .map((item, index) => {
        if (item.match(/(^\n$|^\r$|^$\r\n)/)) {
          return <br key={index} />
        }
        if (item.match(/^\\url-/)) {
          const i = item.match(/\\url-\d/)
          if (i) {
            let link
            replacedUrls.forEach((url: { index: number; url: string }) => {
              if (parseInt(i[0].replace(/\\url-/, ''), 10) === url.index)
                link = (
                  <a href={url.url} key={index} target="_blank" rel="noopener">
                    {url.url}
                  </a>
                )
            })
            return link
          }
        }
        return item
      })
    return parsedText
  }

  if (!styles) return <></>

  return (
    <div
      className={styles.default.precheck_message_component}
      onClick={(event: React.MouseEvent<HTMLDivElement>) =>
        event.stopPropagation()
      }
    >
      <div className={styles.default.main}>
        <div className={styles.default.header}>
          <span>以下のメッセージを送信します。</span>
        </div>
        <div className={styles.default.sub_header}>
          <span>
            {`${props.contributorName}（個人）`}
            からのメッセージとして送信されます。
          </span>
        </div>
        <div className={styles.default.content_list}>
          {props.messageText.length > 0 && (
            <div className={styles.default.content}>
              <div className={styles.default.content_field}>
                <div className={styles.default.text_content}>
                  <pre>{parseText(props.messageText)}</pre>
                </div>
              </div>
            </div>
          )}
          {props.audioVisualMessage &&
            props.audioVisualMessage.type === 'image' &&
            props.audioVisualMessage.file && (
              <div className={styles.default.content}>
                <div className={styles.default.content_field}>
                  <img
                    src={props.imagePreview}
                    className={styles.default.image_content}
                  />
                </div>
              </div>
            )}
          {props.audioVisualMessage &&
            props.audioVisualMessage.type === 'video' &&
            props.audioVisualMessage.file && (
              <div className={styles.default.content}>
                <div className={styles.default.content_field}>
                  <div className={styles.default.video_content}>
                    <Video />
                  </div>
                  <span className={styles.default.preview_caution}>
                    動画はファイルサイズによって表示に不具合が発生するためプレビューを表示していません。
                  </span>
                </div>
              </div>
            )}
          {props.audioVisualMessage &&
            props.audioVisualMessage.type === 'voice' &&
            props.audioVisualMessage.file && (
              <div className={styles.default.content}>
                <div className={styles.default.content_field}>
                  <div className={styles.default.voice_content}>
                    <div className={styles.default.voice_detail}>
                      <div className={styles.default.icon}>
                        <VoicePhone />
                      </div>
                      <div className={styles.default.label}>
                        <span>ボイスメッセージ</span>
                      </div>
                    </div>
                    <div className={styles.default.voice_control}>
                      {props.voicePreviewAudio && props.voicePreviewPlaying ? (
                        <VoicePause
                          onClick={() => props.controlPreviewVoice()}
                        />
                      ) : (
                        <VoicePlay
                          onClick={() => props.controlPreviewVoice()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      {props.xPostEnabled && (
        <div className={styles.default.option_btns}>
          <input
            type="checkbox"
            id="x_post"
            name="x_post"
            defaultChecked
            onChange={props.xPostHandler}
          />
          <label htmlFor="x_post">X にも通知をポストする</label>
        </div>
      )}
      <div className={styles.default.btns}>
        <div className={styles.default.cancel} onClick={props.dismissHandler}>
          <span>キャンセル</span>
        </div>
        <div className={styles.default.execute} onClick={props.executeHandler}>
          <span>送信</span>
        </div>
      </div>
    </div>
  )
}

export default PrecheckMessage
