import { useStyles } from 'hooks/useStyles'
import { ContributorMessage } from 'models/messages'
import Cross from 'images/cross.png'
import { ManualBotMessage } from 'models/manual_bot_messages'

type Props = {
  dm: ContributorMessage | null
  templateResponse: boolean
  closeHandler: () => void
  checkTemplateHandler: (checked: boolean) => void
  selectTemplateHandler: (templateId: number) => void
  manualBotMessages?: ManualBotMessage[]
}

const DMDetail: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/direct_messages/detail/components/dm_detail',
  })

  if (!styles || !props.dm) return <></>

  return (
    <div className={styles.default.dm_detail}>
      <div className={styles.default.metadata}>
        <h1 className={styles.default.title}>返信先メッセージ</h1>
        <img src={Cross} onClick={props.closeHandler} />
      </div>
      <div className={styles.default.direct_message}>
        <pre>{props.dm.text}</pre>
      </div>
      <div className={styles.default.template_response}>
        <h1 className={styles.default.template_title}>
          テンプレートを選択して送信
        </h1>
        <p className={styles.default.template_explanation}>
          テンプレート返信を行う場合はチェックした上でテンプレートを選択してください。
        </p>
        <input
          type="checkbox"
          defaultChecked={props.templateResponse}
          onChange={event => props.checkTemplateHandler(event.target.checked)}
        />
        {props.manualBotMessages && (
          <select
            onChange={event => {
              props.selectTemplateHandler(Number(event.target.value))
            }}
            className={styles.default.template_select}
            disabled={!props.templateResponse}
          >
            <option value={0}> - </option>
            {props.manualBotMessages.map((message: ManualBotMessage) => (
              <option key={message.id} value={message.id}>
                {message.title}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  )
}

export default DMDetail
