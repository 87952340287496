export const emailValidation = (value: string) => {
  let message = 'メールアドレスの入力値は正常です'
  let type = 'succeeded'
  if (value.length === 0) {
    message = 'メールアドレスを入力してください'
    type = 'error'
    return { message, type }
  }
  if (!value.match(/@.+\..+$/)) {
    message = 'メールアドレスの形式が正しくありません'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
