import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/contributors/types'
import {
  createContributor,
  updateContributor,
  deleteContributor,
  getContributor,
  getContributors,
  updateAccountDetails,
  getContributorAnalysis,
  updateContributorProfiles,
} from 'actions/contributors'
import { AnalysisDashboard } from 'models/analysis_dashboards'
import { Contributor, Contributors } from 'models/contributors'
import {
  getContributorFactory,
  getContributorsFactory,
  createContributorFactory,
  updateContributorFactory,
  deleteContributorFactory,
  updateAccountDetailsFactory,
  getContributorAnalysisFactory,
  updateContributorProfilesFactory,
} from 'services/contributors/api'

function* runGetContributors(
  action: ReturnType<typeof getContributors.started>
) {
  try {
    const result: Contributors = yield call(
      getContributorsFactory(),
      action.payload
    )

    yield put(getContributors.succeeded({ contributors: result, message: '' }))
  } catch (err) {
    yield put(getContributors.failed({ message: '' }))
  }
}

function* runGetContributor(action: ReturnType<typeof getContributor.started>) {
  try {
    const result: Contributor = yield call(
      getContributorFactory(),
      action.payload
    )

    yield put(getContributor.succeeded({ contributor: result, message: '' }))
  } catch (err) {
    yield put(getContributor.failed({ message: '' }))
  }
}

function* runCreateContributor(
  action: ReturnType<typeof createContributor.started>
) {
  try {
    yield call(createContributorFactory(), action.payload)

    yield put(
      createContributor.succeeded({ message: 'アカウントを新規登録しました' })
    )
  } catch (err) {
    yield put(
      createContributor.failed({
        message: 'アカウントの新規登録に失敗しました',
      })
    )
  }
}

function* runUpdateContributor(
  action: ReturnType<typeof updateContributor.started>
) {
  try {
    yield call(updateContributorFactory(), action.payload)

    yield put(
      updateContributor.succeeded({ message: 'プロフィールを保存しました' })
    )
  } catch (err) {
    yield put(
      updateContributor.failed({ message: 'プロフィールの保存に失敗しました' })
    )
  }
}

function* runDeleteContributor(
  action: ReturnType<typeof deleteContributor.started>
) {
  try {
    yield call(deleteContributorFactory(), action.payload)

    yield put(
      deleteContributor.succeeded({ message: '配信者が削除されました' })
    )
  } catch (err) {
    yield put(
      deleteContributor.failed({ message: '配信者の削除に失敗しました' })
    )
  }
}

function* runUpdateAccountDetails(
  action: ReturnType<typeof updateAccountDetails.started>
) {
  try {
    yield call(updateAccountDetailsFactory(), action.payload)

    yield put(
      updateAccountDetails.succeeded({
        message: action.payload.password
          ? 'パスワードを変更しました'
          : 'メールアドレスを保存しました',
      })
    )
  } catch (err) {
    yield put(
      updateAccountDetails.failed({
        message: action.payload.password
          ? 'パスワードの変更に失敗しました'
          : 'メールアドレスの保存に失敗しました',
      })
    )
  }
}

function* runGetContributorAnalysis(
  action: ReturnType<typeof getContributorAnalysis.started>
) {
  try {
    const result: AnalysisDashboard = yield call(
      getContributorAnalysisFactory(),
      action.payload
    )

    yield put(
      getContributorAnalysis.succeeded({
        message: '',
        analysis: result,
      })
    )
  } catch (err) {
    yield put(
      getContributorAnalysis.failed({
        message: '',
      })
    )
  }
}

function* runUpdateContributorProfiles(
  action: ReturnType<typeof updateContributorProfiles.started>
) {
  try {
    yield call(updateContributorProfilesFactory(), action.payload)

    yield put(
      updateContributorProfiles.succeeded({
        message: '',
      })
    )
  } catch (err) {
    yield put(updateContributorProfiles.failed({ message: '' }))
  }
}

export function* watchContributorsSaga() {
  yield takeLatest(Action.STARTED_GET_CONTRIBUTORS, runGetContributors)
  yield takeLatest(Action.STARTED_GET_CONTRIBUTOR, runGetContributor)
  yield takeLatest(Action.STARTED_CREATE_CONTRIBUTOR, runCreateContributor)
  yield takeLatest(Action.STARTED_UPDATE_CONTRIBUTOR, runUpdateContributor)
  yield takeLatest(Action.STARTED_DELETE_CONTRIBUTOR, runDeleteContributor)
  yield takeLatest(
    Action.STARTED_UPDATE_ACCOUNT_DETAILS,
    runUpdateAccountDetails
  )
  yield takeLatest(
    Action.STARTED_GET_CONTRIBUTOR_ANALYSIS,
    runGetContributorAnalysis
  )
  yield takeLatest(
    Action.STARTED_UPDATE_CONTRIBUTOR_PROFILES,
    runUpdateContributorProfiles
  )
}
