import { DigitalContents } from 'models/digital_contents'

function digitalContentsDeserializer(input: {
  data: any
  meta?: any
}): DigitalContents {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      title: item.title,
      description: item.description,
      point: item.point,
      contentType: item.content_type,
      qrContent: item.qr_content,
      releasedAt: item.released_at,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      file: item.file,
      sampleFile: item.sample_file,
      image: item.image,
      qrCode: item.qr_code,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default digitalContentsDeserializer
