import { Platform } from 'models/platforms'
import * as ActionType from './types'

export const getPlatforms = {
  started: () => ({
    type: ActionType.STARTED_GET_PLATFORMS as typeof ActionType.STARTED_GET_PLATFORMS,
  }),
  succeeded: (response: { platforms: Platform[]; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_PLATFORMS as typeof ActionType.SUCCEEDED_GET_PLATFORMS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_PLATFORMS as typeof ActionType.FAILED_GET_PLATFORMS,
    payload: response,
  }),
}

export const getPlatform = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_GET_PLATFORM as typeof ActionType.STARTED_GET_PLATFORM,
    payload: request,
  }),
  succeeded: (response: { platform: Platform; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_PLATFORM as typeof ActionType.SUCCEEDED_GET_PLATFORM,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_PLATFORM as typeof ActionType.FAILED_GET_PLATFORM,
    payload: response,
  }),
}

export const updatePlatforms = {
  started: (request: { id: number; version: string }) => ({
    type: ActionType.STARTED_UPDATE_PLATFORM as typeof ActionType.STARTED_UPDATE_PLATFORM,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_PLATFORM as typeof ActionType.SUCCEEDED_UPDATE_PLATFORM,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_PLATFORM as typeof ActionType.FAILED_UPDATE_PLATFORM,
    payload: response,
  }),
}

export const resetMessages = {
  started: () => ({
    type: ActionType.STARTED_RESET_MESSAGES as typeof ActionType.STARTED_RESET_MESSAGES,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_MESSAGES as typeof ActionType.SUCCEEDED_RESET_MESSAGES,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_MESSAGES as typeof ActionType.FAILED_RESET_MESSAGES,
  }),
}

export const resetAllStatusFlags = {
  started: () => ({
    type: ActionType.STARTED_RESET_ALL_STATUS_FLAGS as typeof ActionType.STARTED_RESET_ALL_STATUS_FLAGS,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS as typeof ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_ALL_STATUS_FLAGS as typeof ActionType.FAILED_RESET_ALL_STATUS_FLAGS,
  }),
}

export type PlatformsAction =
  | ReturnType<typeof getPlatforms.started>
  | ReturnType<typeof getPlatforms.succeeded>
  | ReturnType<typeof getPlatforms.failed>
  | ReturnType<typeof getPlatform.started>
  | ReturnType<typeof getPlatform.succeeded>
  | ReturnType<typeof getPlatform.failed>
  | ReturnType<typeof updatePlatforms.started>
  | ReturnType<typeof updatePlatforms.succeeded>
  | ReturnType<typeof updatePlatforms.failed>
  | ReturnType<typeof resetMessages.started>
  | ReturnType<typeof resetMessages.succeeded>
  | ReturnType<typeof resetMessages.failed>
  | ReturnType<typeof resetAllStatusFlags.started>
  | ReturnType<typeof resetAllStatusFlags.succeeded>
  | ReturnType<typeof resetAllStatusFlags.failed>
