import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

export function useResponsive(): [responsive: string] {
  const small = useMediaQuery({ query: '(max-width: 374px)' })
  const medium = useMediaQuery({ query: '(max-width: 1023px)' })

  const [responsive, setResponsive] = useState<string>('normal')

  useEffect(() => {
    if (small) return setResponsive('small')
    if (medium) return setResponsive('medium')
    if (!small && !medium) return setResponsive('normal')
  }, [small, medium])

  return [responsive]
}
