import { useStyles } from 'hooks/useStyles'
import { Transition } from 'react-transition-group'
import { SyncLoader } from 'react-spinners'
import { css } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'

const transitionStyles: {
  [key: string]: { opacity: number; display: string }
} = {
  entering: { opacity: 1, display: 'block' },
  entered: { opacity: 1, display: 'block' },
  exiting: { opacity: 0, display: 'block' },
  exited: { opacity: 0, display: 'none' },
}

const Loader: React.FC<{ loading: boolean }> = ({ loading }) => {
  const smallDevice = useMediaQuery({ query: '(max-width: 359px)' })
  const [styles, setStyles] = useStyles({
    page: 'components/modules/overlay',
  })

  if (!styles) return <></>

  return (
    <Transition in={loading} timeout={500}>
      {(state: string) => (
        <div
          className={styles.default.overlay_component}
          style={{
            ...transitionStyles[state],
          }}
        >
          <SyncLoader
            color={'#54CBE4'}
            loading={loading}
            size={smallDevice ? 24 : 32}
            margin={12}
            css={css`
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
        </div>
      )}
    </Transition>
  )
}

export default Loader
