import { useState, useEffect } from 'react'
import { authValidations } from 'utils/validations/auth'
import { messageValidations } from 'utils/validations/message'
import { contributorValidations } from 'utils/validations/profile'
import { tagValidations } from 'utils/validations/tag'
import { manualBotMessageValidations } from 'utils/validations/manual_bot_message'
import { membershipValidations } from 'utils/validations/membership'
import { digitalContentValidations } from 'utils/validations/digital_content'
import { financialReportValidations } from 'utils/validations/financial_report'

export type ValidationValue = string | number | File | null | undefined

export function useValidation(input: {
  type: string
  value: ValidationValue
}): [
  validation: { message: string; type: string },
  setValidation: (type: string, value: ValidationValue) => void
] {
  const [defaultValidation, setDefaultValidation] = useState<{
    message: string
    type: string
  }>({
    message: '',
    type: 'error',
  })
  const setValidation = async (type: string, value: ValidationValue) => {
    let validationResult
    switch (type) {
      case 'contributor_name':
        if (typeof value === 'string')
          validationResult = authValidations.contributorName(value)
        break
      case 'email':
        if (typeof value === 'string')
          validationResult = authValidations.email(value)
        break
      case 'password':
        if (typeof value === 'string')
          validationResult = authValidations.password(value)
        break
      case 'message_text':
        if (typeof value === 'string')
          validationResult = messageValidations.text(value)
        break
      case 'message_image':
        if (typeof value === 'object')
          validationResult = messageValidations.image(value)
        break
      case 'message_video':
        if (typeof value === 'object')
          validationResult = messageValidations.video(value)
        break
      case 'message_voice':
        if (typeof value === 'object')
          validationResult = messageValidations.voice(value)
        break
      case 'message_datetime':
        if (typeof value === 'string')
          validationResult = messageValidations.datetime(value)
        break
      case 'contributor_description':
        if (typeof value === 'string')
          validationResult = contributorValidations.description(value)
        break
      case 'contributor_profile_title':
        if (typeof value === 'string')
          validationResult = contributorValidations.profileTitle(value)
        break
      case 'contributor_profile_description':
        if (typeof value === 'string')
          validationResult = contributorValidations.profileDescription(value)
        break
      case 'contributor_icon':
        if (typeof value === 'object')
          validationResult = contributorValidations.icon(value)
        break
      case 'contributor_sample_voice':
        if (typeof value === 'object')
          validationResult = contributorValidations.sampleVoice(value)
        break
      case 'contributor_sample_voice_label':
        if (typeof value === 'string')
          validationResult = contributorValidations.sampleVoiceLabel(value)
        break
      case 'tag_label':
        if (typeof value === 'string')
          validationResult = tagValidations.label(value)
        break
      case 'manual_bot_message_title':
        if (typeof value === 'string')
          validationResult = manualBotMessageValidations.title(value)
        break
      case 'manual_bot_message_body':
        if (typeof value === 'string')
          validationResult = manualBotMessageValidations.body(value)
        break
      case 'membership_description':
        if (typeof value === 'string')
          validationResult = membershipValidations.description(value)
        break
      case 'digital_content_image':
        if (typeof value === 'object')
          validationResult = digitalContentValidations.image(value)
        break
      case 'digital_content_voice':
        if (typeof value === 'object')
          validationResult = digitalContentValidations.voice(value)
        break
      case 'digital_content_title':
        if (typeof value === 'string')
          validationResult = digitalContentValidations.title(value)
        break
      case 'digital_content_description':
        if (typeof value === 'string')
          validationResult = digitalContentValidations.description(value)
        break
      case 'stripe_file':
        if (typeof value === 'object')
          validationResult = financialReportValidations.stripeFile(value)
        break
      case 'asc_file':
        if (typeof value === 'object')
          validationResult = financialReportValidations.ascFile(value)
        break
      case 'reset':
        if (!value)
          validationResult = {
            message: '',
            type: 'error',
          }
        break
    }
    validationResult && setDefaultValidation(validationResult)
  }
  useEffect(() => {
    input.value && setValidation(input.type, input.value)
  }, [])

  return [defaultValidation || null, setValidation]
}
