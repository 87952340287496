import { getContributor } from 'actions/contributors'
import {
  getSubscriptionTier,
  updateSubscriptionTier,
} from 'actions/subscription_tiers'
import Button from 'components/modules/button'
import MainForm from 'components/modules/main_form'
import NumberInput from 'components/modules/main_form/components/number_input'
import TextInput from 'components/modules/main_form/components/text_input'
import MainHeader from 'components/modules/main_header'
import { CombineReducers } from 'models'
import { MembershipsState } from 'models/memberships'
import { SessionsState } from 'models/sessions'
import { SubscriptionTiersState } from 'models/subscription_tiers'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as Save } from 'images/save.svg'
import { useValidation } from 'hooks/useValidation'

const Memberships: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{
    id: string
    subscription_tier_id: string
  }>()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const membershipsState: MembershipsState = useSelector(
    (state: CombineReducers) => {
      return state.memberships
    }
  )
  const subscriptionTiersState: SubscriptionTiersState = useSelector(
    (state: CombineReducers) => {
      return state.subscriptionTiers
    }
  )

  const [description, setDescription] = useState<string>('')
  const [meetingTickets, setMeetingTickets] = useState<string>('0')
  const [meetingMinutes, setMeetingMinutes] = useState<string>('0')
  const [activeRegistrationButton, setActiveRegistrationButton] =
    useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(false)

  const [descriptionValidation, useDescriptionValidation] = useValidation({
    type: 'membership_description',
    value: description,
  })

  const descriptionHandler = (value: string) => {
    setDescription(value)
    useDescriptionValidation('membership_description', value)
  }

  const subscriptionTierTitle = (tier: string) => {
    switch (tier) {
      case 'bronze':
        return 'ブロンズプラン編集'
      case 'silver':
        return 'シルバープラン編集'
      case 'gold':
        return 'ゴールドプラン編集'
      default:
        return 'プラン編集'
    }
  }

  const registrationHandler = () => {
    const formData = new FormData()
    formData.append('description', description)
    formData.append('meeting_tickets', meetingTickets)
    formData.append('meeting_minutes', meetingMinutes)

    dispatch(
      updateSubscriptionTier.started({
        id: Number(params.subscription_tier_id),
        data: formData,
      })
    )

    history.push(
      `/contributors/${params.id}/subscription_tiers/${subscriptionTiersState.subscriptionTier?.id}`
    )
  }

  const registrationButtonChecker = () => {
    if (descriptionValidation && descriptionValidation.type === 'succeeded') {
      setActiveRegistrationButton(true)
      return
    }
    setActiveRegistrationButton(false)
  }

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
      dispatch(
        getSubscriptionTier.started({
          subscriptionTierId: parseInt(params.subscription_tier_id),
        })
      )
    }
  }, [])

  useEffect(() => {
    if (subscriptionTiersState.subscriptionTier && !initialized) {
      setDescription(subscriptionTiersState.subscriptionTier.description)
      useDescriptionValidation(
        'membership_description',
        subscriptionTiersState.subscriptionTier.description
      )
      setMeetingMinutes(
        String(subscriptionTiersState.subscriptionTier.meetingMinutes)
      )
      setMeetingTickets(
        String(subscriptionTiersState.subscriptionTier.meetingTickets)
      )
      setInitialized(true)
    }
  }, [subscriptionTiersState.subscriptionTier])

  useEffect(() => {
    registrationButtonChecker()
  }, [descriptionValidation])

  if (!subscriptionTiersState.subscriptionTier) return <></>

  return (
    <>
      <MainHeader
        heading={subscriptionTierTitle(
          subscriptionTiersState.subscriptionTier.tier
        )}
      >
        <Button
          label={'キャンセル'}
          textColor={'#828282'}
          color={'#FFFFFF'}
          border={'2px solid rgb(224, 224, 224)'}
          disabled={false}
          onClickHandler={() =>
            history.push(
              `/contributors/${params.id}/subscription_tiers/${subscriptionTiersState.subscriptionTier?.id}`
            )
          }
        />
        <ButtonSpacer />
        <Button
          label={'保存する'}
          icon={Save}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={!activeRegistrationButton}
          onClickHandler={() => registrationHandler()}
        />
      </MainHeader>
      <MainForm>
        <TextInput
          label={'プラン説明文'}
          defaultValue={description}
          textarea
          updateHandler={(value: string) => descriptionHandler(value)}
          wordCount={{
            max: 500,
            current: description ? description.length : 0,
          }}
          validation={{ message: '', type: '' }}
        />
        <NumberInput
          label={'通話チケット枚数'}
          defaultValue={meetingTickets}
          updateHandler={(value: string) => setMeetingTickets(value)}
          min={0}
          max={10}
        />
        <NumberInput
          label={'通話分数'}
          defaultValue={meetingMinutes}
          updateHandler={(value: string) => setMeetingMinutes(value)}
          min={1}
          max={10}
        />
      </MainForm>
    </>
  )
}

export default Memberships
