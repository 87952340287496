import React from 'react'

const FormText: React.FC<{
  text: string
  styles: { default: { readonly [key: string]: string } }
}> = ({ text, styles }) => {
  return (
    <div className={styles.default.form_text}>
      <p>{text}</p>
    </div>
  )
}

export default FormText
