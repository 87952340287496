// GET_MEMBERSHIPS
export const STARTED_GET_MEMBERSHIPS = 'MEMBERSHIPS/STARTED_GET_MEMBERSHIPS'
export const SUCCEEDED_GET_MEMBERSHIPS = 'MEMBERSHIPS/SUCCEEDED_GET_MEMBERSHIPS'
export const FAILED_GET_MEMBERSHIPS = 'MEMBERSHIPS/FAILED_GET_MEMBERSHIPS'
// GET_MEMBERSHIP
export const STARTED_GET_MEMBERSHIP = 'MEMBERSHIPS/STARTED_GET_MEMBERSHIP'
export const SUCCEEDED_GET_MEMBERSHIP = 'MEMBERSHIPS/SUCCEEDED_GET_MEMBERSHIP'
export const FAILED_GET_MEMBERSHIP = 'MEMBERSHIPS/FAILED_GET_MEMBERSHIP'
// GET_MEMBERSHIP_BIRTHDAYS
export const STARTED_GET_MEMBERSHIP_BIRTHDAYS =
  'MEMBERSHIPS/STARTED_GET_MEMBERSHIP_BIRTHDAYS'
export const SUCCEEDED_GET_MEMBERSHIP_BIRTHDAYS =
  'MEMBERSHIPS/SUCCEEDED_GET_MEMBERSHIP_BIRTHDAYS'
export const FAILED_GET_MEMBERSHIP_BIRTHDAYS =
  'MEMBERSHIPS/FAILED_GET_MEMBERSHIP_BIRTHDAYS'
