import { Listeners } from 'models/listeners'

function listenersDeserializer(input: { data: any; meta?: any }): Listeners {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      name: item.name,
      birthDate: item.birth_date,
      gender: item.gender,
      isWithdrawn: item.is_withdrawn,
      firstPlatform: item.first_platform,
      latestPlatform: item.latest_platform,
      registrationToken: item.registration_token,
      customerId: item.customer_id,
      userId: item.user_id,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default listenersDeserializer
