import axios from 'axios'
import { loggingError } from 'services/interceptor'
import listenersDeserializer from 'deserializers/listeners'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200

export const getDMSendingListenersFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getDMSendingListeners = async (request: {
    page: number
    contributorId: number
  }) => {
    const response = await instance.get(
      `/api/cms/v1/listeners?contributor_id=${request.contributorId}&page=${request.page}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/listeners?contributor_id=${request.contributorId}&page=${request.page}`,
        'getDMSendingListenersFactory'
      )

    return listenersDeserializer({
      data: response.data.listeners,
      meta: response.data.meta,
    })
  }
  return getDMSendingListeners
}
