export const messageTextValidation = (value: string) => {
  let message = 'テキストの入力値は正常です'
  let type = 'succeeded'
  if (value.length > 5000) {
    message = '1文字以上5000文字以下のテキストを入力してください'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
