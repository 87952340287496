export const videoValidation = (value: File | null) => {
  let message = '動画ファイルの入力値は正常です'
  let type = 'succeeded'
  if (!value) return { message, type }

  if (value.size === 0) {
    message = '添付されたファイルが破損している可能性があります'
    type = 'error'
    return { message, type }
  }
  if (value.type !== 'video/mp4' && value.type !== 'video/quicktime') {
    message = '動画には mp4 / mov のみアップロードできます'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
