import React from 'react'
import { useStyles } from 'hooks/useStyles'

const Button: React.FC<{
  color: string
  textColor: string
  label: string
  onClickHandler: () => void
}> = ({ color, textColor, label, onClickHandler }) => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/modal/components/button',
  })

  if (!styles) return <></>

  return (
    <button
      className={styles.default.button_component}
      style={{
        backgroundColor: color,
        color: textColor,
      }}
      onClick={() => onClickHandler()}
    >
      {label}
    </button>
  )
}

export default Button
