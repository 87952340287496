import { useState } from 'react'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import {
  SelectItem,
  Selections,
} from 'components/modules/main_form/components/select_input'

type Props = {
  label: string
  onChangeHandler: (
    item: SelectItem,
    previousValue: number | string | null
  ) => void
  onRemoveSelectedHandler?: (value: string) => void
  selectableItems: Selections
  selectedItem?: SelectItem
  multiple: boolean
  resettable?: boolean
}

const SelectRow: React.FC<Props> = props => {
  const [selectedValue, setSelectedValue] = useState<string>()

  const findSelectedItem = (value: string) =>
    props.selectableItems.find((item: SelectItem) =>
      isNaN(Number(item.value))
        ? item.value == value
        : item.value === parseInt(value)
    )

  return (
    <>
      <select
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          setSelectedValue(event.target.value)
          const selectedItem =
            Number(event.target.value) === 0
              ? { label: 'reset', value: 0 }
              : findSelectedItem(event.target.value)
          const previousValue = selectedValue ? parseInt(selectedValue) : null
          selectedItem && props.onChangeHandler(selectedItem, previousValue)
        }}
        value={props.selectedItem?.value}
      >
        <option value={undefined}>選択してください</option>
        {props.selectedItem && props.resettable && (
          <option value={0}>リセット</option>
        )}
        {props.selectableItems.map((selectable: SelectItem) => (
          <option key={selectable.label} value={selectable.value}>
            {selectable.label}
          </option>
        ))}
      </select>
      {props.multiple && (
        <Bucket
          style={{ cursor: 'pointer' }}
          onClick={() => {
            props.selectedItem &&
              props.onRemoveSelectedHandler &&
              props.onRemoveSelectedHandler(String(props.selectedItem.value))
          }}
        />
      )}
    </>
  )
}

export default SelectRow
