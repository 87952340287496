import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { useResponsive } from 'hooks/useResponsive'
import SVGWrapper from 'components/modules/svg_wrapper'

type Props = {
  label: string
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
  textColor?: string
  disabledTextColor?: string
  color?: string
  disabledColor?: string
  border?: string
  disabled: boolean
  onClickHandler: () => void
}

const Button: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/button',
  })
  const [responsive] = useResponsive()

  if (!styles) return <></>

  return (
    <div
      style={{
        color: props.disabled ? props.disabledTextColor : props.textColor,
        backgroundColor: props.disabled ? props.disabledColor : props.color,
        border: props.border,
      }}
      className={styles.default.button_component}
      onClick={props.disabled ? undefined : props.onClickHandler}
    >
      {props.icon && responsive === 'normal' ? (
        <div className={styles.default.label}>
          <SVGWrapper
            icon={props.icon}
            width={'24'}
            height={'24'}
            stroke={'#FFFFFF'}
          />
          <div className={styles.default.label_spacer} />
          <span>{props.label}</span>
        </div>
      ) : (
        <span>{props.label}</span>
      )}
    </div>
  )
}

export default Button
