import { useSelector } from 'react-redux'
import { CombineReducers } from 'models'

export function useSpinner(): [isLoading: () => boolean] {
  const stateStore: CombineReducers = useSelector((state: CombineReducers) => {
    return state
  })

  const isLoading = () => {
    return (
      stateStore.contributors.status.startedCreateContributor ||
      stateStore.messages.status.startedCreateContributorMessage ||
      stateStore.messages.status.startedCreateReservedContributorMessage ||
      stateStore.contributors.status.startedDeleteContributor ||
      stateStore.messages.status.startedDeleteContributorMessage ||
      stateStore.contributors.status.startedGetContributor ||
      stateStore.messages.status.startedGetContributorMessages ||
      stateStore.messages.status.startedGetReservedContributorMessages ||
      stateStore.contributors.status.startedGetContributors ||
      stateStore.messages.status.startedGetBotMessage ||
      stateStore.messages.status.startedGetBotMessages ||
      stateStore.messages.status.startedUpdateBotMessage ||
      stateStore.messages.status.startedSendApprovedMessage ||
      stateStore.contributors.status.startedUploadOGPImage ||
      stateStore.messages.status.startedUpdateContributorMessage ||
      stateStore.messages.status.startedGetPreviewMessages ||
      stateStore.messages.status.startedGetPreviewReservedMessages ||
      stateStore.auth.status.startedSignIn ||
      stateStore.auth.status.startedSignUp ||
      stateStore.tags.status.startedCreateTag ||
      stateStore.tags.status.startedDeleteTag ||
      stateStore.tags.status.startedGetTags ||
      stateStore.maintenances.status.startedGetMaintenanceWindows ||
      stateStore.maintenances.status.startedCreateMaintenanceWindow ||
      stateStore.maintenances.status.startedDeleteMaintenanceWindow ||
      stateStore.messages.status.startedGetListenersDMs ||
      stateStore.loaders.loading
    )
  }

  return [isLoading]
}
