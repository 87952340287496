// GET_SUBSCRIPTION_TIERS
export const STARTED_GET_SUBSCRIPTION_TIERS =
  'SUBSCRIPTION_TIERS/STARTED_GET_SUBSCRIPTION_TIERS'
export const SUCCEEDED_GET_SUBSCRIPTION_TIERS =
  'SUBSCRIPTION_TIERS/SUCCEEDED_GET_SUBSCRIPTION_TIERS'
export const FAILED_GET_SUBSCRIPTION_TIERS =
  'SUBSCRIPTION_TIERS/FAILED_GET_SUBSCRIPTION_TIERS'
// GET_SUBSCRIPTION_TIER
export const STARTED_GET_SUBSCRIPTION_TIER =
  'SUBSCRIPTION_TIERS/STARTED_GET_SUBSCRIPTION_TIER'
export const SUCCEEDED_GET_SUBSCRIPTION_TIER =
  'SUBSCRIPTION_TIERS/SUCCEEDED_GET_SUBSCRIPTION_TIER'
export const FAILED_GET_SUBSCRIPTION_TIER =
  'SUBSCRIPTION_TIERS/FAILED_GET_SUBSCRIPTION_TIER'
// UPDATE_SUBSCRIPTION_TIER
export const STARTED_UPDATE_SUBSCRIPTION_TIER =
  'SUBSCRIPTION_TIERS/STARTED_UPDATE_SUBSCRIPTION_TIER'
export const SUCCEEDED_UPDATE_SUBSCRIPTION_TIER =
  'SUBSCRIPTION_TIERS/SUCCEEDED_UPDATE_SUBSCRIPTION_TIER'
export const FAILED_UPDATE_SUBSCRIPTION_TIER =
  'SUBSCRIPTION_TIERS/FAILED_UPDATE_SUBSCRIPTION_TIER'
