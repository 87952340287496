import { MessagesAction } from 'actions/messages'
import * as ActionType from 'actions/messages/types'
import { MessagesState } from 'models/messages'

export const initialState: MessagesState = {
  currentContributorMessages: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  currentContributorMessage: undefined,
  currentContributorBotMessages: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  currentContributorBotMessage: undefined,
  currentImmediatePreviewMessages: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  currentReservedPreviewMessages: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  listenerDMs: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  dmToRespond: null,
  status: {
    startedUpdateContributorMessage: false,
    succeededUpdateContributorMessage: true,
    failedUpdateContributorMessage: false,
    startedGetContributorMessages: false,
    succeededGetContributorMessages: false,
    failedGetContributorMessages: false,
    startedGetContributorMessage: false,
    succeededGetContributorMessage: false,
    failedGetContributorMessage: false,
    startedGetReservedContributorMessages: false,
    succeededGetReservedContributorMessages: false,
    failedGetReservedContributorMessages: false,
    startedCreateContributorMessage: false,
    succeededCreateContributorMessage: false,
    failedCreateContributorMessage: false,
    startedCreateReservedContributorMessage: false,
    succeededCreateReservedContributorMessage: false,
    failedCreateReservedContributorMessage: false,
    startedDeleteContributorMessage: false,
    succeededDeleteContributorMessage: false,
    failedDeleteContributorMessage: false,
    startedGetBotMessages: false,
    succeededGetBotMessages: false,
    failedGetBotMessages: false,
    startedGetBotMessage: false,
    succeededGetBotMessage: false,
    failedGetBotMessage: false,
    startedUpdateBotMessage: false,
    succeededUpdateBotMessage: false,
    failedUpdateBotMessage: false,
    startedSendApprovedMessage: false,
    succeededSendApprovedMessage: false,
    failedSendApprovedMessage: false,
    startedGetPreviewMessages: false,
    succeededGetPreviewMessages: false,
    failedGetPreviewMessages: false,
    startedGetPreviewReservedMessages: false,
    succeededGetPreviewReservedMessages: false,
    failedGetPreviewReservedMessages: false,
    startedGetListenersDMs: false,
    succeededGetListenersDMs: false,
    failedGetListenersDMs: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const messagesReducer = (
  state: MessagesState = initialState,
  action: MessagesAction
): MessagesState => {
  switch (action.type) {
    case ActionType.STARTED_GET_CONTRIBUTOR_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributorMessages: true,
          succeededGetContributorMessages: false,
          failedGetContributorMessages: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CONTRIBUTOR_MESSAGES:
      return {
        ...state,
        currentContributorMessages: action.payload.contributorMessages,
        status: {
          ...state.status,
          startedGetContributorMessages: false,
          succeededGetContributorMessages: true,
          failedGetContributorMessages: false,
        },
      }
    case ActionType.FAILED_GET_CONTRIBUTOR_MESSAGES:
      return {
        ...state,
        currentContributorMessages: {
          data: [],
          meta: {
            currentPage: 0,
            nextPage: 0,
            prevPage: 0,
            totalCount: 0,
            totalPages: 0,
          },
        },
        status: {
          ...state.status,
          startedGetContributorMessages: false,
          succeededGetContributorMessages: false,
          failedGetContributorMessages: true,
        },
      }
    case ActionType.STARTED_GET_RESERVED_CONTRIBUTOR_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetReservedContributorMessages: true,
          succeededGetReservedContributorMessages: false,
          failedGetReservedContributorMessages: false,
        },
      }
    case ActionType.SUCCEEDED_GET_RESERVED_CONTRIBUTOR_MESSAGES:
      return {
        ...state,
        currentContributorMessages: action.payload.contributorMessages,
        status: {
          ...state.status,
          startedGetReservedContributorMessages: false,
          succeededGetReservedContributorMessages: true,
          failedGetReservedContributorMessages: false,
        },
      }
    case ActionType.FAILED_GET_RESERVED_CONTRIBUTOR_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetReservedContributorMessages: false,
          succeededGetReservedContributorMessages: false,
          failedGetReservedContributorMessages: true,
        },
      }
    case ActionType.STARTED_CREATE_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateContributorMessage: true,
          succeededCreateContributorMessage: false,
          failedCreateContributorMessage: false,
        },
      }
    case ActionType.SUCCEEDED_CREATE_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateContributorMessage: false,
          succeededCreateContributorMessage: true,
          failedCreateContributorMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_CREATE_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateContributorMessage: false,
          succeededCreateContributorMessage: false,
          failedCreateContributorMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateReservedContributorMessage: true,
          succeededCreateReservedContributorMessage: false,
          failedCreateReservedContributorMessage: false,
        },
      }
    case ActionType.SUCCEEDED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateReservedContributorMessage: false,
          succeededCreateReservedContributorMessage: true,
          failedCreateReservedContributorMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateReservedContributorMessage: false,
          succeededCreateReservedContributorMessage: false,
          failedCreateReservedContributorMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_GET_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributorMessage: true,
          succeededGetContributorMessage: false,
          failedGetContributorMessage: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        currentContributorMessage: action.payload.contributorMessage,
        status: {
          ...state.status,
          startedGetContributorMessage: false,
          succeededGetContributorMessage: true,
          failedGetContributorMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_GET_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetContributorMessage: false,
          succeededGetContributorMessage: false,
          failedGetContributorMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_DELETE_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteContributorMessage: true,
          succeededDeleteContributorMessage: false,
          failedDeleteContributorMessage: false,
        },
      }
    case ActionType.SUCCEEDED_DELETE_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteContributorMessage: false,
          succeededDeleteContributorMessage: true,
          failedDeleteContributorMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_DELETE_CONTRIBUTOR_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteContributorMessage: false,
          succeededDeleteContributorMessage: false,
          failedDeleteContributorMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_GET_CONTRIBUTOR_BOT_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetBotMessages: true,
          succeededGetBotMessages: false,
          failedGetBotMessages: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGES:
      return {
        ...state,
        currentContributorBotMessages: action.payload.contributorBotMessages,
        status: {
          ...state.status,
          startedGetBotMessages: false,
          succeededGetBotMessages: true,
          failedGetBotMessages: false,
        },
      }
    case ActionType.FAILED_GET_CONTRIBUTOR_BOT_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetBotMessages: false,
          succeededGetBotMessages: false,
          failedGetBotMessages: true,
        },
      }
    case ActionType.STARTED_GET_CONTRIBUTOR_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetBotMessage: true,
          succeededGetBotMessage: false,
          failedGetBotMessage: false,
        },
      }
    case ActionType.SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGE:
      return {
        ...state,
        currentContributorBotMessage: action.payload.contributorBotMessage,
        status: {
          ...state.status,
          startedGetBotMessage: false,
          succeededGetBotMessage: true,
          failedGetBotMessage: false,
        },
      }
    case ActionType.FAILED_GET_CONTRIBUTOR_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetBotMessage: false,
          succeededGetBotMessage: false,
          failedGetBotMessage: true,
        },
      }
    case ActionType.STARTED_UPDATE_CONTRIBUTOR_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateBotMessage: true,
          succeededUpdateBotMessage: false,
          failedUpdateBotMessage: false,
        },
      }
    case ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateBotMessage: false,
          succeededUpdateBotMessage: true,
          failedUpdateBotMessage: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_UPDATE_CONTRIBUTOR_BOT_MESSAGE:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateBotMessage: false,
          succeededUpdateBotMessage: false,
          failedUpdateBotMessage: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_GET_PREVIEW_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetPreviewMessages: true,
          succeededGetPreviewMessages: false,
          failedGetPreviewMessages: false,
        },
      }
    case ActionType.SUCCEEDED_GET_PREVIEW_MESSAGES:
      const immediatePreviewMessages = action.payload.previewMessages.data
        .reverse()
        .concat(state.currentImmediatePreviewMessages.data)
      return {
        ...state,
        currentImmediatePreviewMessages: {
          data: immediatePreviewMessages,
          meta: action.payload.previewMessages.meta,
        },
        status: {
          ...state.status,
          startedGetPreviewMessages: false,
          succeededGetPreviewMessages: true,
          failedGetPreviewMessages: false,
        },
      }
    case ActionType.FAILED_GET_PREVIEW_MESSAGES:
      return {
        ...state,
        currentImmediatePreviewMessages: {
          data: [],
          meta: {
            currentPage: 0,
            nextPage: 0,
            prevPage: 0,
            totalCount: 0,
            totalPages: 0,
          },
        },
        status: {
          ...state.status,
          startedGetPreviewMessages: false,
          succeededGetPreviewMessages: false,
          failedGetPreviewMessages: true,
        },
      }
    case ActionType.STARTED_GET_PREVIEW_RESERVED_MESSAGES:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetPreviewReservedMessages: true,
          succeededGetPreviewReservedMessages: false,
          failedGetPreviewReservedMessages: false,
        },
      }
    case ActionType.SUCCEEDED_GET_PREVIEW_RESERVED_MESSAGES:
      const reservedPreviewMessages = action.payload.previewMessages.data
        .reverse()
        .concat(state.currentReservedPreviewMessages.data)
      return {
        ...state,
        currentReservedPreviewMessages: {
          data: reservedPreviewMessages,
          meta: action.payload.previewMessages.meta,
        },
        status: {
          ...state.status,
          startedGetPreviewReservedMessages: false,
          succeededGetPreviewReservedMessages: true,
          failedGetPreviewReservedMessages: false,
        },
      }
    case ActionType.FAILED_GET_PREVIEW_RESERVED_MESSAGES:
      return {
        ...state,
        currentReservedPreviewMessages: {
          data: [],
          meta: {
            currentPage: 0,
            nextPage: 0,
            prevPage: 0,
            totalCount: 0,
            totalPages: 0,
          },
        },
        status: {
          ...state.status,
          startedGetPreviewReservedMessages: false,
          succeededGetPreviewReservedMessages: false,
          failedGetPreviewReservedMessages: true,
        },
      }
    case ActionType.STARTED_GET_LISTENER_DMS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetListenersDMs: true,
          succeededGetListenersDMs: false,
          failedGetListenersDMs: false,
        },
      }
    case ActionType.SUCCEEDED_GET_LISTENER_DMS:
      const listenerMessages = action.payload.contributorMessages.data
        .reverse()
        .concat(state.listenerDMs.data)
      return {
        ...state,
        listenerDMs: {
          data: listenerMessages,
          meta: action.payload.contributorMessages.meta,
        },
        status: {
          ...state.status,
          startedGetListenersDMs: false,
          succeededGetListenersDMs: true,
          failedGetListenersDMs: false,
        },
      }
    case ActionType.FAILED_GET_LISTENER_DMS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetListenersDMs: false,
          succeededGetListenersDMs: false,
          failedGetListenersDMs: true,
        },
      }
    case ActionType.SET_INITIAL_STATE:
      return initialState
    case ActionType.SET_DM_TO_RESPOND:
      return {
        ...state,
        dmToRespond: action.payload.message,
      }
    case ActionType.REMOVE_DM_TO_RESPOND:
      return {
        ...state,
        dmToRespond: null,
      }
    case ActionType.STARTED_RESET_ALL_STATUS_FLAGS:
      return {
        ...state,
        status: {
          startedUpdateContributorMessage: false,
          succeededUpdateContributorMessage: true,
          failedUpdateContributorMessage: false,
          startedGetContributorMessages: false,
          succeededGetContributorMessages: false,
          failedGetContributorMessages: false,
          startedGetContributorMessage: false,
          succeededGetContributorMessage: false,
          failedGetContributorMessage: false,
          startedGetReservedContributorMessages: false,
          succeededGetReservedContributorMessages: false,
          failedGetReservedContributorMessages: false,
          startedCreateContributorMessage: false,
          succeededCreateContributorMessage: false,
          failedCreateContributorMessage: false,
          startedCreateReservedContributorMessage: false,
          succeededCreateReservedContributorMessage: false,
          failedCreateReservedContributorMessage: false,
          startedDeleteContributorMessage: false,
          succeededDeleteContributorMessage: false,
          failedDeleteContributorMessage: false,
          startedGetBotMessages: false,
          succeededGetBotMessages: false,
          failedGetBotMessages: false,
          startedGetBotMessage: false,
          succeededGetBotMessage: false,
          failedGetBotMessage: false,
          startedUpdateBotMessage: false,
          succeededUpdateBotMessage: false,
          failedUpdateBotMessage: false,
          startedSendApprovedMessage: false,
          succeededSendApprovedMessage: false,
          failedSendApprovedMessage: false,
          startedGetPreviewMessages: false,
          succeededGetPreviewMessages: false,
          failedGetPreviewMessages: false,
          startedGetPreviewReservedMessages: false,
          succeededGetPreviewReservedMessages: false,
          failedGetPreviewReservedMessages: false,
          startedGetListenersDMs: false,
          succeededGetListenersDMs: false,
          failedGetListenersDMs: false,
        },
      }
    case ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    case ActionType.FAILED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    default:
      return state
  }
}

export default messagesReducer
