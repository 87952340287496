import { useStyles } from 'hooks/useStyles'

type Props = {
  label: string
  step?: number
  onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onResetHandler?: () => void
  defaultValue?: string
  description?: string
  resettable?: boolean
}

const DatetimeInput: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/datetime_input',
  })

  const resetValueHandler = () => {
    props.onChangeHandler && props.onResetHandler && props.onResetHandler()
  }

  if (!styles) return <></>

  return (
    <div className={styles.default.datetime_input_component}>
      <div className={styles.default.input_label}>{props.label}</div>
      <div className={styles.default.input_field}>
        {props.onChangeHandler && (
          <input
            type="datetime-local"
            value={props.defaultValue}
            onChange={props.onChangeHandler}
          />
        )}
        {props.resettable && (
          <div className={styles.default.reset}>
            <button onClick={resetValueHandler}>リセット</button>
          </div>
        )}
        {props.description && (
          <div className={styles.default.description}>
            <span>{props.description}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default DatetimeInput
