import { useStyles } from 'hooks/useStyles'
import SelectInput from 'components/modules/main_form/components/select_input'

type Props = {
  label: string
  selectedColor: string
  onChange: (color: string) => void
}

const colorList = [
  { label: 'Orange', code: '#F59C29' },
  { label: 'Yellow', code: '#F5C814' },
  { label: 'Light Green', code: '#91C758' },
  { label: 'Green', code: '#4FBA74' },
  { label: 'Light Blue', code: '#44BCD4' },
  { label: 'Blue', code: '#4390EF' },
  { label: 'Violet', code: '#8675E6' },
  { label: 'Purple', code: '#AC61D1' },
  { label: 'Pink', code: '#D96DB0' },
]

const ColorPicker: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/color_picker',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.color_picker_component}>
      <SelectInput
        label={props.label}
        onChangeHandler={(item: { label: string; value: number | string }) =>
          props.onChange(String(item.value))
        }
        selectableItems={colorList.map(
          (color: { label: string; code: string }) => ({
            label: color.label,
            value: color.code,
          })
        )}
      />
      <div
        className={styles.default.selected_color}
        style={{ backgroundColor: props.selectedColor }}
      />
    </div>
  )
}

export default ColorPicker
