import { EventBotMessage } from 'models/messages'

function eventBotMessageDeserializer(input: { data: any }): EventBotMessage {
  return {
    id: input.data.id,
    patternName: input.data.pattern_name,
    distributionCondition: input.data.distribution_condition,
    runAt: input.data.run_at,
    runAtEditable: input.data.run_at_editable,
    text: input.data.text,
    voice: input.data.voice,
    voiceFileName: input.data.voice_file_name,
    image: input.data.image,
    imageFileName: input.data.image_file_name,
    video: input.data.video,
    videoFileName: input.data.video_file_name,
    videoThumbnail: input.data.video_thumbnail,
  }
}

export default eventBotMessageDeserializer
