import React from 'react'

export const ContentLabel: React.FC<{
  label: string
  liked: number
}> = ({ label, liked }) => (
  <div className="messages_detail_content_title">
    <p className="messages_detail_content_title_label">{label}</p>
    <p className="message_detail_content_liked">いいね:{liked}</p>
  </div>
)

const MessageContent: React.FC<{
  type: string
  value: string
  liked: number
}> = ({ type, value, liked }) => {
  return (
    <>
      {type === 'text' && (
        <div className="messages_detail_content">
          <div className="messages_detail_label_wrapper">
            <ContentLabel label="テキスト" liked={liked} />
          </div>
          <div style={{ clear: 'both' }} />
          <pre className="messages_detail_content_text">{value}</pre>
        </div>
      )}
      {type === 'voice' && (
        <div className="messages_detail_content">
          <div className="messages_detail_label_wrapper">
            <ContentLabel label="音声" liked={liked} />
          </div>
          <div style={{ clear: 'both' }} />
          <audio
            className="messages_detail_content_voice"
            src={value}
            controls
          />
        </div>
      )}
      {type === 'image' && (
        <div className="messages_detail_content">
          <div className="messages_detail_label_wrapper">
            <ContentLabel label="画像" liked={liked} />
          </div>
          <div style={{ clear: 'both' }} />
          <img
            className="messages_detail_content_image"
            src={value}
            height={272}
          />
        </div>
      )}
      {type === 'video' && (
        <div className="messages_detail_content">
          <div className="messages_detail_label_wrapper">
            <ContentLabel label="動画" liked={liked} />
          </div>
          <div style={{ clear: 'both' }} />
          <video
            className="messages_detail_content_video"
            controls
            height={272}
          >
            <source src={value} />
          </video>
        </div>
      )}
    </>
  )
}

export default MessageContent
