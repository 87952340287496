import { useStyles } from 'hooks/useStyles'

type Props = {
  type: string
  label: string
  maxLength?: number
  onChangedString?: (value: string) => void
  onChangedNumber?: (value: number) => void
  onChangedBoolean?: (value: boolean) => void
  selectedValue?: string
  defaultValue?: string
  radioSelectables?: Array<{ label: string; value: string }>
  validation?: { [key: string]: { message: string; type: string } }
  children?: React.ReactNode
}

const ItemRow: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/accounts/detail/components/item_row',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.item_row_component}>
      <div className={styles.default.item_label}>
        <label>{props.label}</label>
      </div>
      <div className={styles.default.item_value_component}>
        {props.type === 'inline_text' && (
          <>
            {props.validation &&
              props.validation.inlineText.type === 'error' &&
              props.validation.inlineText.message.length > 0 && (
                <div className={styles.default.validation_message}>
                  <span>{props.validation.inlineText.message}</span>
                </div>
              )}
            <div className={styles.default.inline_text_edit}>
              <input
                className={styles.default.inline_text_item}
                type="text"
                defaultValue={props.defaultValue}
                onChange={event =>
                  props.onChangedString &&
                  props.onChangedString(event.target.value)
                }
                style={{
                  backgroundColor:
                    props.validation &&
                    props.validation.inlineText.type === 'error'
                      ? '#FDF1F1'
                      : '#FFFFFF',
                }}
              />
              {props.maxLength && (
                <div
                  className={styles.default.word_count}
                  style={{
                    color:
                      props.validation &&
                      props.validation.inlineText.type === 'error'
                        ? '#E25047'
                        : '#828282',
                  }}
                >
                  <span>
                    {props.defaultValue ? props.defaultValue.length : 0}/20
                  </span>
                </div>
              )}
            </div>
          </>
        )}
        {props.type === 'password' && (
          <>
            {props.validation &&
              props.validation.password.type === 'error' &&
              props.validation.password.message.length > 0 && (
                <div className={styles.default.validation_message}>
                  <span>{props.validation.password.message}</span>
                </div>
              )}
            <div className={styles.default.inline_text_edit}>
              <input
                className={styles.default.inline_text_item}
                type="password"
                defaultValue={props.defaultValue}
                onChange={event =>
                  props.onChangedString &&
                  props.onChangedString(event.target.value)
                }
                style={{
                  backgroundColor:
                    props.validation &&
                    props.validation.password.type === 'error'
                      ? '#FDF1F1'
                      : '#FFFFFF',
                }}
              />
            </div>
          </>
        )}
        {props.type === 'radio' && (
          <div className={styles.default.radio_edit}>
            {props.radioSelectables &&
              props.radioSelectables.map(
                (selectable: { label: string; value: string }) => (
                  <div
                    className={styles.default.radio_row}
                    key={selectable.label}
                  >
                    <div className={styles.default.radio_btn}>
                      {props.defaultValue === selectable.value ? (
                        <div className={styles.default.enabled}>
                          <div className={styles.default.enabled_bulls_eye} />
                        </div>
                      ) : (
                        <div
                          className={styles.default.disabled}
                          onClick={() =>
                            props.onChangedString &&
                            props.onChangedString(selectable.value)
                          }
                        />
                      )}
                    </div>
                    <div className={styles.default.label}>
                      <span>{selectable.label}</span>
                    </div>
                  </div>
                )
              )}
          </div>
        )}
      </div>
      {props.children && (
        <div className={styles.default.execute_btns}>{props.children}</div>
      )}
    </div>
  )
}

export default ItemRow
