import { SessionsAction } from 'actions/sessions'
import * as ActionType from 'actions/sessions/types'
import { SessionsState } from 'models/sessions'

export const initialState: SessionsState = {
  currentUser: undefined,
  status: {
    startedGetCurrentUser: false,
    succeededGetCurrentUser: false,
    failedGetCurrentUser: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const sessionsReducer = (
  state: SessionsState = initialState,
  action: SessionsAction
): SessionsState => {
  switch (action.type) {
    case ActionType.STARTED_GET_CURRENT_USER:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetCurrentUser: true,
          succeededGetCurrentUser: false,
          failedGetCurrentUser: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_GET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload.user,
        status: {
          ...state.status,
          startedGetCurrentUser: false,
          succeededGetCurrentUser: true,
          failedGetCurrentUser: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_GET_CURRENT_USER:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetCurrentUser: false,
          succeededGetCurrentUser: false,
          failedGetCurrentUser: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    default:
      return state
  }
}

export default sessionsReducer
