import { useState, useEffect } from 'react'
import './App.css'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { CombineReducers } from './models'
import { useMediaQuery } from 'react-responsive'
import { useSpinner } from 'hooks/useSpinner'

import Header from 'components/modules/side_pane_menu/components/header'
import Footer from 'components/modules/side_pane_menu/components/footer'
import Home from 'components/Home'
import Auth from 'components/auth'
import OverlayLoader from 'components/modules/overlay/components/loader'
import SidePaneMenu from 'components/modules/side_pane_menu'
import SPSidePaneMenu from 'components/modules/side_pane_menu/sp'
import MaintenanceToast from 'components/modules/maintenance_toast'
import Toast from 'components/modules/toast'
import { EventBotMessage } from 'models/messages'

function App() {
  const history = useHistory()
  const stateStore: CombineReducers = useSelector((state: CombineReducers) => {
    return state
  })
  const isSmartPhone = useMediaQuery({ query: '(max-width: 1023px)' })
  const [loading] = useSpinner()
  const location = useLocation()

  const [requireReAuth, setRequireReAuth] = useState<boolean>(false)

  const checkSessionData = () => {
    if (
      localStorage.getItem('access-token') &&
      localStorage.getItem('client') &&
      localStorage.getItem('uid')
    )
      return true

    return false
  }

  const renderMainContent = () => {
    if (stateStore.sessions.currentUser?.administrator.id) return <Home />

    if (!stateStore.contributors.currentContributor) return <Home />

    const lostParamMessages = []
    if (stateStore.contributors.currentContributor.name === '') {
      lostParamMessages.push('プロフィールの名前を設定してください。')
    }
    if (stateStore.contributors.currentContributor.englishName === 'mydear') {
      lostParamMessages.push('プロフィールの英語名を設定してください。')
    }
    if (
      stateStore.contributors.currentContributor.hiraganaName === 'まいでぃあー'
    ) {
      lostParamMessages.push('プロフィールの名前（かな）を設定してください。')
    }
    if (
      stateStore.contributors.currentContributor.katakanaName === 'マイディアー'
    ) {
      lostParamMessages.push('プロフィールの名前（カナ）を設定してください。')
    }
    if (!stateStore.contributors.currentContributor.icon) {
      lostParamMessages.push('プロフィールのアイコンを設定してください。')
    }
    if (stateStore.contributors.currentContributor.description === '') {
      lostParamMessages.push('プロフィールの説明文を設定してください。')
    }
    if (!stateStore.contributors.currentContributor.twitterLink) {
      lostParamMessages.push('プロフィールの X リンクを設定してください。')
    }
    if (!stateStore.contributors.currentContributor.youtubeLink) {
      lostParamMessages.push(
        'プロフィールの YouTube リンクを設定してください。'
      )
    }
    if (stateStore.contributors.currentContributor.profiles.data.length === 0) {
      lostParamMessages.push('プロフィールの一問一答を設定してください。')
    }
    if (
      !stateStore.contributors.currentContributor.discordWebhookUrl ||
      !stateStore.contributors.currentContributor.discordUserId
    ) {
      lostParamMessages.push(
        'Discord の設定が完了されていません。運営までご連絡ください。'
      )
    }
    if (
      !stateStore.contributors.currentContributor.isApproved ||
      !stateStore.contributors.currentContributor.approvedAt
    ) {
      lostParamMessages.push(
        'アプリの配信開始準備が整っていません。運営までご連絡ください。'
      )
    }
    if (
      stateStore.contributors.currentContributor.botMessages.data.length > 0
    ) {
      stateStore.contributors.currentContributor.botMessages.data.forEach(
        (botMessage: EventBotMessage) => {
          if (botMessage.text && botMessage.text.length > 0) return

          if (botMessage.patternName === '誕生日') {
            lostParamMessages.push(
              '誕生日 Bot メッセージのテキストを入力してください。'
            )
          }
          if (botMessage.patternName === 'メンバーシップ登録時') {
            lostParamMessages.push(
              'メンバーシップ登録時 Bot メッセージのテキストを入力してください。'
            )
          }
          if (botMessage.patternName === 'フォロー登録時') {
            lostParamMessages.push(
              'フォロー登録時 Bot メッセージのテキストを入力してください。'
            )
          }
          if (
            stateStore.contributors.currentContributor?.voiceCall &&
            botMessage.patternName === '通話予約後'
          ) {
            lostParamMessages.push(
              '通話予約後 Bot メッセージのテキストを入力してください。'
            )
          }
          if (
            stateStore.contributors.currentContributor?.voiceCall &&
            botMessage.patternName === '通話終了後'
          ) {
            lostParamMessages.push(
              '通話終了後 Bot メッセージのテキストを入力してください。'
            )
          }
          if (
            stateStore.contributors.currentContributor?.voiceCall &&
            botMessage.patternName === '通話開始3時間前'
          ) {
            lostParamMessages.push(
              '通話開始3時間前 Bot メッセージのテキストを入力してください。'
            )
          }
        }
      )
    }

    if (
      location.pathname.match(/contributors\/\d+\/bot_messages/) ||
      location.pathname.match(/contributors\/\d+\/manual_bot_messages/) ||
      location.pathname.match(/contributors\/\d+\/subscription_tiers/) ||
      location.pathname.match(/contributors\/\d+\/meeting_tickets/) ||
      location.pathname.match(/contributors\/\d+\/meeting_reservations/) ||
      location.pathname.match(/contributors\/\d+\/membership_birthdays/) ||
      location.pathname.match(/contributors\/\d+\/analysis/) ||
      location.pathname.match(/contributors\/\d+\/direct_messages/) ||
      location.pathname.match(/contributors\/\d+\/meeting_schedules/) ||
      location.pathname.match(/contributors\/\d+\/voice_contents/) ||
      location.pathname.match(/contributors\/\d+\/profile/) ||
      location.pathname.match(/contributors\/\d+\/account/)
    ) {
      return <Home />
    }

    if (lostParamMessages.length === 0) return <Home />

    return (
      <main className="onboarding_cautions_main">
        <section className="onboarding_cautions_section">
          <h1 className="onboarding_cautions_heading">
            アカウント設定が未完了状態です。
          </h1>
          <p>CMS の機能を利用するには、以下の項目をご確認ください。</p>
          <p>
            各項目の変更後もこの画面が表示される場合はリロードを行なってください。
          </p>
          {lostParamMessages.map((message: string, i: number) => (
            <p key={i} className="onboarding_cautions_text">
              {message}
            </p>
          ))}
        </section>
      </main>
    )
  }

  useEffect(() => {
    if (
      stateStore.sessions.error.message ===
      'ログインユーザー情報の取得に失敗しました'
    ) {
      localStorage.removeItem('access-token')
      localStorage.removeItem('client')
      localStorage.removeItem('uid')
      setRequireReAuth(true)
      history.push('/')
    }
  }, [stateStore.sessions.status.failedGetCurrentUser, localStorage])

  if (!checkSessionData()) return <Auth requireReAuth={requireReAuth} />

  return (
    <>
      <Toast body={stateStore.toasts.success.message} error={false} />
      <Toast body={stateStore.toasts.error.message} error={true} />
      <MaintenanceToast />
      <OverlayLoader loading={loading()} />
      {isSmartPhone ? (
        <div className="sp_app_body">
          <div className="sp_app_header">
            <SPSidePaneMenu />
          </div>
          <div className="sp_app_main">{renderMainContent()}</div>
        </div>
      ) : (
        <div className="app_body">
          <div className="app_side_pane">
            <Header />
            <SidePaneMenu />
            <Footer />
          </div>
          <div className="app_main">{renderMainContent()}</div>
        </div>
      )}
    </>
  )
}

export default App
