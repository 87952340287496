import { useEffect } from 'react'
import { DEFAULT_API_CONFIG } from 'models'
import { useMutation } from 'react-query'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import axios from 'axios'
import { buildHeader } from 'services'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

export default function XAPIToken() {
  const history = useHistory()
  const dispatch = useDispatch()

  const createXToken = useMutation(
    async (req: { code: string }) => {
      const formData = new FormData()
      formData.append('code', req.code)

      const uri = '/api/cms/v1/x_notifications/create_access_token'
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.post(uri, formData, {
        headers: buildHeader('multipart/form-data'),
      })

      return response
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: 'X との連携に成功しました' })
        )
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: 'X との連携に失敗しました',
          })
        )
      },
      onSettled: () => {
        history.push('/')
      },
    }
  )

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code')
    if (code) {
      createXToken.mutate({ code })
    }
  }, [])

  return (
    <section>
      <p>トークン取得中...</p>
    </section>
  )
}
