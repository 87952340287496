import { useDispatch } from 'react-redux'
import { DEFAULT_API_CONFIG } from 'models'
import axios from 'axios'
import { UseMutationResult, useMutation } from 'react-query'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { useHistory } from 'react-router-dom'

export function useSendEmail(input: {
  email?: string
}): [sendEmail: UseMutationResult<void, unknown, void, unknown>] {
  const dispatch = useDispatch()
  const history = useHistory()

  const sendEmail = useMutation(
    async () => {
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      await instance.post('/api/cms/v1/auth/password', {
        email: input.email,
        redirect_url: process.env.REACT_APP_PASSWORD_CHANGE_REDIRECT_URL,
      })
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: 'リセットメールが送信されました' })
        )
        history.push('/sign_in')
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: 'パスワードリセットに失敗しました',
          })
        )
        history.push('/sign_in')
      },
    }
  )

  return [sendEmail]
}
