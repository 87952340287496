import axios from 'axios'
import userDeserializer from 'deserializers/user'
import {
  buildErrorLog,
  RESPONSE_NO_CONTENT,
  RESPONSE_OK,
  setSessionTokens,
} from 'services'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

export const signUpFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const signUp = async (request: {
    name: string
    email: string
    password: string
  }) => {
    const method = 'signUpFactory'
    const uri = '/api/cms/v1/auth'

    const response = await instance.post(uri, request)

    buildErrorLog(response.status, RESPONSE_NO_CONTENT, uri, method)
  }
  return signUp
}

export const signInFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const signIn = async (request: { email: string; password: string }) => {
    const method = 'signInFactory'
    const uri = '/api/cms/v1/auth/sign_in'

    const response = await instance.post(uri, request)

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    setSessionTokens(
      response.headers['access-token'],
      response.headers['client'],
      response.headers['uid']
    )

    return userDeserializer({
      data: response.data,
    })
  }
  return signIn
}

export const verifyFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const signIn = async (request: { token: string }) => {
    const method = 'verifyFactory'
    const uri = '/api/cms/v1/auth/sign_in'

    const response = await instance.post(uri, request)

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    setSessionTokens(
      response.headers['access-token'],
      response.headers['client'],
      response.headers['uid']
    )

    return userDeserializer({
      data: response.data,
    })
  }
  return signIn
}

export const sendResetPasswordEmailFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const sendResetPasswordEmail = async (request: { email: string }) => {
    const method = 'sendResetPasswordEmailFactory'
    const uri = '/api/v1/auth/password'

    const response = await instance.post(uri, {
      ...request,
      redirect_url: `${process.env
        .REACT_APP_PASSWORD_CHANGE_REDIRECT_URL!}/change_password`,
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return response.data.message
  }
  return sendResetPasswordEmail
}

export const changePasswordFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const changePassword = async (request: {
    password: string
    confirmationPassword: string
    headers: { uid: string; client: string; accessToken: string }
  }) => {
    const method = 'changePasswordFactory'
    const uri = '/api/v1/auth/password'

    const response = await instance.put(
      '/api/v1/auth/password',
      {
        password: request.password,
        password_confirmation: request.confirmationPassword,
      },
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': request.headers.accessToken,
          client: request.headers.client,
          uid: request.headers.uid,
        },
      }
    )

    buildErrorLog(response.status, RESPONSE_OK, uri, method)
  }
  return changePassword
}
