import { DigitalContent } from 'models/digital_contents'

function digitalContentDeserializer(input: { data: any }): DigitalContent {
  return {
    id: input.data.id,
    title: input.data.title,
    description: input.data.description,
    point: input.data.point,
    contentType: input.data.content_type,
    qrContent: input.data.qr_content,
    releasedAt: input.data.released_at,
    createdAt: input.data.created_at,
    updatedAt: input.data.updated_at,
    file: input.data.file,
    sampleFile: input.data.sample_file,
    image: input.data.image,
    qrCode: input.data.qr_code,
  }
}

export default digitalContentDeserializer
