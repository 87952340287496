import { useStyles } from 'hooks/useStyles'

type Props = {
  specifyReservedTime: boolean
  birthDayBotSendingTime?: string
  birthDayBotSendingTimeHandler?: (time: string) => void
  selectableReplies?: boolean
  enabledReplies?: boolean
  enabledRepliesHandler?: (value: boolean) => void
}

const MessageOptions: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/messages/create/components/message_options',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.message_options_component}>
      {props.specifyReservedTime && (
        <div className={styles.default.configuration}>
          <div className={styles.default.label}>
            <span>送信時間</span>
          </div>
          <div className={styles.default.form}>
            <div className={styles.default.item}>
              <div className={styles.default.row}>
                <div className={styles.default.input}>
                  <input
                    type="time"
                    value={props.birthDayBotSendingTime}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      props.birthDayBotSendingTimeHandler &&
                      props.birthDayBotSendingTimeHandler(event.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.selectableReplies && (
        <div className={styles.default.configuration}>
          <div className={styles.default.label}>
            <span>テキスト選択</span>
          </div>
          <div className={styles.default.form}>
            <div className={styles.default.item}>
              <div className={styles.default.row}>
                <div className={styles.default.input}>
                  {props.enabledReplies ? (
                    <div className={styles.default.radio_enabled} />
                  ) : (
                    <div
                      className={styles.default.radio_disabled}
                      onClick={() =>
                        props.enabledRepliesHandler &&
                        props.enabledRepliesHandler(true)
                      }
                    />
                  )}
                </div>
                <span
                  onClick={() =>
                    props.enabledRepliesHandler &&
                    props.enabledRepliesHandler(true)
                  }
                  style={{
                    color: props.enabledReplies ? '#333333' : '#BDBDBD',
                    cursor: 'pointer',
                  }}
                >
                  有効
                </span>
              </div>
            </div>
            <div className={styles.default.item}>
              <div className={styles.default.row}>
                <div className={styles.default.input}>
                  {props.enabledReplies ? (
                    <div
                      className={styles.default.radio_disabled}
                      onClick={() =>
                        props.enabledRepliesHandler &&
                        props.enabledRepliesHandler(false)
                      }
                    />
                  ) : (
                    <div className={styles.default.radio_enabled} />
                  )}
                </div>
                <span
                  onClick={() =>
                    props.enabledRepliesHandler &&
                    props.enabledRepliesHandler(false)
                  }
                  style={{
                    color: props.enabledReplies ? '#BDBDBD' : '#333333',
                    cursor: 'pointer',
                  }}
                >
                  無効
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MessageOptions
