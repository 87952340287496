// GET_PLATFORMS
export const STARTED_GET_PLATFORMS = 'PLATFORMS/STARTED_GET_PLATFORMS'
export const SUCCEEDED_GET_PLATFORMS = 'PLATFORMS/SUCCEEDED_GET_PLATFORMS'
export const FAILED_GET_PLATFORMS = 'PLATFORMS/FAILED_GET_PLATFORMS'

// GET_PLATFORM
export const STARTED_GET_PLATFORM = 'PLATFORMS/STARTED_GET_PLATFORM'
export const SUCCEEDED_GET_PLATFORM = 'PLATFORMS/SUCCEEDED_GET_PLATFORM'
export const FAILED_GET_PLATFORM = 'PLATFORMS/FAILED_GET_PLATFORM'

// UPDATE_PLATFORM
export const STARTED_UPDATE_PLATFORM = 'PLATFORMS/STARTED_UPDATE_PLATFORM'
export const SUCCEEDED_UPDATE_PLATFORM = 'PLATFORMS/SUCCEEDED_UPDATE_PLATFORM'
export const FAILED_UPDATE_PLATFORM = 'PLATFORMS/FAILED_UPDATE_PLATFORM'

// RESET_MESSAGES
export const STARTED_RESET_MESSAGES = 'PLATFORMS/STARTED_RESET_MESSAGES'
export const SUCCEEDED_RESET_MESSAGES = 'PLATFORMS/SUCCEEDED_RESET_MESSAGES'
export const FAILED_RESET_MESSAGES = 'PLATFORMS/FAILED_RESET_MESSAGES'

// RESET_ALL_STATUS_FLAGS
export const STARTED_RESET_ALL_STATUS_FLAGS =
  'PLATFORMS/STARTED_RESET_ALL_STATUS_FLAGS'
export const SUCCEEDED_RESET_ALL_STATUS_FLAGS =
  'PLATFORMS/SUCCEEDED_RESET_ALL_STATUS_FLAGS'
export const FAILED_RESET_ALL_STATUS_FLAGS =
  'PLATFORMS/FAILED_RESET_ALL_STATUS_FLAGS'
