import { useState, useEffect } from 'react'
import { useStyles } from 'hooks/useStyles'
import MediaSelection from 'components/modules/message_form/components/media_selection'
import FileValidationMessage from 'components/modules/message_form/components/file_validation_message'
import MediaInput from 'components/modules/message_form/components/media_input'
import heic2any from 'heic2any'
import { typeGuard } from 'utils/type_guard'

import { ReactComponent as Text } from 'images/text.svg'
import { ReactComponent as Image } from 'images/image.svg'
import { ReactComponent as ImageInput } from 'images/image_input.svg'
import { ReactComponent as Voice } from 'images/voice.svg'
import { ReactComponent as VoiceInput } from 'images/voice_input.svg'
import { ReactComponent as Video } from 'images/video.svg'
import { ReactComponent as VideoInput } from 'images/video_input.svg'
import { ReactComponent as Cross } from 'images/cross_black.svg'
import { AudioVisualMessage } from 'models/messages'

const birthdayPlaceholder = `誕生日おめでとうございまーす！いぇーい！！
今年も(username)さんにとって素敵な1年になりますように！
これからも楽しい日々を過ごしていこう～
`
const followPlaceholder = `(username)さん、有料登録してくれてありがとう！
my dear.でしか見られない特別なコンテンツをお届けしていきます！
これからもよろしくね！
`
const trialPlaceholder = `(username)さん、また登録してくれてありがとう！
引き続き限定コンテンツを送信していくので、これからもよろしくね！
`
const billingPlaceholder = `(username)さん、フォローありがとうございます！
声優の〇〇です！ これから私の日常を送信していきます！
もし気に入ってくれたら定期購読もお願いします！
これからよろしくお願いします！
`

type Props = {
  type: string
  value?: string
  selectedFile?: AudioVisualMessage
  preview?: string
  textValidation?: { message: string; type: string }
  imageValidation?: { message: string; type: string }
  videoValidation?: { message: string; type: string }
  voiceValidation?: { message: string; type: string }
  adaptVariableHandler?: (variable: string, targetCursor: number) => void
  onChangeText: (value: string) => void
  onChangeAudioVisual: (order: string, value: File) => void
  closeForm?: () => void
  botPattern?: string
  children?: any
  sp?: boolean
}

const MessageForm: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/message_form',
  })

  const [selectedMedia, setSelectedMedia] = useState<{
    text: boolean
    image: boolean
    voice: boolean
    video: boolean
  }>({
    text: props.type === 'text',
    image: props.type === 'audio_visual',
    voice: false,
    video: false,
  })
  const [targetCursor, setTargetCursor] = useState<number>(0)
  const [preview, setPreview] = useState<string>()

  const selectedMediaHandler = (type: string) => {
    switch (type) {
      case 'image':
        setSelectedMedia({
          text: false,
          image: true,
          voice: false,
          video: false,
        })
        break
      case 'voice':
        setSelectedMedia({
          text: false,
          image: false,
          voice: true,
          video: false,
        })
        break
      case 'video':
        setSelectedMedia({
          text: false,
          image: false,
          voice: false,
          video: true,
        })
        break
    }
  }

  const uploadedFileSize = (fileSize: number) => {
    if (Math.floor(fileSize / 1000000) > 0)
      return `${Math.floor(fileSize / 1000000)}MB`

    return `${Math.floor(fileSize / 1000)}KB`
  }

  const heicToJpg = async (blob: File) => {
    await heic2any({
      blob: blob,
      toType: 'image/jpeg',
      quality: 1,
    }).then((result: Blob | Blob[]) => {
      if (!typeGuard<Blob>(result)) return
      const imgUrl = URL.createObjectURL(result)
      setPreview(imgUrl)
    })
  }

  const generateUrl = () => {
    if (props.selectedFile && props.selectedFile.blob) {
      const heic = props.selectedFile.blob.name.match(/\.(heic|HEIC)$/)

      if (heic) {
        heicToJpg(props.selectedFile.blob)
      } else {
        const imgUrl = URL.createObjectURL(props.selectedFile.blob)
        setPreview(imgUrl)
      }
    }
  }

  const generatePlaceholder = () => {
    switch (props.botPattern) {
      case '誕生日':
        return birthdayPlaceholder
      case '初回有料登録時':
        return trialPlaceholder
      case '有料登録復帰時':
        return billingPlaceholder
      case '無料登録時':
        return followPlaceholder
      default:
        return '本文を入力してください。'
    }
  }

  useEffect(() => {
    if (props.selectedFile) {
      selectedMediaHandler(props.selectedFile.type)
      if (props.selectedFile.type === 'image') generateUrl()
    }
  }, [props.selectedFile])

  if (!styles) return <></>

  return (
    <div className={styles.default.message_form_component}>
      <div className={styles.default.card}>
        <div className={styles.default.header}>
          <div className={styles.default.media_selection}>
            {props.type === 'text' && (
              <MediaSelection Icon={Text} label={'テキスト'} />
            )}
            {props.type === 'audio_visual' && (
              <>
                <MediaSelection
                  Icon={Image}
                  label={'画像'}
                  selectedMedia={selectedMedia.image}
                  onClick={() => selectedMediaHandler('image')}
                />
                <MediaSelection
                  Icon={Voice}
                  label={'音声'}
                  selectedMedia={selectedMedia.voice}
                  onClick={() => selectedMediaHandler('voice')}
                />
                <MediaSelection
                  Icon={Video}
                  label={'動画'}
                  selectedMedia={selectedMedia.video}
                  onClick={() => selectedMediaHandler('video')}
                />
              </>
            )}
          </div>
          {!props.sp && props.closeForm && (
            <div
              className={styles.default.close_btn}
              onClick={() => props.closeForm && props.closeForm()}
            >
              <Cross />
            </div>
          )}
        </div>
        <FileValidationMessage validation={props.imageValidation} />
        <FileValidationMessage validation={props.voiceValidation} />
        <FileValidationMessage validation={props.videoValidation} />
        {selectedMedia.text && (
          <div className={styles.default.text_input}>
            <textarea
              className={styles.default.text_field}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                props.onChangeText(event.target.value)
              }
              onKeyUp={(event: { target: any }) =>
                setTargetCursor(event.target.selectionStart)
              }
              onClick={(event: { target: any }) => {
                setTargetCursor(event.target.selectionStart)
              }}
              value={props.value}
              placeholder={generatePlaceholder()}
            />
            <div className={styles.default.variables}>
              {props.adaptVariableHandler && (
                <div
                  className={styles.default.variable_item}
                  onClick={() =>
                    props.adaptVariableHandler &&
                    props.adaptVariableHandler('username', targetCursor)
                  }
                >
                  <div className={styles.default.variable_btn}>
                    <span>ユーザー名</span>
                  </div>
                </div>
              )}
              {props.adaptVariableHandler &&
                props.botPattern === 'キャンペーン応募時' && (
                  <div
                    className={styles.default.variable_item}
                    onClick={() =>
                      props.adaptVariableHandler &&
                      props.adaptVariableHandler('wad', targetCursor)
                    }
                  >
                    <div className={styles.default.variable_btn}>
                      <span>当選発表日時</span>
                    </div>
                  </div>
                )}
            </div>
            <div className={styles.default.input_count}>
              <span
                style={{
                  color:
                    props.textValidation?.type === 'error'
                      ? '#E25047'
                      : '#828282',
                }}
              >
                {props.value ? props.value.length : 0}
              </span>
              <span>/5000</span>
            </div>
          </div>
        )}
        {selectedMedia.image &&
          props.selectedFile &&
          !props.selectedFile.blob && (
            <MediaInput
              setAudioVisual={props.onChangeAudioVisual}
              label={'画像をアップロード（10MB以内）'}
              subLabel={'JPG / PNG / HEICのみ'}
              type={'image'}
              Icon={ImageInput}
            />
          )}
        {selectedMedia.voice &&
          props.selectedFile &&
          !props.selectedFile.blob && (
            <MediaInput
              setAudioVisual={props.onChangeAudioVisual}
              label={'音声をアップロードする（100MB以内）'}
              subLabel={'mp3 / m4a / aacのみ'}
              type={'voice'}
              Icon={VoiceInput}
            />
          )}
        {selectedMedia.video &&
          props.selectedFile &&
          !props.selectedFile.blob && (
            <MediaInput
              setAudioVisual={props.onChangeAudioVisual}
              label={'動画をアップロードする（1GB以内）'}
              subLabel={'mp4 / movのみ'}
              type={'video'}
              Icon={VideoInput}
            />
          )}
        {props.type === 'audio_visual' &&
          props.selectedFile &&
          props.selectedFile.blob && (
            <div className={styles.default.uploaded_field}>
              <div className={styles.default.file_box}>
                {props.selectedFile.type === 'image' && props.preview ? (
                  <div className={styles.default.uploaded_img}>
                    {props.preview ? (
                      <img
                        className={styles.default.preview}
                        src={props.preview}
                      />
                    ) : (
                      <img className={styles.default.preview} src={preview} />
                    )}
                    {!props.preview && !preview && (
                      <span className={styles.default.generating_preview}>
                        プレビューを作成しています...
                      </span>
                    )}
                  </div>
                ) : (
                  <div className={styles.default.file_detail}>
                    {props.selectedFile.type === 'image' && (
                      <ImageInput className={styles.default.media_icon} />
                    )}
                    {props.selectedFile.type === 'voice' && (
                      <VoiceInput className={styles.default.media_icon} />
                    )}
                    {props.selectedFile.type === 'video' && (
                      <VideoInput className={styles.default.media_icon} />
                    )}
                    <div className={styles.default.detail_text}>
                      <span className={styles.default.file_name}>
                        {props.selectedFile?.blob.name}
                      </span>
                      <span className={styles.default.byte_size}>
                        {props.selectedFile?.blob.size &&
                          uploadedFileSize(props.selectedFile?.blob.size)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
      <div className={styles.default.children_component}>{props.children}</div>
    </div>
  )
}

export default MessageForm
