import axios from 'axios'
import MembershipDeserializer from 'deserializers/memberships'
import { loggingError } from 'services/interceptor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200
const COUNT = 10

export const getMembershipsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getMemberships = async (request: {
    contributorId: number
    subscriptionTierId: number
    page: number
  }) => {
    const response = await instance.get(
      `/api/cms/v1/subscription_memberships?contributor_id=${request.contributorId}&subscription_tier_id=${request.subscriptionTierId}&page=${request.page}&per=${COUNT}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/subscription_memberships?contributor_id=${request.contributorId}&subscription_tier_id=${request.subscriptionTierId}&page=${request.page}&per=${COUNT}`,
        'getMembershipsFactory'
      )

    return MembershipDeserializer({
      data: response.data.subscription_memberships,
      meta: response.data.meta,
    })
  }
  return getMemberships
}

export const getMembershipFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getMembership = async (request: { membershipId: number }) => {
    const response = await instance.get(
      `/api/cms/v1/subscription_memberships/${request.membershipId}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/subscription_memberships/${request.membershipId}`,
        'getMembershipFactory'
      )

    return MembershipDeserializer({
      data: response.data.subscription_membership,
    })
  }
  return getMembership
}

export const getMembershipBirthdaysFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getMembershipBirthdays = async (request: {
    contributorId: number
    page: number
  }) => {
    const response = await instance.get(
      `/api/cms/v1/membership_birthdays?contributor_id=${request.contributorId}&page=${request.page}&per=${COUNT}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/membership_birthdays?contributor_id=${request.contributorId}&page=${request.page}&per=${COUNT}`,
        'getMembershipBirthdaysFactory'
      )

    return MembershipDeserializer({
      data: response.data.subscription_memberships,
      meta: response.data.meta,
    })
  }
  return getMembershipBirthdays
}
