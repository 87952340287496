import { ContributorsState } from 'models/contributors'
import { MaintenancesState } from 'models/maintenances'
import { PlatformsState } from 'models/platforms'
import { MessagesState } from 'models/messages'
import { SessionsState } from './sessions'
import { TagsState } from './tags'
import { ListenersState } from './listeners'
import { ManualBotMessagesState } from './manual_bot_messages'
import { SubscriptionTiersState } from './subscription_tiers'
import { MembershipsState } from './memberships'
import { ToastsState } from './toasts'
import { AuthState } from './auth'
import { UsersState } from './users'
import { LoadersState } from './loadings'
import { DigitalContentsState } from './digital_contents'

export type CombineReducers = {
  auth: AuthState
  sessions: SessionsState
  contributors: ContributorsState
  tags: TagsState
  maintenances: MaintenancesState
  platforms: PlatformsState
  messages: MessagesState
  listeners: ListenersState
  manualBotMessages: ManualBotMessagesState
  subscriptionTiers: SubscriptionTiersState
  memberships: MembershipsState
  toasts: ToastsState
  loaders: LoadersState
  users: UsersState
  digitalContents: DigitalContentsState
}

export type Validation = {
  message: string
  type: string
}

export type APIMessage = {
  message: string
}

export type PaginationMetaData = {
  currentPage: number
  nextPage: number | null
  prevPage: number | null
  totalCount: number
  totalPages: number
}

export type ApiConfig = {
  baseURL: string
  timeout: number
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}
