import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { CombineReducers } from 'models'
import MenuItems from 'components/modules/side_pane_menu/components/menu_items'
import { composeRoutes } from 'utils/routes_generator'

type Props = {
  store: CombineReducers
  closeSidePaneHandler: () => void
  sp?: boolean
}

const Contributor: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/side_pane_menu',
  })

  if (
    !styles ||
    !props.store.sessions.currentUser ||
    !props.store.contributors.currentContributor
  )
    return <></>

  return (
    <div className={styles.default.side_pane_btns_component}>
      <div className={styles.default.distribution_header}>
        <span>個人配信</span>
      </div>
      <MenuItems
        routes={composeRoutes(
          props.store.sessions.currentUser.authType,
          'contributor_single',
          props.store.contributors.currentContributor.id
        )}
        closeSidePaneHandler={props.closeSidePaneHandler}
      />
    </div>
  )
}

export default Contributor
