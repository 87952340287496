import React from 'react'
import { useStyles } from 'hooks/useStyles'

type Validation = { type: string; message: string }

type Props = {
  validation?: Validation
}

const FileValidationMessage: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/message_form/components/file_validation_message',
  })

  if (!styles || !props.validation) return <></>

  if (props.validation.type !== 'error') return <></>

  if (props.validation.message.length === 0) return <></>

  return (
    <div className={styles.default.message_component}>
      <div className={styles.default.text_area}>{props.validation.message}</div>
    </div>
  )
}

export default FileValidationMessage
