import { AuthAction } from 'actions/auth'
import * as ActionType from 'actions/auth/types'
import { AuthState } from 'models/auth'

export const initialState: AuthState = {
  status: {
    startedSignUp: false,
    succeededSignUp: false,
    failedSignUp: false,
    startedSignIn: false,
    succeededSignIn: false,
    failedSignIn: false,
    startedVerify: false,
    succeededVerify: false,
    failedVerify: false,
    startedSendResetPasswordEmail: false,
    succeededSendResetPasswordEmail: false,
    failedSendResetPasswordEmail: false,
    startedChangePasswordEmail: false,
    succeededChangePasswordEmail: false,
    failedChangePasswordEmail: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const authReducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case ActionType.STARTED_SIGN_UP:
      return {
        ...state,
        status: {
          ...state.status,
          startedSignUp: true,
          succeededSignUp: false,
          failedSignUp: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_SIGN_UP:
      return {
        ...state,
        status: {
          ...state.status,
          startedSignUp: false,
          succeededSignUp: true,
          failedSignUp: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.FAILED_SIGN_UP:
      return {
        ...state,
        status: {
          ...state.status,
          startedSignUp: false,
          succeededSignUp: false,
          failedSignUp: true,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.STARTED_SIGN_IN:
      return {
        ...state,
        status: {
          ...state.status,
          startedSignIn: true,
          succeededSignIn: false,
          failedSignIn: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_SIGN_IN:
      return {
        ...state,
        status: {
          ...state.status,
          startedSignIn: false,
          succeededSignIn: true,
          failedSignIn: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_SIGN_IN:
      return {
        ...state,
        status: {
          ...state.status,
          startedSignIn: false,
          succeededSignIn: false,
          failedSignIn: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_VERIFY:
      return {
        ...state,
        status: {
          ...state.status,
          startedVerify: true,
          succeededVerify: false,
          failedVerify: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_VERIFY:
      return {
        ...state,
        status: {
          ...state.status,
          startedVerify: false,
          succeededVerify: true,
          failedVerify: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_VERIFY:
      return {
        ...state,
        status: {
          ...state.status,
          startedVerify: false,
          succeededVerify: false,
          failedVerify: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_SEND_RESET_PASSWORD_EMAIL:
      return {
        ...state,
        status: {
          ...state.status,
          startedSendResetPasswordEmail: true,
          succeededSendResetPasswordEmail: false,
          failedSendResetPasswordEmail: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_SEND_RESET_PASSWORD_EMAIL:
      return {
        ...state,
        status: {
          ...state.status,
          startedSendResetPasswordEmail: false,
          succeededSendResetPasswordEmail: true,
          failedSendResetPasswordEmail: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_SEND_RESET_PASSWORD_EMAIL:
      return {
        ...state,
        status: {
          ...state.status,
          startedSendResetPasswordEmail: false,
          succeededSendResetPasswordEmail: false,
          failedSendResetPasswordEmail: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_CHANGE_PASSWORD_EMAIL:
      return {
        ...state,
        status: {
          ...state.status,
          startedChangePasswordEmail: true,
          succeededChangePasswordEmail: false,
          failedChangePasswordEmail: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_CHANGE_PASSWORD_EMAIL:
      return {
        ...state,
        status: {
          ...state.status,
          startedChangePasswordEmail: false,
          succeededChangePasswordEmail: true,
          failedChangePasswordEmail: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_CHANGE_PASSWORD_EMAIL:
      return {
        ...state,
        status: {
          ...state.status,
          startedChangePasswordEmail: false,
          succeededChangePasswordEmail: false,
          failedChangePasswordEmail: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    default:
      return state
  }
}

export default authReducer
