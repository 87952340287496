import axios from 'axios'
import { Platform } from 'models/platforms'
import { loggingError } from 'services/interceptor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200
const NO_CONTENT = 204

export const getPlatformsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getPlatforms = async () => {
    const response = await instance.get('/api/cms/v1/platforms', {
      headers: {
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      },
    })

    if (response.status !== OK)
      loggingError('/api/cms/v1/platforms', 'getPlatformsFactory')

    const platforms: Platform[] = response.data.platforms
    return platforms
  }
  return getPlatforms
}

export const getPlatformFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getPlatform = async (request: { id: number }) => {
    const response = await instance.get(`/api/cms/v1/platforms/${request.id}`, {
      headers: {
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      },
    })

    if (response.status !== OK)
      loggingError(`/api/cms/v1/platforms/${request.id}`, 'getPlatformFactory')

    const platform: Platform = response.data.platform
    return platform
  }
  return getPlatform
}

export const updatePlatformFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updatePlatform = async (request: { id: number; version: string }) => {
    const response = await instance.put(
      `/api/cms/v1/platforms/${request.id}`,
      { version: request.version },
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError(
        `/api/cms/v1/platforms/${request.id}`,
        'updatePlatformFactory'
      )
  }
  return updatePlatform
}
