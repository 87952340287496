import axios from 'axios'
import tagsDeserializer from 'deserializers/tags/many'
import { Tag } from 'models/tags'
import { loggingError } from 'services/interceptor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200
const NO_CONTENT = 204

export const getTagsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getTags = async () => {
    const response = await instance.get('/api/cms/v1/tags', {
      headers: {
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      },
    })

    if (response.status !== OK)
      loggingError('/api/cms/v1/tags', 'getTagsFactory')

    return tagsDeserializer({ data: response.data.tags })
  }
  return getTags
}

export const createTagFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const createTag = async (request: {
    tagLabel: string
    tagBGColor: string
    tagLetterColor: string
  }) => {
    const response = await instance.post(
      '/api/cms/v1/tags',
      {
        label: request.tagLabel,
        bg_color: request.tagBGColor,
        letter_color: request.tagLetterColor,
        project_id: process.env.REACT_APP_PROJECT_ID,
      },
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError('/api/cms/v1/tags', 'createTagFactory')
  }
  return createTag
}

export const deleteTagFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const deleteTag = async (request: { id: number }) => {
    const response = await instance.delete(`/api/cms/v1/tags/${request.id}`, {
      headers: {
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      },
    })

    if (response.status !== NO_CONTENT)
      loggingError(`/api/cms/v1/tags/${request.id}`, 'deleteTagFactory')
  }
  return deleteTag
}

export const updateTagManagementFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateTagManagement = async (request: {
    contributorId: number
    tags: number[]
  }) => {
    const response = await instance.post(
      '/api/cms/v1/tag_managements',
      {
        contributor_id: request.contributorId,
        tags: request.tags,
      },
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError('/api/cms/v1/tag_managements', 'updateTagManagementFactory')

    const tags: Tag[] = response.data
    return tags
  }
  return updateTagManagement
}
