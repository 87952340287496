import { useStyles } from 'hooks/useStyles'
import { useHistory } from 'react-router-dom'
import { ContributorProfile, ContributorProfiles } from 'models/contributors'

type Props = {
  image?: string
  voice?: string
  inlineText?: string
  text?: string
  link?: { name: string; path: string }
  route?: { name: string; path: string }
  tags?: Array<{ label: string; bgColor: string; color: string }>
  profiles?: ContributorProfiles
  number?: number
  date?: string
}

const ItemValue: React.FC<Props> = props => {
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/account_profiles/detail/components/item_value',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.item_value_component}>
      {props.image && (
        <img src={props.image} className={styles.default.img_item} />
      )}
      {props.voice && <audio src={props.voice} controls />}
      {props.inlineText && <span>{props.inlineText}</span>}
      {props.text && (
        <textarea
          className={styles.default.text_item}
          readOnly
          value={props.text}
        />
      )}
      {props.link && (
        <a href={props.link.path} target="_blank" rel="noopener noreferrer">
          {props.link.name}
        </a>
      )}
      {props.route && (
        <a
          className={styles.default.route_link}
          onClick={() => props.route && history.push(props.route.path)}
        >
          {props.route.name}
        </a>
      )}
      {props.tags && (
        <div className={styles.default.tag_items}>
          {props.tags.map(
            (tag: { label: string; bgColor: string; color: string }) => (
              <div
                key={tag.label}
                className={styles.default.tag_item}
                style={{
                  backgroundColor: tag.bgColor,
                  color: tag.color,
                }}
              >
                <span>#{tag.label}</span>
              </div>
            )
          )}
        </div>
      )}
      {props.profiles && (
        <div className={styles.default.profile_items}>
          {props.profiles.data.map(
            (profile: ContributorProfile, index: number) => (
              <div key={index} className={styles.default.profile_item}>
                <div className={styles.default.title}>
                  <h3>{profile.title}</h3>
                </div>
                <div className={styles.default.description}>
                  <textarea
                    className={styles.default.text_item}
                    readOnly
                    value={profile.description}
                  />
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  )
}

export default ItemValue
