import MainHeader from 'components/modules/main_header'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import Button from 'components/modules/button'
import { useHistory, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { buildHeader } from 'services'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { DEFAULT_API_CONFIG, CombineReducers } from 'models'
import { useDispatch, useSelector } from 'react-redux'
import { SessionsState } from 'models/sessions'
import { getContributor } from 'actions/contributors'
import meetingSchedulesDeserializer from 'deserializers/meeting_schedules'
import { MeetingSchedule } from 'models/meeting_schedules'
import { format, parse } from 'date-fns'
import Overlay from 'components/modules/overlay'
import Modal from 'components/modules/modal'
import ModalButton from 'components/modules/modal/components/button'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { useResponsive } from 'hooks/useResponsive'
const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar)

export type CalendarEvent = {
  id: number
  title: string
  start: Date
  end: Date
  isEnded: boolean
  isReserved: boolean
}

const MeetingSchedules: React.FC = () => {
  const [responsive] = useResponsive()
  const history = useHistory()
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const queryClient = useQueryClient()
  const [meetingScheduleId, setMeetingScheduleId] = useState<number>()
  const [startAt, setStartAt] = useState<Date>()
  const [endAt, setEndAt] = useState<Date>()
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)

  const fetchMeetingSchedules = useQuery('meetingSchedules', async () => {
    const uri = `/api/cms/v1/meeting_schedules?contributor_id=${params.id}`
    const instance = axios.create({
      ...DEFAULT_API_CONFIG,
    })
    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    return meetingSchedulesDeserializer({
      data: response.data.meeting_schedules,
    })
  })

  const updateMeetingSchedule = useMutation(
    async () => {
      const formData = new FormData()
      formData.append('start_at', format(startAt!, 'yyyy/MM/dd HH:mm'))
      formData.append('end_at', format(endAt!, 'yyyy/MM/dd HH:mm'))
      formData.append('contributor_id', params.id)

      const uri = `/api/cms/v1/meeting_schedules/${meetingScheduleId}`
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.put(uri, formData, {
        headers: buildHeader('multipart/form-data'),
      })

      return response
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: '通話スケジュールを更新しました' })
        )
        queryClient.invalidateQueries('meetingSchedules')
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: '通話スケジュールの更新に失敗しました',
          })
        )
      },
      onSettled: () => {
        fetchMeetingSchedules.refetch()
        setShowUpdateModal(false)
        setShowDeleteModal(false)
      },
    }
  )

  const deleteMeetingSchedule = useMutation(
    async () => {
      const uri = `/api/cms/v1/meeting_schedules/${meetingScheduleId}`
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.delete(uri, {
        headers: buildHeader('multipart/form-data'),
      })

      return response
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: '通話スケジュールを削除しました' })
        )
        queryClient.invalidateQueries('meetingSchedules')
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: '通話スケジュールの削除に失敗しました',
          })
        )
      },
      onSettled: () => {
        fetchMeetingSchedules.refetch()
        setShowUpdateModal(false)
        setShowDeleteModal(false)
      },
    }
  )

  const initializeEvents = () => {
    if (!fetchMeetingSchedules.data) return []

    return fetchMeetingSchedules.data.data.map(
      (meetingSchedule: MeetingSchedule) => ({
        id: meetingSchedule.id,
        title: meetingSchedule.isEnded
          ? '終了'
          : `${
              meetingSchedule.isReserved ? '予約済' : '未予約'
            }(${meetingSchedule.startAt.match(/\d{2}:\d{2}$/)}~)`,
        start: parse(meetingSchedule.startAt, 'yyyy/MM/dd HH:mm', new Date()),
        end: parse(meetingSchedule.endAt, 'yyyy/MM/dd HH:mm', new Date()),
        isEnded: meetingSchedule.isEnded,
        isReserved: meetingSchedule.isReserved,
      })
    )
  }

  const eventPropGetter = useCallback(event => {
    return {
      ...(event.isReserved && {
        style: {
          backgroundColor: '#E05252',
        },
      }),
      ...(event.isEnded && {
        style: {
          backgroundColor: '#BDBDBD',
        },
      }),
      ...(!event.isReserved &&
        !event.isEnded && {
          style: {
            backgroundColor: '#59DDB8',
          },
        }),
    }
  }, [])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id: parseInt(params.id),
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  return (
    <>
      {(showUpdateModal || showDeleteModal) && (
        <Overlay
          dismissHandler={() =>
            showUpdateModal
              ? setShowUpdateModal(false)
              : setShowDeleteModal(false)
          }
        >
          <Modal
            title={
              showUpdateModal
                ? 'スケジュールを更新しますか？'
                : 'スケジュールを削除しますか？'
            }
            description={
              showUpdateModal && startAt && endAt
                ? `${format(startAt!, 'yyyy/MM/dd HH:mm')}~${format(
                    endAt!,
                    'yyyy/MM/dd HH:mm'
                  )}`
                : ''
            }
            dismissHandler={() =>
              showUpdateModal
                ? setShowUpdateModal(false)
                : setShowDeleteModal(false)
            }
          >
            <ModalButton
              color={'#FFFFFF'}
              textColor={'#828282'}
              label={'キャンセル'}
              onClickHandler={() =>
                showUpdateModal
                  ? setShowUpdateModal(false)
                  : setShowDeleteModal(false)
              }
            />
            <ModalButton
              color={showUpdateModal ? '#17DBC9' : '#E25047'}
              textColor={'#FFFFFF'}
              label={showUpdateModal ? '更新' : '削除'}
              onClickHandler={() =>
                showUpdateModal
                  ? updateMeetingSchedule.mutate()
                  : deleteMeetingSchedule.mutate()
              }
            />
          </Modal>
        </Overlay>
      )}
      <MainHeader heading={'通話スケジュール'}>
        <Button
          label={'新規作成'}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            history.push(`/contributors/${params.id}/meeting_schedules/new`)
          }
        />
      </MainHeader>
      <DnDCalendar
        views={['month', 'week', 'day']}
        defaultDate={moment().toDate()}
        defaultView="day"
        eventPropGetter={eventPropGetter}
        events={initializeEvents()}
        localizer={localizer}
        onEventDrop={e => {
          const calendarEvent = e.event as CalendarEvent

          if (calendarEvent.isEnded || calendarEvent.isReserved) return

          setMeetingScheduleId(calendarEvent.id)
          setStartAt(e.start as Date)
          setEndAt(e.end as Date)
          setShowUpdateModal(true)
        }}
        onDoubleClickEvent={e => {
          const calendarEvent = e as CalendarEvent

          if (calendarEvent.isEnded || calendarEvent.isReserved) return

          setMeetingScheduleId(calendarEvent.id)
          setShowDeleteModal(true)
        }}
        onEventResize={e => {
          const calendarEvent = e.event as CalendarEvent

          if (calendarEvent.isEnded || calendarEvent.isReserved) return

          setMeetingScheduleId(calendarEvent.id)
          setShowDeleteModal(true)
        }}
        resizable
        dayLayoutAlgorithm={'no-overlap'}
        step={15}
        style={{
          height: responsive !== 'normal' ? 'calc(100vh - 192px)' : 800,
        }}
      />
    </>
  )
}

export default MeetingSchedules
