import { useEffect } from 'react'
import { useVerifyToken } from 'hooks/api/useVerifyToken'

const Verify: React.FC = () => {
  const [verifyToken] = useVerifyToken()

  useEffect(() => {
    verifyToken.mutate()
  }, [])

  return (
    <section>
      <p>検証中...</p>
    </section>
  )
}

export default Verify
