import React from 'react'
import { useHistory } from 'react-router-dom'

const Template: React.FC<{
  label: string
  styles: { default: { readonly [key: string]: string } }
  children: React.ReactNode
}> = ({ label, styles, children }) => {
  const history = useHistory()

  return (
    <div className={styles.default.auth_form_wrapper}>
      <div className={styles.default.auth_form_header}>
        <p>{label}</p>
      </div>
      <div className={styles.default.auth_form}>{children}</div>
    </div>
  )
}

export default Template
