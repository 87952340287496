import MainHeader from 'components/modules/main_header'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Button from 'components/modules/button'
import { useHistory, useParams } from 'react-router-dom'
import MainForm from 'components/modules/main_form'
import SPButton from 'components/modules/main_form/components/button'
import SPButtonSpacer from 'components/modules/main_form/components/button/components/spacer'
import SPButtonWrapper from 'components/modules/main_form/components/button_wrapper'
import { useResponsive } from 'hooks/useResponsive'
import DatetimeInput from 'components/modules/main_form/components/datetime_input'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as Save } from 'images/save.svg'
import { useCallback, useEffect, useState } from 'react'
import {
  format,
  parseISO,
  isAfter,
  isBefore,
  addDays,
  addMinutes,
  getMinutes,
  subMinutes,
} from 'date-fns'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { buildHeader } from 'services'
import { DEFAULT_API_CONFIG, CombineReducers } from 'models'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { useDispatch, useSelector } from 'react-redux'
import { SessionsState } from 'models/sessions'
import { getContributor } from 'actions/contributors'
import meetingSchedulesDeserializer from 'deserializers/meeting_schedules'
import { MeetingSchedule } from 'models/meeting_schedules'

const NewMeetingSchedule: React.FC = () => {
  const [responsive] = useResponsive()
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const [startAt, setStartAt] = useState<Date>()
  const [endAt, setEndAt] = useState<Date>()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })

  const fetchMeetingSchedules = useQuery('meetingSchedules', async () => {
    const uri = `/api/cms/v1/meeting_schedules?contributor_id=${params.id}`
    const instance = axios.create({
      ...DEFAULT_API_CONFIG,
    })
    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    return meetingSchedulesDeserializer({
      data: response.data.meeting_schedules,
    })
  })

  const createMeetingSchedule = useMutation(
    async () => {
      const formData = new FormData()
      formData.append('start_at', format(startAt!, 'yyyy/MM/dd HH:mm'))
      formData.append('end_at', format(endAt!, 'yyyy/MM/dd HH:mm'))
      formData.append('contributor_id', params.id)

      const uri = '/api/cms/v1/meeting_schedules'
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.post(uri, formData, {
        headers: buildHeader('multipart/form-data'),
      })

      return response
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: '通話スケジュールを作成しました' })
        )
        queryClient.invalidateQueries('meetingSchedules')
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: '通話スケジュールの作成に失敗しました',
          })
        )
      },
      onSettled: () => {
        history.push(`/contributors/${params.id}/meeting_schedules`)
      },
    }
  )

  const saveMeetingSchedule = () => {
    createMeetingSchedule.mutate()
  }

  const executable = useCallback(() => {
    if (!startAt || !endAt) return false
    if (isAfter(endAt, addDays(new Date(), 30))) return false
    if (isBefore(startAt, new Date())) return false
    if (
      fetchMeetingSchedules.data &&
      fetchMeetingSchedules.data.data.some(
        (data: MeetingSchedule) =>
          data.startAt === format(startAt, 'yyyy/MM/dd HH:mm')
      )
    )
      return false
    return true
  }, [startAt, endAt])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id: parseInt(params.id),
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  return (
    <>
      <MainHeader heading={'通話枠作成'}>
        {responsive === 'normal' && (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid rgb(224, 224, 224)'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <ButtonSpacer />
            <Button
              label={'保存する'}
              icon={Save}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable()}
              onClickHandler={saveMeetingSchedule}
            />
          </>
        )}
      </MainHeader>
      <MainForm>
        <DatetimeInput
          label={'開始日時'}
          defaultValue={startAt && format(startAt, 'yyyy-MM-dd HH:mm')}
          onChangeHandler={e => {
            const date = parseISO(e.target.value)
            const minutes = getMinutes(date)
            if (minutes >= 0 && minutes < 15) {
              setStartAt(subMinutes(date, minutes))
              setEndAt(addMinutes(subMinutes(date, minutes), 15))
            } else if (minutes >= 15 && minutes < 30) {
              setStartAt(subMinutes(date, minutes - 15))
              setEndAt(addMinutes(subMinutes(date, minutes - 15), 15))
            } else if (minutes >= 30 && minutes < 45) {
              setStartAt(subMinutes(date, minutes - 30))
              setEndAt(addMinutes(subMinutes(date, minutes - 30), 15))
            } else if (minutes >= 45 && minutes <= 59) {
              setStartAt(subMinutes(date, minutes - 45))
              setEndAt(addMinutes(subMinutes(date, minutes - 45), 15))
            }
          }}
        />
        {(responsive === 'small' || responsive === 'medium') && (
          <SPButtonWrapper>
            <SPButton
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid #E0E0E0'}
              disabled={false}
              onClickHandler={() => history.goBack()}
            />
            <SPButtonSpacer />
            <SPButton
              label={'保存する'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!executable()}
              onClickHandler={saveMeetingSchedule}
            />
          </SPButtonWrapper>
        )}
      </MainForm>
    </>
  )
}

export default NewMeetingSchedule
