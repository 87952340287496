import * as ActionType from 'actions/toasts/types'

export const setSuccessToast = {
  started: (request: { message: string }) => ({
    type: ActionType.SET_SUCCESS_TOAST as typeof ActionType.SET_SUCCESS_TOAST,
    payload: request,
  }),
}

export const setErrorToast = {
  started: (request: { message: string }) => ({
    type: ActionType.SET_ERROR_TOAST as typeof ActionType.SET_ERROR_TOAST,
    payload: request,
  }),
}

export const resetToast = {
  started: () => ({
    type: ActionType.RESET_TOAST as typeof ActionType.RESET_TOAST,
  }),
}

export type ToastsAction =
  | ReturnType<typeof setSuccessToast.started>
  | ReturnType<typeof setErrorToast.started>
  | ReturnType<typeof resetToast.started>
