import contributorDeserializer from 'deserializers/contributor'
import financialReportDeserializer from 'deserializers/financial_report'
import { PaymentStatements } from 'models/financial_reports'

function paymentStatementsDeserializer(input: {
  data: any
  meta?: any
}): PaymentStatements {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      file: item.file,
      contributor: contributorDeserializer({ data: item.contributor }),
      financialReport: financialReportDeserializer({
        data: item.financial_report,
      }),
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default paymentStatementsDeserializer
