import React, { ReactNode } from 'react'
import { useStyles } from 'hooks/useStyles'
import { ReactComponent as Cross } from 'images/cross_black.svg'

type Props = {
  title: string
  description?: string
  input?: ReactNode
  dismissHandler: () => void
  children: React.ReactNode
}

const Modal: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/modal',
  })

  if (!styles) return <></>

  return (
    <div
      className={styles.default.modal_component}
      onClick={(event: React.MouseEvent<HTMLDivElement>) =>
        event.stopPropagation()
      }
    >
      <div className={styles.default.content}>
        <div className={styles.default.dismiss_btn_line}>
          <Cross
            className={styles.default.dismiss}
            onClick={props.dismissHandler}
          />
        </div>
        <div className={styles.default.title}>
          <p>{props.title}</p>
        </div>
        <div className={styles.default.description}>
          <p>{props.description}</p>
        </div>
        {props.input && (
          <div className={styles.default.input}>{props.input}</div>
        )}
      </div>
      <div className={styles.default.children}>{props.children}</div>
    </div>
  )
}

export default Modal
