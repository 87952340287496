import { useStyles } from 'hooks/useStyles'
import React from 'react'

const ButtonWrapper: React.FC<{ children: React.ReactNode }> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/button_wrapper',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.button_wrapper_component}>
      {props.children}
    </div>
  )
}

export default ButtonWrapper
