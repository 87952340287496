import { useValidation, ValidationValue } from 'hooks/useValidation'
import { ManualBotMessageValidations } from 'models/manual_bot_messages'

export function useValidations(): [
  validations: ManualBotMessageValidations,
  setValidation: (type: string, value?: ValidationValue) => void
] {
  const [titleValidation, setTitleValidation] = useValidation({
    type: 'manual_bot_message_title',
    value: '',
  })
  const [bodyValidation, setBodyValidation] = useValidation({
    type: 'manual_bot_message_body',
    value: null,
  })

  const setValidation = (type: string, value: ValidationValue) => {
    switch (type) {
      case 'manual_bot_message_title':
        setTitleValidation(type, value)
        break
      case 'manual_bot_message_body':
        setBodyValidation(type, value)
        break
    }
  }

  return [
    {
      titleValidation,
      bodyValidation,
    },
    setValidation,
  ]
}
