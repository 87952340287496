import { EventBotMessages } from 'models/messages'

function eventBotMessagesDeserializer(input: {
  data: any
  meta?: any
}): EventBotMessages {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      patternName: item.pattern_name,
      distributionCondition: item.distribution_condition,
      runAt: item.run_at,
      runAtEditable: item.run_at_editable,
      text: item.text,
      voice: item.voice,
      voiceFileName: item.voice_file_name,
      image: item.image,
      imageFileName: item.image_file_name,
      video: item.video,
      videoFileName: item.video_file_name,
      videoThumbnail: item.video_thumbnail,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default eventBotMessagesDeserializer
