import { SubscriptionTier } from 'models/subscription_tiers'

function subscriptionTiersDeserializer(input: {
  data: any
}): SubscriptionTier | SubscriptionTier[] {
  return Array.isArray(input.data)
    ? input.data.map((item: any) => ({
        id: item.id,
        title: item.title,
        description: item.description,
        tier: item.tier,
        hasTrial: item.has_trial,
        meetingTickets: item.meeting_tickets,
        meetingMinutes: item.meeting_minutes,
      }))
    : {
        id: input.data.id,
        title: input.data.title,
        description: input.data.description,
        tier: input.data.tier,
        hasTrial: input.data.has_trial,
        meetingTickets: input.data.meeting_tickets,
        meetingMinutes: input.data.meeting_minutes,
      }
}

export default subscriptionTiersDeserializer
