import axios from 'axios'
import userDeserializer from 'deserializers/user'
import { buildErrorLog, RESPONSE_OK } from 'services'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

export const getCurrentUserFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getCurrentUser = async () => {
    const method = 'getCurrentUserFactory'
    const uri = '/api/cms/v1/users/me'

    const response = await instance.get(uri, {
      headers: {
        'Project-ID': process.env.REACT_APP_PROJECT_ID,
        'access-token': localStorage.getItem('access-token'),
        client: localStorage.getItem('client'),
        uid: localStorage.getItem('uid'),
      },
    })

    buildErrorLog(response.status, RESPONSE_OK, uri, method)

    return userDeserializer({
      data: response.data.user,
    })
  }
  return getCurrentUser
}
