import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import MainHeader from 'components/modules/main_header'
import { useStyles } from 'hooks/useStyles'
import { useValidation } from 'hooks/useValidation'
import { Howl } from 'howler'
import Preview from 'components/modules/preview'
import PreviewMessageForm from 'components/modules/preview/components/message_form'
import { getContributor } from 'actions/contributors'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import { generateUrlFromFile } from 'utils/file_manager'
import Toast from 'components/modules/toast'
import { AudioVisualMessage } from 'models/messages'
import { SubscriptionTiersState } from 'models/subscription_tiers'
import { getSubscriptionTiers } from 'actions/subscription_tiers'
import {
  createReservedContributorMessage,
  setInitialState,
} from 'actions/messages'

const ReservedMessage: React.FC = () => {
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const subscriptionTiersState: SubscriptionTiersState = useSelector(
    (state: CombineReducers) => {
      return state.subscriptionTiers
    }
  )
  const [styles, setStyles] = useStyles({
    page: 'components/reserved_messages/create',
  })
  const params = useParams<{
    id: string
  }>()

  const [selectedDate, setSelectedDate] = useState<Date>()
  const [selectedTime, setSelectedTime] = useState<string>()
  const [audioVisualMessage, setAudioVisualMessage] =
    useState<AudioVisualMessage>()
  const [publicMessage, setPublicMessage] = useState<boolean>(true)
  const [activeRegistrationButton, setActiveRegistrationButton] =
    useState<boolean>(false)
  const [imgPreview, setImgPreview] = useState<string>()
  const [videoPreview, setVideoPreview] = useState<string>()
  const [voicePreview, setVoicePreview] = useState<string>()
  const [voicePreviewAudio, setVoicePreviewAudio] = useState<Howl>()

  const [messageText, setMessageText] = useState<string>('')
  const [messageTextValidation, useMessageTextValidation] = useValidation({
    type: 'message_text',
    value: messageText,
  })
  const [messageImageValidation, useMessageImageValidation] = useValidation({
    type: 'message_image',
    value: null,
  })
  const [messageVideoValidation, useMessageVideoValidation] = useValidation({
    type: 'message_video',
    value: null,
  })
  const [messageVoiceValidation, useMessageVoiceValidation] = useValidation({
    type: 'message_voice',
    value: null,
  })
  const [messageDatetimeValidation, useMessageDatetimeValidation] =
    useValidation({
      type: 'message_datetime',
      value: moment(new Date()).format('YYYY/MM/DD HH:mm'),
    })
  const [subscriptionTier, setSubscriptionTier] = useState<number>()

  const confirmValidText = () => {
    if (messageText.length > 0) {
      return messageTextValidation && messageTextValidation.type === 'succeeded'
    } else if (audioVisualMessage) {
      return true
    } else {
      return false
    }
  }
  const confirmValidMedia = () => {
    if (audioVisualMessage) {
      switch (audioVisualMessage.type) {
        case 'image':
          return (
            messageImageValidation &&
            messageImageValidation.type === 'succeeded'
          )
        case 'video':
          return (
            messageVideoValidation &&
            messageVideoValidation.type === 'succeeded'
          )
        case 'voice':
          return (
            messageVoiceValidation &&
            messageVoiceValidation.type === 'succeeded'
          )
        default:
          return false
      }
    }
    return true
  }
  const confirmValidDatetime = () =>
    messageDatetimeValidation &&
    selectedDate &&
    selectedTime &&
    messageDatetimeValidation.type === 'succeeded'

  const registrationButtonChecker = () => {
    if (
      confirmValidText() &&
      confirmValidMedia() &&
      confirmValidDatetime() &&
      subscriptionTier
    ) {
      setActiveRegistrationButton(true)
      return
    }
    setActiveRegistrationButton(false)
  }
  const adaptVariable = (variable: string, targetCursor: number) => {
    const newTextMessage = `${messageText.slice(
      0,
      targetCursor
    )}(${variable})${messageText.slice(targetCursor)}`
    setMessageText(newTextMessage)
  }
  const selectedTimeHandler = (time: string) => {
    const runAt = moment(selectedDate).format('YYYY/MM/DD') + ' ' + selectedTime

    useMessageDatetimeValidation('message_datetime', runAt)

    setSelectedTime(time)
  }
  const selectedDateHandler = (date: Date) => {
    const runAt = moment(date).format('YYYY/MM/DD') + ' ' + selectedTime

    useMessageDatetimeValidation('message_datetime', runAt)

    setSelectedDate(date)
  }
  const removeAudioVisualHandler = () => {
    setAudioVisualMessage(undefined)
    controlPreviewVoice()
    setImgPreview(undefined)
    setVideoPreview(undefined)
    setVoicePreview(undefined)
    setVoicePreviewAudio(undefined)
    useMessageImageValidation('reset', null)
    useMessageVideoValidation('reset', null)
    useMessageVoiceValidation('reset', null)
  }
  const messageTextHandler = (value: string) => {
    useMessageTextValidation('message_text', value)
    setMessageText(value)
    localStorage.setItem('stashed-message', value)
  }
  const uploadAudioVisualHandler = (type: string, blob: File) => {
    if (type === 'image') {
      setVideoPreview(undefined)
      setVoicePreview(undefined)
      useMessageImageValidation('message_image', blob)
    }
    if (type === 'video') {
      setImgPreview(undefined)
      setVoicePreview(undefined)
      useMessageVideoValidation('message_video', blob)
    }
    if (type === 'voice') {
      setImgPreview(undefined)
      setVideoPreview(undefined)
      useMessageVoiceValidation('message_voice', blob)
    }
    setAudioVisualMessage({ type, blob })
  }
  const publicMessageHandler = (value: boolean) => setPublicMessage(value)

  const sendMessageHandler = () => {
    const formData = new FormData()
    sessionsState.currentUser &&
      formData.append(
        'contributor_id',
        JSON.stringify(
          sessionsState.currentUser.authType === 'Administrator'
            ? contributorsState.currentContributor!.id
            : sessionsState.currentUser.contributor.id
        )
      )
    if (messageText.length > 0) formData.append('text', messageText)
    formData.append('public_text', String(publicMessage))

    if (audioVisualMessage && audioVisualMessage.blob) {
      switch (audioVisualMessage.type) {
        case 'image':
          formData.append('message_image', audioVisualMessage.blob)
          formData.append('public_image', String(publicMessage))
          formData.append('type', audioVisualMessage.type)
          break
        case 'voice':
          formData.append('message_voice', audioVisualMessage.blob)
          formData.append('public_voice', String(publicMessage))
          formData.append('type', audioVisualMessage.type)
          break
        case 'video':
          formData.append('message_video', audioVisualMessage.blob)
          formData.append('public_video', String(publicMessage))
          formData.append('type', audioVisualMessage.type)
          break
      }
    }

    formData.append('subscription_tier_id', String(subscriptionTier))

    if (!contributorsState.currentContributor) return

    const runAt = moment(selectedDate).format('YYYY/MM/DD') + ' ' + selectedTime
    formData.append('run_at', runAt)
    formData.append('delivered', 'false')
    formData.append('reserved', 'true')

    const five_minutes_after = moment(runAt).isSameOrAfter(
      moment(new Date()).add(5, 'm').format('YYYY/MM/DD HH:mm')
    )

    if (five_minutes_after) {
      dispatch(createReservedContributorMessage.started({ data: formData }))
    } else {
      alert('予約配信では最低5分以上未来の日時を入力してください。')
    }

    setMessageText('')
    localStorage.removeItem('stashed-message')
    removeAudioVisualHandler()
  }

  const controlPreviewVoice = () => {
    if (!voicePreview) return

    if (voicePreviewAudio) {
      if (voicePreviewAudio.playing()) {
        voicePreviewAudio.pause()
      } else {
        voicePreviewAudio.play()
      }
    } else {
      const voice = new Howl({
        src: [voicePreview],
        html5: true,
      })
      setVoicePreviewAudio(voice)
    }
  }

  const distributionLabel = () => {
    if (contributorsState.currentContributor)
      return `${contributorsState.currentContributor.name}の予約配信`
    return '予約配信'
  }

  const distributionSubLabel = () => {
    if (contributorsState.currentContributor)
      return `この予約メッセージは${contributorsState.currentContributor.name}（個人）のメッセージとして送信されます。`
    return ''
  }

  useEffect(() => {
    if (audioVisualMessage)
      generateUrlFromFile(
        audioVisualMessage,
        setImgPreview,
        setVideoPreview,
        setVoicePreview
      )
  }, [audioVisualMessage])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
      dispatch(
        getSubscriptionTiers.started({
          contributorId:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
    useMessageTextValidation('message_text', '')

    const stashedMessage = localStorage.getItem('stashed-message')
    if (stashedMessage) {
      messageTextHandler(stashedMessage)
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  useEffect(() => {
    const runAt = moment(selectedDate).format('YYYY/MM/DD') + ' ' + selectedTime
    useMessageDatetimeValidation('message_datetime', runAt)

    if (audioVisualMessage)
      generateUrlFromFile(
        audioVisualMessage,
        setImgPreview,
        setVideoPreview,
        setVoicePreview
      )

    if (voicePreviewAudio && !voicePreviewAudio.playing()) {
      voicePreviewAudio.play()
    }
  }, [selectedDate, selectedTime, voicePreviewAudio])

  useEffect(() => {
    registrationButtonChecker()
  }, [
    audioVisualMessage,
    messageTextValidation,
    messageImageValidation,
    messageVideoValidation,
    messageVoiceValidation,
    messageDatetimeValidation,
  ])

  useEffect(() => {
    dispatch(setInitialState())
  }, [])

  if (
    !styles ||
    !contributorsState.currentContributor ||
    !sessionsState.currentUser
  )
    return <></>

  return (
    <>
      <Toast body={contributorsState.success.message} error={false} />
      <Toast body={contributorsState.error.message} error={true} />
      <MainHeader
        heading={distributionLabel()}
        subText={distributionSubLabel()}
      />
      <div className={styles.default.new_message_component}>
        <div className={styles.default.message_preview}>
          <Preview
            contributor={contributorsState.currentContributor}
            messageText={messageText}
            audioVisualMessage={audioVisualMessage}
            imagePreview={imgPreview}
            videoPreview={videoPreview}
            voicePreview={voicePreview}
            sp={true}
            immediate={false}
            reservation={true}
            eventBot={false}
            directMessage={false}
          >
            <PreviewMessageForm
              messageText={messageText}
              messageTextHandler={messageTextHandler}
              publicMessage={publicMessage}
              publicMessageHandler={publicMessageHandler}
              selectedTimeHandler={selectedTimeHandler}
              selectedDateHandler={selectedDateHandler}
              audioVisualMessage={audioVisualMessage}
              imagePreview={imgPreview}
              voicePreview={voicePreview}
              videoPreview={videoPreview}
              adaptVariableHandler={adaptVariable}
              uploadAudioVisualHandler={uploadAudioVisualHandler}
              removeAudioVisualHandler={removeAudioVisualHandler}
              textValidation={messageTextValidation}
              imageValidation={messageImageValidation}
              videoValidation={messageVideoValidation}
              voiceValidation={messageVoiceValidation}
              sendMessageHandler={sendMessageHandler}
              disabled={!activeRegistrationButton}
              reserved={true}
              bot={false}
              subscriptionTiers={subscriptionTiersState.subscriptionTiers}
              selectedSubscriptionTierHandler={(subscriptionTierId: number) =>
                setSubscriptionTier(subscriptionTierId)
              }
            />
          </Preview>
        </div>
      </div>
    </>
  )
}

export default ReservedMessage
