import React, { useState, useEffect } from 'react'
import '../../index.css'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { push as Menu } from 'react-burger-menu'
import { CombineReducers } from 'models'
import { Contributor } from 'models/contributors'
import Popup from 'reactjs-popup'
import { getContributor, getContributors } from 'actions/contributors'

import Sample from 'images/primary.webp'
import PopupArrow from 'images/popup_arrow.png'
import Cross from 'images/cross.png'
import MyDearLogo from 'images/cms_logo_mydear.png'
import RightArrow from 'images/right_arrow.png'

import ContributorMenu from 'components/modules/side_pane_menu/components/contributor'
import AdministratorMenu from 'components/modules/side_pane_menu/components/administrator'

const SPSidePaneMenu: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const stateStore: CombineReducers = useSelector((state: CombineReducers) => {
    return state
  })

  const [overlay, openOverlay] = useState<boolean>(false)
  const closeSidePaneHandler = () => openOverlay(!overlay)

  const contributorsPathPage = () => {
    if (
      (location.pathname.match(/contributors\/\d+\/messages$/) ||
        location.pathname.match(/contributors\/\d+\/reserved_messages$/) ||
        location.pathname.match(/contributors\/\d+\/messages\/\d+/) ||
        location.pathname.match(/contributors\/\d+\/new_message$/) ||
        location.pathname.match(/contributors\/\d+\/bot_messages$/) ||
        location.pathname.match(/contributors\/\d+\/new_bot_message$/) ||
        location.pathname.match(/contributors\/\d+\/profile$/) ||
        location.pathname.match(/contributors\/\d+\/profile\/edit$/) ||
        location.pathname.match(/contributors\/\d+\/account$/) ||
        location.pathname.match(/contributors\/\d+\/bot_messages$/) ||
        location.pathname.match(/contributors\/\d+\/bot_messages\/\d+$/) ||
        location.pathname.match(/contributors\/\d+\/analysis$/)) &&
      stateStore.contributors.currentContributor
    )
      return true

    return false
  }

  const fetchAllContributors = () => {
    dispatch(getContributors.started({ page: 1 }))
  }
  const openPopup = () => {
    fetchAllContributors()
  }

  useEffect(() => {
    if (
      stateStore.sessions.currentUser &&
      !stateStore.contributors.currentContributor
    ) {
      stateStore.sessions.currentUser.authType !== 'Administrator' &&
        dispatch(
          getContributor.started({
            id: stateStore.sessions.currentUser.contributor.id,
          })
        )
    }
  }, [
    stateStore.sessions.status.succeededGetCurrentUser,
    stateStore.contributors.status.succeededGetContributor,
  ])

  return (
    <div className="header_menus_for_sp">
      {contributorsPathPage() &&
      stateStore.sessions.currentUser?.authType === 'Contributor' ? (
        <div className="header_contributor_select_for_sp">
          <div className="header_contributor_select_label_wrapper_for_sp">
            <img
              src={
                stateStore.contributors.currentContributor?.icon
                  ? stateStore.contributors.currentContributor.icon
                  : Sample
              }
              className="header_contributor_icon_for_sp"
              onClick={() =>
                stateStore.contributors.currentContributor &&
                history.push(
                  `/contributors/${stateStore.contributors.currentContributor.id}/messages`
                )
              }
            />
            <p
              className="p_ellipsis"
              onClick={() =>
                stateStore.contributors.currentContributor &&
                history.push(
                  `/contributors/${stateStore.contributors.currentContributor.id}/messages`
                )
              }
            >
              {stateStore.contributors.currentContributor &&
                stateStore.contributors.currentContributor.name}
            </p>
          </div>
        </div>
      ) : (
        <Popup
          trigger={
            <div className="header_contributor_select_for_sp">
              <div className="header_contributor_select_label_wrapper_for_sp">
                <img
                  src={
                    stateStore.contributors.currentContributor?.icon
                      ? stateStore.contributors.currentContributor.icon
                      : Sample
                  }
                  className="header_contributor_icon_for_sp"
                />
                <p className="p_ellipsis">
                  {stateStore.contributors.currentContributor &&
                    stateStore.contributors.currentContributor.name}
                </p>
                <img
                  src={PopupArrow}
                  className="header_selection_icon_for_sp"
                />
              </div>
            </div>
          }
          arrow={false}
          position="right top"
          onOpen={openPopup}
          contentStyle={{
            width: '268px',
            height: '425px',
            zIndex: 1100,
            backgroundColor: '#FFFFFF',
            border: '1px solid #828282',
            borderRadius: '10px',
          }}
          offsetX={-116}
          offsetY={36}
        >
          {(close: () => void) => (
            <>
              <div className="popup_header">
                <p>アカウント切り替え</p>
              </div>
              <div className="popup_body">
                {stateStore.contributors.contributors &&
                  stateStore.contributors.contributors.data
                    .slice(0, 5)
                    .map((contributor: Contributor) => {
                      return (
                        <div
                          key={contributor.id}
                          className="popup_contributor_row"
                        >
                          <div
                            className="popup_contributor_label_wrapper"
                            onClick={() => {
                              dispatch(
                                getContributor.started({
                                  id: contributor.id,
                                })
                              )
                              history.push(
                                `/contributors/${contributor.id}/messages`
                              )
                              close()
                              closeSidePaneHandler()
                            }}
                          >
                            <img src={contributor.icon || Sample} />
                            <p>{contributor.name}</p>
                          </div>
                        </div>
                      )
                    })}
              </div>
              <div className="popup_footer_border" />
              <div className="popup_footer_wrapper">
                <div className="popup_footer_label_wrapper">
                  <p>すべての配信者をみる</p>
                  <img
                    src={RightArrow}
                    onClick={() => history.push('/contributors')}
                  />
                </div>
              </div>
            </>
          )}
        </Popup>
      )}
      <div className="header_menu_hamburger_for_sp">
        <Menu
          disableAutoFocus
          isOpen={overlay}
          onOpen={() => closeSidePaneHandler()}
          onClose={() => closeSidePaneHandler()}
          customBurgerIcon={
            <>
              <div className="hamburger_icon_top" />
              <div className="hamburger_icon_middle" />
              <div className="hamburger_icon_bottom" />
            </>
          }
          customCrossIcon={
            <img
              src={Cross}
              width={32}
              height={32}
              style={{ margin: '8px 0px' }}
            />
          }
          right
        >
          <div className="hamburger_menus_wrapper">
            <>
              {stateStore.sessions.currentUser?.authType === 'Contributor' && (
                <div className="hamburger_menus_header">
                  <img src={MyDearLogo} />
                  <span>管理画面</span>
                </div>
              )}
              {stateStore.sessions.currentUser &&
                stateStore.sessions.currentUser.authType ===
                  'Administrator' && (
                  <>
                    {contributorsPathPage() ? (
                      <div className="hamburger_menus_header_on_contributor_page">
                        <img
                          src={
                            stateStore.contributors.currentContributor?.icon
                              ? stateStore.contributors.currentContributor.icon
                              : Sample
                          }
                        />
                        <p>
                          {stateStore.contributors.currentContributor &&
                            stateStore.contributors.currentContributor.name}
                        </p>
                        <Popup
                          trigger={
                            <img
                              src={PopupArrow}
                              className="popup_trigger_img"
                            />
                          }
                          arrow={false}
                          position="left top"
                          onOpen={openPopup}
                          contentStyle={{
                            width: '268px',
                            height: '425px',
                            zIndex: 1100,
                            backgroundColor: '#FFFFFF',
                            border: '1px solid #828282',
                            borderRadius: '10px',
                          }}
                          offsetX={-74}
                          offsetY={60}
                        >
                          {(close: () => void) => (
                            <>
                              <div className="popup_header">
                                <p>アカウント切り替え</p>
                              </div>
                              <div className="popup_body">
                                {stateStore.contributors.contributors &&
                                  stateStore.contributors.contributors.data
                                    .slice(0, 5)
                                    .map((contributor: Contributor) => {
                                      return (
                                        <div
                                          key={contributor.id}
                                          className="popup_contributor_row"
                                        >
                                          <div
                                            className="popup_contributor_label_wrapper"
                                            onClick={() => {
                                              dispatch(
                                                getContributor.started({
                                                  id: contributor.id,
                                                })
                                              )
                                              history.push(
                                                `/contributors/${contributor.id}/messages`
                                              )
                                              close()
                                              closeSidePaneHandler()
                                            }}
                                          >
                                            <img
                                              src={contributor.icon || Sample}
                                            />
                                            <p>{contributor.name}</p>
                                          </div>
                                        </div>
                                      )
                                    })}
                              </div>
                              <div className="popup_footer_border" />
                              <div className="popup_footer_wrapper">
                                <div className="popup_footer_label_wrapper">
                                  <p>すべての配信者をみる</p>
                                  <img
                                    src={RightArrow}
                                    onClick={() =>
                                      history.push('/contributors')
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </Popup>
                      </div>
                    ) : (
                      <div className="hamburger_menus_header">
                        <img src={MyDearLogo} />
                        <span>管理画面</span>
                      </div>
                    )}
                  </>
                )}
              <div style={{ clear: 'both' }} />
            </>
            {stateStore.sessions.currentUser?.authType === 'Contributor' && (
              <ContributorMenu
                store={stateStore}
                closeSidePaneHandler={closeSidePaneHandler}
                sp
              />
            )}
            {stateStore.sessions.currentUser?.authType === 'Administrator' && (
              <AdministratorMenu
                store={stateStore}
                closeSidePaneHandler={closeSidePaneHandler}
                sp
              />
            )}
          </div>
        </Menu>
      </div>
    </div>
  )
}

export default SPSidePaneMenu
