import axios from 'axios'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import financialReportsDeserializer from 'deserializers/financial_reports'
import { DEFAULT_API_CONFIG } from 'models'
import { FinancialReport } from 'models/financial_reports'
import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { buildHeader } from 'services'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import Overlay from 'components/modules/overlay'
import Modal from 'components/modules/modal'
import ModalButton from 'components/modules/modal/components/button'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router-dom'
import Button from 'components/modules/button'
import { ReactComponent as Create } from 'images/create.svg'
import OverlayLoader from 'components/modules/overlay/components/loader'

export default function FinancialReportsList() {
  const queryClient = useQueryClient()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deleteTarget, setDeleteTarget] = useState<number>()
  const [page, setPage] = useState<number>(1)
  const history = useHistory()

  const fetchFinancialReports = useQuery(
    ['financialReports', page],
    async () => {
      const uri = `/api/cms/v1/financial_reports?page=${page}`
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.get(uri, {
        headers: buildHeader(),
      })

      return financialReportsDeserializer({
        data: response.data.financial_reports,
        meta: response.data.meta,
      })
    }
  )

  const deleteFinancialReport = useMutation(
    async () => {
      const uri = `/api/cms/v1/financial_reports/${deleteTarget}`
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.delete(uri, {
        headers: buildHeader('multipart/form-data'),
      })

      return response
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('financialReports')
      },
      onSettled: () => {
        fetchFinancialReports.refetch()
      },
    }
  )

  const lastMonth = () => {
    const now = new Date()
    const endOfLastMonth = new Date(now.getTime())
    endOfLastMonth.setDate(0)
    return endOfLastMonth.getMonth() + 1
  }

  const lastMonthYear = () => {
    const now = new Date()
    const endOfLastMonth = new Date(now.getTime())
    endOfLastMonth.setDate(0)
    return endOfLastMonth.getFullYear()
  }

  const currentYearMonthReport = () => {
    return fetchFinancialReports.data?.data.find(
      (report: FinancialReport) =>
        report.year === lastMonthYear() && report.month === lastMonth()
    )
  }

  if (fetchFinancialReports.isLoading || deleteFinancialReport.isLoading)
    return <OverlayLoader loading={true} />

  if (!fetchFinancialReports.data) return <></>

  return (
    <>
      {deleteModal && (
        <Overlay dismissHandler={() => setDeleteModal(false)}>
          <Modal
            title={'レポートを削除しますか？'}
            dismissHandler={() => setDeleteModal(false)}
          >
            <ModalButton
              color={'#FFFFFF'}
              textColor={'#828282'}
              label={'キャンセル'}
              onClickHandler={() => setDeleteModal(false)}
            />
            <ModalButton
              color={'#E25047'}
              textColor={'#FFFFFF'}
              label={'削除'}
              onClickHandler={() => {
                setDeleteModal(false)
                deleteFinancialReport.mutate()
              }}
            />
          </Modal>
        </Overlay>
      )}
      <MainHeader heading={'財務レポート管理'}>
        {!currentYearMonthReport() && (
          <Button
            label={'新規作成'}
            icon={Create}
            textColor={'#FFFFFF'}
            disabledTextColor={'#FFFFFF'}
            color={'#17DBC9'}
            disabledColor={'#E0E0E0'}
            disabled={false}
            onClickHandler={() => history.push('/financial_reports/new')}
          />
        )}
      </MainHeader>
      <MainTable
        columns={{
          scale: '40% 30% 30%',
          items: [
            { label: 'レポート', grid: '1 / 2' },
            { label: 'ステータス', grid: '2 / 3' },
            { label: '作成日時', grid: '3 / 4' },
          ],
        }}
        items={fetchFinancialReports.data.data.map(
          (financialReport: FinancialReport) => ({
            id: financialReport.id,
            data: {
              title: {
                id: financialReport.id,
                value: `${financialReport.year}年${financialReport.month}月レポート`,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              status: {
                id: financialReport.id,
                value: financialReport.status,
                clickable: false,
                subLabel: false,
                grid: '2 / 3',
              },
              created_at: {
                id: financialReport.id,
                value: financialReport.createdAt,
                clickable: false,
                subLabel: false,
                grid: '3 / 4',
              },
            },
          })
        )}
        selectItemHandler={(id: number) => {
          history.push(`/financial_reports/${id}/payment_statements`)
        }}
        option={{
          svg: Bucket,
          clickHandler: (targetId: number) => {
            setDeleteModal(true)
            setDeleteTarget(targetId)
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ReactPaginate
            pageCount={fetchFinancialReports.data.meta.totalPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            activeClassName={'active'}
            onPageChange={(event: { selected: number }) => {
              setPage(event.selected)
            }}
            previousLabel={
              <div>
                <span>&lt;</span>
              </div>
            }
            nextLabel={
              <div>
                <span>&gt;</span>
              </div>
            }
            breakLabel={'...'}
          />
        </div>
      </MainTable>
    </>
  )
}
