import * as ActionType from 'actions/digital_contents/types'
import { DigitalContentsState } from 'models/digital_contents'
import { DigitalContentsAction } from 'actions/digital_contents'

export const initialState: DigitalContentsState = {
  digitalContents: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  digitalContent: undefined,
  status: {
    startedGetDigitalContents: false,
    succeededGetDigitalContents: false,
    failedGetDigitalContents: false,
    startedGetDigitalContent: false,
    succeededGetDigitalContent: false,
    failedGetDigitalContent: false,
    startedCreateDigitalContent: false,
    succeededCreateDigitalContent: false,
    failedCreateDigitalContent: false,
    startedUpdateDigitalContent: false,
    succeededUpdateDigitalContent: false,
    failedUpdateDigitalContent: false,
    startedDeleteDigitalContent: false,
    succeededDeleteDigitalContent: false,
    failedDeleteDigitalContent: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const digitalContentsReducer = (
  state: DigitalContentsState = initialState,
  action: DigitalContentsAction
): DigitalContentsState => {
  switch (action.type) {
    case ActionType.STARTED_GET_DIGITAL_CONTENTS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetDigitalContents: true,
          succeededGetDigitalContents: false,
          failedGetDigitalContents: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_GET_DIGITAL_CONTENTS:
      return {
        ...state,
        digitalContents: action.payload.digitalContents,
        status: {
          ...state.status,
          startedGetDigitalContents: false,
          succeededGetDigitalContents: true,
          failedGetDigitalContents: false,
        },
      }
    case ActionType.FAILED_GET_DIGITAL_CONTENTS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetDigitalContents: false,
          succeededGetDigitalContents: false,
          failedGetDigitalContents: true,
        },
      }
    case ActionType.STARTED_GET_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetDigitalContent: true,
          succeededGetDigitalContent: false,
          failedGetDigitalContent: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_GET_DIGITAL_CONTENT:
      return {
        ...state,
        digitalContent: action.payload.digitalContent,
        status: {
          ...state.status,
          startedGetDigitalContent: false,
          succeededGetDigitalContent: true,
          failedGetDigitalContent: false,
        },
      }
    case ActionType.FAILED_GET_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetDigitalContent: false,
          succeededGetDigitalContent: false,
          failedGetDigitalContent: true,
        },
      }
    case ActionType.STARTED_CREATE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateDigitalContent: true,
          succeededCreateDigitalContent: false,
          failedCreateDigitalContent: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_CREATE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateDigitalContent: false,
          succeededCreateDigitalContent: true,
          failedCreateDigitalContent: false,
        },
      }
    case ActionType.FAILED_CREATE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateDigitalContent: false,
          succeededCreateDigitalContent: false,
          failedCreateDigitalContent: true,
        },
      }
    case ActionType.STARTED_UPDATE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateDigitalContent: true,
          succeededUpdateDigitalContent: false,
          failedUpdateDigitalContent: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_UPDATE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateDigitalContent: false,
          succeededUpdateDigitalContent: true,
          failedUpdateDigitalContent: false,
        },
      }
    case ActionType.FAILED_UPDATE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateDigitalContent: false,
          succeededUpdateDigitalContent: false,
          failedUpdateDigitalContent: true,
        },
      }
    case ActionType.STARTED_DELETE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteDigitalContent: true,
          succeededDeleteDigitalContent: false,
          failedDeleteDigitalContent: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_DELETE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteDigitalContent: false,
          succeededDeleteDigitalContent: true,
          failedDeleteDigitalContent: false,
        },
      }
    case ActionType.FAILED_DELETE_DIGITAL_CONTENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteDigitalContent: false,
          succeededDeleteDigitalContent: false,
          failedDeleteDigitalContent: true,
        },
      }
    default:
      return state
  }
}

export default digitalContentsReducer
