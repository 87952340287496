import { CombineReducers } from 'models'
import { useDispatch, useSelector } from 'react-redux'
import { Contributors, ContributorsState } from 'models/contributors'
import { getContributors } from 'actions/contributors'

export function useContributors(): [
  contributors: Contributors,
  fetchContributors: () => void
] {
  const dispatch = useDispatch()

  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => state.contributors
  )

  const fetchContributors = () => {
    dispatch(getContributors.started({ page: 1 }))
  }

  return [contributorsState.contributors, fetchContributors]
}
