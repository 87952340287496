import { digitalContentImageValidation } from './image'
import { digitalContentVoiceValidation } from './voice'
import { digitalContentTitleValidation } from './title'
import { digitalContentDescriptionValidation } from './description'

export const digitalContentValidations = {
  image: digitalContentImageValidation,
  voice: digitalContentVoiceValidation,
  title: digitalContentTitleValidation,
  description: digitalContentDescriptionValidation,
}
