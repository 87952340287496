import { TagsAction } from 'actions/tags'
import * as ActionType from 'actions/tags/types'
import { TagsState } from 'models/tags'

export const initialState: TagsState = {
  tags: null,
  status: {
    startedGetTags: false,
    succeededGetTags: false,
    failedGetTags: false,
    startedCreateTag: false,
    succeededCreateTag: false,
    failedCreateTag: false,
    startedDeleteTag: false,
    succeededDeleteTag: false,
    failedDeleteTag: false,
    startedUpdateTagManagement: false,
    succeededUpdateTagManagement: false,
    failedUpdateTagManagement: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const tagsReducer = (
  state: TagsState = initialState,
  action: TagsAction
): TagsState => {
  switch (action.type) {
    case ActionType.STARTED_GET_TAGS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetTags: true,
          succeededGetTags: false,
          failedGetTags: false,
        },
      }
    case ActionType.SUCCEEDED_GET_TAGS:
      return {
        ...state,
        tags: action.payload.tags,
        status: {
          ...state.status,
          startedGetTags: false,
          succeededGetTags: true,
          failedGetTags: false,
        },
      }
    case ActionType.FAILED_GET_TAGS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetTags: false,
          succeededGetTags: false,
          failedGetTags: true,
        },
      }
    case ActionType.STARTED_CREATE_TAG:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateTag: true,
          succeededCreateTag: false,
          failedCreateTag: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_CREATE_TAG:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateTag: false,
          succeededCreateTag: true,
          failedCreateTag: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_CREATE_TAG:
      return {
        ...state,
        status: {
          ...state.status,
          startedCreateTag: false,
          succeededCreateTag: false,
          failedCreateTag: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_DELETE_TAG:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteTag: true,
          succeededDeleteTag: false,
          failedDeleteTag: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_DELETE_TAG:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteTag: false,
          succeededDeleteTag: true,
          failedDeleteTag: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_DELETE_TAG:
      return {
        ...state,
        status: {
          ...state.status,
          startedDeleteTag: false,
          succeededDeleteTag: false,
          failedDeleteTag: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_UPDATE_TAG_MANAGEMENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateTagManagement: true,
          succeededUpdateTagManagement: false,
          failedUpdateTagManagement: false,
        },
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_UPDATE_TAG_MANAGEMENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateTagManagement: false,
          succeededUpdateTagManagement: true,
          failedUpdateTagManagement: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_UPDATE_TAG_MANAGEMENT:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdateTagManagement: false,
          succeededUpdateTagManagement: false,
          failedUpdateTagManagement: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_RESET_ALL_STATUS_FLAGS:
      return {
        ...state,
        status: {
          startedGetTags: false,
          succeededGetTags: false,
          failedGetTags: false,
          startedCreateTag: false,
          succeededCreateTag: false,
          failedCreateTag: false,
          startedDeleteTag: false,
          succeededDeleteTag: false,
          failedDeleteTag: false,
          startedUpdateTagManagement: false,
          succeededUpdateTagManagement: false,
          failedUpdateTagManagement: false,
        },
      }
    case ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    case ActionType.FAILED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    default:
      return state
  }
}

export default tagsReducer
