import { AnalysisDashboard } from 'models/analysis_dashboards'
import { Contributor, Contributors } from 'models/contributors'
import { Profile } from 'models/contributors'
import * as ActionType from './types'

export const getContributors = {
  started: (request: { page: number }) => ({
    type: ActionType.STARTED_GET_CONTRIBUTORS as typeof ActionType.STARTED_GET_CONTRIBUTORS,
    payload: request,
  }),
  succeeded: (response: { contributors: Contributors; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_CONTRIBUTORS as typeof ActionType.SUCCEEDED_GET_CONTRIBUTORS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CONTRIBUTORS as typeof ActionType.FAILED_GET_CONTRIBUTORS,
    payload: response,
  }),
}

export const getContributor = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_GET_CONTRIBUTOR as typeof ActionType.STARTED_GET_CONTRIBUTOR,
    payload: request,
  }),
  succeeded: (response: { contributor: Contributor; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_CONTRIBUTOR as typeof ActionType.SUCCEEDED_GET_CONTRIBUTOR,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CONTRIBUTOR as typeof ActionType.FAILED_GET_CONTRIBUTOR,
    payload: response,
  }),
}

export const createContributor = {
  started: (request: {
    accountType: string
    email: string
    password: string
    name: string
    englishName: string
  }) => ({
    type: ActionType.STARTED_CREATE_CONTRIBUTOR as typeof ActionType.STARTED_CREATE_CONTRIBUTOR,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_CREATE_CONTRIBUTOR as typeof ActionType.SUCCEEDED_CREATE_CONTRIBUTOR,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CREATE_CONTRIBUTOR as typeof ActionType.FAILED_CREATE_CONTRIBUTOR,
    payload: response,
  }),
}

export const updateContributor = {
  started: (request: { id: number; data: FormData }) => ({
    type: ActionType.STARTED_UPDATE_CONTRIBUTOR as typeof ActionType.STARTED_UPDATE_CONTRIBUTOR,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR as typeof ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_CONTRIBUTOR as typeof ActionType.FAILED_UPDATE_CONTRIBUTOR,
    payload: response,
  }),
}

export const deleteContributor = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_DELETE_CONTRIBUTOR as typeof ActionType.STARTED_DELETE_CONTRIBUTOR,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_DELETE_CONTRIBUTOR as typeof ActionType.SUCCEEDED_DELETE_CONTRIBUTOR,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_DELETE_CONTRIBUTOR as typeof ActionType.FAILED_DELETE_CONTRIBUTOR,
    payload: response,
  }),
}

export const updateAccountDetails = {
  started: (request: {
    auth: string
    type: string
    email?: string
    accountId: string
    password?: string
    currentPassword?: string
  }) => ({
    type: ActionType.STARTED_UPDATE_ACCOUNT_DETAILS as typeof ActionType.STARTED_UPDATE_ACCOUNT_DETAILS,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_ACCOUNT_DETAILS as typeof ActionType.SUCCEEDED_UPDATE_ACCOUNT_DETAILS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_ACCOUNT_DETAILS as typeof ActionType.FAILED_UPDATE_ACCOUNT_DETAILS,
    payload: response,
  }),
}

export const getContributorAnalysis = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_GET_CONTRIBUTOR_ANALYSIS as typeof ActionType.STARTED_GET_CONTRIBUTOR_ANALYSIS,
    payload: request,
  }),
  succeeded: (response: { message: string; analysis: AnalysisDashboard }) => ({
    type: ActionType.SUCCEEDED_GET_CONTRIBUTOR_ANALYSIS as typeof ActionType.SUCCEEDED_GET_CONTRIBUTOR_ANALYSIS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CONTRIBUTOR_ANALYSIS as typeof ActionType.FAILED_GET_CONTRIBUTOR_ANALYSIS,
    payload: response,
  }),
}

export const updateContributorProfiles = {
  started: (request: {
    contributorId: number
    contributorProfiles: Profile[]
  }) => ({
    type: ActionType.STARTED_UPDATE_CONTRIBUTOR_PROFILES as typeof ActionType.STARTED_UPDATE_CONTRIBUTOR_PROFILES,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR_PROFILES as typeof ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR_PROFILES,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_CONTRIBUTOR_PROFILES as typeof ActionType.FAILED_UPDATE_CONTRIBUTOR_PROFILES,
    payload: response,
  }),
}

export const resetMessages = {
  started: () => ({
    type: ActionType.STARTED_RESET_MESSAGES as typeof ActionType.STARTED_RESET_MESSAGES,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_MESSAGES as typeof ActionType.SUCCEEDED_RESET_MESSAGES,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_MESSAGES as typeof ActionType.FAILED_RESET_MESSAGES,
  }),
}

export const resetAllStatusFlags = {
  started: () => ({
    type: ActionType.STARTED_RESET_ALL_STATUS_FLAGS as typeof ActionType.STARTED_RESET_ALL_STATUS_FLAGS,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS as typeof ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_ALL_STATUS_FLAGS as typeof ActionType.FAILED_RESET_ALL_STATUS_FLAGS,
  }),
}

export type ContributorsAction =
  | ReturnType<typeof getContributors.started>
  | ReturnType<typeof getContributors.succeeded>
  | ReturnType<typeof getContributors.failed>
  | ReturnType<typeof getContributor.started>
  | ReturnType<typeof getContributor.succeeded>
  | ReturnType<typeof getContributor.failed>
  | ReturnType<typeof createContributor.started>
  | ReturnType<typeof createContributor.succeeded>
  | ReturnType<typeof createContributor.failed>
  | ReturnType<typeof updateContributor.started>
  | ReturnType<typeof updateContributor.succeeded>
  | ReturnType<typeof updateContributor.failed>
  | ReturnType<typeof deleteContributor.started>
  | ReturnType<typeof deleteContributor.succeeded>
  | ReturnType<typeof deleteContributor.failed>
  | ReturnType<typeof updateAccountDetails.started>
  | ReturnType<typeof updateAccountDetails.succeeded>
  | ReturnType<typeof updateAccountDetails.failed>
  | ReturnType<typeof getContributorAnalysis.started>
  | ReturnType<typeof getContributorAnalysis.succeeded>
  | ReturnType<typeof getContributorAnalysis.failed>
  | ReturnType<typeof updateContributorProfiles.started>
  | ReturnType<typeof updateContributorProfiles.succeeded>
  | ReturnType<typeof updateContributorProfiles.failed>
  | ReturnType<typeof resetMessages.started>
  | ReturnType<typeof resetMessages.succeeded>
  | ReturnType<typeof resetMessages.failed>
  | ReturnType<typeof resetAllStatusFlags.started>
  | ReturnType<typeof resetAllStatusFlags.succeeded>
  | ReturnType<typeof resetAllStatusFlags.failed>
