import { useStyles } from 'hooks/useStyles'
import { ReactComponent as VideoPreview } from 'images/video_preview.svg'
import { ReactComponent as VideoThumbnailShadow } from 'images/video_thumbnail_shadow.svg'
import { ReactComponent as VoicePreview } from 'images/voice_preview.svg'
import SVGWrapper from 'components/modules/svg_wrapper'

type Item = {
  id: number
  data: {
    [key: string]: {
      id: number
      value: string | number | boolean | string[]
      type?: 'IMAGE' | 'VIDEO' | 'VOICE' | 'ARRAY'
      clickable: boolean
      subLabel: boolean
      grid: string
    }
  }
}

type Props = {
  columns: { scale: string; items: Array<{ label: string; grid: string }> }
  items: Item[]
  selectItemHandler: (id: number) => void
  option?: {
    label?: string
    svg?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string
      }
    >
    clickHandler: (targetId: number) => void
  }
  children?: React.ReactNode
}

const MainTable: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_table',
  })

  if (!styles) return <></>

  const composeArrayColumn = (value: string | number | boolean | string[]) => {
    const array = value as string[]

    return array.map((value: string, index: number) => (
      <span key={index}>
        {value}
        {array[index + 1] ? '・' : ''}
      </span>
    ))
  }

  return (
    <div className={styles.default.main_table_component}>
      <div className={styles.default.table}>
        <div className={styles.default.header}>
          <div
            className={styles.default.items}
            style={{
              gridTemplateColumns: `${props.columns.scale}`,
            }}
          >
            {props.columns.items.length > 0 &&
              props.columns.items.map((column: { label: string }) => (
                <div className={styles.default.column} key={column.label}>
                  <div className={styles.default.label}>
                    <span>{column.label}</span>
                  </div>
                </div>
              ))}
          </div>
          {props.option && <div className={styles.default.option_column} />}
        </div>
        <div className={styles.default.body}>
          {props.items.length > 0 &&
            props.items.map((item: Item, index: number) => (
              <div className={styles.default.row} key={index}>
                <div
                  className={styles.default.item}
                  style={{
                    gridTemplateColumns: `${props.columns.scale}`,
                  }}
                >
                  {Object.keys(item.data).map((key: string) => (
                    <div
                      style={{
                        gridColumn: item.data[key].grid,
                      }}
                      className={
                        item.data[key].clickable
                          ? styles.default.clickable
                          : styles.default.unclickable
                      }
                      key={key}
                    >
                      {item.data[key].type ? (
                        <>
                          {item.data[key].type === 'IMAGE' && (
                            <div className={styles.default.content}>
                              <img
                                src={String(item.data[key].value)}
                                style={
                                  item.data[key].clickable
                                    ? {
                                        cursor: 'pointer',
                                      }
                                    : {}
                                }
                                onClick={() =>
                                  item.data[key].clickable &&
                                  props.selectItemHandler(item.id)
                                }
                              />
                            </div>
                          )}
                          {item.data[key].type === 'VIDEO' && (
                            <div className={styles.default.content}>
                              <div
                                className={styles.default.video_background}
                                style={
                                  item.data[key].clickable
                                    ? {
                                        cursor: 'pointer',
                                      }
                                    : {}
                                }
                                onClick={() =>
                                  item.data[key].clickable &&
                                  props.selectItemHandler(item.id)
                                }
                              >
                                <img
                                  src={String(item.data[key].value)}
                                  className={styles.default.video_thumbnail}
                                />
                                <VideoThumbnailShadow
                                  className={
                                    styles.default.video_thumbnail_shadow
                                  }
                                />
                                <VideoPreview
                                  className={styles.default.video_icon}
                                />
                              </div>
                            </div>
                          )}
                          {item.data[key].type === 'VOICE' && (
                            <div className={styles.default.content}>
                              <div
                                className={styles.default.voice_background}
                                style={
                                  item.data[key].clickable
                                    ? {
                                        cursor: 'pointer',
                                      }
                                    : {}
                                }
                                onClick={() =>
                                  item.data[key].clickable &&
                                  props.selectItemHandler(item.id)
                                }
                              >
                                <VoicePreview
                                  className={styles.default.voice_icon}
                                />
                              </div>
                            </div>
                          )}
                          {item.data[key].type === 'ARRAY' && (
                            <div className={styles.default.array_field}>
                              {composeArrayColumn(item.data[key].value)}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className={styles.default.content}>
                          <span
                            onClick={() =>
                              item.data[key].clickable &&
                              props.selectItemHandler(item.id)
                            }
                          >
                            {item.data[key].value}
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {props.option && props.option.label ? (
                  <div className={styles.default.option_btn}>
                    <span
                      onClick={() =>
                        props.option && props.option.clickHandler(item.id)
                      }
                    >
                      {props.option.label}
                    </span>
                  </div>
                ) : props.option && props.option.svg ? (
                  <div className={styles.default.option_btn}>
                    <SVGWrapper
                      icon={props.option.svg}
                      width={'24'}
                      height={'24'}
                      fill={'#828282'}
                      onClick={() =>
                        props.option && props.option.clickHandler(item.id)
                      }
                    />
                  </div>
                ) : null}
                <div className={styles.default.sp_details}>
                  <div className={styles.default.sp_row_value}>
                    {Object.keys(item.data).map((key: string) => (
                      <div className={styles.default.each_item} key={key}>
                        {(item.data[key].clickable ||
                          item.data[key].subLabel) && (
                          <div
                            className={
                              item.data[key].clickable
                                ? styles.default.main
                                : styles.default.sub
                            }
                          >
                            {!item.data[key].type && (
                              <span
                                onClick={() =>
                                  item.data[key].clickable &&
                                  props.selectItemHandler(item.id)
                                }
                              >
                                {item.data[key].value}
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {props.option && props.option.label ? (
                    <div className={styles.default.sp_option_btn}>
                      <span
                        onClick={() =>
                          props.option && props.option.clickHandler(item.id)
                        }
                      >
                        {props.option.label}
                      </span>
                    </div>
                  ) : props.option && props.option.svg ? (
                    <div className={styles.default.sp_option_btn}>
                      <props.option.svg
                        onClick={() =>
                          props.option && props.option.clickHandler(item.id)
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.default.children}>{props.children}</div>
    </div>
  )
}

export default MainTable
