import { useState, useEffect } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import MainHeader from 'components/modules/main_header'
import { useStyles } from 'hooks/useStyles'
import { useValidation } from 'hooks/useValidation'
import MessageOptions from 'components/messages/create/components/message_options'
import { Howl } from 'howler'
import Preview from 'components/modules/preview'
import PreviewMessageForm from 'components/modules/preview/components/message_form'
import { getContributor } from 'actions/contributors'
import {
  createContributorMessage,
  createReservedContributorMessage,
  setInitialState,
} from 'actions/messages'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as Save } from 'images/save.svg'
import { ReactComponent as Send } from 'images/send.svg'
import MessageForm from 'components/modules/message_form'
import CreateIcon from 'images/create_message.png'
import { useResponsive } from 'hooks/useResponsive'
import { generateUrlFromFile } from 'utils/file_manager'
import Toast from 'components/modules/toast'
import PrecheckMessage from 'components/modules/preview/components/precheck_message'
import Overlay from 'components/modules/overlay'
import { AudioVisualMessage } from 'models/messages'
import { getSubscriptionTiers } from 'actions/subscription_tiers'
import { SubscriptionTiersState } from 'models/subscription_tiers'

const ContributorMessage: React.FC = () => {
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const subscriptionTiersState: SubscriptionTiersState = useSelector(
    (state: CombineReducers) => {
      return state.subscriptionTiers
    }
  )
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/messages/create',
  })
  const [responsive] = useResponsive()
  const params = useParams<{
    id: string
  }>()
  const location = useLocation()

  const [selectedDate, setSelectedDate] = useState<Date>()
  const [selectedHour, setSelectedHour] = useState<string>('00')
  const [selectedMinute, setSelectedMinute] = useState<string>('00')
  const [audioVisualMessage, setAudioVisualMessage] =
    useState<AudioVisualMessage>()
  const [publicMessage, setPublicMessage] = useState<boolean>(true)
  const [activeRegistrationButton, setActiveRegistrationButton] =
    useState<boolean>(false)
  const [reservedMessage, setReservedMessage] = useState<boolean>(false)
  const [imgPreview, setImgPreview] = useState<string>()
  const [videoPreview, setVideoPreview] = useState<string>()
  const [voicePreview, setVoicePreview] = useState<string>()
  const [voicePreviewAudio, setVoicePreviewAudio] = useState<Howl>()
  const [voicePreviewPlaying, setVoicePreviewPlaying] = useState<boolean>(false)
  const [showPrecheckModal, setShowPrecheckModal] = useState<boolean>(false)
  const [subscriptionTier, setSubscriptionTier] = useState<number>()
  const [xPost, setXPost] = useState<boolean>(true)

  const [messageText, setMessageText] = useState<string>('')
  const [messageTextValidation, useMessageTextValidation] = useValidation({
    type: 'message_text',
    value: messageText,
  })
  const [messageImageValidation, useMessageImageValidation] = useValidation({
    type: 'message_image',
    value: null,
  })
  const [messageVideoValidation, useMessageVideoValidation] = useValidation({
    type: 'message_video',
    value: null,
  })
  const [messageVoiceValidation, useMessageVoiceValidation] = useValidation({
    type: 'message_voice',
    value: null,
  })
  const [messageDatetimeValidation, useMessageDatetimeValidation] =
    useValidation({
      type: 'message_datetime',
      value: moment(new Date()).format('YYYY/MM/DD HH:mm'),
    })

  const confirmValidText = () => {
    if (messageText.length > 0) {
      return messageTextValidation && messageTextValidation.type === 'succeeded'
    } else if (audioVisualMessage) {
      return true
    } else {
      return false
    }
  }
  const confirmValidMedia = () => {
    if (audioVisualMessage) {
      switch (audioVisualMessage.type) {
        case 'image':
          return (
            messageImageValidation &&
            messageImageValidation.type === 'succeeded'
          )
        case 'video':
          return (
            messageVideoValidation &&
            messageVideoValidation.type === 'succeeded'
          )
        case 'voice':
          return (
            messageVoiceValidation &&
            messageVoiceValidation.type === 'succeeded'
          )
        default:
          return false
      }
    }
    return true
  }
  const confirmValidDatetime = () => {
    if (reservedMessage) {
      if (selectedDate && selectedHour && selectedMinute) {
        return (
          messageDatetimeValidation &&
          messageDatetimeValidation.type === 'succeeded'
        )
      } else {
        return false
      }
    } else {
      return true
    }
  }

  const registrationButtonChecker = () => {
    if (
      confirmValidText() &&
      confirmValidMedia() &&
      confirmValidDatetime() &&
      ((sessionsState.currentUser!.authType === 'Administrator' &&
        contributorsState.currentContributor?.productId ===
          'mydear_operator') ||
        subscriptionTier)
    ) {
      setActiveRegistrationButton(true)
      return
    }
    setActiveRegistrationButton(false)
  }
  const adaptVariableHandler = (variable: string, targetCursor: number) => {
    const newTextMessage = `${messageText.slice(
      0,
      targetCursor
    )}(${variable})${messageText.slice(targetCursor)}`
    setMessageText(newTextMessage)
  }
  const reservedMessageHandler = (reserved: boolean) => {
    setReservedMessage(reserved)

    const runAt =
      moment(selectedDate).format('YYYY/MM/DD') +
      ' ' +
      selectedHour +
      ':' +
      selectedMinute

    useMessageDatetimeValidation('message_datetime', runAt)
  }
  const selectedHourHandler = (hour: string) => {
    setSelectedHour(hour)

    const runAt =
      moment(selectedDate).format('YYYY/MM/DD') +
      ' ' +
      hour +
      ':' +
      selectedMinute

    useMessageDatetimeValidation('message_datetime', runAt)
  }
  const selectedMinuteHandler = (minute: string) => {
    setSelectedMinute(minute)

    const runAt =
      moment(selectedDate).format('YYYY/MM/DD') +
      ' ' +
      selectedHour +
      ':' +
      minute

    useMessageDatetimeValidation('message_datetime', runAt)
  }
  const selectedDateHandler = (date: Date) => {
    setSelectedDate(date)

    const runAt =
      moment(date).format('YYYY/MM/DD') +
      ' ' +
      selectedHour +
      ':' +
      selectedMinute

    useMessageDatetimeValidation('message_datetime', runAt)
  }
  const addAudioVisualHandler = () =>
    setAudioVisualMessage({ type: 'image', blob: undefined })

  const removeAudioVisualHandler = () => {
    setAudioVisualMessage(undefined)
    controlPreviewVoice()
    setImgPreview(undefined)
    setVideoPreview(undefined)
    setVoicePreview(undefined)
    setVoicePreviewAudio(undefined)
    setVoicePreviewPlaying(false)
    useMessageImageValidation('reset', null)
    useMessageVideoValidation('reset', null)
    useMessageVoiceValidation('reset', null)
  }
  const messageTextHandler = (value: string) => {
    useMessageTextValidation('message_text', value)
    setMessageText(value)
    localStorage.setItem('stashed-message', value)
  }
  const uploadAudioVisualHandler = (type: string, blob: File) => {
    if (type === 'image') {
      setVideoPreview(undefined)
      setVoicePreview(undefined)
      useMessageImageValidation('message_image', blob)
    }
    if (type === 'video') {
      setImgPreview(undefined)
      setVoicePreview(undefined)
      useMessageVideoValidation('message_video', blob)
    }
    if (type === 'voice') {
      setImgPreview(undefined)
      setVideoPreview(undefined)
      useMessageVoiceValidation('message_voice', blob)
    }
    setAudioVisualMessage({ type, blob })
  }
  const publicMessageHandler = (value: boolean) => setPublicMessage(value)

  const xPostHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setXPost(event.target.checked)
  }

  const showPrecheckHandler = () =>
    reservedMessage
      ? sendMessageHandler()
      : setShowPrecheckModal(!showPrecheckModal)

  const pageRollbackHandler = () => {
    history.push(
      `/contributors/${contributorsState.currentContributor!.id}/messages`
    )
  }

  const sendMessageHandler = () => {
    const formData = new FormData()
    sessionsState.currentUser &&
      formData.append(
        'contributor_id',
        JSON.stringify(
          sessionsState.currentUser.authType === 'Administrator'
            ? contributorsState.currentContributor!.id
            : sessionsState.currentUser.contributor.id
        )
      )
    if (messageText.length > 0) formData.append('text', messageText)
    formData.append('public_text', String(publicMessage))
    if (xPost) formData.append('x_post', 'enabled')

    if (audioVisualMessage && audioVisualMessage.blob) {
      switch (audioVisualMessage.type) {
        case 'image':
          formData.append('message_image', audioVisualMessage.blob)
          formData.append('public_image', String(publicMessage))
          formData.append('type', audioVisualMessage.type)
          break
        case 'voice':
          formData.append('message_voice', audioVisualMessage.blob)
          formData.append('public_voice', String(publicMessage))
          formData.append('type', audioVisualMessage.type)
          break
        case 'video':
          formData.append('message_video', audioVisualMessage.blob)
          formData.append('public_video', String(publicMessage))
          formData.append('type', audioVisualMessage.type)
          break
      }
    }

    formData.append('subscription_tier_id', String(subscriptionTier))

    if (!contributorsState.currentContributor) return

    if (reservedMessage) {
      const runAt =
        moment(selectedDate).format('YYYY/MM/DD') +
        ' ' +
        selectedHour +
        ':' +
        selectedMinute
      formData.append('run_at', runAt)
      formData.append('delivered', 'false')
      formData.append('reserved', 'true')
      const five_minutes_after = moment(runAt).isSameOrAfter(
        moment(new Date()).add(5, 'm').format('YYYY/MM/DD HH:mm')
      )

      if (five_minutes_after) {
        dispatch(
          createReservedContributorMessage.started({
            data: formData,
          })
        )
      } else {
        alert('予約配信では最低5分以上未来の日時を入力してください。')
        return
      }
    } else {
      dispatch(
        createContributorMessage.started({
          data: formData,
        })
      )
    }

    localStorage.removeItem('stashed-message')

    if (responsive === 'small' || responsive === 'medium') {
      setMessageText('')
      removeAudioVisualHandler()
    } else {
      pageRollbackHandler()
    }

    setShowPrecheckModal(false)
  }

  const controlPreviewVoice = () => {
    if (!voicePreview) return

    if (voicePreviewAudio) {
      if (voicePreviewAudio.playing()) {
        voicePreviewAudio.pause()
        setVoicePreviewPlaying(false)
      } else {
        voicePreviewAudio.play()
        setVoicePreviewPlaying(true)
      }
    } else {
      const voice = new Howl({
        src: [voicePreview],
        html5: true,
      })
      setVoicePreviewAudio(voice)
    }
  }

  const distributionLabel = () => {
    if (contributorsState.currentContributor)
      return `${contributorsState.currentContributor.name}の配信`
    return 'メッセージ配信'
  }

  const distributionSubLabel = () => {
    if (contributorsState.currentContributor)
      return `このメッセージは${contributorsState.currentContributor.name}（個人）のメッセージとして送信されます。`
    return ''
  }

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
      dispatch(
        getSubscriptionTiers.started({
          contributorId:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
    useMessageTextValidation('message_text', '')

    const stashedMessage = localStorage.getItem('stashed-message')
    if (stashedMessage) {
      messageTextHandler(stashedMessage)
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  useEffect(() => {
    if (audioVisualMessage)
      generateUrlFromFile(
        audioVisualMessage,
        setImgPreview,
        setVideoPreview,
        setVoicePreview
      )
  }, [audioVisualMessage])

  useEffect(() => {
    if (reservedMessage) {
      const runAt =
        moment(selectedDate).format('YYYY/MM/DD') +
        ' ' +
        selectedHour +
        ':' +
        selectedMinute

      useMessageDatetimeValidation('message_datetime', runAt)
    }

    if (voicePreviewAudio && !voicePreviewAudio.playing()) {
      voicePreviewAudio.play()
      setVoicePreviewPlaying(true)
    }
  }, [
    selectedDate,
    selectedHour,
    selectedMinute,
    reservedMessage,
    voicePreviewAudio,
  ])

  useEffect(() => {
    registrationButtonChecker()
  }, [
    audioVisualMessage,
    messageTextValidation,
    messageImageValidation,
    messageVideoValidation,
    messageVoiceValidation,
    messageDatetimeValidation,
    subscriptionTier,
  ])

  useEffect(() => {
    dispatch(setInitialState())
  }, [])

  if (
    !styles ||
    !contributorsState.currentContributor ||
    !sessionsState.currentUser
  )
    return <></>

  return (
    <>
      <Toast body={contributorsState.success.message} error={false} />
      <Toast body={contributorsState.error.message} error={true} />
      {showPrecheckModal && (
        <Overlay dismissHandler={showPrecheckHandler}>
          <PrecheckMessage
            type={'instant'}
            messageText={messageText}
            audioVisualMessage={audioVisualMessage}
            imagePreview={imgPreview}
            videoPreview={videoPreview}
            voicePreviewAudio={voicePreviewAudio}
            voicePreviewPlaying={voicePreviewPlaying}
            controlPreviewVoice={controlPreviewVoice}
            xPostEnabled={
              !!contributorsState.currentContributor.xAccount.accessToken
            }
            xPostHandler={xPostHandler}
            dismissHandler={showPrecheckHandler}
            executeHandler={sendMessageHandler}
            contributorName={contributorsState.currentContributor.name}
          />
        </Overlay>
      )}
      <MainHeader
        heading={
          responsive === 'small' || responsive === 'medium'
            ? distributionLabel()
            : 'メッセージ新規作成'
        }
        subText={distributionSubLabel()}
      >
        {responsive === 'normal' && (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid rgb(224, 224, 224)'}
              disabled={false}
              onClickHandler={() => pageRollbackHandler()}
            />
            <ButtonSpacer />
            <Button
              label={reservedMessage ? '保存する' : '送信する'}
              icon={reservedMessage ? Save : Send}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!activeRegistrationButton}
              onClickHandler={showPrecheckHandler}
            />
          </>
        )}
      </MainHeader>
      <div className={styles.default.new_message_component}>
        <div className={styles.default.message_form}>
          <MessageOptions
            reservation={reservedMessage}
            reservationHandler={reservedMessageHandler}
            selectedDateHandler={selectedDateHandler}
            selectedHourHandler={selectedHourHandler}
            selectedMinuteHandler={selectedMinuteHandler}
            reservationOnly={false}
            subscriptionTiers={subscriptionTiersState.subscriptionTiers}
            selectedSubscriptionTierHandler={(subscriptionTierId: number) =>
              setSubscriptionTier(subscriptionTierId)
            }
          />
          <MessageForm
            type={'text'}
            value={messageText}
            textValidation={messageTextValidation}
            adaptVariableHandler={adaptVariableHandler}
            onChangeText={messageTextHandler}
            onChangeAudioVisual={uploadAudioVisualHandler}
          >
            <div className={styles.default.message_thread} />
          </MessageForm>
          {audioVisualMessage && (
            <MessageForm
              type={'audio_visual'}
              selectedFile={audioVisualMessage}
              preview={imgPreview}
              imageValidation={messageImageValidation}
              videoValidation={messageVideoValidation}
              voiceValidation={messageVoiceValidation}
              onChangeText={messageTextHandler}
              onChangeAudioVisual={uploadAudioVisualHandler}
              closeForm={removeAudioVisualHandler}
            />
          )}
          {!audioVisualMessage && (
            <div className={styles.default.append_media_btn}>
              <button onClick={addAudioVisualHandler}>
                <div className={styles.default.label}>
                  <img src={CreateIcon} />
                  <span>画像・音声・動画を追加する</span>
                </div>
              </button>
            </div>
          )}
        </div>
        <div className={styles.default.message_preview}>
          <Preview
            contributor={contributorsState.currentContributor}
            messageText={messageText}
            audioVisualMessage={audioVisualMessage}
            imagePreview={imgPreview}
            videoPreview={videoPreview}
            voicePreview={voicePreview}
            sp={responsive === 'small' || responsive === 'medium'}
            immediate={true}
            reservation={false}
            eventBot={false}
            directMessage={false}
          >
            {(responsive === 'small' || responsive === 'medium') && (
              <PreviewMessageForm
                messageText={messageText}
                messageTextHandler={messageTextHandler}
                publicMessage={publicMessage}
                publicMessageHandler={publicMessageHandler}
                audioVisualMessage={audioVisualMessage}
                imagePreview={imgPreview}
                voicePreview={voicePreview}
                videoPreview={videoPreview}
                adaptVariableHandler={adaptVariableHandler}
                uploadAudioVisualHandler={uploadAudioVisualHandler}
                removeAudioVisualHandler={removeAudioVisualHandler}
                textValidation={messageTextValidation}
                imageValidation={messageImageValidation}
                videoValidation={messageVideoValidation}
                voiceValidation={messageVoiceValidation}
                sendMessageHandler={showPrecheckHandler}
                disabled={!activeRegistrationButton}
                reserved={false}
                bot={false}
                subscriptionTiers={subscriptionTiersState.subscriptionTiers}
                selectedSubscriptionTierHandler={(subscriptionTierId: number) =>
                  setSubscriptionTier(subscriptionTierId)
                }
              />
            )}
          </Preview>
        </div>
      </div>
    </>
  )
}

export default ContributorMessage
