import { CombineReducers } from 'models'
import { User } from 'models/auth'
import { SessionsState } from 'models/sessions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentUser } from 'actions/sessions'

export function useUser(): [user?: User] {
  const dispatch = useDispatch()

  const sessionsState: SessionsState = useSelector(
    (state: CombineReducers) => state.sessions
  )

  useEffect(() => {
    dispatch(getCurrentUser.started())
  }, [])

  return [sessionsState.currentUser]
}
