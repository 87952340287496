export const descriptionValidation = (value: string) => {
  let message = 'テキストの入力値は正常です'
  let type = 'succeeded'
  if (value.length < 1 || value.length > 100) {
    message = '1文字以上100文字以下のテキストを入力してください'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
