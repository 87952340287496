import { ToastsAction } from 'actions/toasts'
import * as ActionType from 'actions/toasts/types'
import { ToastsState } from 'models/toasts'

export const initialState: ToastsState = {
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const toastsReducer = (
  state: ToastsState = initialState,
  action: ToastsAction
): ToastsState => {
  switch (action.type) {
    case ActionType.SET_SUCCESS_TOAST:
      return {
        ...state,
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.SET_ERROR_TOAST:
      return {
        ...state,
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.RESET_TOAST:
      return {
        ...state,
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    default:
      return state
  }
}

export default toastsReducer
