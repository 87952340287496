import axios from 'axios'
import maintenanceWindowsDeserializer from 'deserializers/maintenance_windows'
import { loggingError } from 'services/interceptor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200
const NO_CONTENT = 204

export const getAppMaintenanceWindowsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getMaintenanceWindows = async () => {
    const response = await instance.get(
      '/api/cms/v1/maintenance_windows?platform=app',
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        '/api/cms/v1/maintenance_windows?platform=cms',
        'getMaintenanceWindowsFactory'
      )

    return maintenanceWindowsDeserializer({
      data: response.data.maintenance_windows,
    })
  }
  return getMaintenanceWindows
}

export const getCMSMaintenanceWindowsFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getMaintenanceWindows = async () => {
    const response = await instance.get(
      '/api/cms/v1/maintenance_windows?platform=cms',
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        '/api/cms/v1/maintenance_windows?platform=cms',
        'getMaintenanceWindowsFactory'
      )

    return maintenanceWindowsDeserializer({
      data: response.data.maintenance_windows,
    })
  }
  return getMaintenanceWindows
}

export const createMaintenanceWindowFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const createMaintenanceWindow = async (request: {
    title: string
    body: string
    platform: string
    windowStart: string
    windowEnd: string
    announcedAt: string
  }) => {
    const response = await instance.post(
      '/api/cms/v1/maintenance_windows',
      {
        title: request.title,
        body: request.body,
        platform: request.platform,
        window_start: request.windowStart,
        window_end: request.windowEnd,
        announced_at: request.announcedAt,
      },
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError(
        '/api/cms/v1/maintenance_windows',
        'createMaintenanceWindowFactory'
      )
  }
  return createMaintenanceWindow
}

export const deleteMaintenanceWindowFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const deleteMaintenanceWindow = async (request: { id: number }) => {
    const response = await instance.delete(
      `/api/cms/v1/maintenance_windows/${request.id}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError(
        `/api/cms/v1/contributors/${request.id}`,
        'deleteMaintenanceWindowFactory'
      )
  }
  return deleteMaintenanceWindow
}
