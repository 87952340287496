import { Listeners } from 'models/listeners'
import * as ActionType from './types'

export const getDMSendingListeners = {
  started: (request: { page: number; contributorId: number }) => ({
    type: ActionType.STARTED_GET_DM_SENDING_LISTENERS as typeof ActionType.STARTED_GET_DM_SENDING_LISTENERS,
    payload: request,
  }),
  succeeded: (response: { message: string; listeners: Listeners }) => ({
    type: ActionType.SUCCEEDED_GET_DM_SENDING_LISTENERS as typeof ActionType.SUCCEEDED_GET_DM_SENDING_LISTENERS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_DM_SENDING_LISTENERS as typeof ActionType.FAILED_GET_DM_SENDING_LISTENERS,
    payload: response,
  }),
}

export type ListenersAction =
  | ReturnType<typeof getDMSendingListeners.started>
  | ReturnType<typeof getDMSendingListeners.succeeded>
  | ReturnType<typeof getDMSendingListeners.failed>
