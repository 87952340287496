import React, { useEffect } from 'react'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getContributor } from 'actions/contributors'
import { getContributorBotMessages } from 'actions/messages'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import { EventBotMessage, MessagesState } from 'models/messages'

import Toast from 'components/modules/toast'

const EventBotMessages: React.FC = () => {
  const params = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const messagesState: MessagesState = useSelector((state: CombineReducers) => {
    return state.messages
  })
  const history = useHistory()

  const fetchAllBotMessages = () => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributorBotMessages.started({
          contributorId:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }

  const extractType = (message: EventBotMessage) => {
    if (message.image) return 'IMAGE'
    if (message.video) return 'VIDEO'
    if (message.voice) return 'VOICE'
    return undefined
  }

  const selectItemHandler = (id: number) => {
    history.push(`/contributors/${params.id}/bot_messages/${id}`)
  }

  const distributionLabel = () => {
    if (contributorsState.currentContributor)
      return `${contributorsState.currentContributor.name}の bot 配信`
    return 'bot 配信'
  }

  useEffect(() => {
    if (sessionsState.currentUser && !contributorsState.currentContributor) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
    fetchAllBotMessages()
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  return (
    <>
      <Toast body={messagesState.success.message} error={false} />
      <Toast body={messagesState.error.message} error={true} />
      <MainHeader
        heading={distributionLabel()}
        subText="経過日数や指定した条件に応じて、メッセージが配信されます。"
      />
      <MainTable
        columns={{
          scale: '30% 25% 45%',
          items: [
            { label: '内容', grid: '1 / 2' },
            { label: '画像 / 音声 / 動画', grid: '2 / 3' },
            { label: '配信条件', grid: '3 / 4' },
          ],
        }}
        items={messagesState.currentContributorBotMessages.data.map(
          (message: EventBotMessage) => ({
            id: message.id,
            data: {
              content: {
                id: message.id,
                value: message.patternName,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              media: {
                id: message.id,
                value: message.image || message.videoThumbnail || message.voice,
                type: extractType(message),
                clickable: false,
                subLabel: false,
                grid: '2 / 3',
              },
              condition: {
                id: message.id,
                value: message.distributionCondition,
                clickable: false,
                subLabel: true,
                grid: '3 / 4',
              },
            },
          })
        )}
        selectItemHandler={selectItemHandler}
      />
    </>
  )
}

export default EventBotMessages
