// SIGN_UP
export const STARTED_SIGN_UP = 'AUTH/STARTED_SIGN_UP'
export const SUCCEEDED_SIGN_UP = 'AUTH/SUCCEEDED_SIGN_UP'
export const FAILED_SIGN_UP = 'AUTH/FAILED_SIGN_UP'

// SIGN_IN
export const STARTED_SIGN_IN = 'AUTH/STARTED_SIGN_IN'
export const SUCCEEDED_SIGN_IN = 'AUTH/SUCCEEDED_SIGN_IN'
export const FAILED_SIGN_IN = 'AUTH/FAILED_SIGN_IN'

// VERIFY
export const STARTED_VERIFY = 'AUTH/STARTED_VERIFY'
export const SUCCEEDED_VERIFY = 'AUTH/SUCCEEDED_VERIFY'
export const FAILED_VERIFY = 'AUTH/FAILED_VERIFY'

// SEND_RESET_PASSWORD_EMAIL
export const STARTED_SEND_RESET_PASSWORD_EMAIL =
  'AUTH/STARTED_SEND_RESET_PASSWORD_EMAIL'
export const SUCCEEDED_SEND_RESET_PASSWORD_EMAIL =
  'AUTH/SUCCEEDED_SEND_RESET_PASSWORD_EMAIL'
export const FAILED_SEND_RESET_PASSWORD_EMAIL =
  'AUTH/FAILED_SEND_RESET_PASSWORD_EMAIL'

// CHANGE_PASSWORD_EMAIL
export const STARTED_CHANGE_PASSWORD_EMAIL =
  'AUTH/STARTED_CHANGE_PASSWORD_EMAIL'
export const SUCCEEDED_CHANGE_PASSWORD_EMAIL =
  'AUTH/SUCCEEDED_CHANGE_PASSWORD_EMAIL'
export const FAILED_CHANGE_PASSWORD_EMAIL = 'AUTH/FAILED_CHANGE_PASSWORD_EMAIL'
