import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Popup from 'reactjs-popup'
import { isContributorPath } from 'utils/routes_generator'

import { Contributor } from 'models/contributors'
import { getContributor } from 'actions/contributors'

import MyDearLogo from 'images/cms_logo_mydear.png'
import Sample from 'images/primary.webp'
import PopupArrow from 'images/popup_arrow.png'
import RightArrow from 'images/right_arrow.png'
import '../index.css'
import { useUser } from 'hooks/useUser'
import { useAuth } from 'hooks/useAuth'
import { useContributors } from 'hooks/useContributors'

const Header: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  const [user] = useUser()
  const [contributor] = useAuth({ user })
  const [contributors, fetchContributors] = useContributors()

  if (!user) return <></>

  return (
    <div className="header_wrapper">
      {contributor && isContributorPath(location.pathname, contributor) ? (
        <div className="menu_header_on_contributors">
          <div className="menu_header_inner_wrapper">
            <img
              src={contributor?.icon ? contributor.icon : Sample}
              onClick={() =>
                history.push(`/contributors/${contributor.id}/messages`)
              }
            />
            <p
              onClick={() =>
                history.push(`/contributors/${contributor.id}/messages`)
              }
            >
              {contributor && contributor.name}
            </p>
            {user && user.authType === 'Administrator' ? (
              <Popup
                trigger={<img src={PopupArrow} className="popup_trigger_img" />}
                arrow={false}
                position="right top"
                onOpen={fetchContributors}
                contentStyle={{
                  width: '205px',
                  height: '425px',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #828282',
                  borderRadius: '10px',
                }}
                nested
              >
                {(close: () => void) => (
                  <>
                    <div className="popup_header">
                      <p>アカウント切り替え</p>
                    </div>
                    <div style={{ height: '300px', width: '100%' }}>
                      {contributors &&
                        contributors.data
                          .slice(0, 5)
                          .map((contributor: Contributor) => {
                            return (
                              <div
                                key={contributor.id}
                                style={{
                                  padding: '13px 0px',
                                }}
                              >
                                <div
                                  style={{
                                    margin: '0 auto',
                                    width: '181px',
                                    height: '34px',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      getContributor.started({
                                        id: contributor.id,
                                      })
                                    )
                                    history.push(
                                      `/contributors/${contributor.id}/messages`
                                    )
                                    close()
                                  }}
                                >
                                  <img
                                    src={contributor.icon || Sample}
                                    width={34}
                                    height={34}
                                    style={{
                                      display: 'block',
                                      float: 'left',
                                      objectFit: 'cover',
                                      borderRadius: '4px',
                                    }}
                                  />
                                  <p
                                    style={{
                                      margin: '7px 0px 7px 12px',
                                      float: 'left',
                                      maxWidth: '135px',
                                      wordBreak: 'break-all',
                                    }}
                                  >
                                    {contributor.name}
                                  </p>
                                </div>
                              </div>
                            )
                          })}
                    </div>
                    <div
                      style={{ width: '100%', border: '1px solid #E0E0E0' }}
                    />
                    <div style={{ padding: '20px 0px' }}>
                      <div
                        style={{
                          width: '151px',
                          height: '24px',
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: '#4F4F4F',
                          margin: '0 auto',
                        }}
                      >
                        <p style={{ margin: '3px 0px', float: 'left' }}>
                          すべての配信者をみる
                        </p>
                        <img
                          src={RightArrow}
                          height={24}
                          width={24}
                          style={{
                            display: 'block',
                            float: 'right',
                            cursor: 'pointer',
                          }}
                          onClick={() => history.push('/contributors')}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Popup>
            ) : null}
            <div style={{ clear: 'both' }} />
          </div>
        </div>
      ) : (
        <div className="header_logo">
          <img
            src={MyDearLogo}
            width={100}
            style={{
              display: 'block',
              float: 'left',
            }}
          />
          <p style={{ margin: '6px 0px 6px 5px', float: 'left' }}>管理画面</p>
        </div>
      )}
    </div>
  )
}

export default Header
