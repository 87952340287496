import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/auth/types'
import {
  signUp,
  signIn,
  verify,
  sendResetPasswordEmail,
  changePassword,
} from 'actions/auth'
import {
  signUpFactory,
  signInFactory,
  verifyFactory,
  sendResetPasswordEmailFactory,
  changePasswordFactory,
} from 'services/auth/api'

function* runSignUp(action: ReturnType<typeof signUp.started>) {
  try {
    yield call(signUpFactory(), action.payload)

    yield put(signUp.succeeded({ message: '認証メールを送信しました' }))
  } catch (err) {
    yield put(
      signUp.failed({
        message: '新規登録が正常に行われませんでした',
      })
    )
  }
}

function* runSignIn(action: ReturnType<typeof signIn.started>) {
  try {
    yield call(signInFactory(), action.payload)

    yield put(signIn.succeeded({ message: 'サインインに成功しました' }))
  } catch (err) {
    yield put(
      signIn.failed({
        message: 'メールアドレス、またはパスワードが正しくありません',
      })
    )
  }
}

function* runVerify(action: ReturnType<typeof verify.started>) {
  try {
    yield call(verifyFactory(), action.payload)

    yield put(verify.succeeded({ message: 'トークンの検証に成功しました' }))
  } catch (err) {
    yield put(
      verify.failed({
        message: 'トークンの検証に失敗しました',
      })
    )
  }
}

function* runSendResetPasswordEmail(
  action: ReturnType<typeof sendResetPasswordEmail.started>
) {
  try {
    const result: string = yield call(
      sendResetPasswordEmailFactory(),
      action.payload
    )

    yield put(
      sendResetPasswordEmail.succeeded({
        message: result,
      })
    )
  } catch (err) {
    yield put(
      sendResetPasswordEmail.failed({
        message: 'パスワードリセットメールの送信に失敗しました',
      })
    )
  }
}

function* runChangePassword(action: ReturnType<typeof changePassword.started>) {
  try {
    yield call(changePasswordFactory(), action.payload)

    yield put(
      changePassword.succeeded({
        message: 'パスワードの更新に成功しました',
      })
    )
  } catch (err) {
    yield put(
      changePassword.failed({
        message: 'パスワードの更新に失敗しました',
      })
    )
  }
}

export function* watchAuthSaga() {
  yield takeLatest(Action.STARTED_SIGN_UP, runSignUp)
  yield takeLatest(Action.STARTED_SIGN_IN, runSignIn)
  yield takeLatest(Action.STARTED_VERIFY, runVerify)
  yield takeLatest(
    Action.STARTED_SEND_RESET_PASSWORD_EMAIL,
    runSendResetPasswordEmail
  )
  yield takeLatest(Action.STARTED_CHANGE_PASSWORD_EMAIL, runChangePassword)
}
