import { getContributor } from 'actions/contributors'
import { getMembershipBirthdays } from 'actions/memberships'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import { CombineReducers } from 'models'
import { Membership, MembershipsState } from 'models/memberships'
import { SessionsState } from 'models/sessions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const MembershipBirthdays: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const params = useParams<{
    id: string
  }>()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const membershipsState: MembershipsState = useSelector(
    (state: CombineReducers) => {
      return state.memberships
    }
  )

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
      dispatch(
        getMembershipBirthdays.started({
          contributorId: parseInt(params.id),
          page: 1,
        })
      )
    }
  }, [])

  return (
    <>
      <MainHeader heading={'誕生月のユーザー'} />
      <MainTable
        columns={{
          scale: '25% 25% 25% 25%',
          items: [
            { label: 'ユーザー名', grid: '1 / 2' },
            { label: '誕生日', grid: '2 / 3' },
            { label: '性別', grid: '3 / 4' },
            { label: '継続歴', grid: '4 / 5' },
          ],
        }}
        items={membershipsState.membershipBirthdays.data.map(
          (membership: Membership) => ({
            id: membership.listenerId,
            data: {
              name: {
                id: membership.id,
                value: membership.name,
                clickable: true,
                subLabel: false,
                grid: '1 / 2',
              },
              birthDate: {
                id: membership.id,
                value: membership.birthDate,
                clickable: false,
                subLabel: true,
                grid: '2 / 3',
              },
              gender: {
                id: membership.id,
                value: membership.gender,
                clickable: false,
                subLabel: true,
                grid: '3 / 4',
              },
              membershipDuration: {
                id: membership.id,
                value: membership.membershipDuration,
                clickable: false,
                subLabel: true,
                grid: '4 / 5',
              },
            },
          })
        )}
        selectItemHandler={(listenerId: number) =>
          history.push(
            `/contributors/${params.id}/direct_messages/${listenerId}`
          )
        }
      />
    </>
  )
}

export default MembershipBirthdays
