import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/tags/types'
import {
  createTag,
  deleteTag,
  getTags,
  updateTagManagement,
} from 'actions/tags'
import { Tag, Tags } from 'models/tags'
import {
  getTagsFactory,
  createTagFactory,
  deleteTagFactory,
  updateTagManagementFactory,
} from 'services/tags/api'

function* runGetTags(action: ReturnType<typeof getTags.started>) {
  try {
    const result: Tags = yield call(getTagsFactory())

    yield put(getTags.succeeded({ tags: result, message: '' }))
  } catch (err) {
    yield put(getTags.failed({ message: '' }))
  }
}

function* runCreateTag(action: ReturnType<typeof createTag.started>) {
  try {
    yield call(createTagFactory(), action.payload)

    yield put(createTag.succeeded({ message: 'タグを追加しました' }))
  } catch (err) {
    yield put(createTag.failed({ message: 'タグの追加に失敗しました' }))
  }
}

function* runDeleteTag(action: ReturnType<typeof deleteTag.started>) {
  try {
    yield call(deleteTagFactory(), action.payload)

    yield put(deleteTag.succeeded({ message: 'タグが削除されました' }))
  } catch (err) {
    yield put(deleteTag.failed({ message: 'タグの削除に失敗しました' }))
  }
}

function* runUpdateTagManagement(
  action: ReturnType<typeof updateTagManagement.started>
) {
  try {
    const tags: Tag[] = yield call(updateTagManagementFactory(), action.payload)

    yield put(updateTagManagement.succeeded({ message: '' }))
  } catch (err) {
    yield put(updateTagManagement.failed({ message: '' }))
  }
}

export function* watchTagsSaga() {
  yield takeLatest(Action.STARTED_GET_TAGS, runGetTags)
  yield takeLatest(Action.STARTED_CREATE_TAG, runCreateTag)
  yield takeLatest(Action.STARTED_DELETE_TAG, runDeleteTag)
  yield takeLatest(Action.STARTED_UPDATE_TAG_MANAGEMENT, runUpdateTagManagement)
}
