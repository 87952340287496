import { useState } from 'react'
import {
  AudioVisualMessage,
  MessageValueTypes,
  PremiumChatMessageValues,
} from 'models/messages'
import { typeGuard } from 'utils/type_guard'
import { generateUrlFromFile } from 'utils/file_manager'

export function useValues(): [
  values: PremiumChatMessageValues,
  setValue: (type: string, value?: MessageValueTypes) => void
] {
  const [messageText, setMessageText] = useState<string>('')
  const [audioVisualMessage, setAudioVisualMessage] =
    useState<AudioVisualMessage>()
  const [publicMessage, setPublicMessage] = useState<boolean>(true)
  const [imagePreview, setImagePreview] = useState<string>()
  const [videoPreview, setVideoPreview] = useState<string>()
  const [voicePreview, setVoicePreview] = useState<string>()

  const setValue = async (type: string, value?: MessageValueTypes) => {
    switch (type) {
      case 'message_text':
        if (typeGuard<string>(value)) {
          setMessageText(value)
          localStorage.setItem('stashed-message', value)
        }
        break
      case 'audio_visual_message':
        if (!value) {
          setAudioVisualMessage(undefined)
        } else if (typeGuard<AudioVisualMessage>(value)) {
          setAudioVisualMessage(value)
          generateUrlFromFile(
            value,
            setImagePreview,
            setVideoPreview,
            setVoicePreview
          )
        }
        break
      case 'public_message':
        if (typeGuard<boolean>(value)) setPublicMessage(value)
        break
      case 'image_preview':
        if (!value) {
          setImagePreview(undefined)
        } else if (typeGuard<string>(value)) {
          setImagePreview(value)
        }
        break
      case 'video_preview':
        if (!value) {
          setVideoPreview(undefined)
        } else if (typeGuard<string>(value)) {
          setVideoPreview(value)
        }
        break
      case 'voice_preview':
        if (!value) {
          setVoicePreview(undefined)
        } else if (typeGuard<string>(value)) {
          setVoicePreview(value)
        }
        break
    }
  }

  return [
    {
      messageText,
      audioVisualMessage,
      publicMessage,
      imagePreview,
      videoPreview,
      voicePreview,
    },
    setValue,
  ]
}
