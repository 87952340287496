import React from 'react'
import { useStyles } from 'hooks/useStyles'
import { useValidation } from 'hooks/useValidation'
import Template from 'components/auth/components/Template'
import FormInput from 'components/auth/components/FormInput'
import Button from 'components/auth/components/button'
import ErrorText from 'components/auth/components/ErrorText'
import { useSignIn } from 'hooks/api/useSignIn'
import { useHistory } from 'react-router-dom'
import Link from '../components/Link'

const SignIn: React.FC = () => {
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/auth',
  })

  const [email, setEmail] = React.useState<string>()
  const [password, setPassword] = React.useState<string>()
  const [emailValidation, setEmailValidation] = useValidation({
    type: 'email',
    value: email,
  })
  const [passwordValidation, setPasswordValidation] = useValidation({
    type: 'password',
    value: password,
  })
  const [signIn] = useSignIn({ email, password })

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValidation('email', event.target.value)
    setEmail(event.target.value)
  }

  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValidation('password', event.target.value)
    setPassword(event.target.value)
  }

  const buttonActivationCheck = () => {
    if (
      emailValidation?.type === 'error' ||
      passwordValidation?.type === 'error'
    )
      return true
    return false
  }

  if (!styles) return <></>

  return (
    <Template label={'管理画面にログイン'} styles={styles}>
      {emailValidation?.type === 'error' && (
        <ErrorText message={emailValidation.message} styles={styles} />
      )}
      {passwordValidation?.type === 'error' && (
        <ErrorText message={passwordValidation.message} styles={styles} />
      )}
      <FormInput
        label="メールアドレス"
        type="email"
        placeholder="メールアドレス"
        onChangeHandler={emailHandler}
        styles={styles}
      />
      <FormInput
        label="パスワード"
        type="password"
        placeholder="英数字を含む8文字のパスワード"
        onChangeHandler={passwordHandler}
        styles={styles}
      />
      <div className={styles.default.form_submit_btn_spacer} />
      <Button
        label="ログイン"
        disabled={buttonActivationCheck()}
        onClickHandler={() => signIn.mutate()}
      />
      <section className={styles.default.other_options}>
        <Link
          label="新規登録"
          onClickHandler={() => history.push('/sign_up')}
          styles={styles}
        />
        <Link
          label="パスワードを忘れた場合"
          onClickHandler={() => history.push('/reset_password')}
          styles={styles}
        />
      </section>
    </Template>
  )
}

export default SignIn
