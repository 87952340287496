import {
  ContributorMessage,
  ContributorMessages,
  EventBotMessage,
  EventBotMessages,
} from 'models/messages'
import * as ActionType from './types'

export const getContributorMessages = {
  started: (request: { contributorId: number; page: number }) => ({
    type: ActionType.STARTED_GET_CONTRIBUTOR_MESSAGES as typeof ActionType.STARTED_GET_CONTRIBUTOR_MESSAGES,
    payload: request,
  }),
  succeeded: (response: {
    contributorMessages: ContributorMessages
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_CONTRIBUTOR_MESSAGES as typeof ActionType.SUCCEEDED_GET_CONTRIBUTOR_MESSAGES,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CONTRIBUTOR_MESSAGES as typeof ActionType.FAILED_GET_CONTRIBUTOR_MESSAGES,
    payload: response,
  }),
}

export const getReservedContributorMessages = {
  started: (request: { contributorId: number; page: number }) => ({
    type: ActionType.STARTED_GET_RESERVED_CONTRIBUTOR_MESSAGES as typeof ActionType.STARTED_GET_RESERVED_CONTRIBUTOR_MESSAGES,
    payload: request,
  }),
  succeeded: (response: {
    contributorMessages: ContributorMessages
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_RESERVED_CONTRIBUTOR_MESSAGES as typeof ActionType.SUCCEEDED_GET_RESERVED_CONTRIBUTOR_MESSAGES,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_RESERVED_CONTRIBUTOR_MESSAGES as typeof ActionType.FAILED_GET_RESERVED_CONTRIBUTOR_MESSAGES,
    payload: response,
  }),
}

export const createContributorMessage = {
  started: (request: { data: FormData }) => ({
    type: ActionType.STARTED_CREATE_CONTRIBUTOR_MESSAGE as typeof ActionType.STARTED_CREATE_CONTRIBUTOR_MESSAGE,
    payload: request,
  }),
  succeeded: (response: { message: string }) => {
    return {
      type: ActionType.SUCCEEDED_CREATE_CONTRIBUTOR_MESSAGE as typeof ActionType.SUCCEEDED_CREATE_CONTRIBUTOR_MESSAGE,
      payload: response,
    }
  },
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CREATE_CONTRIBUTOR_MESSAGE as typeof ActionType.FAILED_CREATE_CONTRIBUTOR_MESSAGE,
    payload: response,
  }),
}

export const createReservedContributorMessage = {
  started: (request: { data: FormData }) => ({
    type: ActionType.STARTED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE as typeof ActionType.STARTED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE,
    payload: request,
  }),
  succeeded: (response: { message: string }) => {
    return {
      type: ActionType.SUCCEEDED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE as typeof ActionType.SUCCEEDED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE,
      payload: response,
    }
  },
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE as typeof ActionType.FAILED_CREATE_RESERVED_CONTRIBUTOR_MESSAGE,
    payload: response,
  }),
}

export const getContributorMessage = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_GET_CONTRIBUTOR_MESSAGE as typeof ActionType.STARTED_GET_CONTRIBUTOR_MESSAGE,
    payload: request,
  }),
  succeeded: (response: {
    contributorMessage: ContributorMessage
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_CONTRIBUTOR_MESSAGE as typeof ActionType.SUCCEEDED_GET_CONTRIBUTOR_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CONTRIBUTOR_MESSAGE as typeof ActionType.FAILED_GET_CONTRIBUTOR_MESSAGE,
    payload: response,
  }),
}

export const deleteContributorMessage = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_DELETE_CONTRIBUTOR_MESSAGE as typeof ActionType.STARTED_DELETE_CONTRIBUTOR_MESSAGE,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_DELETE_CONTRIBUTOR_MESSAGE as typeof ActionType.SUCCEEDED_DELETE_CONTRIBUTOR_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_DELETE_CONTRIBUTOR_MESSAGE as typeof ActionType.FAILED_DELETE_CONTRIBUTOR_MESSAGE,
    payload: response,
  }),
}

export const getContributorBotMessages = {
  started: (request: { contributorId: number }) => ({
    type: ActionType.STARTED_GET_CONTRIBUTOR_BOT_MESSAGES as typeof ActionType.STARTED_GET_CONTRIBUTOR_BOT_MESSAGES,
    payload: request,
  }),
  succeeded: (response: {
    message: string
    contributorBotMessages: EventBotMessages
  }) => ({
    type: ActionType.SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGES as typeof ActionType.SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGES,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CONTRIBUTOR_BOT_MESSAGES as typeof ActionType.FAILED_GET_CONTRIBUTOR_BOT_MESSAGES,
    payload: response,
  }),
}

export const getContributorBotMessage = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_GET_CONTRIBUTOR_BOT_MESSAGE as typeof ActionType.STARTED_GET_CONTRIBUTOR_BOT_MESSAGE,
    payload: request,
  }),
  succeeded: (response: {
    message: string
    contributorBotMessage: EventBotMessage
  }) => ({
    type: ActionType.SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGE as typeof ActionType.SUCCEEDED_GET_CONTRIBUTOR_BOT_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CONTRIBUTOR_BOT_MESSAGE as typeof ActionType.FAILED_GET_CONTRIBUTOR_BOT_MESSAGE,
    payload: response,
  }),
}

export const updateContributorBotMessage = {
  started: (request: { id: number; data: FormData }) => ({
    type: ActionType.STARTED_UPDATE_CONTRIBUTOR_BOT_MESSAGE as typeof ActionType.STARTED_UPDATE_CONTRIBUTOR_BOT_MESSAGE,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR_BOT_MESSAGE as typeof ActionType.SUCCEEDED_UPDATE_CONTRIBUTOR_BOT_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_CONTRIBUTOR_BOT_MESSAGE as typeof ActionType.FAILED_UPDATE_CONTRIBUTOR_BOT_MESSAGE,
    payload: response,
  }),
}

export const getPreviewMessages = {
  started: (request: { contributorId: number; page: number }) => ({
    type: ActionType.STARTED_GET_PREVIEW_MESSAGES as typeof ActionType.STARTED_GET_PREVIEW_MESSAGES,
    payload: request,
  }),
  succeeded: (response: {
    previewMessages: ContributorMessages
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_PREVIEW_MESSAGES as typeof ActionType.SUCCEEDED_GET_PREVIEW_MESSAGES,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_PREVIEW_MESSAGES as typeof ActionType.FAILED_GET_PREVIEW_MESSAGES,
    payload: response,
  }),
}

export const getPreviewReservedMessages = {
  started: (request: { contributorId: number; page: number }) => ({
    type: ActionType.STARTED_GET_PREVIEW_RESERVED_MESSAGES as typeof ActionType.STARTED_GET_PREVIEW_RESERVED_MESSAGES,
    payload: request,
  }),
  succeeded: (response: {
    previewMessages: ContributorMessages
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_PREVIEW_RESERVED_MESSAGES as typeof ActionType.SUCCEEDED_GET_PREVIEW_RESERVED_MESSAGES,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_PREVIEW_RESERVED_MESSAGES as typeof ActionType.FAILED_GET_PREVIEW_RESERVED_MESSAGES,
    payload: response,
  }),
}

export const getListenerDMs = {
  started: (request: {
    contributorId: number
    listenerId: number
    page: number
  }) => ({
    type: ActionType.STARTED_GET_LISTENER_DMS as typeof ActionType.STARTED_GET_LISTENER_DMS,
    payload: request,
  }),
  succeeded: (response: {
    contributorMessages: ContributorMessages
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_LISTENER_DMS as typeof ActionType.SUCCEEDED_GET_LISTENER_DMS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_LISTENER_DMS as typeof ActionType.FAILED_GET_LISTENER_DMS,
    payload: response,
  }),
}

export const setInitialState = () => {
  return {
    type: ActionType.SET_INITIAL_STATE as typeof ActionType.SET_INITIAL_STATE,
  }
}

export const setDmToRespond = (request: { message: ContributorMessage }) => {
  return {
    type: ActionType.SET_DM_TO_RESPOND as typeof ActionType.SET_DM_TO_RESPOND,
    payload: request,
  }
}

export const removeDmToRespond = () => {
  return {
    type: ActionType.REMOVE_DM_TO_RESPOND as typeof ActionType.REMOVE_DM_TO_RESPOND,
  }
}

export const resetAllStatusFlags = {
  started: () => ({
    type: ActionType.STARTED_RESET_ALL_STATUS_FLAGS as typeof ActionType.STARTED_RESET_ALL_STATUS_FLAGS,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS as typeof ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_ALL_STATUS_FLAGS as typeof ActionType.FAILED_RESET_ALL_STATUS_FLAGS,
  }),
}

export type MessagesAction =
  | ReturnType<typeof getContributorMessages.started>
  | ReturnType<typeof getContributorMessages.succeeded>
  | ReturnType<typeof getContributorMessages.failed>
  | ReturnType<typeof getReservedContributorMessages.started>
  | ReturnType<typeof getReservedContributorMessages.succeeded>
  | ReturnType<typeof getReservedContributorMessages.failed>
  | ReturnType<typeof createContributorMessage.started>
  | ReturnType<typeof createContributorMessage.succeeded>
  | ReturnType<typeof createContributorMessage.failed>
  | ReturnType<typeof createReservedContributorMessage.started>
  | ReturnType<typeof createReservedContributorMessage.succeeded>
  | ReturnType<typeof createReservedContributorMessage.failed>
  | ReturnType<typeof getContributorMessage.started>
  | ReturnType<typeof getContributorMessage.succeeded>
  | ReturnType<typeof getContributorMessage.failed>
  | ReturnType<typeof deleteContributorMessage.started>
  | ReturnType<typeof deleteContributorMessage.succeeded>
  | ReturnType<typeof deleteContributorMessage.failed>
  | ReturnType<typeof getContributorBotMessages.started>
  | ReturnType<typeof getContributorBotMessages.succeeded>
  | ReturnType<typeof getContributorBotMessages.failed>
  | ReturnType<typeof getContributorBotMessage.started>
  | ReturnType<typeof getContributorBotMessage.succeeded>
  | ReturnType<typeof getContributorBotMessage.failed>
  | ReturnType<typeof updateContributorBotMessage.started>
  | ReturnType<typeof updateContributorBotMessage.succeeded>
  | ReturnType<typeof updateContributorBotMessage.failed>
  | ReturnType<typeof getPreviewMessages.started>
  | ReturnType<typeof getPreviewMessages.succeeded>
  | ReturnType<typeof getPreviewMessages.failed>
  | ReturnType<typeof getPreviewReservedMessages.started>
  | ReturnType<typeof getPreviewReservedMessages.succeeded>
  | ReturnType<typeof getPreviewReservedMessages.failed>
  | ReturnType<typeof getListenerDMs.started>
  | ReturnType<typeof getListenerDMs.succeeded>
  | ReturnType<typeof getListenerDMs.failed>
  | ReturnType<typeof setInitialState>
  | ReturnType<typeof setDmToRespond>
  | ReturnType<typeof removeDmToRespond>
  | ReturnType<typeof resetAllStatusFlags.started>
  | ReturnType<typeof resetAllStatusFlags.succeeded>
  | ReturnType<typeof resetAllStatusFlags.failed>
