import { ContributorProfiles } from 'models/contributors'

function contributorProfilesDeserializer(input: {
  data: any
}): ContributorProfiles {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      title: item.title,
      description: item.description,
    })),
  }
}

export default contributorProfilesDeserializer
