import axios from 'axios'
import manualBotMessagesDeserializer from 'deserializers/manual_bot_messages'
import { loggingError } from 'services/interceptor'

interface ApiConfig {
  baseURL: string
  timeout: number
}

const DEFAULT_API_CONFIG: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

const OK = 200
const NO_CONTENT = 204

export const getManualBotMessagesFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getManualBotMessages = async (request: { formData: FormData }) => {
    const response = await instance.get(
      `/api/cms/v1/manual_bot_messages?contributor_id=${request.formData.get(
        'contributor_id'
      )}&type=$request.formData.get('type')}&page=${request.formData.get(
        'page'
      )}&count=${request.formData.get('count')}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        '/api/cms/v1/manual_bot_messages',
        'getManualBotMessagesFactory'
      )

    return manualBotMessagesDeserializer({
      data: response.data.manual_bot_messages,
      meta: response.data.meta,
    })
  }
  return getManualBotMessages
}

export const getManualBotMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const getManualBotMessage = async (request: { formData: FormData }) => {
    const response = await instance.get(
      `/api/cms/v1/manual_bot_messages/${request.formData.get('id')}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== OK)
      loggingError(
        `/api/cms/v1/manual_bot_messages/:id`,
        'getManualBotMessageFactory'
      )

    return manualBotMessagesDeserializer({
      data: response.data.manual_bot_message,
      meta: response.data.meta,
    })
  }
  return getManualBotMessage
}

export const createManualBotMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const createManualBotMessage = async (request: { formData: FormData }) => {
    const response = await instance.post(
      '/api/cms/v1/manual_bot_messages',
      request.formData,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'content-type': 'multipart/form-data',
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError(
        '/api/cms/v1/manual_bot_messages',
        'createManualBotMessageFactory'
      )
  }
  return createManualBotMessage
}

export const updateManualBotMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const updateManualBotMessage = async (request: { formData: FormData }) => {
    const response = await instance.put(
      `/api/cms/v1/manual_bot_messages/${request.formData.get('id')}`,
      request.formData,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'content-type': 'multipart/form-data',
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError(
        `/api/cms/v1/manual_bot_messages/:id`,
        'updateManualBotMessageFactory'
      )
  }
  return updateManualBotMessage
}

export const deleteManualBotMessageFactory = (optionConfig?: ApiConfig) => {
  const config = {
    ...DEFAULT_API_CONFIG,
    ...optionConfig,
  }
  const instance = axios.create(config)
  const deleteManualBotMessage = async (request: { formData: FormData }) => {
    const response = await instance.delete(
      `/api/cms/v1/manual_bot_messages/${request.formData.get('id')}`,
      {
        headers: {
          'Project-ID': process.env.REACT_APP_PROJECT_ID,
          'content-type': 'multipart/form-data',
          'access-token': localStorage.getItem('access-token'),
          client: localStorage.getItem('client'),
          uid: localStorage.getItem('uid'),
        },
      }
    )

    if (response.status !== NO_CONTENT)
      loggingError(
        `/api/cms/v1/manual_bot_messages/:id`,
        'deleteManualBotMessageFactory'
      )
  }
  return deleteManualBotMessage
}
