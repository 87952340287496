import React from 'react'
import { useStyles } from 'hooks/useStyles'

export type Tab = {
  label: string
  value: string
  selected: boolean
  changeHandler: (value: string) => void
}

export type Props = {
  heading: string
  subText?: string
  children?: React.ReactNode
  tabs?: Tab[]
}

const MainHeader: React.FC<Props> = ({ heading, subText, children, tabs }) => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_header',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.main_header_component}>
      <div className={styles.default.component_columns}>
        <div className={styles.default.header_field}>
          <div className={styles.default.heading}>
            <span className={styles.default.heading_text}>{heading}</span>
            {children && (
              <div className={styles.default.heading_btns}>{children}</div>
            )}
          </div>
          {!subText && !tabs && (
            <div className={styles.default.heading_spacer} />
          )}
          {subText && (
            <div className={styles.default.sub_text}>
              <span>{subText}</span>
            </div>
          )}
          {tabs && (
            <div className={styles.default.tabs}>
              {tabs?.map((tab: Tab) => (
                <div className={styles.default.tab} key={tab.label}>
                  <div
                    className={
                      tab.selected
                        ? styles.default.active
                        : styles.default.inactive
                    }
                    onClick={() => tab.changeHandler(tab.value)}
                  >
                    <span
                      className={
                        tab.selected
                          ? styles.default.active_label
                          : styles.default.inactive_label
                      }
                    >
                      {tab.label}
                    </span>
                  </div>
                  <div className={styles.default.spacer} />
                </div>
              ))}
            </div>
          )}
        </div>
        {tabs && (
          <div className={styles.default.sp_select_box}>
            <select
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedTab = tabs.find((tab: Tab) => {
                  if (tab.value === event.target.value) return tab
                })
                selectedTab && selectedTab.changeHandler(event.target.value)
              }}
            >
              {tabs?.map((tab: Tab) => (
                <option label={tab.label} value={tab.value} key={tab.label} />
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  )
}

export default MainHeader
