import * as ActionType from 'actions/subscription_tiers/types'
import { SubscriptionTier } from 'models/subscription_tiers'

export const getSubscriptionTiers = {
  started: (request: { contributorId: number }) => ({
    type: ActionType.STARTED_GET_SUBSCRIPTION_TIERS as typeof ActionType.STARTED_GET_SUBSCRIPTION_TIERS,
    payload: request,
  }),
  succeeded: (response: {
    subscriptionTiers: SubscriptionTier[]
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_SUBSCRIPTION_TIERS as typeof ActionType.SUCCEEDED_GET_SUBSCRIPTION_TIERS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_SUBSCRIPTION_TIERS as typeof ActionType.FAILED_GET_SUBSCRIPTION_TIERS,
    payload: response,
  }),
}

export const getSubscriptionTier = {
  started: (request: { subscriptionTierId: number }) => ({
    type: ActionType.STARTED_GET_SUBSCRIPTION_TIER as typeof ActionType.STARTED_GET_SUBSCRIPTION_TIER,
    payload: request,
  }),
  succeeded: (response: {
    subscriptionTier: SubscriptionTier
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_SUBSCRIPTION_TIER as typeof ActionType.SUCCEEDED_GET_SUBSCRIPTION_TIER,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_SUBSCRIPTION_TIER as typeof ActionType.FAILED_GET_SUBSCRIPTION_TIER,
    payload: response,
  }),
}

export const updateSubscriptionTier = {
  started: (request: { id: number; data: FormData }) => ({
    type: ActionType.STARTED_UPDATE_SUBSCRIPTION_TIER as typeof ActionType.STARTED_UPDATE_SUBSCRIPTION_TIER,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_SUBSCRIPTION_TIER as typeof ActionType.SUCCEEDED_UPDATE_SUBSCRIPTION_TIER,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_SUBSCRIPTION_TIER as typeof ActionType.FAILED_UPDATE_SUBSCRIPTION_TIER,
    payload: response,
  }),
}

export type SubscriptionTiersAction =
  | ReturnType<typeof getSubscriptionTiers.started>
  | ReturnType<typeof getSubscriptionTiers.succeeded>
  | ReturnType<typeof getSubscriptionTiers.failed>
  | ReturnType<typeof getSubscriptionTier.started>
  | ReturnType<typeof getSubscriptionTier.succeeded>
  | ReturnType<typeof getSubscriptionTier.failed>
  | ReturnType<typeof updateSubscriptionTier.started>
  | ReturnType<typeof updateSubscriptionTier.succeeded>
  | ReturnType<typeof updateSubscriptionTier.failed>
