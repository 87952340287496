import { useEffect } from 'react'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import { getPlatforms } from 'actions/platforms'
import { useDispatch, useSelector } from 'react-redux'
import { CombineReducers } from 'models'
import { PlatformsState, Platform } from 'models/platforms'
import Toast from 'components/modules/toast'
import { useHistory } from 'react-router-dom'

const Platforms: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const platformsState: PlatformsState = useSelector(
    (state: CombineReducers) => state.platforms
  )

  useEffect(() => {
    dispatch(getPlatforms.started())
  }, [])

  return (
    <>
      <Toast body={platformsState.success.message} error={false} />
      <Toast body={platformsState.error.message} error={true} />
      <MainHeader heading="バージョン管理" />
      <MainTable
        columns={{
          scale: '50% 50%',
          items: [
            { label: 'プラットフォーム', grid: '1 / 2' },
            { label: 'バージョン', grid: ' 2 / 3' },
          ],
        }}
        items={platformsState.platforms.map((platform: Platform) => ({
          id: platform.id,
          data: {
            platform: {
              id: platform.id,
              value: platform.name,
              clickable: true,
              subLabel: false,
              grid: '1 / 2',
            },
            version: {
              id: platform.id,
              value: platform.version,
              clickable: false,
              subLabel: false,
              grid: '2 / 3',
            },
          },
        }))}
        selectItemHandler={(platformId: number) => {
          history.push(`/platforms/${platformId}`)
        }}
      />
    </>
  )
}

export default Platforms
