import React from 'react'
import Select from 'components/modules/select'
import { useStyles } from 'hooks/useStyles'
import { typeGuard } from 'utils/type_guard'
import { SubscriptionTier } from 'models/subscription_tiers'

type Props = {
  reservation?: boolean
  reservationHandler?: (value: boolean) => void
  selectedDateHandler?: (value: Date) => void
  selectedHourHandler?: (value: string) => void
  selectedMinuteHandler?: (value: string) => void
  reservationOnly?: boolean
  subscriptionTiers?: SubscriptionTier[]
  selectedSubscriptionTierHandler?: (value: number) => void
}

const MessageOptions: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/messages/create/components/message_options',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.message_options_component}>
      <div className={styles.default.configuration}>
        <div className={styles.default.label}>
          <span>配信日時</span>
        </div>
        <div className={styles.default.form}>
          {!props.reservationOnly && (
            <div className={styles.default.item}>
              <div className={styles.default.row}>
                <div className={styles.default.input}>
                  {props.reservation ? (
                    <div
                      className={styles.default.radio_disabled}
                      onClick={() =>
                        props.reservationHandler &&
                        props.reservationHandler(false)
                      }
                    />
                  ) : (
                    <div className={styles.default.radio_enabled} />
                  )}
                </div>
                <span
                  onClick={() =>
                    props.reservationHandler && props.reservationHandler(false)
                  }
                  style={{
                    color: props.reservation ? '#BDBDBD' : '#333333',
                    cursor: 'pointer',
                  }}
                >
                  今すぐ配信
                </span>
              </div>
            </div>
          )}
          <div className={styles.default.item}>
            <div className={styles.default.row}>
              <div className={styles.default.input}>
                {props.reservation ? (
                  <div className={styles.default.radio_enabled} />
                ) : (
                  <div
                    className={styles.default.radio_disabled}
                    onClick={() =>
                      props.reservationHandler && props.reservationHandler(true)
                    }
                  />
                )}
              </div>
              <span
                onClick={() =>
                  props.reservationHandler && props.reservationHandler(true)
                }
                style={{
                  color: props.reservation ? '#333333' : '#BDBDBD',
                  cursor: 'pointer',
                }}
              >
                予約配信
              </span>
            </div>
            <div className={styles.default.row}>
              <div className={styles.default.custom_input}>
                <input
                  type="date"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    props.selectedDateHandler &&
                    props.selectedDateHandler(new Date(event.target.value))
                  }
                  className={styles.default.calendar}
                />
                <div className={styles.default.time_selector}>
                  <Select
                    type={'hour'}
                    width={'80px'}
                    onChange={(
                      value: string | number | boolean | null | undefined
                    ) =>
                      typeGuard<string>(value) &&
                      props.selectedHourHandler &&
                      props.selectedHourHandler(value)
                    }
                  />
                  <span>:</span>
                  <Select
                    type={'minute'}
                    width={'80px'}
                    onChange={(
                      value: string | number | boolean | null | undefined
                    ) =>
                      typeGuard<string>(value) &&
                      props.selectedMinuteHandler &&
                      props.selectedMinuteHandler(value)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.subscriptionTiers && (
        <div className={styles.default.configuration}>
          <div className={styles.default.label}>
            <span>公開設定</span>
          </div>
          <div className={styles.default.form}>
            <select
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                props.selectedSubscriptionTierHandler &&
                event.target.value &&
                props.selectedSubscriptionTierHandler(
                  Number(event.target.value)
                )
              }
            >
              <option value={undefined}>-</option>
              {props.subscriptionTiers.map(
                (subscriptionTier: SubscriptionTier) => (
                  <option key={subscriptionTier.id} value={subscriptionTier.id}>
                    {subscriptionTier.title}
                  </option>
                )
              )}
            </select>
            <p className={styles.default.option_description}>
              指定されたプラン以上のプランを登録しているユーザーのみに公開されます。
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default MessageOptions
