import { combineReducers } from 'redux'

import sessionsReducer from 'reducers/sessions'
import contributorsReducer from 'reducers/contributors'
import tagsReducer from 'reducers/tags'
import maintenancesReducer from 'reducers/maintenances'
import platformsReducer from 'reducers/platforms'
import messagesReducer from 'reducers/messages'
import listenersReducer from 'reducers/listeners'
import manualBotMessagesReducer from 'reducers/manual_bot_messages'
import subscriptionTiersReducer from 'reducers/subscription_tiers'
import membershipsReducer from 'reducers/memberships'
import toastsReducer from 'reducers/toasts'
import authReducer from 'reducers/auth'
import usersReducer from 'reducers/users'
import loadersReducer from 'reducers/loaders'
import digitalContentsReducer from './digital_contents'

const RootReducer = combineReducers({
  auth: authReducer,
  sessions: sessionsReducer,
  contributors: contributorsReducer,
  tags: tagsReducer,
  maintenances: maintenancesReducer,
  platforms: platformsReducer,
  messages: messagesReducer,
  listeners: listenersReducer,
  manualBotMessages: manualBotMessagesReducer,
  subscriptionTiers: subscriptionTiersReducer,
  memberships: membershipsReducer,
  toasts: toastsReducer,
  loaders: loadersReducer,
  users: usersReducer,
  digitalContents: digitalContentsReducer,
})

export default RootReducer
