import { useStyles } from 'hooks/useStyles'
import SubContent from 'components/modules/preview/components/sub_content'
import { ContributorMessage, MessageContent as Content } from 'models/messages'
import MessageContent from 'components/modules/preview/components/message_content'
import { Contributor } from 'models/contributors'

type Props = {
  contributor: Contributor
  self: boolean
  message?: ContributorMessage
  newMessage: string
  subContent: boolean
  sp: boolean
  immediate: boolean
  reservation: boolean
  eventBot: boolean
  directMessage: boolean
}

const MessageSuite: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/message_suite',
  })

  const countLikes = (messageContents: Content[]) => {
    return messageContents.reduce(
      (accumulator, content) => accumulator + content.likes,
      0
    )
  }

  if (!styles) return <></>

  return (
    <div className={styles.default.message_suite_component}>
      {props.message && (
        <div
          className={styles.default.content_list}
          style={
            props.message.sendingType === 'premium_chat'
              ? { justifyContent: 'flex-start' }
              : { justifyContent: 'flex-end' }
          }
        >
          {props.message.messageContents &&
            props.message.messageContents.data.map(
              (content: Content, index: number) => (
                <MessageContent
                  key={index}
                  contributor={props.contributor}
                  message={props.message!}
                  content={content}
                />
              )
            )}
          {props.subContent && (
            <SubContent
              updatedAt={
                props.message.runAt
                  ? props.message.runAt
                  : props.message.updatedAt
              }
              liked={countLikes(props.message.messageContents.data)}
              messageType={props.message.messageType}
              messageId={props.message.id}
              reservation={props.reservation}
              responseMessage={props.message.sendingType === 'premium_chat'}
              directMessage={props.directMessage}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MessageSuite
