import { useEffect, useRef, useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { ResponseMessage } from 'models/messages'
import { Contributor } from 'models/contributors'
import { ContributorMessages } from 'models/messages'
import { AudioVisualMessage } from 'models/messages'
import MessageList from 'components/modules/preview/components/message_list'
import { usePreviewMessages } from 'components/modules/preview/hooks/use_preview_messages'
import MessageLoadButton from 'components/modules/preview/components/message_load_button'

type Props = {
  contributor: Contributor
  messageText: string
  messages?: ContributorMessages
  refetchMessages?: () => void
  audioVisualMessage?: AudioVisualMessage
  imagePreview?: string
  videoPreview?: string
  voicePreview?: string
  sp: boolean // max-width < 1024px
  immediate: boolean // 即時送信
  reservation: boolean // 予約送信
  eventBot: boolean // イベント型bot送信
  directMessage: boolean // DM
  targetListenerId?: number
  responseMessages?: ResponseMessage[]
  children?: React.ReactNode
}

const Preview: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview',
  })
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const [previewInitialized, setPreviewInitialized] = useState<boolean>(false)

  const [executeRefetchMessages, dateList, messages] = usePreviewMessages({
    contributor: props.contributor,
    immediate: props.immediate,
    reservation: props.reservation,
    directMessage: props.directMessage,
    targetListenerId: props.targetListenerId,
  })

  useEffect(() => {
    if (messages && messages.data.length > 0 && !previewInitialized) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
        setPreviewInitialized(true)
      }, 100)
    }
  }, [messages])

  if (!styles) return <></>

  return (
    <>
      <div className={styles.default.preview_header}>
        <span>プレビュー</span>
      </div>
      <div
        className={styles.default.display}
        style={{
          height: props.sp ? 'calc(100vh - 158px)' : '680px',
        }}
      >
        <MessageLoadButton
          messages={messages}
          onClick={() => executeRefetchMessages()}
        />
        <MessageList
          contributor={props.contributor}
          messageText={props.messageText}
          messages={messages?.data || []}
          dateList={dateList}
          sp={props.sp}
          immediate={props.immediate}
          reservation={props.reservation}
          eventBot={props.eventBot}
          directMessage={props.directMessage}
          audioVisualMessage={props.audioVisualMessage}
          imagePreview={props.imagePreview}
          videoPreview={props.videoPreview}
          voicePreview={props.voicePreview}
          responseMessages={props.responseMessages}
        />
        <div ref={messagesEndRef} />
      </div>
      {props.children}
    </>
  )
}

export default Preview
