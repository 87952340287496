export const STARTED_GET_DIGITAL_CONTENTS =
  'DIGITAL_CONTENTS/STARTED_GET_DIGITAL_CONTENTS'
export const SUCCEEDED_GET_DIGITAL_CONTENTS =
  'DIGITAL_CONTENTS/SUCCEEDED_GET_DIGITAL_CONTENTS'
export const FAILED_GET_DIGITAL_CONTENTS =
  'DIGITAL_CONTENTS/FAILED_GET_DIGITAL_CONTENTS'
export const STARTED_GET_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/STARTED_GET_DIGITAL_CONTENT'
export const SUCCEEDED_GET_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/SUCCEEDED_GET_DIGITAL_CONTENT'
export const FAILED_GET_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/FAILED_GET_DIGITAL_CONTENTS'
export const STARTED_CREATE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/STARTED_CREATE_DIGITAL_CONTENT'
export const SUCCEEDED_CREATE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/SUCCEEDED_CREATE_DIGITAL_CONTENT'
export const FAILED_CREATE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/FAILED_CREATE_DIGITAL_CONTENT'
export const STARTED_UPDATE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/STARTED_UPDATE_DIGITAL_CONTENT'
export const SUCCEEDED_UPDATE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/SUCCEEDED_UPDATE_DIGITAL_CONTENT'
export const FAILED_UPDATE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/FAILED_UPDATE_DIGITAL_CONTENT'
export const STARTED_DELETE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/STARTED_DELETE_DIGITAL_CONTENT'
export const SUCCEEDED_DELETE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/SUCCEEDED_DELETE_DIGITAL_CONTENT'
export const FAILED_DELETE_DIGITAL_CONTENT =
  'DIGITAL_CONTENTS/FAILED_DELETE_DIGITAL_CONTENT'
