import { useDispatch } from 'react-redux'
import { DEFAULT_API_CONFIG } from 'models'
import axios from 'axios'
import { UseMutationResult, useMutation } from 'react-query'
import { setSuccessToast } from 'actions/toasts'
import { useHistory } from 'react-router-dom'

export function useSignOut(): [
  signOut: UseMutationResult<void, unknown, void, unknown>
] {
  const dispatch = useDispatch()
  const history = useHistory()

  const signOut = useMutation(
    async () => {
      const accessToken = localStorage.getItem('access-token')
      const client = localStorage.getItem('client')
      const uid = localStorage.getItem('uid')

      localStorage.removeItem('access-token')
      localStorage.removeItem('client')
      localStorage.removeItem('uid')

      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      await instance.delete('/api/cms/v1/auth/sign_out', {
        headers: {
          uid,
          client,
          'access-token': accessToken,
        },
      })
    },
    {
      onSettled: () => {
        dispatch(setSuccessToast.started({ message: 'ログアウトしました' }))
        history.push('/')
      },
    }
  )

  return [signOut]
}
