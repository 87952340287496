import moment from 'moment'

export const datetimeValidation = (value: string) => {
  let message = '日時の入力値は正常です'
  let type = 'succeeded'
  if (
    moment(value).isSameOrBefore(moment(new Date()).format('YYYY/MM/DD HH:mm'))
  ) {
    message = '日時の入力値が不正です'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
