import { LoadersAction } from 'actions/loaders'
import * as ActionType from 'actions/loaders/types'
import { LoadersState } from 'models/loadings'

export const initialState: LoadersState = {
  loading: false,
}

const loadersReducer = (
  state: LoadersState = initialState,
  action: LoadersAction
): LoadersState => {
  switch (action.type) {
    case ActionType.SET_LOADER:
      return {
        ...state,
        loading: true,
      }
    case ActionType.RESET_LOADER:
      return {
        ...state,
        loading: false,
      }
    default:
      return state
  }
}

export default loadersReducer
