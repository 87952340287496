import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/maintenances/types'
import {
  getAppMaintenanceWindows,
  getCMSMaintenanceWindows,
  createMaintenanceWindow,
  deleteMaintenanceWindow,
} from 'actions/maintenances'
import { MaintenanceWindows } from 'models/maintenances'
import {
  getAppMaintenanceWindowsFactory,
  getCMSMaintenanceWindowsFactory,
  createMaintenanceWindowFactory,
  deleteMaintenanceWindowFactory,
} from 'services/maintenances/api'

function* runGetAppMaintenanceWindows(
  action: ReturnType<typeof getAppMaintenanceWindows.started>
) {
  try {
    const result: MaintenanceWindows = yield call(
      getAppMaintenanceWindowsFactory()
    )

    yield put(
      getAppMaintenanceWindows.succeeded({
        maintenance_windows: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getAppMaintenanceWindows.failed({ message: '' }))
  }
}

function* runGetCMSMaintenanceWindows(
  action: ReturnType<typeof getCMSMaintenanceWindows.started>
) {
  try {
    const result: MaintenanceWindows = yield call(
      getCMSMaintenanceWindowsFactory()
    )

    yield put(
      getCMSMaintenanceWindows.succeeded({
        maintenance_windows: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getCMSMaintenanceWindows.failed({ message: '' }))
  }
}

function* runCreateMaintenanceWindow(
  action: ReturnType<typeof createMaintenanceWindow.started>
) {
  try {
    yield call(createMaintenanceWindowFactory(), action.payload)

    yield put(
      createMaintenanceWindow.succeeded({
        message: 'メンテナンスウィンドウを作成しました',
      })
    )
  } catch (err) {
    yield put(
      createMaintenanceWindow.failed({
        message: 'メンテナンスウィンドウの作成に失敗しました',
      })
    )
  }
}

function* runDeleteMaintenanceWindow(
  action: ReturnType<typeof deleteMaintenanceWindow.started>
) {
  try {
    yield call(deleteMaintenanceWindowFactory(), action.payload)

    yield put(
      deleteMaintenanceWindow.succeeded({
        message: 'メンテナンスウィンドウを削除しました',
      })
    )
  } catch (err) {
    yield put(
      deleteMaintenanceWindow.failed({
        message: 'メンテナンスウィンドウの削除に失敗しました',
      })
    )
  }
}

export function* watchMaintenancesSaga() {
  yield takeLatest(
    Action.STARTED_GET_APP_MAINTENANCE_WINDOWS,
    runGetAppMaintenanceWindows
  )
  yield takeLatest(
    Action.STARTED_GET_CMS_MAINTENANCE_WINDOWS,
    runGetCMSMaintenanceWindows
  )
  yield takeLatest(
    Action.STARTED_CREATE_MAINTENANCE_WINDOW,
    runCreateMaintenanceWindow
  )
  yield takeLatest(
    Action.STARTED_DELETE_MAINTENANCE_WINDOW,
    runDeleteMaintenanceWindow
  )
}
