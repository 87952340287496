import { PlatformsAction } from 'actions/platforms'
import * as ActionType from 'actions/platforms/types'
import { PlatformsState } from 'models/platforms'

export const initialState: PlatformsState = {
  platforms: [],
  platform: null,
  status: {
    startedGetPlatforms: false,
    succeededGetPlatforms: false,
    failedGetPlatforms: false,
    startedGetPlatform: false,
    succeededGetPlatform: false,
    failedGetPlatform: false,
    startedUpdatePlatform: false,
    succeededUpdatePlatform: false,
    failedUpdatePlatform: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const maintenanceReducer = (
  state: PlatformsState = initialState,
  action: PlatformsAction
): PlatformsState => {
  switch (action.type) {
    case ActionType.STARTED_GET_PLATFORMS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetPlatforms: true,
          succeededGetPlatforms: false,
          failedGetPlatforms: false,
        },
      }
    case ActionType.SUCCEEDED_GET_PLATFORMS:
      return {
        ...state,
        platforms: action.payload.platforms,
        status: {
          ...state.status,
          startedGetPlatforms: false,
          succeededGetPlatforms: true,
          failedGetPlatforms: false,
        },
      }
    case ActionType.FAILED_GET_PLATFORMS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetPlatforms: false,
          succeededGetPlatforms: false,
          failedGetPlatforms: true,
        },
      }
    case ActionType.STARTED_GET_PLATFORM:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetPlatform: true,
          succeededGetPlatform: false,
          failedGetPlatform: false,
        },
      }
    case ActionType.SUCCEEDED_GET_PLATFORM:
      return {
        ...state,
        platform: action.payload.platform,
        status: {
          ...state.status,
          startedGetPlatform: false,
          succeededGetPlatform: true,
          failedGetPlatform: false,
        },
      }
    case ActionType.FAILED_GET_PLATFORM:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetPlatform: false,
          succeededGetPlatform: false,
          failedGetPlatform: true,
        },
      }
    case ActionType.STARTED_UPDATE_PLATFORM:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdatePlatform: true,
          succeededUpdatePlatform: false,
          failedUpdatePlatform: false,
        },
      }
    case ActionType.SUCCEEDED_UPDATE_PLATFORM:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdatePlatform: false,
          succeededUpdatePlatform: true,
          failedUpdatePlatform: false,
        },
        success: {
          message: action.payload.message,
        },
      }
    case ActionType.FAILED_UPDATE_PLATFORM:
      return {
        ...state,
        status: {
          ...state.status,
          startedUpdatePlatform: false,
          succeededUpdatePlatform: false,
          failedUpdatePlatform: true,
        },
        error: {
          message: action.payload.message,
        },
      }
    case ActionType.STARTED_RESET_MESSAGES:
      return {
        ...state,
        success: {
          message: '',
        },
        error: {
          message: '',
        },
      }
    case ActionType.SUCCEEDED_RESET_MESSAGES:
      return { ...state }
    case ActionType.FAILED_RESET_MESSAGES:
      return { ...state }
    case ActionType.STARTED_RESET_ALL_STATUS_FLAGS:
      return {
        ...state,
        status: {
          startedGetPlatforms: false,
          succeededGetPlatforms: false,
          failedGetPlatforms: false,
          startedGetPlatform: false,
          succeededGetPlatform: false,
          failedGetPlatform: false,
          startedUpdatePlatform: false,
          succeededUpdatePlatform: false,
          failedUpdatePlatform: false,
        },
      }
    case ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    case ActionType.FAILED_RESET_ALL_STATUS_FLAGS:
      return { ...state }
    default:
      return state
  }
}

export default maintenanceReducer
