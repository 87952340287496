import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useValidation } from 'hooks/useValidation'
import { CombineReducers } from 'models'
import { Tag, TagsState } from 'models/tags'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import { EditableProfile, Profile } from 'models/contributors'
import {
  getContributor,
  updateContributor,
  updateContributorProfiles,
} from 'actions/contributors'
import { updateTagManagement } from 'actions/tags'
import { getTags } from 'actions/tags'
import MainHeader from 'components/modules/main_header'
import MainForm from 'components/modules/main_form'
import TextInput from 'components/modules/main_form/components/text_input'
import FileInput from 'components/modules/main_form/components/file_input'
import TagSelectorInput from 'components/modules/main_form/components/tag_selector_input'
import ProfilesInput from 'components/account_profiles/edit/components/profiles_input'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as Save } from 'images/save.svg'

const AccountProfile: React.FC<{ match: any }> = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const tagsState: TagsState = useSelector((state: CombineReducers) => {
    return state.tags
  })

  const [contributorIcon, setContributorIcon] = useState<File>()
  const [contributorName, setContributorName] = useState<string>('')
  const [contributorEnglishName, setContributorEnglishName] =
    useState<string>('')
  const [contributorHiraganaName, setContributorHiraganaName] =
    useState<string>('')
  const [contributorKatakanaName, setContributorKatakanaName] =
    useState<string>('')
  const [contributorDescription, setContributorDescription] =
    useState<string>('')
  const [profiles, setProfiles] = useState<EditableProfile[]>([])
  const [contributorTwitterLink, setContributorTwitterLink] =
    useState<string>('')
  const [contributorYouTubeLink, setContributorYouTubeLink] =
    useState<string>('')
  const [contributorTags, setContributorTags] = useState<Tag[]>([])
  const [activeRegistrationButton, setActiveRegistrationButton] =
    useState<boolean>(false)
  const [initialized, setInitialized] = useState<boolean>(false)

  const [descriptionValidation, useDescriptionValidation] = useValidation({
    type: 'contributor_description',
    value: contributorDescription,
  })
  const [nameValidation, useNameValidation] = useValidation({
    type: 'contributor_name',
    value: contributorName,
  })
  const [englishNameValidation, useEnglishNameValidation] = useValidation({
    type: 'contributor_name',
    value: contributorEnglishName,
  })
  const [hiraganaNameValidation, useHiraganaNameValidation] = useValidation({
    type: 'contributor_name',
    value: contributorHiraganaName,
  })
  const [katakanaNameValidation, useKatakanaNameValidation] = useValidation({
    type: 'contributor_name',
    value: contributorKatakanaName,
  })
  const [iconValidation, useIconValidation] = useValidation({
    type: 'contributor_icon',
    value: contributorIcon,
  })

  const confirmValidDescription = () => {
    return descriptionValidation && descriptionValidation.type === 'succeeded'
  }
  const confirmValidName = () => {
    return nameValidation && nameValidation.type === 'succeeded'
  }
  const confirmValidEnglishName = () => {
    return englishNameValidation && englishNameValidation.type === 'succeeded'
  }
  const confirmValidHiraganaName = () => {
    return hiraganaNameValidation && hiraganaNameValidation.type === 'succeeded'
  }
  const confirmValidKatakanaName = () => {
    return katakanaNameValidation && katakanaNameValidation.type === 'succeeded'
  }
  const confirmValidIcon = () => {
    if (!contributorIcon) return true

    return iconValidation && iconValidation.type === 'succeeded'
  }

  const registrationButtonChecker = () => {
    if (
      contributorsState.currentContributor &&
      contributorsState.currentContributor!.productId === 'mydear_operator' &&
      confirmValidName() &&
      confirmValidEnglishName() &&
      confirmValidHiraganaName() &&
      confirmValidKatakanaName() &&
      confirmValidIcon()
    ) {
      setActiveRegistrationButton(true)
      return
    }
    if (
      confirmValidDescription() &&
      confirmValidName() &&
      confirmValidEnglishName() &&
      confirmValidHiraganaName() &&
      confirmValidKatakanaName() &&
      confirmValidIcon()
    ) {
      setActiveRegistrationButton(true)
      return
    }
    setActiveRegistrationButton(false)
  }

  const contributorIconHandler = (value: File) => {
    setContributorIcon(value)
    useIconValidation('contributor_icon', value)
  }

  const contributorNameHandler = (value: string) => {
    setContributorName(value)
    useNameValidation('contributor_name', value)
  }

  const contributorEnglishNameHandler = (value: string) => {
    setContributorEnglishName(value)
    useEnglishNameValidation('contributor_name', value)
  }

  const contributorHiraganaNameHandler = (value: string) => {
    setContributorHiraganaName(value)
    useHiraganaNameValidation('contributor_name', value)
  }

  const contributorKatakanaNameHandler = (value: string) => {
    setContributorKatakanaName(value)
    useKatakanaNameValidation('contributor_name', value)
  }

  const contributorDescriptionHandler = (value: string) => {
    setContributorDescription(value)
    useDescriptionValidation('contributor_description', value)
  }

  const contributorTwitterLinkHandler = (value: string) => {
    setContributorTwitterLink(value)
  }

  const contributorYouTubeLinkHandler = (value: string) => {
    setContributorYouTubeLink(value)
  }

  const contributorTagAddHandler = (value: Tag) => {
    setContributorTags([...contributorTags, value])
  }
  const contributorTagRemoveHandler = (value: Tag[]) => {
    setContributorTags(value)
  }

  const registrationHandler = () => {
    const formData = new FormData()
    formData.append('name', contributorName)
    formData.append('english_name', contributorEnglishName)
    formData.append('hiragana_name', contributorHiraganaName)
    formData.append('katakana_name', contributorKatakanaName)
    formData.append('description', contributorDescription)
    contributorIcon && formData.append('icon', contributorIcon)
    contributorTwitterLink &&
      formData.append('twitter_link', contributorTwitterLink)
    contributorYouTubeLink &&
      formData.append('youtube_link', contributorYouTubeLink)

    dispatch(
      updateContributor.started({
        id: contributorsState.currentContributor!.id,
        data: formData,
      })
    )

    const updatedProfiles = profiles.map((profile: Profile) => ({
      title: profile.title,
      description: profile.description,
    }))
    dispatch(
      updateContributorProfiles.started({
        contributorId: contributorsState.currentContributor!.id,
        contributorProfiles: updatedProfiles,
      })
    )

    if (
      sessionsState.currentUser &&
      sessionsState.currentUser.authType === 'Administrator'
    ) {
      dispatch(
        updateTagManagement.started({
          contributorId: contributorsState.currentContributor!.id,
          tags: contributorTags.map((tag: Tag) => tag.id),
        })
      )
    }

    history.push(
      `/contributors/${contributorsState.currentContributor!.id}/profile`
    )
  }

  useEffect(() => {
    if (sessionsState.currentUser && !contributorsState.currentContributor) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? props.match.params.id
              : sessionsState.currentUser.contributor.id,
        })
      )
      if (sessionsState.currentUser.authType === 'Administrator') {
        dispatch(getTags.started())
      }
    }
  }, [sessionsState.status.succeededGetCurrentUser])

  useEffect(() => {
    if (contributorsState.currentContributor && !initialized) {
      setContributorName(contributorsState.currentContributor.name)
      setContributorEnglishName(
        contributorsState.currentContributor.englishName
      )
      setContributorHiraganaName(
        contributorsState.currentContributor.hiraganaName
      )
      setContributorKatakanaName(
        contributorsState.currentContributor.katakanaName
      )
      useNameValidation(
        'contributor_name',
        contributorsState.currentContributor.name
      )
      useEnglishNameValidation(
        'contributor_name',
        contributorsState.currentContributor.englishName
      )
      useHiraganaNameValidation(
        'contributor_name',
        contributorsState.currentContributor.hiraganaName
      )
      useKatakanaNameValidation(
        'contributor_name',
        contributorsState.currentContributor.katakanaName
      )
      setContributorDescription(
        contributorsState.currentContributor.description
      )
      useDescriptionValidation(
        'contributor_description',
        contributorsState.currentContributor.description
      )
      const initialProfiles =
        contributorsState.currentContributor.profiles.data.map(
          (profile: Profile, index: number) => ({
            id: index + 1,
            title: profile.title,
            description: profile.description,
          })
        )
      setProfiles(initialProfiles)
      setContributorTwitterLink(
        contributorsState.currentContributor.twitterLink
      )
      setContributorYouTubeLink(
        contributorsState.currentContributor.youtubeLink
      )
      setContributorTags(contributorsState.currentContributor.tags.data)
      setInitialized(true)
    }
  }, [contributorsState.status.succeededGetContributor])

  useEffect(() => {
    registrationButtonChecker()
  }, [
    descriptionValidation,
    nameValidation,
    englishNameValidation,
    hiraganaNameValidation,
    katakanaNameValidation,
    iconValidation,
  ])

  if (!sessionsState.currentUser || !contributorsState.currentContributor)
    return <></>

  return (
    <>
      <MainHeader heading={'プロフィール'}>
        <Button
          label={'キャンセル'}
          textColor={'#828282'}
          color={'#FFFFFF'}
          border={'2px solid rgb(224, 224, 224)'}
          disabled={false}
          onClickHandler={() =>
            history.push(
              `/contributors/${
                contributorsState.currentContributor!.id
              }/profile`
            )
          }
        />
        <ButtonSpacer />
        <Button
          label={'保存する'}
          icon={Save}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={!activeRegistrationButton}
          onClickHandler={() => registrationHandler()}
        />
      </MainHeader>
      <MainForm>
        <FileInput
          label={'アイコン画像'}
          fileType={'IMAGE'}
          attachedFile={contributorIcon}
          onChangeHandler={(value: File) => contributorIconHandler(value)}
          currentPreview={
            contributorIcon
              ? URL.createObjectURL(contributorIcon)
              : contributorsState.currentContributor.icon
          }
          croppable
        />
        <TextInput
          label={'アカウント名'}
          defaultValue={contributorName}
          updateHandler={(value: string) => contributorNameHandler(value)}
          wordCount={{
            max: 50,
            current: contributorName ? contributorName.length : 0,
          }}
          validation={nameValidation}
        />
        <TextInput
          label={'アカウント英語名'}
          defaultValue={contributorEnglishName}
          updateHandler={(value: string) =>
            contributorEnglishNameHandler(value)
          }
          wordCount={{
            max: 50,
            current: contributorEnglishName ? contributorEnglishName.length : 0,
          }}
          validation={englishNameValidation}
        />
        <TextInput
          label={'アカウント名（かな）'}
          defaultValue={contributorHiraganaName}
          updateHandler={(value: string) =>
            contributorHiraganaNameHandler(value)
          }
          wordCount={{
            max: 50,
            current: contributorHiraganaName
              ? contributorHiraganaName.length
              : 0,
          }}
          validation={hiraganaNameValidation}
        />
        <TextInput
          label={'アカウント名（カナ）'}
          defaultValue={contributorKatakanaName}
          updateHandler={(value: string) =>
            contributorKatakanaNameHandler(value)
          }
          wordCount={{
            max: 50,
            current: contributorKatakanaName
              ? contributorKatakanaName.length
              : 0,
          }}
          validation={katakanaNameValidation}
        />
        <TextInput
          label={'自己紹介文'}
          defaultValue={contributorDescription}
          textarea
          updateHandler={(value: string) =>
            contributorDescriptionHandler(value)
          }
          wordCount={{
            max: 100,
            current: contributorDescription ? contributorDescription.length : 0,
          }}
          validation={descriptionValidation}
        />
        <ProfilesInput profiles={profiles} setProfiles={setProfiles} />
        <TextInput
          label={'Xリンク'}
          defaultValue={contributorTwitterLink}
          updateHandler={(value: string) =>
            contributorTwitterLinkHandler(value)
          }
          validation={{ message: '', type: 'succeeded' }}
        />
        <TextInput
          label={'YouTubeリンク'}
          defaultValue={contributorYouTubeLink}
          updateHandler={(value: string) =>
            contributorYouTubeLinkHandler(value)
          }
          validation={{ message: '', type: 'succeeded' }}
        />
        {sessionsState.currentUser.authType === 'Administrator' && (
          <>
            <TagSelectorInput
              label={'属性タグ'}
              selectedTags={contributorTags}
              selectableTags={tagsState.tags?.data || []}
              selectTagHandler={(value: Tag) => contributorTagAddHandler(value)}
              removeTagHandler={(value: Tag[]) =>
                contributorTagRemoveHandler(value)
              }
            />
          </>
        )}
      </MainForm>
    </>
  )
}

export default AccountProfile
