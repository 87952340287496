import { useState, useMemo } from 'react'
import {
  Validation,
  ManualBotMessageValidations,
  ManualBotMessageValues,
} from 'models/manual_bot_messages'

export function useButtonStatus(input: {
  values: ManualBotMessageValues
  validations: ManualBotMessageValidations
}): [
  executable: boolean,
  checkExecutable: (
    validations: ManualBotMessageValidations,
    title: string,
    body: string
  ) => void
] {
  const [executable, setExecutable] = useState<boolean>(false)

  const checkTitle = (type: string, validation: Validation, title: string) => {
    if (title.length > 0) {
      return validation && validation.type === 'succeeded'
    } else {
      return false
    }
  }

  const checkBody = (type: string, validation: Validation, body: string) => {
    if (body.length > 0) {
      return validation && validation.type === 'succeeded'
    } else {
      return false
    }
  }

  const checkExecutable = (
    validations: ManualBotMessageValidations,
    title: string,
    body: string
  ) => {
    setExecutable(
      checkTitle(
        'manual_bot_message_title',
        validations.titleValidation,
        title
      ) &&
        checkBody('manual_bot_message_body', validations.bodyValidation, body)
    )
  }

  useMemo(() => {
    checkExecutable(input.validations, input.values.title, input.values.body)
  }, [input.validations.titleValidation, input.validations.bodyValidation])

  return [executable, checkExecutable]
}
