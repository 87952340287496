import { useEffect, useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { useDispatch, useSelector } from 'react-redux'
import { getCMSMaintenanceWindows } from 'actions/maintenances'
import { CombineReducers } from 'models'
import { MaintenancesState } from 'models/maintenances'
import Cross from 'images/cross.png'

const MaintenanceToast: React.FC = () => {
  const dispatch = useDispatch()

  const [styles, setStyles] = useStyles({
    page: 'components/modules/maintenance_toast',
  })

  const maintenanceState: MaintenancesState = useSelector(
    (state: CombineReducers) => {
      return state.maintenances
    }
  )

  const [maintenanceDismissed, setMaintenanceDismissed] =
    useState<boolean>(false)

  useEffect(() => {
    dispatch(getCMSMaintenanceWindows.started())
  }, [])

  useEffect(() => {
    if (!maintenanceState.status.succeededGetMaintenanceWindows) return

    if (!localStorage.getItem('maintenance_dismissed')) return

    setMaintenanceDismissed(true)

    if (maintenanceState.maintenanceWindows.data.length > 0) {
      localStorage.getItem('maintenance_dismissed') &&
        setMaintenanceDismissed(true)
    } else {
      localStorage.removeItem('maintenance_dismissed')
    }
  }, [maintenanceState.status.succeededGetMaintenanceWindows])

  if (!maintenanceState.maintenanceWindows.data[0]) return <></>

  if (maintenanceState.maintenanceWindows.data[0].platform !== 'cms')
    return <></>

  if (!styles) return <></>

  return (
    <>
      {!maintenanceDismissed && (
        <div className="maintenance_toast">
          <div className="maintenance_heading">
            <p>{maintenanceState.maintenanceWindows.data[0].title}</p>
            <img
              src={Cross}
              onClick={() => {
                setMaintenanceDismissed(true)
                localStorage.setItem('maintenance_dismissed', 'true')
              }}
            />
          </div>
          <p>
            期間:
            {maintenanceState.maintenanceWindows.data[0].maintenanceDate}{' '}
            {maintenanceState.maintenanceWindows.data[0].maintenanceStartsAt} ~{' '}
            {maintenanceState.maintenanceWindows.data[0].maintenanceEndsAt}
          </p>
          <pre>{maintenanceState.maintenanceWindows.data[0].body}</pre>
        </div>
      )}
    </>
  )
}

export default MaintenanceToast
