import { CombineReducers } from 'models'
import { User } from 'models/auth'
import { SessionsState } from 'models/sessions'
import { Contributor, ContributorsState } from 'models/contributors'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContributor } from 'actions/contributors'

export function useContributor(input: {
  queryString: { [key: string]: string }
}): [user?: User, contributor?: Contributor] {
  const dispatch = useDispatch()

  const sessionsState: SessionsState = useSelector(
    (state: CombineReducers) => state.sessions
  )
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => state.contributors
  )

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id: Number(input.queryString['id']),
        })
      )
    }
  }, [sessionsState.currentUser])

  return [sessionsState.currentUser, contributorsState.currentContributor]
}
