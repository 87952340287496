import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/platforms/types'
import { getPlatforms, getPlatform, updatePlatforms } from 'actions/platforms'
import { Platform } from 'models/platforms'
import {
  getPlatformsFactory,
  getPlatformFactory,
  updatePlatformFactory,
} from 'services/platforms/api'

function* runGetPlatforms(action: ReturnType<typeof getPlatforms.started>) {
  try {
    const result: Platform[] = yield call(getPlatformsFactory())

    yield put(
      getPlatforms.succeeded({
        platforms: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getPlatforms.failed({ message: '' }))
  }
}

function* runGetPlatform(action: ReturnType<typeof getPlatform.started>) {
  try {
    const result: Platform = yield call(getPlatformFactory(), action.payload)

    yield put(
      getPlatform.succeeded({
        platform: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getPlatform.failed({ message: '' }))
  }
}

function* runUpdatePlatform(
  action: ReturnType<typeof updatePlatforms.started>
) {
  try {
    yield call(updatePlatformFactory(), action.payload)

    yield put(
      updatePlatforms.succeeded({
        message: 'プラットフォームを更新しました',
      })
    )
  } catch (err) {
    yield put(
      updatePlatforms.failed({
        message: 'プラットフォームの更新に失敗しました',
      })
    )
  }
}

export function* watchPlatformsSaga() {
  yield takeLatest(Action.STARTED_GET_PLATFORMS, runGetPlatforms)
  yield takeLatest(Action.STARTED_GET_PLATFORM, runGetPlatform)
  yield takeLatest(Action.STARTED_UPDATE_PLATFORM, runUpdatePlatform)
}
