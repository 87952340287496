import { Tags } from 'models/tags'

function tagsDeserializer(input: { data: any; meta?: any }): Tags {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      label: item.label,
      contributors: item.contributors,
      bgColor: item.bg_color,
      letterColor: item.letter_color,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default tagsDeserializer
