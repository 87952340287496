import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getContributor } from 'actions/contributors'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import MainHeader from 'components/modules/main_header'
import Button from 'components/modules/button'
import Toast from 'components/modules/toast'
import { useStyles } from 'hooks/useStyles'
import { validateSession } from 'utils/session'
import { useContributor } from 'hooks/useContributor'
import { ToastsState } from 'models/toasts'
import {
  getDigitalContent,
  updateDigitalContent,
} from 'actions/digital_contents'
import { DigitalContentsState } from 'models/digital_contents'
import { ReactComponent as Save } from 'images/save.svg'
import { useValidation } from 'hooks/useValidation'
import MainForm from 'components/modules/main_form'
import TextInput from 'components/modules/main_form/components/text_input'
import NumberInput from 'components/modules/main_form/components/number_input'
import Spacer from 'components/modules/button/components/spacer'

const EditVoiceContent: React.FC = () => {
  const [styles, setStyles] = useStyles({
    page: 'components/messages/list',
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{
    id: string
    digital_content_id: string
  }>()

  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const digitalContentsState: DigitalContentsState = useSelector(
    (state: CombineReducers) => {
      return state.digitalContents
    }
  )
  const toastsState: ToastsState = useSelector((state: CombineReducers) => {
    return state.toasts
  })

  const [user, contributor] = useContributor({ queryString: params })

  const [title, setTitle] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [point, setPoint] = useState<string>('0')

  const titleHandler = (value: string) => {
    setTitle(value)
    useTitleValidation('digital_content_title', value)
  }
  const descriptionHandler = (value: string) => {
    setDescription(value)
    useDescriptionValidation('digital_content_description', value)
  }
  const [titleValidation, useTitleValidation] = useValidation({
    type: 'digital_content_title',
    value: title,
  })
  const [descriptionValidation, useDescriptionValidation] = useValidation({
    type: 'digital_content_description',
    value: description,
  })

  const saveDigitalContent = () => {
    const formData = new FormData()
    formData.append('contributor_id', params.id)
    formData.append('title', title)
    formData.append('description', description)
    formData.append('point', point)

    dispatch(
      updateDigitalContent.started({
        id: Number(params.digital_content_id),
        data: formData,
      })
    )
    history.push(
      `/contributors/${params.id}/voice_contents/${params.digital_content_id}`
    )
  }

  const executable = useCallback(() => {
    if (titleValidation.type === 'error') return false
    if (descriptionValidation.type === 'error') return false
    return true
  }, [title, description, point])

  useEffect(() => {
    if (!validateSession()) history.push('/')
  }, [])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  useEffect(() => {
    if (contributorsState.currentContributor) {
      dispatch(
        getDigitalContent.started({
          id: Number(params.digital_content_id),
        })
      )
    }
  }, [contributorsState.status.succeededGetContributor])

  useEffect(() => {
    if (digitalContentsState.digitalContent) {
      setTitle(digitalContentsState.digitalContent.title)
      useTitleValidation(
        'digital_content_title',
        digitalContentsState.digitalContent.title
      )
      setDescription(digitalContentsState.digitalContent.description)
      useDescriptionValidation(
        'digital_content_description',
        digitalContentsState.digitalContent.description
      )
      setPoint(digitalContentsState.digitalContent.point.replace(/,/g, ''))
    }
  }, [digitalContentsState.status.succeededGetDigitalContent])

  const digitalContent = digitalContentsState.digitalContent

  if (!styles || !user || !contributor || !digitalContent) return <></>

  return (
    <>
      <Toast body={toastsState.success.message} />
      <Toast body={toastsState.error.message} error />
      <MainHeader heading={'ボイスコンテンツ'}>
        <Button
          label={'キャンセル'}
          textColor={'#828282'}
          color={'#FFFFFF'}
          border={'2px solid rgb(224, 224, 224)'}
          disabled={false}
          onClickHandler={() =>
            history.push(
              `/contributors/${params.id}/voice_contents/${
                digitalContentsState.digitalContent!.id
              }`
            )
          }
        />
        <Spacer />
        <Button
          label={'保存する'}
          icon={Save}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={!executable()}
          onClickHandler={() => saveDigitalContent()}
        />
      </MainHeader>
      <MainForm>
        <TextInput
          label={'タイトル'}
          defaultValue={title}
          updateHandler={(value: string) => titleHandler(value)}
          wordCount={{
            max: 50,
            current: title ? title.length : 0,
          }}
          validation={titleValidation}
        />
        <TextInput
          label={'説明文'}
          defaultValue={description}
          textarea
          updateHandler={(value: string) => descriptionHandler(value)}
          wordCount={{
            max: 120,
            current: description ? description.length : 0,
          }}
          validation={descriptionValidation}
        />
        <NumberInput
          label={'購入ポイント'}
          defaultValue={point}
          updateHandler={(value: string) => setPoint(value)}
          min={0}
          max={999999}
        />
      </MainForm>
    </>
  )
}

export default EditVoiceContent
