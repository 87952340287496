// GET_MANUAL_BOT_MESSAGES
export const STARTED_GET_MANUAL_BOT_MESSAGES =
  'MANUAL_BOT_MESSAGES/STARTED_GET_MANUAL_BOT_MESSAGES'
export const SUCCEEDED_GET_MANUAL_BOT_MESSAGES =
  'MANUAL_BOT_MESSAGES/SUCCEEDED_GET_MANUAL_BOT_MESSAGES'
export const FAILED_GET_MANUAL_BOT_MESSAGES =
  'MANUAL_BOT_MESSAGES/FAILED_GET_MANUAL_BOT_MESSAGES'

// GET_MANUAL_BOT_MESSAGE
export const STARTED_GET_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/STARTED_GET_MANUAL_BOT_MESSAGE'
export const SUCCEEDED_GET_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/SUCCEEDED_GET_MANUAL_BOT_MESSAGE'
export const FAILED_GET_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/FAILED_GET_MANUAL_BOT_MESSAGE'

// CREATE_MANUAL_BOT_MESSAGE
export const STARTED_CREATE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/STARTED_CREATE_MANUAL_BOT_MESSAGE'
export const SUCCEEDED_CREATE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/SUCCEEDED_CREATE_MANUAL_BOT_MESSAGE'
export const FAILED_CREATE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/FAILED_CREATE_MANUAL_BOT_MESSAGE'

// UPDATE_MANUAL_BOT_MESSAGE
export const STARTED_UPDATE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/STARTED_UPDATE_MANUAL_BOT_MESSAGE'
export const SUCCEEDED_UPDATE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/SUCCEEDED_UPDATE_MANUAL_BOT_MESSAGE'
export const FAILED_UPDATE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/FAILED_UPDATE_MANUAL_BOT_MESSAGE'

// DELETE_MANUAL_BOT_MESSAGE
export const STARTED_DELETE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/STARTED_DELETE_MANUAL_BOT_MESSAGE'
export const SUCCEEDED_DELETE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/SUCCEEDED_DELETE_MANUAL_BOT_MESSAGE'
export const FAILED_DELETE_MANUAL_BOT_MESSAGE =
  'MANUAL_BOT_MESSAGES/FAILED_DELETE_MANUAL_BOT_MESSAGE'

// RESET_ALL_STATUS_FLAGS
export const STARTED_RESET_ALL_STATUS_FLAGS =
  'MANUAL_BOT_MESSAGES/STARTED_RESET_ALL_STATUS_FLAGS'
export const SUCCEEDED_RESET_ALL_STATUS_FLAGS =
  'MANUAL_BOT_MESSAGES/SUCCEEDED_RESET_ALL_STATUS_FLAGS'
export const FAILED_RESET_ALL_STATUS_FLAGS =
  'MANUAL_BOT_MESSAGES/FAILED_RESET_ALL_STATUS_FLAGS'
