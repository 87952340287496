import { useState, useEffect, useCallback, useRef, Dispatch } from 'react'
import { useStyles } from 'hooks/useStyles'
import Overlay from 'components/modules/overlay'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

type Props = {
  preview?: string
  onChangeHandler: (value: File) => void
  setShowCropModal: Dispatch<boolean>
}

const CropModal: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/file_input/components/crop_modal',
  })

  const imgRef = useRef<any>()
  const previewCanvasRef = useRef<any>()
  const [croppedTarget, setCroppedTarget] = useState<any>({
    aspect: 1 / 1,
    width: 30,
    height: 30,
    unit: '%',
    x: 0,
    y: 0,
  })
  const [croppedPreview, setCroppedPreview] = useState<any>(null)

  const onLoad = useCallback(img => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (croppedPreview && previewCanvasRef.current && imgRef.current) {
      const image: HTMLImageElement = imgRef.current
      const canvas: HTMLCanvasElement = previewCanvasRef.current
      const crop = croppedPreview

      const scaleX = image.naturalWidth / image.width
      const scaleY = image.naturalHeight / image.height
      const ctx = canvas.getContext('2d')
      const pixelRatio = window.devicePixelRatio

      canvas.width = crop.width * pixelRatio * scaleX
      canvas.height = crop.height * pixelRatio * scaleY

      if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
        ctx.imageSmoothingQuality = 'high'

        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width * scaleX,
          crop.height * scaleY
        )
      }
      return
    }
  }, [croppedPreview])

  if (!styles) return <></>

  return (
    <Overlay dismissHandler={() => null}>
      {props.preview && (
        <div className={styles.default.crop_modal}>
          <div className={styles.default.label}>
            <h3>画像の範囲指定</h3>
            <span>画像範囲を選択してください。</span>
          </div>
          <div className={styles.default.images}>
            <p>オリジナル</p>
            <div className={styles.default.target}>
              <ReactCrop
                src={props.preview}
                onImageLoaded={onLoad}
                crop={croppedTarget}
                onChange={c => setCroppedTarget(c)}
                onComplete={c => setCroppedPreview(c)}
              />
            </div>
            <p>切り抜き後</p>
            <div className={styles.default.result}>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(croppedPreview?.width ?? 0),
                  height: Math.round(croppedPreview?.height ?? 0),
                }}
              />
            </div>
          </div>
          <button
            onClick={() => {
              previewCanvasRef.current &&
                previewCanvasRef.current.toBlob(
                  (blob: File) => {
                    props.onChangeHandler && props.onChangeHandler(blob)
                  },
                  'image/png',
                  1
                )
              props.setShowCropModal(false)
            }}
          >
            決定
          </button>
        </div>
      )}
    </Overlay>
  )
}

export default CropModal
