import contributorDeserializer from 'deserializers/contributor'
import financialReportDeserializer from 'deserializers/financial_report'
import { PaymentStatement } from 'models/financial_reports'

function paymentStatementDeserializer(input: {
  data: any
  meta?: any
}): PaymentStatement {
  return {
    id: input.data?.id,
    file: input.data?.file,
    contributor: contributorDeserializer({ data: input.data?.contributor }),
    financialReport: financialReportDeserializer({
      data: input.data?.financial_report,
    }),
    premiumChatRevenue: input.data?.premium_chat_revenue.map(
      (revenue: any) => ({
        type: revenue.type,
        unitPrice: revenue.unit_price,
        count: revenue.count,
        total: revenue.total,
      })
    ),
    premiumChatRevenueTotal: input.data?.premium_chat_revenue_total,
    membershipRevenue: input.data?.membership_revenue,
    membershipRevenueTotal: input.data?.membership_revenue_total,
  }
}

export default paymentStatementDeserializer
