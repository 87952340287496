import { useStyles } from 'hooks/useStyles'

type Props = {
  children?: React.ReactNode
}

const MainBody: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_body',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.main_body_component}>{props.children}</div>
  )
}

export default MainBody
