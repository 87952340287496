import React, { useState, useEffect } from 'react'
import { useStyles } from 'hooks/useStyles'
import { useDispatch } from 'react-redux'
import { resetMessages as resetContributorsMessages } from 'actions/contributors'
import { resetAllStatusFlags as resetAllContributorsStatusFlags } from 'actions/contributors'
import { resetAllStatusFlags as resetAllTagsStatusFlags } from 'actions/tags'
import { resetMessages as resetMaintenancesMessages } from 'actions/maintenances'
import { resetAllStatusFlags as resetAllMaintenancesStatusFlags } from 'actions/maintenances'
import { resetMessages as resetPlatformsMessages } from 'actions/platforms'
import { resetAllStatusFlags as resetAllPlatformsStatusFlags } from 'actions/platforms'
import { resetToast } from 'actions/toasts'

type Props = { body: string; error?: boolean }

const Toast: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/toast',
  })

  const dispatch = useDispatch()

  const [visible, setVisible] = useState<boolean>(true)

  const visibilityTimeOut = () => {
    setVisible(true)
    setTimeout(() => {
      setVisible(false)
      dispatch(resetContributorsMessages.started())
      dispatch(resetAllContributorsStatusFlags.started())
      dispatch(resetAllTagsStatusFlags.started())
      dispatch(resetMaintenancesMessages.started())
      dispatch(resetAllMaintenancesStatusFlags.started())
      dispatch(resetPlatformsMessages.started())
      dispatch(resetAllPlatformsStatusFlags.started())
      dispatch(resetToast.started())
    }, 3000)
  }

  useEffect(() => {
    props.body.length > 0 && visibilityTimeOut()
  }, [props.body])

  if (!styles) return <></>

  return (
    <>
      {visible && props.body.length > 0 && (
        <div
          className={styles.default.message_module}
          style={{
            backgroundColor: props.error ? '#FDF1F1' : '#E1F6F5',
          }}
        >
          <div
            className={styles.default.message}
            style={{ color: props.error ? '#E25047' : '#000000' }}
          >
            {props.error ? (
              <span>{props.body}</span>
            ) : (
              <span>{props.body}&#x2728;</span>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Toast
