import React from 'react'
import Dropzone from 'react-dropzone'
import { useStyles } from 'hooks/useStyles'

const MediaInput: React.FC<{
  setAudioVisual: (type: string, blob: File) => void
  label: string
  subLabel: string
  type: string
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >
}> = ({ setAudioVisual, label, subLabel, type, Icon }) => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/message_form/components/media_input',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.media_input_component}>
      <Dropzone
        onDrop={acceptedFiles => setAudioVisual(type, acceptedFiles[0])}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={styles.default.droppable_area}>
            <div className={styles.default.droppable_directions}>
              <input {...getInputProps()} />
              <div className={styles.default.icon}>
                <Icon />
              </div>
              <div className={styles.default.text}>
                <span className={styles.default.label}>{label}</span>
                <span className={styles.default.sub_label}>{subLabel}</span>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  )
}

export default MediaInput
