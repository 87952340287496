import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { Tag, TagsState } from 'models/tags'
import { ContributorsState } from 'models/contributors'
import { getContributor } from 'actions/contributors'
import MainHeader from 'components/modules/main_header'
import MainBody from 'components/modules/main_body'
import Button from 'components/modules/button'
import Toast from 'components/modules/toast'
import ItemRow from 'components/account_profiles/detail/components/item_row'
import Icon from 'images/primary.webp'
import { ReactComponent as Edit } from 'images/edit.svg'

const AccountProfile: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{
    id: string
  }>()

  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const tagsState: TagsState = useSelector((state: CombineReducers) => {
    return state.tags
  })

  const fetchCurrentContributor = () => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }

  useEffect(() => {
    fetchCurrentContributor()
  }, [
    sessionsState.status.succeededGetCurrentUser,
    contributorsState.status.succeededUpdateContributor,
    contributorsState.status.failedUpdateContributor,
    tagsState.status.succeededUpdateTagManagement,
    tagsState.status.failedUpdateTagManagement,
  ])

  if (!sessionsState.currentUser || !contributorsState.currentContributor)
    return <></>

  return (
    <>
      <Toast body={contributorsState.success.message} error={false} />
      <Toast body={contributorsState.error.message} error={true} />
      <MainHeader heading={'プロフィール'}>
        <Button
          label={'編集する'}
          icon={Edit}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            contributorsState.currentContributor &&
            history.push(
              `/contributors/${contributorsState.currentContributor.id}/profile/edit`
            )
          }
        />
      </MainHeader>
      <MainBody>
        <ItemRow
          label={'アイコン画像'}
          image={
            contributorsState.currentContributor.icon
              ? contributorsState.currentContributor.icon
              : Icon
          }
        />
        <ItemRow
          label={'アカウント名'}
          inlineText={contributorsState.currentContributor.name}
        />
        <ItemRow
          label={'アカウント英語名'}
          inlineText={contributorsState.currentContributor.englishName}
        />
        <ItemRow
          label={'アカウント名（かな）'}
          inlineText={contributorsState.currentContributor.hiraganaName}
        />
        <ItemRow
          label={'アカウント名（カナ）'}
          inlineText={contributorsState.currentContributor.katakanaName}
        />
        {contributorsState.currentContributor.productId ===
        'mydear_operator' ? null : (
          <>
            <ItemRow
              label={'自己紹介文'}
              text={contributorsState.currentContributor.description}
            />
            <ItemRow
              label={'一問一答'}
              profiles={contributorsState.currentContributor.profiles}
            />
            <ItemRow
              label={'Xリンク'}
              link={{
                name: contributorsState.currentContributor.twitterLink,
                path: contributorsState.currentContributor.twitterLink,
              }}
            />
            <ItemRow
              label={'YouTubeリンク'}
              link={{
                name: contributorsState.currentContributor.youtubeLink,
                path: contributorsState.currentContributor.youtubeLink,
              }}
            />
            <ItemRow
              label={'属性タグ'}
              tags={contributorsState.currentContributor.tags.data.map(
                (tag: Tag) => ({
                  label: tag.label,
                  bgColor: tag.bgColor,
                  color: tag.letterColor,
                })
              )}
            />
          </>
        )}
      </MainBody>
    </>
  )
}

export default AccountProfile
