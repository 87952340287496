export function useTextParse(): [parseText: (text: string) => string] {
  const parseText = (text: string) => {
    if (!text) return ''

    const urls = text.match(/https?:\/\/[\w/:%#$&!?()~.=+-]+/g)

    let urlReplacedText = text
    const replacedUrls: Array<{ index: number; url: string }> = []
    urls?.forEach((url: string, index: number) => {
      urlReplacedText = urlReplacedText.replace(url, `\\url-${index}`)
      replacedUrls.push({ index: index, url: url })
    })

    const parsedText = urlReplacedText
      .split(/(\n|\r|\r\n|\\urls|\\urle)/)
      .map((item, index) => {
        if (item.match(/^\\url-/)) {
          const i = item.match(/\\url-\d/)
          if (i) {
            let link = ''
            replacedUrls.forEach((url: { index: number; url: string }) => {
              if (parseInt(i[0].replace(/\\url-/, ''), 10) === url.index)
                link = `<a href={url.url} key={index} target="_blank" rel="noopener">${url.url}</a>`
            })
            return link
          }
        }
        if (item.match(/\(username\)/)) {
          return item.replace(
            /\(username\)/g,
            '<span style="font-weight: bold;">(username)</span>'
          )
        }
        if (item.match(/<script>/)) {
          return item.replace(/<script>/g, '')
        }
        return item
      })
    return parsedText.join('')
  }

  return [parseText]
}
