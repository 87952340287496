import { useCallback } from 'react'
import '../index.css'

const Select: React.FC<{
  type: string
  label?: string
  width?: string
  selectableItems?: Array<{ label: string; value: any }>
  onChange: (value: string | number | boolean | null | undefined) => void
  defaultValue?: string | null
  sp?: boolean
}> = ({ type, label, width, selectableItems, onChange, defaultValue, sp }) => {
  const hourSelection = () => {
    return Array.from(Array(24).keys()).map((number: number) => ({
      label: number < 10 ? `0${number}` : `${number}`,
      value: number < 10 ? `0${number}` : `${number}`,
    }))
  }
  const minuteSelection = () => {
    return Array.from(Array(60).keys()).map((number: number) => ({
      label: number < 10 ? `0${number}` : `${number}`,
      value: number < 10 ? `0${number}` : `${number}`,
    }))
  }

  const timeComponent = () => {
    return type === 'hour'
      ? hourSelection().map((item: { label: string; value: string }) => {
          return (
            <option key={item.value} value={item.label}>
              {item.value}
            </option>
          )
        })
      : minuteSelection().map((item: { label: string; value: string }) => {
          return (
            <option key={item.value} value={item.label}>
              {item.value}
            </option>
          )
        })
  }

  const defaultSelectedValue = useCallback(() => {
    if (defaultValue) return defaultValue
  }, [defaultValue])

  return (
    <>
      <div className="select_module">
        {label && (
          <label
            style={{ margin: '12px 0px', textAlign: sp ? 'left' : 'right' }}
          >
            {label}
          </label>
        )}
        <select
          style={{
            width: width ? width : '300px',
          }}
          defaultValue={defaultSelectedValue()}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            onChange(event.target.value)
          }
        >
          {type === 'custom' && selectableItems
            ? selectableItems.map((item: { label: string; value: any }) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                )
              })
            : timeComponent()}
        </select>
      </div>
      <div style={{ clear: 'both' }} />
    </>
  )
}

export default Select
