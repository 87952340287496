import { User } from 'models/auth'
import * as ActionType from 'actions/sessions/types'

export const getCurrentUser = {
  started: () => ({
    type: ActionType.STARTED_GET_CURRENT_USER as typeof ActionType.STARTED_GET_CURRENT_USER,
  }),
  succeeded: (response: { user: User; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_CURRENT_USER as typeof ActionType.SUCCEEDED_GET_CURRENT_USER,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CURRENT_USER as typeof ActionType.FAILED_GET_CURRENT_USER,
    payload: response,
  }),
}

export type SessionsAction =
  | ReturnType<typeof getCurrentUser.started>
  | ReturnType<typeof getCurrentUser.succeeded>
  | ReturnType<typeof getCurrentUser.failed>
