import { useState } from 'react'

export function useConstructFormData(): [
  formData: FormData,
  setData: (key: string, value: string | Blob) => void
] {
  const [formData, setFormData] = useState<FormData>(new FormData())

  const setData = (key: string, value: string | Blob) => {
    if (formData.get(key)) formData.delete(key)
    formData.append(key, value)
    setFormData(formData)
  }

  return [formData, setData]
}
