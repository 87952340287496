import { useDispatch } from 'react-redux'
import { DEFAULT_API_CONFIG } from 'models'
import axios from 'axios'
import { UseMutationResult, useMutation } from 'react-query'
import { setSuccessToast, setErrorToast } from 'actions/toasts'
import { useHistory } from 'react-router-dom'
import { buildHeader } from 'services'

export function useRevokeXToken(): [
  revokeXToken: UseMutationResult<void, unknown, void, unknown>
] {
  const dispatch = useDispatch()
  const history = useHistory()

  const revokeXToken = useMutation(
    async () => {
      const uri = '/api/cms/v1/x_notifications/revoke_access_token'
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      await instance.delete(uri, {
        headers: buildHeader(),
      })
    },
    {
      onSuccess: () => {
        dispatch(
          setSuccessToast.started({ message: 'X 連携解除に成功しました' })
        )
      },
      onError: () => {
        dispatch(
          setErrorToast.started({
            message: 'X 連携解除に失敗しました',
          })
        )
      },
      onSettled: () => {
        history.push('/')
      },
    }
  )

  return [revokeXToken]
}
