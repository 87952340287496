import { XAccount } from 'models/contributors'

function xAccountDeserializer(input: { data: any }): XAccount {
  return {
    link: input.data?.link,
    accessToken: input.data?.access_token,
    refreshToken: input.data?.refresh_token,
    accessTokenIssuedAt: input.data?.access_token_issued_at,
    refreshTokenExpiresDate: input.data?.refresh_token_expires_date,
  }
}

export default xAccountDeserializer
