import { useEffect, useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { useResponsive } from 'hooks/useResponsive'
import { ReactComponent as Image } from 'images/image.svg'
import { ReactComponent as Voice } from 'images/voice.svg'
import { ReactComponent as Video } from 'images/video.svg'
import { ReactComponent as Send } from 'images/send.svg'
import { ReactComponent as Cross } from 'images/cross_white.svg'
import { ReactComponent as SummarizedMedias } from 'images/summarized_medias.svg'
import MediaInput from 'components/messages/create/components/media_input'
import { useSelector } from 'react-redux'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import { AudioVisualMessage } from 'models/messages'
import { SubscriptionTier } from 'models/subscription_tiers'

const birthdayPlaceholder = `誕生日おめでとうございまーす！いぇーい！！
今年も(username)さんにとって素敵な1年になりますように！
これからも楽しい日々を過ごしていこう～
`
const followPlaceholder = `(username)さん、有料登録してくれてありがとう！
my dear.でしか見られない特別なコンテンツをお届けしていきます！
これからもよろしくね！
`
const trialPlaceholder = `(username)さん、また登録してくれてありがとう！
引き続き限定コンテンツを送信していくので、これからもよろしくね！
`
const billingPlaceholder = `(username)さん、フォローありがとうございます！
声優の〇〇です！ これから私の日常を送信していきます！
もし気に入ってくれたら定期購読もお願いします！
これからよろしくお願いします！
`

type Props = {
  messageText: string
  messageTextHandler: React.Dispatch<string>
  publicMessage?: boolean
  selectedTime?: string
  audioVisualMessage?: AudioVisualMessage
  imagePreview?: string
  voicePreview?: string
  videoPreview?: string
  textValidation: { message: string; type: string }
  imageValidation: { message: string; type: string }
  videoValidation: { message: string; type: string }
  voiceValidation: { message: string; type: string }
  disabled: boolean
  reserved: boolean
  bot: boolean
  botPattern?: string
  subscriptionTiers?: SubscriptionTier[]
  publicMessageHandler?: (value: boolean) => void
  selectedTimeHandler?: (time: string) => void
  selectedDateHandler?: (date: Date) => void
  uploadAudioVisualHandler: (type: string, value: File) => void
  adaptVariableHandler: (variable: string, targetCursor: number) => void
  sendMessageHandler: () => void
  removeAudioVisualHandler: () => void
  selectedSubscriptionTierHandler?: (value: number) => void
}

const MessageForm: React.FC<Props> = props => {
  const [responsive] = useResponsive()
  const [scrollHeight, setScrollHeight] = useState<number>(20)
  const [targetCursor, setTargetCursor] = useState<number>(0)
  const [showVariables, setShowVariables] = useState<boolean>(false)
  const [avoidClose, setAvoidClose] = useState<boolean>(false)
  const [summarizedMedias, setSummarizedMedias] = useState<boolean>(false)

  const avoidCloseHandler = (noClose: boolean) => {
    if (noClose) {
      setAvoidClose(true)
    } else {
      setAvoidClose(false)
      !avoidClose && setShowVariables(false)
      !avoidClose && setSummarizedMedias(false)
    }
  }

  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/message_form',
  })

  const generatePlaceholder = () => {
    switch (props.botPattern) {
      case '誕生日':
        return birthdayPlaceholder
      case '初回有料登録時':
        return trialPlaceholder
      case '有料登録復帰時':
        return billingPlaceholder
      case '無料登録時':
        return followPlaceholder
      default:
        return '本文を入力してください。'
    }
  }

  if (!styles) return <></>

  return (
    <div className={styles.default.send_form}>
      {props.subscriptionTiers && (
        <div className={styles.default.subscription_tier_option}>
          <div className={styles.default.content}>
            <select
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                props.selectedSubscriptionTierHandler &&
                event.target.value &&
                props.selectedSubscriptionTierHandler(
                  Number(event.target.value)
                )
              }
            >
              <option value={undefined}>-</option>
              {props.subscriptionTiers.map(
                (subscriptionTier: SubscriptionTier) => (
                  <option key={subscriptionTier.id} value={subscriptionTier.id}>
                    {subscriptionTier.title}
                  </option>
                )
              )}
            </select>
            <p className={styles.default.option_description}>
              指定されたプラン以上のプランを登録しているユーザーのみに公開されます。
            </p>
          </div>
        </div>
      )}
      {props.reserved && (
        <div className={styles.default.send_option}>
          <div className={styles.default.content}>
            {props.bot ? <span>送信時間</span> : <span>予約日時</span>}
            {!props.bot && (
              <input
                type="date"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  props.selectedDateHandler &&
                  props.selectedDateHandler(new Date(event.target.value))
                }
              />
            )}
            <input
              type="time"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.selectedTimeHandler &&
                props.selectedTimeHandler(event.target.value)
              }
              value={props.selectedTime}
            />
          </div>
        </div>
      )}
      <div className={styles.default.content_editor}>
        {props.audioVisualMessage && props.imageValidation.type === 'error' && (
          <div className={styles.default.validation}>
            <div className={styles.default.message}>
              <span>{props.imageValidation.message}</span>
            </div>
          </div>
        )}
        {props.audioVisualMessage && props.videoValidation.type === 'error' && (
          <div className={styles.default.validation}>
            <div className={styles.default.message}>
              <span>{props.videoValidation.message}</span>
            </div>
          </div>
        )}
        {props.audioVisualMessage && props.voiceValidation.type === 'error' && (
          <div className={styles.default.validation}>
            <div className={styles.default.message}>
              <span>{props.voiceValidation.message}</span>
            </div>
          </div>
        )}
        {props.audioVisualMessage && props.audioVisualMessage.blob && (
          <div className={styles.default.media_previews}>
            {props.imagePreview && (
              <div className={styles.default.image_content}>
                <div
                  className={styles.default.media_remove_btn}
                  onClick={() => props.removeAudioVisualHandler()}
                >
                  <Cross />
                </div>
                <img src={props.imagePreview} />
              </div>
            )}
            {props.voicePreview && (
              <div className={styles.default.image_content}>
                <div
                  className={styles.default.media_remove_btn}
                  onClick={() => props.removeAudioVisualHandler()}
                >
                  <Cross />
                </div>
                <Voice />
              </div>
            )}
            {props.videoPreview && (
              <div className={styles.default.image_content}>
                <div
                  className={styles.default.media_remove_btn}
                  onClick={() => props.removeAudioVisualHandler()}
                >
                  <Cross />
                </div>
                <Video />
              </div>
            )}
          </div>
        )}
        <div className={styles.default.inputs}>
          <div className={styles.default.medias}>
            {summarizedMedias ? (
              <div className={styles.default.summarized_medias}>
                <SummarizedMedias onClick={() => setSummarizedMedias(false)} />
              </div>
            ) : (
              <>
                <MediaInput
                  setAudioVisual={props.uploadAudioVisualHandler}
                  type={'image'}
                  icon={Image}
                  selected={props.audioVisualMessage?.type === 'image'}
                  disabled={
                    props.audioVisualMessage?.type === 'image' ||
                    props.audioVisualMessage?.type === 'voice' ||
                    props.audioVisualMessage?.type === 'video'
                  }
                />
                <MediaInput
                  setAudioVisual={props.uploadAudioVisualHandler}
                  type={'voice'}
                  icon={Voice}
                  selected={props.audioVisualMessage?.type === 'voice'}
                  disabled={
                    props.audioVisualMessage?.type === 'image' ||
                    props.audioVisualMessage?.type === 'voice' ||
                    props.audioVisualMessage?.type === 'video'
                  }
                />
                <MediaInput
                  setAudioVisual={props.uploadAudioVisualHandler}
                  type={'video'}
                  icon={Video}
                  selected={props.audioVisualMessage?.type === 'video'}
                  disabled={
                    props.audioVisualMessage?.type === 'image' ||
                    props.audioVisualMessage?.type === 'voice' ||
                    props.audioVisualMessage?.type === 'video'
                  }
                />
              </>
            )}
          </div>
          <div
            className={styles.default.text}
            style={{
              width:
                responsive === 'small'
                  ? summarizedMedias
                    ? '251px'
                    : '180px'
                  : summarizedMedias
                  ? '303px'
                  : '232px',
            }}
          >
            <textarea
              value={props.messageText}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                const currentLines = event.target.value.split('\n').length
                setScrollHeight(currentLines * 20)
                props.messageTextHandler(event.target.value)
              }}
              onKeyUp={(event: { target: any }) =>
                setTargetCursor(event.target.selectionStart)
              }
              onClick={(event: { target: any }) => {
                setTargetCursor(event.target.selectionStart)
              }}
              onFocus={() => {
                setShowVariables(true)
                setSummarizedMedias(true)
              }}
              onBlur={() => {
                avoidCloseHandler(false)
              }}
              style={{
                width:
                  responsive === 'small'
                    ? summarizedMedias
                      ? '188px'
                      : '120px'
                    : summarizedMedias
                    ? '243px'
                    : '172px',
                height: scrollHeight,
              }}
              placeholder={generatePlaceholder()}
            />
            <div
              className={
                props.disabled
                  ? styles.default.send_btn_inactive
                  : styles.default.send_btn_active
              }
              onClick={() => {
                !props.disabled && props.sendMessageHandler()
              }}
            >
              {props.bot && (
                <span className={styles.default.btn_label}>設定</span>
              )}
              {props.reserved && !props.bot && (
                <span className={styles.default.btn_label}>予約</span>
              )}
              {!props.reserved && !props.bot && <Send />}
            </div>
          </div>
        </div>
        <div
          className={styles.default.under_textarea}
          style={{
            justifyContent: showVariables ? 'space-between' : 'flex-end',
          }}
        >
          {showVariables && (
            <div
              className={styles.default.variables}
              style={{
                width:
                  responsive === 'small'
                    ? summarizedMedias
                      ? '188px'
                      : '120px'
                    : summarizedMedias
                    ? '243px'
                    : '172px',
                padding: summarizedMedias
                  ? '0px 16px 8px 56px'
                  : '0px 16px 8px 127px',
              }}
            >
              <div className={styles.default.container}>
                <div
                  className={styles.default.variable}
                  onMouseDown={() => {
                    avoidCloseHandler(true)
                    props.adaptVariableHandler('username', targetCursor)
                  }}
                >
                  <span>ユーザー名</span>
                </div>
                {props.botPattern === 'キャンペーン応募時' && (
                  <div
                    className={styles.default.variable}
                    onMouseDown={() => {
                      avoidCloseHandler(true)
                      props.adaptVariableHandler('username', targetCursor)
                    }}
                  >
                    <span>当選発表日時</span>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={styles.default.word_count}>
            <span
              style={{
                color:
                  props.textValidation.type === 'error' ? '#E25047' : '#828282',
              }}
            >
              {props.messageText.length}/5000
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageForm
