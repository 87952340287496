import { all, fork } from 'redux-saga/effects'
import { watchSessionsSaga } from 'sagas/sessions'
import { watchContributorsSaga } from 'sagas/contributors'
import { watchTagsSaga } from 'sagas/tags'
import { watchMaintenancesSaga } from 'sagas/maintenances'
import { watchPlatformsSaga } from 'sagas/platforms'
import { watchMessagesSaga } from 'sagas/messages'
import { watchListenersSaga } from 'sagas/listeners'
import { watchManualBotMessagesSaga } from 'sagas/manual_bot_messages'
import { watchSubscriptionTiersSaga } from 'sagas/subscription_tiers'
import { watchMembershipsSaga } from 'sagas/memberships'
import { watchAuthSaga } from 'sagas/auth'
import { watchDigitalContentsSaga } from 'sagas/digital_contents'

export default function* RootSaga() {
  yield all([fork(watchAuthSaga)])
  yield all([fork(watchSessionsSaga)])
  yield all([fork(watchContributorsSaga)])
  yield all([fork(watchTagsSaga)])
  yield all([fork(watchMaintenancesSaga)])
  yield all([fork(watchPlatformsSaga)])
  yield all([fork(watchMessagesSaga)])
  yield all([fork(watchListenersSaga)])
  yield all([fork(watchManualBotMessagesSaga)])
  yield all([fork(watchSubscriptionTiersSaga)])
  yield all([fork(watchMembershipsSaga)])
  yield all([fork(watchDigitalContentsSaga)])
}
