import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CombineReducers } from 'models'
import { Contributor, ContributorsState } from 'models/contributors'
import { getContributors, deleteContributor } from 'actions/contributors'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import Button from 'components/modules/button'
import Toast from 'components/modules/toast'
import { useResponsive } from 'hooks/useResponsive'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import Overlay from 'components/modules/overlay'
import Modal from 'components/modules/modal'
import ModalButton from 'components/modules/modal/components/button'
import { ToastsState } from 'models/toasts'
import Pagination from 'components/modules/pagination'

const Accounts: React.FC = () => {
  const [responsive] = useResponsive()
  const dispatch = useDispatch()
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const toastsState: ToastsState = useSelector((state: CombineReducers) => {
    return state.toasts
  })
  const history = useHistory()

  const fetchAllContributors = () => {
    dispatch(getContributors.started({ page: 1 }))
  }

  const [removeAccountModal, setRemoveAccountModal] = useState<boolean>(false)
  const [removeTarget, setRemoveTarget] = useState<number>()
  const showRemoveAccountModalHandler = (targetId: number) => {
    setRemoveAccountModal(true)
    setRemoveTarget(targetId)
  }
  const removeAccount = () => {
    if (removeTarget) dispatch(deleteContributor.started({ id: removeTarget }))
    setRemoveAccountModal(false)
  }

  useEffect(() => {
    fetchAllContributors()
  }, [
    contributorsState.status.succeededCreateContributor,
    contributorsState.status.succeededDeleteContributor,
  ])

  if (!contributorsState.contributors) return <></>

  return (
    <>
      <Toast body={toastsState.success.message} />
      <Toast body={toastsState.error.message} error />
      <Toast body={contributorsState.success.message} error={false} />
      <Toast body={contributorsState.error.message} error={true} />
      {removeAccountModal && (
        <Overlay dismissHandler={() => setRemoveAccountModal(false)}>
          <Modal
            title={'配信者を削除しますか？'}
            dismissHandler={() => setRemoveAccountModal(false)}
          >
            <ModalButton
              color={'#FFFFFF'}
              textColor={'#828282'}
              label={'キャンセル'}
              onClickHandler={() => setRemoveAccountModal(false)}
            />
            <ModalButton
              color={'#E25047'}
              textColor={'#FFFFFF'}
              label={'削除'}
              onClickHandler={() => removeAccount()}
            />
          </Modal>
        </Overlay>
      )}
      <MainHeader heading={'配信者リスト'}>
        {responsive !== 'normal' && (
          <Button
            label={'新規追加'}
            textColor={'#FFFFFF'}
            disabledTextColor={'#FFFFFF'}
            color={'#17DBC9'}
            disabledColor={'#E0E0E0'}
            disabled={false}
            onClickHandler={() => history.push('/contributors/new')}
          />
        )}
      </MainHeader>
      <MainTable
        columns={{
          scale: '20% 50% 30%',
          items: [
            { label: 'アカウント名', grid: '1 / 2' },
            { label: '', grid: '1 / 2' },
            { label: '登録者数', grid: '2 / 3' },
          ],
        }}
        items={contributorsState.contributors.data.map(
          (contributor: Contributor) => {
            return {
              id: contributor.id,
              data: {
                icon: {
                  id: contributor.id,
                  value: contributor.icon,
                  type: 'IMAGE',
                  clickable: false,
                  subLabel: false,
                  grid: '1 / 2',
                },
                name: {
                  id: contributor.id,
                  value: contributor.name,
                  clickable: true,
                  subLabel: false,
                  grid: '2 / 3',
                },
                subscribers: {
                  id: contributor.id,
                  value:
                    responsive === 'normal'
                      ? contributor.subscribersCount
                      : `登録者数: ${contributor.subscribersCount}`,
                  clickable: false,
                  subLabel: true,
                  grid: '3 / 4',
                },
              },
            }
          }
        )}
        selectItemHandler={(contributorId: number) =>
          history.push(`/contributors/${contributorId}/messages`)
        }
        option={{
          svg: Bucket,
          clickHandler: (targetId: number) =>
            showRemoveAccountModalHandler(targetId),
        }}
      >
        <Pagination
          meta={contributorsState.contributors.meta!}
          dispatchedActions={getContributors}
          actionArguments={{}}
        />
      </MainTable>
    </>
  )
}

export default Accounts
