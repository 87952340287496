import { MembershipsAction } from 'actions/memberships'
import * as ActionType from 'actions/memberships/types'
import { MembershipsState } from 'models/memberships'

export const initialState: MembershipsState = {
  memberships: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  membership: undefined,
  membershipBirthdays: {
    data: [],
    meta: {
      currentPage: 0,
      nextPage: 0,
      prevPage: 0,
      totalCount: 0,
      totalPages: 0,
    },
  },
  status: {
    startedGetMemberships: false,
    succeededGetMemberships: false,
    failedGetMemberships: false,
    startedGetMembership: false,
    succeededGetMembership: false,
    failedGetMembership: false,
    startedGetMembershipBirthdays: false,
    succeededGetMembershipBirthdays: false,
    failedGetMembershipBirthdays: false,
  },
  success: {
    message: '',
  },
  error: {
    message: '',
  },
}

const membershipsReducer = (
  state: MembershipsState = initialState,
  action: MembershipsAction
): MembershipsState => {
  switch (action.type) {
    case ActionType.STARTED_GET_MEMBERSHIPS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMemberships: true,
          succeededGetMemberships: false,
          failedGetMemberships: false,
        },
      }
    case ActionType.SUCCEEDED_GET_MEMBERSHIPS:
      return {
        ...state,
        memberships: action.payload.memberships,
        status: {
          ...state.status,
          startedGetMemberships: false,
          succeededGetMemberships: true,
          failedGetMemberships: false,
        },
      }
    case ActionType.FAILED_GET_MEMBERSHIPS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMemberships: false,
          succeededGetMemberships: false,
          failedGetMemberships: true,
        },
      }
    case ActionType.STARTED_GET_MEMBERSHIP:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMembership: true,
          succeededGetMembership: false,
          failedGetMembership: false,
        },
      }
    case ActionType.SUCCEEDED_GET_MEMBERSHIP:
      return {
        ...state,
        membership: action.payload.membership,
        status: {
          ...state.status,
          startedGetMembership: false,
          succeededGetMembership: true,
          failedGetMembership: false,
        },
      }
    case ActionType.FAILED_GET_MEMBERSHIP:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMembership: false,
          succeededGetMembership: false,
          failedGetMembership: true,
        },
      }
    case ActionType.STARTED_GET_MEMBERSHIP_BIRTHDAYS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMembershipBirthdays: true,
          succeededGetMembershipBirthdays: false,
          failedGetMembershipBirthdays: false,
        },
      }
    case ActionType.SUCCEEDED_GET_MEMBERSHIP_BIRTHDAYS:
      return {
        ...state,
        membershipBirthdays: action.payload.membershipBirthdays,
        status: {
          ...state.status,
          startedGetMembershipBirthdays: false,
          succeededGetMembershipBirthdays: true,
          failedGetMembershipBirthdays: false,
        },
      }
    case ActionType.FAILED_GET_MEMBERSHIP_BIRTHDAYS:
      return {
        ...state,
        status: {
          ...state.status,
          startedGetMembershipBirthdays: false,
          succeededGetMembershipBirthdays: false,
          failedGetMembershipBirthdays: true,
        },
      }
    default:
      return state
  }
}

export default membershipsReducer
