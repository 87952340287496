import { useValidation, ValidationValue } from 'hooks/useValidation'
import { MessageValidations } from 'models/messages'
import moment from 'moment'

export function useValidations(): [
  validations: MessageValidations,
  setValidation: (style: string, value?: ValidationValue) => void
] {
  const [messageTextValidation, useMessageTextValidation] = useValidation({
    type: 'message_text',
    value: '',
  })
  const [messageImageValidation, useMessageImageValidation] = useValidation({
    type: 'message_image',
    value: null,
  })
  const [messageVideoValidation, useMessageVideoValidation] = useValidation({
    type: 'message_video',
    value: null,
  })
  const [messageVoiceValidation, useMessageVoiceValidation] = useValidation({
    type: 'message_voice',
    value: null,
  })
  const [messageDatetimeValidation, useMessageDatetimeValidation] =
    useValidation({
      type: 'message_datetime',
      value: moment(new Date()).format('YYYY/MM/DD HH:mm'),
    })

  const setValidation = (type: string, value: ValidationValue) => {
    switch (type) {
      case 'message_text':
        useMessageTextValidation(type, value)
        break
      case 'message_image':
        useMessageImageValidation(type, value)
        break
      case 'message_video':
        useMessageVideoValidation(type, value)
        break
      case 'message_voice':
        useMessageVoiceValidation(type, value)
        break
      case 'message_datetime':
        useMessageDatetimeValidation(type, value)
        break
    }
  }

  return [
    {
      messageTextValidation,
      messageImageValidation,
      messageVideoValidation,
      messageVoiceValidation,
      messageDatetimeValidation,
    },
    setValidation,
  ]
}
