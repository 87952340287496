import { Tags } from 'models/tags'
import * as ActionType from 'actions/tags/types'

export const getTags = {
  started: () => ({
    type: ActionType.STARTED_GET_TAGS as typeof ActionType.STARTED_GET_TAGS,
  }),
  succeeded: (response: { tags: Tags; message: string }) => ({
    type: ActionType.SUCCEEDED_GET_TAGS as typeof ActionType.SUCCEEDED_GET_TAGS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_TAGS as typeof ActionType.FAILED_GET_TAGS,
    payload: response,
  }),
}

export const createTag = {
  started: (request: {
    tagLabel: string
    tagBGColor: string
    tagLetterColor: string
  }) => ({
    type: ActionType.STARTED_CREATE_TAG as typeof ActionType.STARTED_CREATE_TAG,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_CREATE_TAG as typeof ActionType.SUCCEEDED_CREATE_TAG,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CREATE_TAG as typeof ActionType.FAILED_CREATE_TAG,
    payload: response,
  }),
}

export const deleteTag = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_DELETE_TAG as typeof ActionType.STARTED_DELETE_TAG,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_DELETE_TAG as typeof ActionType.SUCCEEDED_DELETE_TAG,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_DELETE_TAG as typeof ActionType.FAILED_DELETE_TAG,
    payload: response,
  }),
}

export const updateTagManagement = {
  started: (request: { contributorId: number; tags: number[] }) => ({
    type: ActionType.STARTED_UPDATE_TAG_MANAGEMENT as typeof ActionType.STARTED_UPDATE_TAG_MANAGEMENT,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_TAG_MANAGEMENT as typeof ActionType.SUCCEEDED_UPDATE_TAG_MANAGEMENT,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_TAG_MANAGEMENT as typeof ActionType.FAILED_UPDATE_TAG_MANAGEMENT,
    payload: response,
  }),
}

export const resetAllStatusFlags = {
  started: () => ({
    type: ActionType.STARTED_RESET_ALL_STATUS_FLAGS as typeof ActionType.STARTED_RESET_ALL_STATUS_FLAGS,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS as typeof ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_ALL_STATUS_FLAGS as typeof ActionType.FAILED_RESET_ALL_STATUS_FLAGS,
  }),
}

export type TagsAction =
  | ReturnType<typeof getTags.started>
  | ReturnType<typeof getTags.succeeded>
  | ReturnType<typeof getTags.failed>
  | ReturnType<typeof createTag.started>
  | ReturnType<typeof createTag.succeeded>
  | ReturnType<typeof createTag.failed>
  | ReturnType<typeof deleteTag.started>
  | ReturnType<typeof deleteTag.succeeded>
  | ReturnType<typeof deleteTag.failed>
  | ReturnType<typeof updateTagManagement.started>
  | ReturnType<typeof updateTagManagement.succeeded>
  | ReturnType<typeof updateTagManagement.failed>
  | ReturnType<typeof resetAllStatusFlags.started>
  | ReturnType<typeof resetAllStatusFlags.succeeded>
  | ReturnType<typeof resetAllStatusFlags.failed>
