import { useStyles } from 'hooks/useStyles'
import { useTextParse } from 'components/modules/preview/hooks/use_text_parse'

type Props = {
  type: string
  newMessage?: string
  image?: string
  video?: string
  voice?: string
}

const projectColor = process.env.REACT_APP_PROJECT_BASE_COLOR!

const MessageBubble: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/message_bubble',
  })

  const [parseText] = useTextParse()

  if (!styles) return <></>

  return (
    <div className={styles.default.message_bubble_component}>
      <div className={styles.default.detail}>
        {props.type === 'text' &&
          props.newMessage &&
          props.newMessage.length > 0 && (
            <div
              className={styles.default.text_content}
              style={{
                background: `#${projectColor}`,
              }}
            >
              <pre
                dangerouslySetInnerHTML={{
                  __html: parseText(props.newMessage),
                }}
              ></pre>
            </div>
          )}
        {props.type === 'image' && (
          <img src={props.image} className={styles.default.image_content} />
        )}
        {props.type === 'video' && (
          <video
            src={props.video}
            controls
            className={styles.default.video_content}
          />
        )}
      </div>
      {props.type === 'voice' && <audio src={props.voice} controls />}
    </div>
  )
}

export default MessageBubble
