import heic2any from 'heic2any'
import { AudioVisualMessage } from 'models/messages'
import React from 'react'
import { typeGuard } from 'utils/type_guard'

const heicToJpg = async (
  blob: File,
  setImagePreview: React.Dispatch<string>
): Promise<void> => {
  await heic2any({
    blob: blob,
    toType: 'image/jpeg',
    quality: 1,
  }).then((result: Blob | Blob[]) => {
    if (!typeGuard<Blob>(result)) return
    const jpgImg = URL.createObjectURL(result)
    setImagePreview(jpgImg)
  })
}

const heicToJpgV2 = async (blob: File): Promise<string | undefined> => {
  return await heic2any({
    blob: blob,
    toType: 'image/jpeg',
    quality: 1,
  }).then((result: Blob | Blob[]) => {
    if (!typeGuard<Blob>(result)) return
    return URL.createObjectURL(result)
  })
}

export const generateUrlFromFile = (
  audioVisualMessage: AudioVisualMessage,
  setImagePreview: React.Dispatch<string>,
  setVideoPreview: React.Dispatch<string>,
  setVoicePreview: React.Dispatch<string>
): void => {
  if (audioVisualMessage && audioVisualMessage.blob) {
    if (audioVisualMessage.type === 'image') {
      const heic = audioVisualMessage.blob.name.match(/\.(heic|HEIC)$/)

      if (heic) {
        heicToJpg(audioVisualMessage.blob, setImagePreview)
      } else {
        const imgUrl = URL.createObjectURL(audioVisualMessage.blob)
        setImagePreview(imgUrl)
      }
    } else {
      const blobUrl = URL.createObjectURL(audioVisualMessage.blob)
      if (audioVisualMessage.type === 'video') setVideoPreview(blobUrl)
      if (audioVisualMessage.type === 'voice') setVoicePreview(blobUrl)
    }
  }
}

export const generateUrlFromFileV2 = (audioVisualMessage: {
  type: string
  blob?: File
}): string | undefined => {
  if (audioVisualMessage && audioVisualMessage.blob) {
    if (audioVisualMessage.type === 'image') {
      const heic = audioVisualMessage.blob.name.match(/\.(heic|HEIC)$/)

      if (heic) {
        const a = heicToJpgV2(audioVisualMessage.blob) as unknown
        const b = a as string
        return b
      } else {
        const imgUrl = URL.createObjectURL(audioVisualMessage.blob)
        return imgUrl
      }
    } else {
      const blobUrl = URL.createObjectURL(audioVisualMessage.blob)
      return blobUrl
    }
  }
}

export const generateFileFromUrl = async (
  url: string,
  filename: string,
  type: string
): Promise<AudioVisualMessage> => {
  const blob = await (await fetch(url)).blob()

  const file = new File([blob], filename, {
    type: blob.type,
  })

  const audioVisual: AudioVisualMessage = { type: type, blob: file }

  return audioVisual
}
