import * as ActionType from 'actions/loaders/types'

export const setLoader = {
  started: () => ({
    type: ActionType.SET_LOADER as typeof ActionType.SET_LOADER,
  }),
}

export const resetLoader = {
  started: () => ({
    type: ActionType.RESET_LOADER as typeof ActionType.RESET_LOADER,
  }),
}

export type LoadersAction =
  | ReturnType<typeof setLoader.started>
  | ReturnType<typeof resetLoader.started>
