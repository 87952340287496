import React from 'react'

const ErrorText: React.FC<{
  message: string
  styles: { default: { readonly [key: string]: string } }
}> = ({ message, styles }) => (
  <>
    {message.length > 0 && (
      <div className={styles.default.error_text_wrapper}>
        <div className={styles.default.error_text}>
          <p>{message}</p>
        </div>
      </div>
    )}
  </>
)

export default ErrorText
