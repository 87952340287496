import { useStyles } from 'hooks/useStyles'

type Props = {
  label: string
  defaultValue: string
  updateHandler: (value: string) => void
  password?: boolean
  textarea?: boolean
  wordCount?: { max: number; current: number }
  placeHolder?: string
  validation: { message: string; type: string }
}

const TextInput: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/text_input',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.text_input_component}>
      <div className={styles.default.input_label}>
        <span>{props.label}</span>
      </div>
      <div className={styles.default.input_field}>
        {props.textarea ? (
          <textarea
            style={{
              backgroundColor:
                props.validation.type === 'error' ? '#FDF1F1' : '#FFFFFF',
            }}
            value={props.defaultValue}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.updateHandler(event.target.value)}
          />
        ) : (
          <input
            style={{
              backgroundColor:
                props.validation.type === 'error' ? '#FDF1F1' : '#FFFFFF',
            }}
            value={props.defaultValue || ''}
            type={props.password ? 'password' : 'text'}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => props.updateHandler(event.target.value)}
          />
        )}
        {props.wordCount && (
          <div className={styles.default.word_count}>
            <span
              style={{
                color:
                  props.validation.type === 'error' ? '#E25047' : '#828282',
              }}
            >
              {props.wordCount.current}
            </span>
            /<span>{props.wordCount.max}</span>
          </div>
        )}
      </div>
      {props.validation.type === 'error' && (
        <div className={styles.default.validation_message}>
          <div className={styles.default.padding} />
          <span>{props.validation.message}</span>
        </div>
      )}
    </div>
  )
}

export default TextInput
