import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getContributor } from 'actions/contributors'
import { CombineReducers } from 'models'
import { SessionsState } from 'models/sessions'
import { ContributorsState } from 'models/contributors'
import MainHeader from 'components/modules/main_header'
import MainTable from 'components/modules/main_table'
import Button from 'components/modules/button'
import Toast from 'components/modules/toast'
import { ReactComponent as Create } from 'images/create.svg'
import { ReactComponent as Bucket } from 'images/bucket.svg'
import { useStyles } from 'hooks/useStyles'
import { validateSession } from 'utils/session'
import Pagination from 'components/modules/pagination'
import { useContributor } from 'hooks/useContributor'
import { ToastsState } from 'models/toasts'
import {
  deleteDigitalContent,
  getDigitalContents,
} from 'actions/digital_contents'
import { DigitalContent, DigitalContentsState } from 'models/digital_contents'
import Overlay from 'components/modules/overlay'
import Modal from 'components/modules/modal'
import ModalButton from 'components/modules/modal/components/button'

const VoiceContents: React.FC = () => {
  const [styles, setStyles] = useStyles({
    page: 'components/messages/list',
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams<{
    id: string
  }>()

  const sessionsState: SessionsState = useSelector((state: CombineReducers) => {
    return state.sessions
  })
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )
  const digitalContentsState: DigitalContentsState = useSelector(
    (state: CombineReducers) => {
      return state.digitalContents
    }
  )
  const toastsState: ToastsState = useSelector((state: CombineReducers) => {
    return state.toasts
  })

  const [user, contributor] = useContributor({ queryString: params })

  const [removeContentModal, setRemoveContentModal] = useState<boolean>(false)
  const [removeTarget, setRemoveTarget] = useState<number>()

  const selectItemHandler = (digitalContentId: number) => {
    history.push(
      `/contributors/${params.id}/voice_contents/${digitalContentId}`
    )
  }

  const showRemoveContentModalHandler = (targetId: number) => {
    setRemoveContentModal(true)
    setRemoveTarget(targetId)
  }

  useEffect(() => {
    if (!validateSession()) history.push('/')
  }, [])

  useEffect(() => {
    if (sessionsState.currentUser) {
      dispatch(
        getContributor.started({
          id:
            sessionsState.currentUser.authType === 'Administrator'
              ? parseInt(params.id)
              : sessionsState.currentUser.contributor.id,
        })
      )
    }
  }, [location.pathname, sessionsState.status.succeededGetCurrentUser])

  useEffect(() => {
    if (contributorsState.currentContributor) {
      dispatch(
        getDigitalContents.started({
          page: 1,
          contributorId: contributorsState.currentContributor.id,
        })
      )
    }
  }, [
    contributorsState.status.succeededGetContributor,
    digitalContentsState.status.succeededCreateDigitalContent,
    digitalContentsState.status.succeededDeleteDigitalContent,
  ])

  if (!styles || !user || !contributor) return <></>

  return (
    <>
      <Toast body={toastsState.success.message} />
      <Toast body={toastsState.error.message} error />
      {removeContentModal && (
        <Overlay dismissHandler={() => setRemoveContentModal(false)}>
          <Modal
            title={'ボイスコンテンツを削除しますか？'}
            dismissHandler={() => setRemoveContentModal(false)}
          >
            <ModalButton
              color={'#FFFFFF'}
              textColor={'#828282'}
              label={'キャンセル'}
              onClickHandler={() => setRemoveContentModal(false)}
            />
            <ModalButton
              color={'#E25047'}
              textColor={'#FFFFFF'}
              label={'削除'}
              onClickHandler={() => {
                setRemoveContentModal(false)
                if (removeTarget) {
                  dispatch(deleteDigitalContent.started({ id: removeTarget }))
                }
              }}
            />
          </Modal>
        </Overlay>
      )}
      <MainHeader heading={'ボイスコンテンツ'}>
        <Button
          label={'新規作成'}
          icon={Create}
          textColor={'#FFFFFF'}
          disabledTextColor={'#FFFFFF'}
          color={'#17DBC9'}
          disabledColor={'#E0E0E0'}
          disabled={false}
          onClickHandler={() =>
            history.push(`/contributors/${params.id}/voice_contents/new`)
          }
        />
      </MainHeader>
      <MainTable
        columns={{
          scale: '40% 30% 30%',
          items: [
            { label: 'タイトル', grid: '1 / 2' },
            { label: 'QR', grid: '3 / 4' },
            { label: '公開日時', grid: '4 / 5' },
          ],
        }}
        items={
          digitalContentsState.digitalContents.data.map(
            (digitalContent: DigitalContent) => ({
              id: digitalContent.id,
              data: {
                title: {
                  id: digitalContent.id,
                  value: digitalContent.title,
                  clickable: true,
                  subLabel: false,
                  grid: '1 / 2',
                },
                qr: {
                  id: digitalContent.id,
                  value: String(digitalContent.qrContent),
                  clickable: false,
                  subLabel: false,
                  grid: '2 / 3',
                },
                releasedAt: {
                  id: digitalContent.id,
                  value: digitalContent.releasedAt,
                  clickable: false,
                  subLabel: true,
                  grid: '3 / 4',
                },
              },
            })
          ) || []
        }
        selectItemHandler={selectItemHandler}
        option={{
          svg: Bucket,
          clickHandler: (targetId: number) =>
            showRemoveContentModalHandler(targetId),
        }}
      >
        <Pagination
          meta={digitalContentsState.digitalContents.meta!}
          dispatchedActions={getDigitalContents}
          actionArguments={{
            contributorId: contributor.id,
          }}
        />
      </MainTable>
    </>
  )
}

export default VoiceContents
