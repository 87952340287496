import { MaintenanceWindows } from 'models/maintenances'
import * as ActionType from './types'

export const getAppMaintenanceWindows = {
  started: () => ({
    type: ActionType.STARTED_GET_APP_MAINTENANCE_WINDOWS as typeof ActionType.STARTED_GET_APP_MAINTENANCE_WINDOWS,
  }),
  succeeded: (response: {
    maintenance_windows: MaintenanceWindows
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_APP_MAINTENANCE_WINDOWS as typeof ActionType.SUCCEEDED_GET_APP_MAINTENANCE_WINDOWS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_APP_MAINTENANCE_WINDOWS as typeof ActionType.FAILED_GET_APP_MAINTENANCE_WINDOWS,
    payload: response,
  }),
}

export const getCMSMaintenanceWindows = {
  started: () => ({
    type: ActionType.STARTED_GET_CMS_MAINTENANCE_WINDOWS as typeof ActionType.STARTED_GET_CMS_MAINTENANCE_WINDOWS,
  }),
  succeeded: (response: {
    maintenance_windows: MaintenanceWindows
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_CMS_MAINTENANCE_WINDOWS as typeof ActionType.SUCCEEDED_GET_CMS_MAINTENANCE_WINDOWS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_CMS_MAINTENANCE_WINDOWS as typeof ActionType.FAILED_GET_CMS_MAINTENANCE_WINDOWS,
    payload: response,
  }),
}

export const createMaintenanceWindow = {
  started: (request: {
    title: string
    body: string
    platform: string
    windowStart: string
    windowEnd: string
    announcedAt: string
  }) => ({
    type: ActionType.STARTED_CREATE_MAINTENANCE_WINDOW as typeof ActionType.STARTED_CREATE_MAINTENANCE_WINDOW,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_CREATE_MAINTENANCE_WINDOW as typeof ActionType.SUCCEEDED_CREATE_MAINTENANCE_WINDOW,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CREATE_MAINTENANCE_WINDOW as typeof ActionType.FAILED_CREATE_MAINTENANCE_WINDOW,
    payload: response,
  }),
}

export const deleteMaintenanceWindow = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_DELETE_MAINTENANCE_WINDOW as typeof ActionType.STARTED_DELETE_MAINTENANCE_WINDOW,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_DELETE_MAINTENANCE_WINDOW as typeof ActionType.SUCCEEDED_DELETE_MAINTENANCE_WINDOW,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_DELETE_MAINTENANCE_WINDOW as typeof ActionType.FAILED_DELETE_MAINTENANCE_WINDOW,
    payload: response,
  }),
}

export const resetMessages = {
  started: () => ({
    type: ActionType.STARTED_RESET_MESSAGES as typeof ActionType.STARTED_RESET_MESSAGES,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_MESSAGES as typeof ActionType.SUCCEEDED_RESET_MESSAGES,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_MESSAGES as typeof ActionType.FAILED_RESET_MESSAGES,
  }),
}

export const resetAllStatusFlags = {
  started: () => ({
    type: ActionType.STARTED_RESET_ALL_STATUS_FLAGS as typeof ActionType.STARTED_RESET_ALL_STATUS_FLAGS,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS as typeof ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_ALL_STATUS_FLAGS as typeof ActionType.FAILED_RESET_ALL_STATUS_FLAGS,
  }),
}

export type MaintenancesAction =
  | ReturnType<typeof getAppMaintenanceWindows.started>
  | ReturnType<typeof getAppMaintenanceWindows.succeeded>
  | ReturnType<typeof getAppMaintenanceWindows.failed>
  | ReturnType<typeof getCMSMaintenanceWindows.started>
  | ReturnType<typeof getCMSMaintenanceWindows.succeeded>
  | ReturnType<typeof getCMSMaintenanceWindows.failed>
  | ReturnType<typeof createMaintenanceWindow.started>
  | ReturnType<typeof createMaintenanceWindow.succeeded>
  | ReturnType<typeof createMaintenanceWindow.failed>
  | ReturnType<typeof deleteMaintenanceWindow.started>
  | ReturnType<typeof deleteMaintenanceWindow.succeeded>
  | ReturnType<typeof deleteMaintenanceWindow.failed>
  | ReturnType<typeof resetMessages.started>
  | ReturnType<typeof resetMessages.succeeded>
  | ReturnType<typeof resetMessages.failed>
  | ReturnType<typeof resetAllStatusFlags.started>
  | ReturnType<typeof resetAllStatusFlags.succeeded>
  | ReturnType<typeof resetAllStatusFlags.failed>
