export const passwordValidation = (value: string) => {
  let message = 'パスワードの入力値は正常です'
  let type = 'succeeded'
  if (value.length === 0) {
    message = 'パスワードを入力してください'
    type = 'error'
    return { message, type }
  }
  if (
    value.length < 8 ||
    !value.match(/[0-9]+/g) ||
    !value.match(/[a-zA-Z]+/g)
  ) {
    message = '英数字を含む8文字以上のパスワードを入力してください'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
