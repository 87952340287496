import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useResponsive } from 'hooks/useResponsive'
import { useValidation } from 'hooks/useValidation'
import { createContributor } from 'actions/contributors'
import MainHeader from 'components/modules/main_header'
import MainForm from 'components/modules/main_form'
import TextInput from 'components/modules/main_form/components/text_input'
import SPButton from 'components/modules/main_form/components/button'
import SPButtonSpacer from 'components/modules/main_form/components/button/components/spacer'
import SPButtonWrapper from 'components/modules/main_form/components/button_wrapper'
import Button from 'components/modules/button'
import ButtonSpacer from 'components/modules/button/components/spacer'
import { ReactComponent as AccountRegistration } from 'images/account.svg'

const Account: React.FC = () => {
  const [responsive] = useResponsive()
  const dispatch = useDispatch()
  const history = useHistory()

  const [contributorName, setContributorName] = useState<string>('')
  const [contributorNameValidation, setContributorNameValidation] =
    useValidation({
      type: 'contributor_name',
      value: contributorName,
    })
  const contributorNameHandler = (value: string) => {
    setContributorName(value)
    setContributorNameValidation('contributor_name', value)
  }

  const [contributorEnglishName, setContributorEnglishName] =
    useState<string>('')
  const [
    contributorEnglishNameValidation,
    setContributorEnglishNameValidation,
  ] = useValidation({
    type: 'contributor_name',
    value: contributorEnglishName,
  })
  const contributorEnglishNameHandler = (value: string) => {
    setContributorEnglishName(value)
    setContributorEnglishNameValidation('contributor_name', value)
  }

  const [email, setEmail] = useState<string>('')
  const [emailValidation, setEmailValidation] = useValidation({
    type: 'email',
    value: email,
  })
  const emailHandler = (value: string) => {
    setEmail(value)
    setEmailValidation('email', value)
  }

  const [password, setPassword] = useState<string>('')
  const [passwordValidation, SetPasswordValidation] = useValidation({
    type: 'password',
    value: password,
  })
  const passwordHandler = (value: string) => {
    setPassword(value)
    SetPasswordValidation('password', value)
  }

  const [confirmationPassword, setConfirmationPassword] = useState<string>('')
  const [confirmationPasswordValidation, setConfirmationPasswordValidation] =
    useValidation({
      type: 'password',
      value: confirmationPassword,
    })
  const confirmationPasswordHandler = (value: string) => {
    setConfirmationPassword(value)
    setConfirmationPasswordValidation('password', value)
  }

  const [activeRegistrationButton, setActiveRegistrationButton] =
    useState<boolean>(false)

  const registrationButtonChecker = () => {
    if (
      contributorNameValidation.type === 'error' ||
      emailValidation.type === 'error' ||
      passwordValidation.type === 'error' ||
      confirmationPasswordValidation.type === 'error'
    ) {
      setActiveRegistrationButton(false)
      return
    }
    setActiveRegistrationButton(true)
  }

  const registrationHandler = () => {
    dispatch(
      createContributor.started({
        accountType: 'contributor',
        email,
        password,
        name: contributorName,
        englishName: contributorEnglishName,
      })
    )

    history.push('/contributors')
  }

  useEffect(() => {
    registrationButtonChecker()
  }, [
    contributorNameValidation,
    contributorEnglishNameValidation,
    emailValidation,
    passwordValidation,
    confirmationPasswordValidation,
  ])

  return (
    <>
      <MainHeader heading={'アカウントの新規登録'}>
        {responsive === 'normal' && (
          <>
            <Button
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid rgb(224, 224, 224)'}
              disabled={false}
              onClickHandler={() => history.push('/contributors')}
            />
            <ButtonSpacer />
            <Button
              label={'登録する'}
              icon={AccountRegistration}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!activeRegistrationButton}
              onClickHandler={() => registrationHandler()}
            />
          </>
        )}
      </MainHeader>
      <MainForm>
        <TextInput
          label={'アカウント名'}
          defaultValue={contributorName}
          updateHandler={(value: string) => contributorNameHandler(value)}
          wordCount={{
            max: 20,
            current: contributorName ? contributorName.length : 0,
          }}
          validation={contributorNameValidation}
        />
        <TextInput
          label={'アカウント英語名'}
          defaultValue={contributorEnglishName}
          updateHandler={(value: string) =>
            contributorEnglishNameHandler(value)
          }
          wordCount={{
            max: 50,
            current: contributorEnglishName ? contributorEnglishName.length : 0,
          }}
          validation={contributorEnglishNameValidation}
        />
        <TextInput
          label={'メールアドレス'}
          defaultValue={email}
          updateHandler={(value: string) => emailHandler(value)}
          validation={emailValidation}
        />
        <TextInput
          label={'パスワード'}
          defaultValue={password}
          updateHandler={(value: string) => passwordHandler(value)}
          validation={passwordValidation}
          password
        />
        <TextInput
          label={'パスワード（確認）'}
          defaultValue={confirmationPassword}
          updateHandler={(value: string) => confirmationPasswordHandler(value)}
          validation={
            password === confirmationPassword
              ? confirmationPasswordValidation
              : {
                  message: 'パスワードが一致しません',
                  type: 'error',
                }
          }
          password
        />
        {(responsive === 'small' || responsive === 'medium') && (
          <SPButtonWrapper>
            <SPButton
              label={'キャンセル'}
              textColor={'#828282'}
              color={'#FFFFFF'}
              border={'2px solid #E0E0E0'}
              disabled={false}
              onClickHandler={() => history.push('/contributors')}
            />
            <SPButtonSpacer />
            <SPButton
              label={'追加する'}
              textColor={'#FFFFFF'}
              disabledTextColor={'#FFFFFF'}
              color={'#17DBC9'}
              disabledColor={'#E0E0E0'}
              disabled={!activeRegistrationButton}
              onClickHandler={registrationHandler}
            />
          </SPButtonWrapper>
        )}
      </MainForm>
    </>
  )
}

export default Account
