import React from 'react'

import { useMediaQuery } from 'react-responsive'

import DeleteModal from './DeleteModal'

const DetailHeader: React.FC<{ deleteHandler: any; targetId: number }> = ({
  deleteHandler,
  targetId,
}) => {
  const isSmartPhone = useMediaQuery({ query: '(max-width: 1023px)' })

  return (
    <div className="messages_detail_header_wrapper">
      <p>メッセージ配信</p>
      <DeleteModal
        trigger={
          <div className="messages_detail_header_btns_wrapper">
            <button
              className="button_module"
              style={{
                width: isSmartPhone ? '100px' : '160px',
                height: '44px',
                border: '2px solid #E25047',
                backgroundColor: '#E25047',
                color: '#FFFFFF',
              }}
            >
              <div className="button_module_content_wrapper">
                <span>削除</span>
              </div>
            </button>
          </div>
        }
        deleteHandler={deleteHandler}
        targetId={targetId}
      />
    </div>
  )
}

export default DetailHeader
