import { useStyles } from 'hooks/useStyles'
import { ContributorMessage } from 'models/messages'
import { useSelector } from 'react-redux'
import { CombineReducers } from 'models'
import { Contributor, ContributorsState } from 'models/contributors'
import { AudioVisualMessage, ResponseMessage } from 'models/messages'
import MessageSuite from 'components/modules/preview/components/message_suite'
import MessageBubble from 'components/modules/preview/components/message_bubble'
import MessageDateBar from 'components/modules/preview/components/message_date_bar'

type Props = {
  contributor: Contributor
  messageText: string
  messages: ContributorMessage[]
  dateList: string[]
  sp: boolean
  immediate: boolean
  reservation: boolean
  eventBot: boolean
  directMessage: boolean
  audioVisualMessage?: AudioVisualMessage
  imagePreview?: string
  videoPreview?: string
  voicePreview?: string
  responseMessages?: ResponseMessage[]
}

const MessageList: React.FC<Props> = props => {
  const contributorsState: ContributorsState = useSelector(
    (state: CombineReducers) => {
      return state.contributors
    }
  )

  const [styles, setStyles] = useStyles({
    page: 'components/modules/preview/components/message_list',
  })

  if (!styles) return <></>

  return (
    <>
      {!props.eventBot &&
        props.messages.map((message: ContributorMessage, index: number) => (
          <div key={index}>
            {(props.immediate || props.reservation) && (
              <MessageDateBar
                index={index}
                message={message}
                messages={props.messages}
                dateList={props.dateList}
              />
            )}
            {(props.immediate || props.reservation) && (
              <>
                {props.sp ? (
                  <MessageSuite
                    contributor={props.contributor}
                    self={
                      message.contributor.name ===
                      contributorsState.currentContributor!.name
                    }
                    message={message}
                    newMessage={props.messageText}
                    subContent={true}
                    sp={props.sp}
                    immediate={props.immediate}
                    reservation={props.reservation}
                    eventBot={props.eventBot}
                    directMessage={props.directMessage}
                  />
                ) : (
                  <MessageSuite
                    contributor={props.contributor}
                    self={
                      message.contributor.name ===
                      contributorsState.currentContributor!.name
                    }
                    message={message}
                    newMessage={props.messageText}
                    subContent={true}
                    sp={props.sp}
                    immediate={props.immediate}
                    reservation={props.reservation}
                    eventBot={props.eventBot}
                    directMessage={props.directMessage}
                  />
                )}
              </>
            )}
            {props.directMessage && (
              <>
                {props.sp ? (
                  <MessageSuite
                    contributor={props.contributor}
                    self={
                      message.contributor.name ===
                      contributorsState.currentContributor!.name
                    }
                    message={message}
                    newMessage={props.messageText}
                    subContent={true}
                    sp={props.sp}
                    immediate={props.immediate}
                    reservation={props.reservation}
                    eventBot={props.eventBot}
                    directMessage={props.directMessage}
                  />
                ) : (
                  <MessageSuite
                    contributor={props.contributor}
                    self={
                      message.contributor.name ===
                      contributorsState.currentContributor!.name
                    }
                    message={message}
                    newMessage={props.messageText}
                    subContent={true}
                    sp={props.sp}
                    immediate={props.immediate}
                    reservation={props.reservation}
                    eventBot={props.eventBot}
                    directMessage={props.directMessage}
                  />
                )}
              </>
            )}
          </div>
        ))}
      {props.responseMessages && (
        <MessageSuite
          contributor={props.contributor}
          self={true}
          newMessage={props.messageText}
          subContent={false}
          sp={props.sp}
          immediate={props.immediate}
          reservation={props.reservation}
          eventBot={props.eventBot}
          directMessage={props.directMessage}
        />
      )}
      {(props.sp || !props.sp || props.eventBot) && (
        <div className={styles.default.current_messages}>
          <div className={styles.default.content_list}>
            <MessageBubble newMessage={props.messageText} type={'text'} />
            {props.audioVisualMessage &&
              props.audioVisualMessage.type === 'image' &&
              props.audioVisualMessage.blob && (
                <MessageBubble
                  newMessage={props.messageText}
                  type={'image'}
                  image={props.imagePreview}
                />
              )}
            {props.audioVisualMessage &&
              props.audioVisualMessage.type === 'video' &&
              props.audioVisualMessage.blob && (
                <MessageBubble
                  newMessage={props.messageText}
                  type={'video'}
                  video={props.videoPreview}
                />
              )}
            {props.audioVisualMessage &&
              props.audioVisualMessage.type === 'voice' &&
              props.audioVisualMessage.blob && (
                <MessageBubble
                  newMessage={props.messageText}
                  type={'voice'}
                  voice={props.voicePreview}
                />
              )}
          </div>
        </div>
      )}
    </>
  )
}

export default MessageList
