import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/sessions/types'
import { getCurrentUser } from 'actions/sessions'
import { User } from 'models/auth'
import { getCurrentUserFactory } from 'services/sessions/api'

function* runGetCurrentUser(action: ReturnType<typeof getCurrentUser.started>) {
  try {
    const result: User = yield call(getCurrentUserFactory())

    yield put(
      getCurrentUser.succeeded({
        user: result,
        message: 'ログインユーザー情報の取得に成功しました',
      })
    )
  } catch (err) {
    localStorage.removeItem('access-token')
    localStorage.removeItem('client')
    localStorage.removeItem('uid')
    localStorage.removeItem('project-id')

    yield put(
      getCurrentUser.failed({
        message: 'ログインユーザー情報の取得に失敗しました',
      })
    )
  }
}

export function* watchSessionsSaga() {
  yield takeLatest(Action.STARTED_GET_CURRENT_USER, runGetCurrentUser)
}
