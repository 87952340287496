import axios from 'axios'
import MainHeader from 'components/modules/main_header'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { DEFAULT_API_CONFIG } from 'models'
import { buildHeader } from 'services'
import paymentStatementDeserializer from 'deserializers/payment_statement'
import MainBody from 'components/modules/main_body'
import ItemRow from 'components/account_profiles/detail/components/item_row'
import { useStyles } from 'hooks/useStyles'
import { MembershipRevenue, PremiumChatRevenue } from 'models/financial_reports'
import OverlayLoader from 'components/modules/overlay/components/loader'

export default function PaymentStatementDetail() {
  const params = useParams<{
    financial_report_id: string
    payment_statement_id: string
  }>()
  const [styles, setStyles] = useStyles({
    page: 'components/payment_statements/detail',
  })

  const fetchPaymentStatement = useQuery('paymentStatements', async () => {
    const uri = `/api/cms/v1/vtuber_payment_statements/${params.payment_statement_id}`
    const instance = axios.create({
      ...DEFAULT_API_CONFIG,
    })
    const response = await instance.get(uri, {
      headers: buildHeader(),
    })

    return paymentStatementDeserializer({
      data: response.data.vtuber_payment_statement,
    })
  })

  const membershipPlanRevenue = (plan: string) => {
    return (
      <section className={styles!.default.membership_item}>
        <p>{plan}</p>
        <section className={styles!.default.membership_revenue}>
          {fetchPaymentStatement
            .data!.membershipRevenue.filter(
              (revenue: MembershipRevenue) => revenue.type === plan
            )
            .map((revenue: MembershipRevenue) => (
              <section
                key={`${revenue.type}-${revenue.interval}`}
                className={styles!.default.membership_plan_intervals}
              >
                <p>{revenue.interval}</p>
                <p>{revenue.count}</p>
                <p>{revenue.total.toLocaleString()}</p>
              </section>
            ))}
        </section>
      </section>
    )
  }

  if (!styles) return <></>

  if (fetchPaymentStatement.isLoading) return <OverlayLoader loading={true} />

  if (!fetchPaymentStatement.data) return <></>

  return (
    <>
      <MainHeader heading={'支払明細書'} />
      <MainBody>
        <ItemRow
          label={'請求書 PDF'}
          link={{
            name: '開く',
            path: fetchPaymentStatement.data.file,
          }}
        />
        <section className={styles.default.payment_statement_details}>
          <h1>内訳</h1>
          <section className={styles.default.payment_items}>
            <section className={styles.default.premium_chat_header}>
              <h2>プレミアムチャット</h2>
              <section className={styles.default.premium_chat_items_header}>
                <p>支払単価</p>
                <p>課金回数</p>
                <p>計</p>
              </section>
            </section>
            <section className={styles.default.premium_chat_types}>
              {fetchPaymentStatement.data.premiumChatRevenue.map(
                (revenue: PremiumChatRevenue) => (
                  <section
                    key={revenue.type}
                    className={styles.default.premium_chat_item}
                  >
                    <p>{revenue.type}</p>
                    <section className={styles.default.premium_chat_revenue}>
                      <p>{revenue.unitPrice.toLocaleString()}</p>
                      <p>{revenue.count}</p>
                      <p>{revenue.total}</p>
                    </section>
                  </section>
                )
              )}
            </section>
            <section className={styles.default.premium_chat_revenue_total}>
              <p>合計</p>
              <p>
                ¥
                {fetchPaymentStatement.data.premiumChatRevenueTotal.toLocaleString()}
              </p>
            </section>
          </section>
          <section className={styles.default.payment_items}>
            <section className={styles.default.membership_header}>
              <h2>メンバーシップ</h2>
              <section className={styles.default.membership_items_header}>
                <p>⚪︎ヶ月</p>
                <p>課金人数</p>
                <p>計</p>
              </section>
            </section>
            <section className={styles.default.membership_types}>
              {membershipPlanRevenue('ブロンズ')}
              {membershipPlanRevenue('シルバー')}
              {membershipPlanRevenue('ゴールド')}
            </section>
            <section className={styles.default.membership_revenue_total}>
              <p>合計</p>
              <p>
                ¥
                {fetchPaymentStatement.data.membershipRevenueTotal.toLocaleString()}
              </p>
            </section>
          </section>
          <p className={styles.default.caveat}>
            *nest
            加入者の場合はメンバーシップの収益金額区分ごとに料率が変化するため合計のみ料率を含めて算出しています。
          </p>
        </section>
      </MainBody>
    </>
  )
}
