import { MaintenanceWindows } from 'models/maintenances'

function maintenanceWindowsDeserializer(input: {
  data: any
  meta?: any
}): MaintenanceWindows {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      title: item.title,
      body: item.body,
      platform: item.platform,
      maintenanceDate: item.maintenance_date,
      maintenanceStartsAt: item.maintenance_starts_at,
      maintenanceEndsAt: item.maintenance_ends_at,
      maintenanceAnnouncedAt: item.maintenance_announced_at,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default maintenanceWindowsDeserializer
