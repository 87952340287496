import contributorDeserializer from 'deserializers/contributor'
import messageContentsDeserializer from 'deserializers/message_contents/many'
import { ContributorMessages } from 'models/messages'

function contributorMessagesDeserializer(input: {
  data: any
  meta?: any
}): ContributorMessages {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      text: item.text,
      messageType: item.message_type,
      sendingType: item.sending_type,
      premiumChatRequest: item.premium_chat_request,
      targetPlan: item.target_plan,
      runAt: item.run_at,
      createdAt: item.created_at,
      updatedAt: item.updated_at,
      messageContents: messageContentsDeserializer({
        data: item.message_contents,
      }),
      contributor: contributorDeserializer({ data: item.contributor }),
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default contributorMessagesDeserializer
