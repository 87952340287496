import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

export function useStyles(input: {
  page: string
}): [
  styles: { default: { readonly [key: string]: string } } | null,
  setStyles: (page: string) => void
] {
  const small = useMediaQuery({ query: '(max-width: 374px)' })
  const medium = useMediaQuery({ query: '(max-width: 1023px)' })

  const [defaultStyles, setDefaultStyles] = useState<{
    default: {
      readonly [key: string]: string
    }
  }>()
  const setStyles = async (page: string) => {
    let targetCssModule

    if (small) {
      targetCssModule = `${page}/index.small.module.scss`
    } else if (medium) {
      targetCssModule = `${page}/index.medium.module.scss`
    } else {
      targetCssModule = `${page}/index.module.scss`
    }

    await import(`../${targetCssModule}`).then(
      (module: { readonly [key: string]: string }) =>
        setDefaultStyles(module as any)
    )
  }

  useEffect(() => {
    setStyles(input.page)
  }, [small, medium])

  return [defaultStyles || null, setStyles]
}
