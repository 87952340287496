import { FinancialReport } from 'models/financial_reports'

function financialReportDeserializer(input: {
  data: any
  meta?: any
}): FinancialReport {
  return {
    id: input.data?.id,
    year: input.data?.year,
    month: input.data?.month,
    status: input.data?.status,
    createdAt: input.data?.created_at,
    updatedAt: input.data?.updated_at,
  }
}

export default financialReportDeserializer
