import { useStyles } from 'hooks/useStyles'

type Props = {
  label: string
}

const ItemLabel: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/account_profiles/detail/components/item_label',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.item_label_component}>
      <label>{props.label}</label>
    </div>
  )
}

export default ItemLabel
