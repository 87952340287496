import { useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import SelectRow from 'components/modules/main_form/components/select_input/components/select_row'

export type SelectItem = { label: string; value: string | number }
export type Selections = SelectItem[]

type Props = {
  label: string
  onChangeHandler: (
    item: SelectItem,
    previousValue: number | string | null
  ) => void
  onRemoveSelectableHandler?: (value: string) => void
  onRemoveSelectedHandler?: (value: string) => void
  appendSelectableHandler?: () => void
  selectableItems: Selections
  selectedItems?: Selections
  multiple?: boolean
  resettable?: boolean
  description?: string
}

const SelectInput: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/select_input',
  })

  if (!styles) return <></>

  return (
    <div className={styles.default.select_input_component}>
      <div className={styles.default.input_label}>{props.label}</div>
      <div className={styles.default.input_field}>
        {props.selectedItems && props.selectedItems.length > 0 ? (
          <>
            {props.selectedItems.map((item: SelectItem) => (
              <div key={item.value} className={styles.default.select_row}>
                <SelectRow
                  label={props.label}
                  onChangeHandler={props.onChangeHandler}
                  onRemoveSelectedHandler={(value: string) => {
                    props.onRemoveSelectedHandler &&
                      props.onRemoveSelectedHandler(value)
                  }}
                  selectableItems={props.selectableItems}
                  selectedItem={item}
                  multiple={props.multiple || false}
                  resettable={props.resettable}
                />
              </div>
            ))}
          </>
        ) : (
          <>
            {!props.multiple && (
              <div className={styles.default.select_row}>
                <SelectRow
                  label={props.label}
                  onChangeHandler={props.onChangeHandler}
                  selectableItems={props.selectableItems}
                  multiple={props.multiple || false}
                  resettable={props.resettable}
                />
              </div>
            )}
          </>
        )}
        {props.multiple && (
          <div className={styles.default.append_select_input}>
            <button
              onClick={() =>
                props.appendSelectableHandler && props.appendSelectableHandler()
              }
            >
              <span>{props.label}を追加</span>
            </button>
          </div>
        )}
        {props.description && (
          <div className={styles.default.description}>
            <span>{props.description}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectInput
