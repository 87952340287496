// import { Tags } from 'models/digital_contents'
import * as ActionType from 'actions/digital_contents/types'
import { DigitalContent, DigitalContents } from 'models/digital_contents'

export const getDigitalContents = {
  started: (request: { page: number; contributorId: number }) => ({
    type: ActionType.STARTED_GET_DIGITAL_CONTENTS as typeof ActionType.STARTED_GET_DIGITAL_CONTENTS,
    payload: request,
  }),
  succeeded: (response: {
    digitalContents: DigitalContents
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_DIGITAL_CONTENTS as typeof ActionType.SUCCEEDED_GET_DIGITAL_CONTENTS,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_DIGITAL_CONTENTS as typeof ActionType.FAILED_GET_DIGITAL_CONTENTS,
    payload: response,
  }),
}

export const getDigitalContent = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_GET_DIGITAL_CONTENT as typeof ActionType.STARTED_GET_DIGITAL_CONTENT,
    payload: request,
  }),
  succeeded: (response: {
    digitalContent: DigitalContent
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_DIGITAL_CONTENT as typeof ActionType.SUCCEEDED_GET_DIGITAL_CONTENT,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_DIGITAL_CONTENT as typeof ActionType.FAILED_GET_DIGITAL_CONTENT,
    payload: response,
  }),
}

export const createDigitalContent = {
  started: (request: { data: FormData }) => ({
    type: ActionType.STARTED_CREATE_DIGITAL_CONTENT as typeof ActionType.STARTED_CREATE_DIGITAL_CONTENT,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_CREATE_DIGITAL_CONTENT as typeof ActionType.SUCCEEDED_CREATE_DIGITAL_CONTENT,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CREATE_DIGITAL_CONTENT as typeof ActionType.FAILED_CREATE_DIGITAL_CONTENT,
    payload: response,
  }),
}

export const updateDigitalContent = {
  started: (request: { id: number; data: FormData }) => ({
    type: ActionType.STARTED_UPDATE_DIGITAL_CONTENT as typeof ActionType.STARTED_UPDATE_DIGITAL_CONTENT,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_DIGITAL_CONTENT as typeof ActionType.SUCCEEDED_UPDATE_DIGITAL_CONTENT,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_DIGITAL_CONTENT as typeof ActionType.FAILED_UPDATE_DIGITAL_CONTENT,
    payload: response,
  }),
}

export const deleteDigitalContent = {
  started: (request: { id: number }) => ({
    type: ActionType.STARTED_DELETE_DIGITAL_CONTENT as typeof ActionType.STARTED_DELETE_DIGITAL_CONTENT,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_DELETE_DIGITAL_CONTENT as typeof ActionType.SUCCEEDED_DELETE_DIGITAL_CONTENT,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_DELETE_DIGITAL_CONTENT as typeof ActionType.FAILED_DELETE_DIGITAL_CONTENT,
    payload: response,
  }),
}

export type DigitalContentsAction =
  | ReturnType<typeof getDigitalContents.started>
  | ReturnType<typeof getDigitalContents.succeeded>
  | ReturnType<typeof getDigitalContents.failed>
  | ReturnType<typeof getDigitalContent.started>
  | ReturnType<typeof getDigitalContent.succeeded>
  | ReturnType<typeof getDigitalContent.failed>
  | ReturnType<typeof createDigitalContent.started>
  | ReturnType<typeof createDigitalContent.succeeded>
  | ReturnType<typeof createDigitalContent.failed>
  | ReturnType<typeof updateDigitalContent.started>
  | ReturnType<typeof updateDigitalContent.succeeded>
  | ReturnType<typeof updateDigitalContent.failed>
  | ReturnType<typeof deleteDigitalContent.started>
  | ReturnType<typeof deleteDigitalContent.succeeded>
  | ReturnType<typeof deleteDigitalContent.failed>
