import { useDispatch } from 'react-redux'
import { DEFAULT_API_CONFIG } from 'models'
import axios from 'axios'
import { UseMutationResult, useMutation } from 'react-query'
import { setErrorToast, setSuccessToast } from 'actions/toasts'
import { useHistory } from 'react-router-dom'
import { setSessionTokens } from 'services'
import userDeserializer from 'deserializers/user'
import { User } from 'models/auth'
import { getCurrentUser } from 'actions/sessions'

export function useSignIn(input: {
  email?: string
  password?: string
}): [signIn: UseMutationResult<User, unknown, void, unknown>] {
  const dispatch = useDispatch()
  const history = useHistory()

  const signIn = useMutation(
    async () => {
      const instance = axios.create({
        ...DEFAULT_API_CONFIG,
      })
      const response = await instance.post('/api/cms/v1/auth/sign_in', {
        email: input.email,
        password: input.password,
      })

      setSessionTokens(
        response.headers['access-token'],
        response.headers['client'],
        response.headers['uid']
      )

      return userDeserializer({
        data: response.data.user,
      })
    },
    {
      onSuccess: (data: User) => {
        dispatch(setSuccessToast.started({ message: 'ログインに成功しました' }))
        dispatch(getCurrentUser.started())
        history.push(`/contributors/${data.contributor.id}/messages`)
      },
      onError: () => {
        dispatch(setErrorToast.started({ message: 'ログインに失敗しました' }))
      },
    }
  )

  return [signIn]
}
