import { useStyles } from 'hooks/useStyles'
import TextInput from 'components/modules/main_form/components/text_input'
import SVGWrapper from 'components/modules/svg_wrapper'
import { ReactComponent as Cross } from 'images/cross_black.svg'
import { useValidation } from 'hooks/useValidation'
import { EditableProfile } from 'models/contributors'

type Props = {
  profile: EditableProfile
  profileTitleHandler: (changedProfile: EditableProfile, value: string) => void
  profileDescriptionHandler: (
    changedProfile: EditableProfile,
    value: string
  ) => void
  removeProfileHandler: (profileId: number) => void
}

const InputItem: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/account_profiles/edit/components/profiles_input',
  })

  const [profileTitleValidation, useProfileTitleValidation] = useValidation({
    type: 'contributor_profile_title',
    value: props.profile.title,
  })

  const [profileDescriptionValidation, useProfileDescriptionValidation] =
    useValidation({
      type: 'contributor_profile_description',
      value: props.profile.description,
    })

  if (!styles) return <></>

  return (
    <div className={styles.default.profile}>
      <div className={styles.default.profile_inputs}>
        <div className={styles.default.input_header}>
          <SVGWrapper
            icon={Cross}
            width={'24'}
            height={'24'}
            pointer
            onClick={() => props.removeProfileHandler(props.profile.id)}
          />
        </div>
        <TextInput
          label={'タイトル'}
          defaultValue={props.profile.title}
          updateHandler={(value: string) => {
            useProfileTitleValidation('contributor_profile_title', value)
            props.profileTitleHandler(props.profile, value)
          }}
          wordCount={{
            max: 50,
            current: props.profile.title ? props.profile.title.length : 0,
          }}
          validation={profileTitleValidation}
        />
        <TextInput
          label={'説明文'}
          defaultValue={props.profile.description}
          updateHandler={(value: string) => {
            useProfileDescriptionValidation(
              'contributor_profile_description',
              value
            )
            props.profileDescriptionHandler(props.profile, value)
          }}
          wordCount={{
            max: 255,
            current: props.profile.description
              ? props.profile.description.length
              : 0,
          }}
          validation={profileDescriptionValidation}
        />
      </div>
    </div>
  )
}

export default InputItem
