import { PaginationMetaData } from 'models'
import ReactPaginate from 'react-paginate'
import { useDispatch } from 'react-redux'

type Props = {
  meta: PaginationMetaData
  dispatchedActions: {
    started: (request: any) => { type: string; payload: any }
    succeeded: (response: any) => { type: string; payload: any }
    failed: (response: any) => { type: string; payload: any }
  }
  actionArguments: any
}

const Pagination: React.FC<Props> = props => {
  const dispatch = useDispatch()

  const jumpToPage = (page: number) => {
    dispatch(
      props.dispatchedActions.started({
        page,
        ...props.actionArguments,
      })
    )
  }
  const prevLabel = () => {
    return (
      <div>
        <span>&lt;</span>
      </div>
    )
  }
  const nextLabel = () => {
    return (
      <div>
        <span>&gt;</span>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ReactPaginate
        pageCount={props.meta.totalPages}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        containerClassName={'pagination'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        activeClassName={'active'}
        onPageChange={(event: { selected: number }) =>
          jumpToPage(event.selected + 1)
        }
        previousLabel={prevLabel()}
        nextLabel={nextLabel()}
        breakLabel={'...'}
      />
    </div>
  )
}

export default Pagination
