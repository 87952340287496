import { useState } from 'react'
import { useStyles } from 'hooks/useStyles'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Up } from 'images/up_arrow.svg'
import { ReactComponent as Down } from 'images/down_arrow.svg'
import SVGWrapper from 'components/modules/svg_wrapper'
import { Route, AccordionItem } from 'models/routes'

type Props = {
  route: Route
  closeSidePaneHandler: () => void
}

const MenuItem: React.FC<Props> = props => {
  const history = useHistory()
  const [styles, setStyles] = useStyles({
    page: 'components/modules/side_pane_menu/components/menu_items',
  })

  const [showAccordionItems, setShowAccordionItems] = useState<boolean>(false)

  const showAccordionItemsHandler = () => {
    if (showAccordionItems) return <Up />
    return <Down />
  }

  const clickHandler = (path: string) => history.push(path)

  if (!styles) return <></>

  return (
    <div className={styles.default.route}>
      <div
        className={styles.default.content}
        onClick={() => {
          if (props.route.path && props.route.accordion.length > 0) {
            clickHandler(props.route.path)
            props.closeSidePaneHandler()
            setShowAccordionItems(!showAccordionItems)
          } else if (props.route.path) {
            clickHandler(props.route.path)
            props.closeSidePaneHandler()
          } else {
            setShowAccordionItems(!showAccordionItems)
          }
        }}
      >
        <SVGWrapper
          icon={props.route.icon}
          width={'24'}
          height={'24'}
          stroke={'#4F4F4F'}
        />
        <div className={styles.default.label}>
          <span>{props.route.label}</span>
        </div>
        {props.route.accordion.length > 0 && showAccordionItemsHandler()}
      </div>
      {props.route.accordion.length > 0 && showAccordionItems && (
        <div className={styles.default.accordion_items}>
          {props.route.accordion.map((item: AccordionItem) => (
            <div
              key={item.label}
              className={styles.default.accordion_item}
              onClick={() => {
                clickHandler(item.path)
                props.closeSidePaneHandler()
              }}
            >
              <div className={styles.default.label}>
                <span>{item.label}</span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MenuItem
