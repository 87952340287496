import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { useStyles } from 'hooks/useStyles'
import 'react-image-crop/dist/ReactCrop.css'
import CropModal from 'components/modules/main_form/components/file_input/components/crop_modal'

type Props = {
  label: string
  fileType: 'IMAGE' | 'VIDEO' | 'VOICE' | 'CSV' | 'TXT'
  attachedFile: File | null | undefined
  onChangeHandler: (blob: File) => void
  onRemoveHandler?: () => void
  currentPreview?: string | File | Blob | null | undefined
  croppable?: boolean
}

const FileInput: React.FC<Props> = props => {
  const [styles, setStyles] = useStyles({
    page: 'components/modules/main_form/components/file_input',
  })

  const [preview, setPreview] = useState<string>()
  const [showCropModal, setShowCropModal] = useState<boolean>(false)

  const onSelectFile = (file: File) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setPreview(String(reader.result))
    })
    reader.readAsDataURL(file)
    setShowCropModal(true)
  }

  const createImagePreviewElement = (currentPreview: string | File | Blob) => {
    if (typeof currentPreview === 'string') {
      return <img src={currentPreview} />
    } else {
      const url = URL.createObjectURL(currentPreview)
      if (url) return <img src={url} />
    }

    return <></>
  }

  const createAudioPreviewElement = (currentPreview: string | File | Blob) => {
    if (typeof currentPreview === 'string') {
      return <audio src={currentPreview} controls />
    } else {
      const url = URL.createObjectURL(currentPreview)
      if (url) return <audio src={url} controls />
    }

    return <></>
  }

  if (!styles) return <></>

  return (
    <>
      {props.croppable && showCropModal && (
        <CropModal
          preview={preview}
          onChangeHandler={props.onChangeHandler}
          setShowCropModal={setShowCropModal}
        />
      )}
      <div className={styles.default.file_input_component}>
        <div className={styles.default.input_label}>{props.label}</div>
        <div className={styles.default.input_field}>
          {props.fileType === 'IMAGE' && props.currentPreview && (
            <div className={styles.default.input_preview}>
              {createImagePreviewElement(props.currentPreview)}
            </div>
          )}
          {props.fileType === 'VOICE' && props.currentPreview && (
            <div className={styles.default.input_preview}>
              {createAudioPreviewElement(props.currentPreview)}
            </div>
          )}
          {props.currentPreview && props.fileType === 'VOICE' ? (
            <>
              {props.onRemoveHandler && (
                <button onClick={props.onRemoveHandler}>
                  ファイルを削除する
                </button>
              )}
            </>
          ) : (
            <Dropzone
              onDrop={acceptedFiles =>
                props.fileType === 'IMAGE' && props.croppable
                  ? onSelectFile(acceptedFiles[0])
                  : props.onChangeHandler(acceptedFiles[0])
              }
            >
              {({ getRootProps, getInputProps }) => (
                <button {...getRootProps()}>
                  {props.attachedFile && (
                    <p>選択中: {props.attachedFile.name}</p>
                  )}
                  <input {...getInputProps()} />
                  <span>{props.label}をアップロード</span>
                </button>
              )}
            </Dropzone>
          )}
        </div>
      </div>
    </>
  )
}

export default FileInput
