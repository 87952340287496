export const imageValidation = (value: File | null) => {
  let message = '画像ファイルの入力値は正常です'
  let type = 'succeeded'
  if (!value) return { message, type }
  if (value.size === 0) {
    message = '添付されたファイルが破損している可能性があります'
    type = 'error'
    return { message, type }
  }
  if (
    value.type !== 'image/jpeg' &&
    value.type !== 'image/png' &&
    value.type !== 'image/heic'
  ) {
    message = '画像には JPG / PNG / HEIC のみアップロードできます'
    type = 'error'
    return { message, type }
  }
  return { message, type }
}
