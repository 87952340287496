import { useState, useMemo } from 'react'
import {
  ContributorMessage,
  EventBotMessage,
  PreviewContent,
  PreviewContents,
} from 'models/messages'
import { generateFileFromUrl } from 'utils/file_manager'

export function useDefaultPreviewContents(input: {
  message?: EventBotMessage
}): [
  defaultPreviewContents: PreviewContents,
  resetPreviewContents: () => void
] {
  const [imagePreviewContent, setImagePreviewContent] =
    useState<PreviewContent>({})
  const [videoPreviewContent, setVideoPreviewContent] =
    useState<PreviewContent>({})
  const [voicePreviewContent, setVoicePreviewContent] =
    useState<PreviewContent>({})

  const setDefaultPreviewContent = async (message: EventBotMessage) => {
    if (message.image) {
      const previewBlob = await generateFileFromUrl(
        message.image,
        message.imageFileName,
        'image'
      )
      setVideoPreviewContent({})
      setVoicePreviewContent({})
      setImagePreviewContent({ previewBlob, previewUrl: message.image })
    } else if (message.video) {
      const previewBlob = await generateFileFromUrl(
        message.video,
        message.videoFileName,
        'video'
      )
      setImagePreviewContent({})
      setVoicePreviewContent({})
      setVideoPreviewContent({ previewBlob, previewUrl: message.video })
    } else if (message.voice) {
      const previewBlob = await generateFileFromUrl(
        message.voice,
        message.voiceFileName,
        'voice'
      )
      setImagePreviewContent({})
      setVideoPreviewContent({})
      setVoicePreviewContent({ previewBlob, previewUrl: message.voice })
    }
  }

  const resetPreviewContents = () => {
    setImagePreviewContent({})
    setVideoPreviewContent({})
    setVoicePreviewContent({})
  }

  useMemo(() => {
    input.message && setDefaultPreviewContent(input.message)
  }, [input.message])

  return [
    {
      image: imagePreviewContent,
      video: videoPreviewContent,
      voice: voicePreviewContent,
    },
    resetPreviewContents,
  ]
}
