import {
  AnalysisDashboard,
  SubscriberCount,
  SubscribersTransition,
} from 'models/analysis_dashboards'

function subscribersTransitionsDeserializer(input: {
  data: any
}): SubscribersTransition[] {
  return input.data.map((item: any) => ({
    formattedSubscriptionDate: item.formatted_subscription_date,
    followSubscribers: item.follow_subscribers,
    trialSubscribers: item.trial_subscribers,
    billingSubscribers: item.billing_subscribers,
  }))
}

function subscriberCountsDeserializer(input: { data: any }): SubscriberCount {
  return {
    subscribers: input.data.subscribers,
  }
}

function analysisDashboardsDeserializer(input: {
  data: any
}): AnalysisDashboard {
  return {
    subscribersTransition: subscribersTransitionsDeserializer({
      data: input.data.subscribers_transition,
    }),
    followSubscribers: subscriberCountsDeserializer({
      data: input.data.follow_subscribers,
    }),
    trialSubscribers: subscriberCountsDeserializer({
      data: input.data.trial_subscribers,
    }),
    billingSubscribers: subscriberCountsDeserializer({
      data: input.data.billing_subscribers,
    }),
  }
}

export default analysisDashboardsDeserializer
