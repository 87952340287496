import { MeetingSchedules } from 'models/meeting_schedules'

function meetingSchedulesDeserializer(input: {
  data: any
  meta?: any
}): MeetingSchedules {
  return {
    data: input.data.map((item: any) => ({
      id: item.id,
      startAt: item.start_at,
      endAt: item.end_at,
      isEnded: item.is_ended,
      isReserved: item.is_reserved,
    })),
    meta: input.meta && {
      currentPage: input.meta.current_page,
      nextPage: input.meta.next_page,
      prevPage: input.meta.prev_page,
      totalCount: input.meta.total_count,
      totalPages: input.meta.total_pages,
    },
  }
}

export default meetingSchedulesDeserializer
