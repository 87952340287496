import { call, put, takeLatest } from 'redux-saga/effects'

import * as Action from 'actions/digital_contents/types'
import { DigitalContent, DigitalContents } from 'models/digital_contents'
import {
  getDigitalContents,
  createDigitalContent,
  getDigitalContent,
  updateDigitalContent,
  deleteDigitalContent,
} from 'actions/digital_contents'
import {
  getDigitalContentsFactory,
  getDigitalContentFactory,
  createDigitalContentFactory,
  updateDigitalContentFactory,
  deleteDigitalContentFactory,
} from 'services/digital_contents/api'

function* runGetDigitalContents(
  action: ReturnType<typeof getDigitalContents.started>
) {
  try {
    const result: DigitalContents = yield call(
      getDigitalContentsFactory(),
      action.payload
    )

    yield put(
      getDigitalContents.succeeded({
        digitalContents: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getDigitalContents.failed({ message: '' }))
  }
}

function* runGetDigitalContent(
  action: ReturnType<typeof getDigitalContent.started>
) {
  try {
    const result: DigitalContent = yield call(
      getDigitalContentFactory(),
      action.payload
    )

    yield put(
      getDigitalContent.succeeded({
        digitalContent: result,
        message: '',
      })
    )
  } catch (err) {
    yield put(getDigitalContent.failed({ message: '' }))
  }
}

function* runCreateDigitalContent(
  action: ReturnType<typeof createDigitalContent.started>
) {
  try {
    yield call(createDigitalContentFactory(), action.payload)

    yield put(
      createDigitalContent.succeeded({
        message: '',
      })
    )
  } catch (err) {
    yield put(createDigitalContent.failed({ message: '' }))
  }
}

function* runUpdateDigitalContent(
  action: ReturnType<typeof updateDigitalContent.started>
) {
  try {
    yield call(updateDigitalContentFactory(), action.payload)

    yield put(
      updateDigitalContent.succeeded({
        message: '',
      })
    )
  } catch (err) {
    yield put(updateDigitalContent.failed({ message: '' }))
  }
}

function* runDeleteDigitalContent(
  action: ReturnType<typeof deleteDigitalContent.started>
) {
  try {
    yield call(deleteDigitalContentFactory(), action.payload)

    yield put(
      deleteDigitalContent.succeeded({
        message: '',
      })
    )
  } catch (err) {
    yield put(deleteDigitalContent.failed({ message: '' }))
  }
}

export function* watchDigitalContentsSaga() {
  yield takeLatest(Action.STARTED_GET_DIGITAL_CONTENTS, runGetDigitalContents)
  yield takeLatest(Action.STARTED_GET_DIGITAL_CONTENT, runGetDigitalContent)
  yield takeLatest(
    Action.STARTED_CREATE_DIGITAL_CONTENT,
    runCreateDigitalContent
  )
  yield takeLatest(
    Action.STARTED_UPDATE_DIGITAL_CONTENT,
    runUpdateDigitalContent
  )
  yield takeLatest(
    Action.STARTED_DELETE_DIGITAL_CONTENT,
    runDeleteDigitalContent
  )
}
