import * as ActionType from 'actions/auth/types'

export const signUp = {
  started: (request: { name: string; email: string; password: string }) => ({
    type: ActionType.STARTED_SIGN_UP as typeof ActionType.STARTED_SIGN_UP,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_SIGN_UP as typeof ActionType.SUCCEEDED_SIGN_UP,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_SIGN_UP as typeof ActionType.FAILED_SIGN_UP,
    payload: response,
  }),
}

export const signIn = {
  started: (request: { email: string; password: string }) => ({
    type: ActionType.STARTED_SIGN_IN as typeof ActionType.STARTED_SIGN_IN,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_SIGN_IN as typeof ActionType.SUCCEEDED_SIGN_IN,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_SIGN_IN as typeof ActionType.FAILED_SIGN_IN,
    payload: response,
  }),
}

export const verify = {
  started: (request: { token: string }) => ({
    type: ActionType.STARTED_VERIFY as typeof ActionType.STARTED_VERIFY,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_VERIFY as typeof ActionType.SUCCEEDED_VERIFY,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_VERIFY as typeof ActionType.FAILED_VERIFY,
    payload: response,
  }),
}

export const sendResetPasswordEmail = {
  started: (request: { email: string }) => ({
    type: ActionType.STARTED_SEND_RESET_PASSWORD_EMAIL as typeof ActionType.STARTED_SEND_RESET_PASSWORD_EMAIL,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_SEND_RESET_PASSWORD_EMAIL as typeof ActionType.SUCCEEDED_SEND_RESET_PASSWORD_EMAIL,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_SEND_RESET_PASSWORD_EMAIL as typeof ActionType.FAILED_SEND_RESET_PASSWORD_EMAIL,
    payload: response,
  }),
}

export const changePassword = {
  started: (request: {
    password: string
    confirmationPassword: string
    headers: { uid: string; client: string; accessToken: string }
  }) => ({
    type: ActionType.STARTED_CHANGE_PASSWORD_EMAIL as typeof ActionType.STARTED_CHANGE_PASSWORD_EMAIL,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_CHANGE_PASSWORD_EMAIL as typeof ActionType.SUCCEEDED_CHANGE_PASSWORD_EMAIL,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CHANGE_PASSWORD_EMAIL as typeof ActionType.FAILED_CHANGE_PASSWORD_EMAIL,
    payload: response,
  }),
}

export type AuthAction =
  | ReturnType<typeof signUp.started>
  | ReturnType<typeof signUp.succeeded>
  | ReturnType<typeof signUp.failed>
  | ReturnType<typeof signIn.started>
  | ReturnType<typeof signIn.succeeded>
  | ReturnType<typeof signIn.failed>
  | ReturnType<typeof verify.started>
  | ReturnType<typeof verify.succeeded>
  | ReturnType<typeof verify.failed>
  | ReturnType<typeof sendResetPasswordEmail.started>
  | ReturnType<typeof sendResetPasswordEmail.succeeded>
  | ReturnType<typeof sendResetPasswordEmail.failed>
  | ReturnType<typeof changePassword.started>
  | ReturnType<typeof changePassword.succeeded>
  | ReturnType<typeof changePassword.failed>
