import { ManualBotMessage, ManualBotMessages } from 'models/manual_bot_messages'
import * as ActionType from 'actions/manual_bot_messages/types'

export const getManualBotMessages = {
  started: (request: { formData: FormData }) => ({
    type: ActionType.STARTED_GET_MANUAL_BOT_MESSAGES as typeof ActionType.STARTED_GET_MANUAL_BOT_MESSAGES,
    payload: request,
  }),
  succeeded: (response: {
    manualBotMessages: ManualBotMessages
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_MANUAL_BOT_MESSAGES as typeof ActionType.SUCCEEDED_GET_MANUAL_BOT_MESSAGES,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_MANUAL_BOT_MESSAGES as typeof ActionType.FAILED_GET_MANUAL_BOT_MESSAGES,
    payload: response,
  }),
}

export const getManualBotMessage = {
  started: (request: { formData: FormData }) => ({
    type: ActionType.STARTED_GET_MANUAL_BOT_MESSAGE as typeof ActionType.STARTED_GET_MANUAL_BOT_MESSAGE,
    payload: request,
  }),
  succeeded: (response: {
    manualBotMessage: ManualBotMessage
    message: string
  }) => ({
    type: ActionType.SUCCEEDED_GET_MANUAL_BOT_MESSAGE as typeof ActionType.SUCCEEDED_GET_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_GET_MANUAL_BOT_MESSAGE as typeof ActionType.FAILED_GET_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
}

export const createManualBotMessage = {
  started: (request: { formData: FormData }) => ({
    type: ActionType.STARTED_CREATE_MANUAL_BOT_MESSAGE as typeof ActionType.STARTED_CREATE_MANUAL_BOT_MESSAGE,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_CREATE_MANUAL_BOT_MESSAGE as typeof ActionType.SUCCEEDED_CREATE_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_CREATE_MANUAL_BOT_MESSAGE as typeof ActionType.FAILED_CREATE_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
}

export const updateManualBotMessage = {
  started: (request: { formData: FormData }) => ({
    type: ActionType.STARTED_UPDATE_MANUAL_BOT_MESSAGE as typeof ActionType.STARTED_UPDATE_MANUAL_BOT_MESSAGE,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_UPDATE_MANUAL_BOT_MESSAGE as typeof ActionType.SUCCEEDED_UPDATE_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_UPDATE_MANUAL_BOT_MESSAGE as typeof ActionType.FAILED_UPDATE_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
}

export const deleteManualBotMessage = {
  started: (request: { formData: FormData }) => ({
    type: ActionType.STARTED_DELETE_MANUAL_BOT_MESSAGE as typeof ActionType.STARTED_DELETE_MANUAL_BOT_MESSAGE,
    payload: request,
  }),
  succeeded: (response: { message: string }) => ({
    type: ActionType.SUCCEEDED_DELETE_MANUAL_BOT_MESSAGE as typeof ActionType.SUCCEEDED_DELETE_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
  failed: (response: { message: string }) => ({
    type: ActionType.FAILED_DELETE_MANUAL_BOT_MESSAGE as typeof ActionType.FAILED_DELETE_MANUAL_BOT_MESSAGE,
    payload: response,
  }),
}

export const resetAllStatusFlags = {
  started: () => ({
    type: ActionType.STARTED_RESET_ALL_STATUS_FLAGS as typeof ActionType.STARTED_RESET_ALL_STATUS_FLAGS,
  }),
  succeeded: () => ({
    type: ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS as typeof ActionType.SUCCEEDED_RESET_ALL_STATUS_FLAGS,
  }),
  failed: () => ({
    type: ActionType.FAILED_RESET_ALL_STATUS_FLAGS as typeof ActionType.FAILED_RESET_ALL_STATUS_FLAGS,
  }),
}

export type ManualBotMessagesAction =
  | ReturnType<typeof getManualBotMessages.started>
  | ReturnType<typeof getManualBotMessages.succeeded>
  | ReturnType<typeof getManualBotMessages.failed>
  | ReturnType<typeof getManualBotMessage.started>
  | ReturnType<typeof getManualBotMessage.succeeded>
  | ReturnType<typeof getManualBotMessage.failed>
  | ReturnType<typeof createManualBotMessage.started>
  | ReturnType<typeof createManualBotMessage.succeeded>
  | ReturnType<typeof createManualBotMessage.failed>
  | ReturnType<typeof updateManualBotMessage.started>
  | ReturnType<typeof updateManualBotMessage.succeeded>
  | ReturnType<typeof updateManualBotMessage.failed>
  | ReturnType<typeof deleteManualBotMessage.started>
  | ReturnType<typeof deleteManualBotMessage.succeeded>
  | ReturnType<typeof deleteManualBotMessage.failed>
  | ReturnType<typeof resetAllStatusFlags.started>
  | ReturnType<typeof resetAllStatusFlags.succeeded>
  | ReturnType<typeof resetAllStatusFlags.failed>
