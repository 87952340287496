// TAGS
export const STARTED_GET_TAGS = 'TAGS/STARTED_GET_TAGS'
export const SUCCEEDED_GET_TAGS = 'TAGS/SUCCEEDED_GET_TAGS'
export const FAILED_GET_TAGS = 'TAGS/FAILED_GET_TAGS'
export const STARTED_CREATE_TAG = 'TAGS/STARTED_CREATE_TAG'
export const SUCCEEDED_CREATE_TAG = 'TAGS/SUCCEEDED_CREATE_TAG'
export const FAILED_CREATE_TAG = 'TAGS/FAILED_CREATE_TAG'
export const STARTED_DELETE_TAG = 'TAGS/STARTED_DELETE_TAG'
export const SUCCEEDED_DELETE_TAG = 'TAGS/SUCCEEDED_DELETE_TAG'
export const FAILED_DELETE_TAG = 'TAGS/FAILED_DELETE_TAG'
export const STARTED_UPDATE_TAG_MANAGEMENT =
  'TAGS/STARTED_UPDATE_TAG_MANAGEMENT'
export const SUCCEEDED_UPDATE_TAG_MANAGEMENT =
  'TAGS/SUCCEEDED_UPDATE_TAG_MANAGEMENT'
export const FAILED_UPDATE_TAG_MANAGEMENT = 'TAGS/FAILED_UPDATE_TAG_MANAGEMENT'

// RESET_ALL_STATUS_FLAGS
export const STARTED_RESET_ALL_STATUS_FLAGS =
  'TAGS/STARTED_RESET_ALL_STATUS_FLAGS'
export const SUCCEEDED_RESET_ALL_STATUS_FLAGS =
  'TAGS/SUCCEEDED_RESET_ALL_STATUS_FLAGS'
export const FAILED_RESET_ALL_STATUS_FLAGS =
  'TAGS/FAILED_RESET_ALL_STATUS_FLAGS'
